import DisplayContext from "context/display-context";
import { useContextStrict } from "helpers/context-strict";
import CustomModal from "shared-components/modal";
import { ParticipantInvoice } from "types/invoice";
import Table from "./table";
import { useEffect, useState } from "react";
import Card from "./card";
import Divider from "shared-components/divider";
import AddInvoice from "./add-invoice";
import { ParticipantDetailType } from "../..";
import { ParseMoney } from "helpers/format-money";
import { useSpendInvoiceUpdateManyMutation } from "graphql/generated";
import ToastContext from "context/toast-context";
import { isNullOrEmpty } from "helpers/null-or-empty";

type BulkEditInvoicesProps = {
  isEditInvoicesOpen: boolean;
  toggleEditInvoices: () => void;
  invoices: ParticipantInvoice[];
  participant: ParticipantDetailType;
};

function BulkEditInvoices({
  isEditInvoicesOpen,
  toggleEditInvoices,
  invoices,
  participant,
}: BulkEditInvoicesProps) {
  const display = useContextStrict(DisplayContext);
  const toast = useContextStrict(ToastContext);

  const [editInvoices, setEditInvoices] = useState(invoices);

  const [bulkUpdateInvoices, { loading: loadingUpdate, data: bulkUpdateData }] =
    useSpendInvoiceUpdateManyMutation({
      refetchQueries: ["GetParticipantsDetails"],
      fetchPolicy: "network-only",
    });

  useEffect(() => {
    if (
      !loadingUpdate &&
      bulkUpdateData &&
      bulkUpdateData.spendInvoiceUpdateMany?.success
    ) {
      toast.setToast({
        message: "Invoices Updated Successfully",
        type: "success",
      });
    }
    // eslint-disable-next-line
  }, [loadingUpdate, bulkUpdateData]);

  useEffect(() => {
    setEditInvoices(invoices);
  }, [invoices]);

  const handleEdits = (index: number, dueDate: string, amount: string) => {
    const tempInvoices = [...editInvoices];
    tempInvoices.splice(index, 1, {
      ...editInvoices[index],
      dueDate,
      amount: ParseMoney(amount),
    });

    setEditInvoices(tempInvoices);
  };

  const handleSubmit = () => {
    const editedInvoices: {
      id: string;
      dueDate: string;
      amount: number;
    }[] = [];
    editInvoices.forEach((ei) => {
      const ogInvoice = invoices.find((i) => i.id === ei.id);
      if (ogInvoice?.amount !== ei.amount || ogInvoice.dueDate !== ei.dueDate) {
        editedInvoices.push({
          id: ei.id,
          dueDate: ei.dueDate,
          amount: ei.amount,
        });
      }
    });

    let hasError: boolean[] = [];

    editedInvoices.forEach((i) => {
      if (isNullOrEmpty(i.dueDate)) {
        hasError.push(true);
      }
    });

    if (hasError.length) {
      toast.setToast({
        message: "An invoice due date is empty.",
        type: "danger",
      });
    } else {
      bulkUpdateInvoices({
        variables: {
          input: {
            invoices: editedInvoices,
          },
        },
      });
    }
  };

  return (
    <CustomModal
      isOpen={isEditInvoicesOpen}
      toggle={toggleEditInvoices}
      title={"Bulk Edit Invoices"}
      btn1={{
        text: "Submit",
        onClick: handleSubmit,
      }}
      btn2={{
        text: "Cancel",
      }}
    >
      <div className="modal-card">
        <p className="font-semibold">Edit Invoices</p>
        {display.isDesktop && (
          <Table invoices={editInvoices} handleEdits={handleEdits} />
        )}
        {display.isMobile &&
          invoices.map((i, idx) => {
            return (
              <Card
                key={i.id}
                invoice={i}
                idx={idx}
                handleEdits={handleEdits}
              />
            );
          })}
        <Divider isVisibleOnMobile />
        <p className="font-semibold mt-4">Add Invoice</p>
        <AddInvoice participant={participant} />
      </div>
    </CustomModal>
  );
}

export default BulkEditInvoices;
