import DisplayContext from "context/display-context";
import GroupContext from "context/group-context";
import ProgramContext from "context/program-context";
import SeasonContext from "context/season-context";
import SplitIOContext from "context/splitio-context";
import ToastContext from "context/toast-context";
import UserContext from "context/user-context";
import { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export function useAppContexts() {
  const user = useContext(UserContext);
  const program = useContext(ProgramContext);
  const group = useContext(GroupContext);
  const season = useContext(SeasonContext);
  const display = useContext(DisplayContext);
  const toast = useContext(ToastContext);
  const split = useContext(SplitIOContext);
  const location = useLocation();
  const navigate = useNavigate();
  return {
    program,
    group,
    user,
    display,
    split,
    season,
    toast,
    location,
    navigate,
  };
}
