import { useLazyQuery, useMutation } from "@apollo/client";
import {
  SpendCheckImageSide,
  SpendGroup,
  SpendImageResponse,
  SpendInvoice,
  SpendSystemNotification,
  useSpendOrganizationCheckImageLazyQuery,
} from "graphql/generated";
import { CREATE_TRANSACTION } from "graphql/mutations/transactions";
import {
  GET_GROUPS_FILTERED,
  GET_GUARDIAN_GROUP_ROSTERS,
} from "graphql/queries/group";
import { GUARDIAN_INVOICES_V2 } from "graphql/queries/invoices";
import useModal from "hooks/use-modal";
import { useEffect, useState } from "react";
import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { ChangePaymentModal } from "shared-components/modal/change-payment/change-payment-modal";
import AddGroup from "shared-components/modal/groups/add-group";
import { MakePaymentModal } from "shared-components/modal/make-payment/make-payment-modal";
import {
  PaymentConfirmationModal,
  StripeResponse,
} from "shared-components/modal/payment-confirmation-modal";
import SessionTimeout from "shared-components/modal/session-timeout";
import {
  SnapAlert,
  SnapAlertAction,
  SnapAlertDescription,
  SnapAlertTitle,
  SnapButton,
  SnapLink,
} from "suit";
import { NavTabs } from "types/nav-tabs";
import { DisplayPageHeader } from "./display-page-header";
import AuthorizeAutoPay from "shared-components/modal/authorize-autopay";
import CustomModal from "shared-components/modal";
import SystemNotification from "shared-components/modal/system-notification";
import { useAppContexts } from "hooks/use-context";

function DisplayPage({ title, Tabs }: { title: string; Tabs: NavTabs[] }) {
  const { user, program, group, season, display, toast, split } =
    useAppContexts();

  const [visibleTabs, setVisibleTabs] = useState<NavTabs[]>([]);
  const [systemNotifications, setSystemNotifications] = useState<
    SpendSystemNotification[]
  >([]);
  const [hasAutoPay, setHasAutoPay] = useState(false);
  const [hasPayNow, setHasPayNow] = useState(false);
  const [closedAlerts, setClosedAlerts] = useState<string[]>([]);
  const performanceAlertId = "performance-delays";

  const [se456flag, setSe456flag] = useState(false);
  const [se698Flag, setSe698Flag] = useState(false);
  const [outageFlag, setOutageFlag] = useState(false);
  const [spe1741Webhook, setSpe1741Webhook] = useState<boolean | undefined>(
    undefined
  );
  const [spe1289DualApprovalApprover, setSPE1289DualApprovalApprover] =
    useState(false);
  const [se2176DisputesTab, setSe2176DisputesTab] = useState(false);

  useEffect(() => {
    setSe456flag(split?.isTreatmentOn(split.act.se456) ?? false);
    setSe698Flag(split?.isTreatmentOn(split.act.se698) ?? false);
    setSpe1741Webhook(split?.isTreatmentOn(split.act.spe1741));
    setSPE1289DualApprovalApprover(
      split?.isTreatmentOn(split.act.spe1288) ?? false
    );
    setSe2176DisputesTab(split?.isTreatmentOn(split.act.se2176) ?? false);
    setOutageFlag(split?.isTreatmentOn(split.act.thirdPtOutage) ?? false);
  }, [split]);

  const { isOpen: isSessionTimeoutOpen, toggle: toggleSessionTimeout } =
    useModal();
  const { isOpen: isNotificationsOpen, toggle: toggleNotifications } =
    useModal();
  const { isOpen: isCheckViewerOpen, toggle: toggleCheckViewer } = useModal();
  const [selectedCheckView, setSelectedCheckView] = useState<
    (SpendImageResponse & { imageId?: string | null }) | undefined
  >();
  const [getImage, { loading: imgLoading, data: imgData }] =
    useSpendOrganizationCheckImageLazyQuery();
  const [, setSearchParams] = useSearchParams();
  const [createTransaction, transactionCreateResponse] = useMutation(
    CREATE_TRANSACTION,
    {
      refetchQueries: [{ query: GET_GUARDIAN_GROUP_ROSTERS }],
      fetchPolicy: "network-only",
    }
  );

  const [
    getArchivedGroup,
    { loading: loadingArchiveGroups, data: archiveGroupData },
  ] = useLazyQuery(GET_GROUPS_FILTERED, {
    variables: {
      where: {
        archived: true,
      },
    },
    fetchPolicy: "network-only",
  });

  const [
    getInvoicesForAutoPay,
    { loading: loadingAutoPayInvoices, data: AutoPayInvoices },
  ] = useLazyQuery(GUARDIAN_INVOICES_V2);

  const location = useLocation();
  const navigate = useNavigate();
  const [showStripePaymentSuccess, setShowStripePaymentSuccess] =
    useState(false);
  const [stripeResponse, setStripeState] = useState<StripeResponse>({
    redirect_status: "spend_init",
  });

  const { isOpen: addGroupOpen, toggle: addGroupToggle } = useModal();
  const { isOpen: changePaymentOpen, toggle: changePaymentToggle } = useModal();
  const { isOpen: authorizeAutoPayOpen, toggle: authorizeAutoPayToggle } =
    useModal();
  let modalPromptTimer: NodeJS.Timeout | null = null;
  useEffect(() => {
    if (!imgLoading && imgData?.spendOrganizationCheckImage) {
      setSelectedCheckView(imgData.spendOrganizationCheckImage);
    }
  }, [imgLoading, imgData]);
  useEffect(() => {
    let newTabs: NavTabs[] = [];
    const isGroupStaff = user?._session?.role?.name === "group_staff";
    if (
      location.pathname.includes("/group") &&
      location.pathname !== "/people/group"
    ) {
      if (!group?.activeGroup) {
        newTabs = Tabs.slice(0, 2);
      } else if (group.isArchived) {
        const filteredTabs = ["Debit Cards", "Active", "Archived"];
        if (group.activeGroup.sharedAccount) {
          filteredTabs.push("Group Banking");
        }
        newTabs = Tabs.filter((tab) => !filteredTabs.includes(tab.name));
      } else if (isGroupStaff) {
        const filteredTabs: string[] = [];
        if (group.activeGroup.sharedAccount) {
          filteredTabs.push(...["Debit Cards", "Group Banking"]);
        }
        newTabs = Tabs.filter((tab) => !filteredTabs.includes(tab.name));
      } else {
        const filteredTabs = ["Active", "Archived"];
        if (!spe1289DualApprovalApprover || !user?.isApprover()) {
          filteredTabs.push("Approvals");
        }
        if (group.activeGroup.sharedAccount) {
          filteredTabs.push(...["Debit Cards", "Group Banking"]);
        }
        newTabs = Tabs.filter((tab) => !filteredTabs.includes(tab.name));
      }
    } else if (location.pathname.includes("programs/")) {
      if (isGroupStaff) {
        newTabs = Tabs.filter((tab) =>
          ["Program Banking", "Program Budget"].includes(tab.name)
        );
      } else {
        newTabs = Tabs;
      }
      const tabsToFilter: string[] = [];
      if (!spe1289DualApprovalApprover || !user?.isApprover()) {
        tabsToFilter.push("Approvals");
      }
      if (!se2176DisputesTab) {
        tabsToFilter.push("Disputes");
      }
      if (tabsToFilter.length) {
        newTabs = newTabs.filter((tab) => !tabsToFilter.includes(tab.name));
      }
    } else if (location.pathname.includes("settings/")) {
      if (isGroupStaff) {
        newTabs = Tabs.filter((tab) =>
          ["Notification Settings"].includes(tab.name)
        );
      } else {
        newTabs = Tabs;
      }
    } else {
      newTabs = Tabs;
    }
    setVisibleTabs(newTabs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, group, user?.isApprover()]);
  useEffect(() => {
    if (transactionCreateResponse.data && !toast?.isToastOpen) {
      setSearchParams("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionCreateResponse.data]);

  useEffect(() => {
    switch (location.pathname) {
      case "/dashboard":
      case "/past-due":
      case "/programs":
      case "/reporting":
      case "/people":
      case "/groups":
        if (Tabs) {
          Tabs[0].selected = true;
        }
        if (Tabs.length === 1) {
          navigate(`/${Tabs && Tabs[0].ParentName}${location.search}`);
        } else {
          navigate(
            `/${Tabs && Tabs[0].ParentName}/${Tabs && Tabs[0].ChildName}${
              location.search
            }`
          );
        }
        season?.handleSelectedSeason();
        group?.setAndStoreActiveGroup(undefined);
        season?.setAndStoreActiveSeason(undefined);
        group?.setIsArchived(false);
        break;
      case "/group":
        if (Tabs) {
          Tabs[0].selected = true;
        }

        if (group?.loadedGroup) {
          const getGroup = group?.getGroup();
          if (!getGroup) {
            getArchivedGroup();
          } else {
            group?.setAndStoreActiveGroup(getGroup);
            season?.handleSelectedSeason({ seasonsToSet: getGroup?.seasons });
          }

          navigate(
            `/${Tabs && Tabs[0].ParentName}/${Tabs && Tabs[0].ChildName}${
              location.search
            }`
          );
        }

        break;
      case "/groups/active":
        if (se456flag) {
          season?.handleSelectedSeason();
          group?.setAndStoreActiveGroup(undefined);
          season?.setAndStoreActiveSeason(undefined);
          group?.setIsArchived(false);
        }
        break;
      case "/settings":
        if (Tabs.length === 1) {
          Tabs[0].selected = true;
          navigate(`/${Tabs && Tabs[0].ParentName}${location.search}`);
        } else {
          let defaultIndex = 0;
          if (user?._session?.role?.name === "group_staff") {
            defaultIndex = 1;
          }
          Tabs[defaultIndex].selected = true;
          navigate(
            `/${Tabs && Tabs[defaultIndex].ParentName}/${
              Tabs && Tabs[defaultIndex].ChildName
            }${location.search}`
          );
        }
        season?.handleSelectedSeason();
        group?.setAndStoreActiveGroup(undefined);
        season?.setAndStoreActiveSeason(undefined);
        group?.setIsArchived(false);
        break;
      default:
        break;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    location.pathname,
    location.search,
    location.state,
    navigate,
    Tabs,
    group?.groups,
    group?.loadedGroup,
  ]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    if (queryParams.get("redirect_from")) {
      const redirect_from = queryParams.get("redirect_from");
      switch (redirect_from) {
        case "stripe":
          const data = atob(queryParams.get("data")!);
          const dataJson = JSON.parse(data);
          setStripeState({
            redirect_from: redirect_from,
            payment_intent: queryParams.get("payment_intent")!,
            payment_intent_client_secret: queryParams.get(
              "payment_intent_client_secret"
            )!,
            external_id: queryParams.get("external_id") ?? "",
            redirect_status: queryParams.get("redirect_status")!,
            data: dataJson.invoiceIds,
          });
          break;
        default:
          navigate(location.pathname);
      }
    }
  }, [location, navigate]);

  useEffect(() => {
    if (
      spe1741Webhook != null &&
      stripeResponse.redirect_status &&
      stripeResponse.redirect_from
    ) {
      if (stripeResponse.redirect_status === "succeeded") {
        setShowStripePaymentSuccess(stripeResponse.redirect_from === "stripe");
        const external_id = stripeResponse.external_id;
        const invoiceIds = stripeResponse.data;
        if (!spe1741Webhook) {
          createTransaction({
            variables: {
              input: {
                source: "payments",
                externalId: external_id,
                invoiceIdList: invoiceIds,
                direction: "debit",
                authorizedAt: new Date().toISOString(),
              },
            },
          });
        }
      } else if (stripeResponse.redirect_status !== "spend_init") {
        toast?.setToastProps({
          message: `There was an error with your transaction: ${stripeResponse.redirect_status}`,
          type: "danger",
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    stripeResponse.redirect_status,
    stripeResponse.redirect_from,
    spe1741Webhook,
  ]);

  useEffect(() => {
    display?.setDropdownOptions(program?.organization, group?.groups);
    if (group?.groups?.length !== 0) {
      group?.setGroups(group?.groups ?? []);
    } else {
      group?.loadedGroup && getArchivedGroup();
    }
    // eslint-disable-next-line
  }, [group?.groups, program?.organization]);

  useEffect(() => {
    if (
      !loadingArchiveGroups &&
      archiveGroupData &&
      archiveGroupData.spendGroupsFiltered &&
      group?.groups?.length === 0 &&
      user?._session?.role?.name === "group_staff"
    ) {
      let groups = archiveGroupData.spendGroupsFiltered.groups.map(
        (group: SpendGroup) => {
          return {
            ...group,
            currentSeason: group.seasons?.at(0),
          };
        }
      );
      if (groups.length) {
        group?.setGroups(groups);
        group?.setAndStoreActiveGroup(groups.at(0));
        season?.handleSelectedSeason({ seasonsToSet: groups.at(0).seasons });
      }
    }
    // eslint-disable-next-line
  }, [loadingArchiveGroups, archiveGroupData, group?.groups]);

  useEffect(() => {
    restartAutoReset();
    window.scrollTo(0, 0);
    window.addEventListener("mousemove", restartAutoReset);

    return () => {
      if (modalPromptTimer) {
        clearTimeout(modalPromptTimer);
        window.removeEventListener("mousemove", restartAutoReset);
      }
    };
    // eslint-disable-next-line
  }, [location.pathname]);

  const restartAutoReset = () => {
    if (modalPromptTimer) {
      clearTimeout(modalPromptTimer);
    }
    modalPromptTimer = setTimeout(() => {
      toggleSessionTimeout();
    }, 60000 * 15);
  };

  useEffect(() => {
    if (user?.isParent()) {
      getInvoicesForAutoPay();
    }
    setSystemNotifications(user?.getNotifications() || []);
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    if (systemNotifications.length) {
      toggleNotifications();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [systemNotifications]);

  useEffect(() => {
    if (AutoPayInvoices) {
      setHasAutoPay(
        AutoPayInvoices.spendGuardianInvoicesV2.invoices.some(
          (invoice: SpendInvoice) => invoice.isAutoPayAuthorized
        )
      );
      setHasPayNow(
        AutoPayInvoices.spendGuardianInvoicesV2.invoices.some(
          (invoice: SpendInvoice) => !invoice.isAutoPayAuthorized
        )
      );
    }
  }, [loadingAutoPayInvoices, AutoPayInvoices]);
  const updateClosed = (id: string) => {
    setClosedAlerts([...closedAlerts, id]);
  };

  return (
    <>
      <DisplayPageHeader
        title={title}
        tabs={Tabs}
        visibleTabs={visibleTabs}
        hasAutoPay={hasAutoPay}
        hasPayNow={hasPayNow}
        changePaymentToggle={changePaymentToggle}
        addGroupToggle={addGroupToggle}
        authorizeAutoPayToggle={authorizeAutoPayToggle}
      />
      {outageFlag && (
        <SnapAlert type="danger" className="mt-1">
          <SnapAlertTitle>
            We are experiencing a third-party outage. We apologize for any
            inconveniece this may cause.
          </SnapAlertTitle>
          <SnapAlertDescription>
            We hope for this to be resolved as quickly as possible. Please
            contact{" "}
            <a
              href="mailTo:spend.support@onsnap.com"
              target="_blank"
              rel="noreferrer"
              className="font-bold"
              style={{ color: "rgb(29, 78, 216)" }}
            >
              spend.support@onsnap.com
            </a>{" "}
            with any questions.
          </SnapAlertDescription>
        </SnapAlert>
      )}
      {se698Flag && (
        <SnapAlert
          hidden={closedAlerts.some((id) => id === performanceAlertId)}
          id={performanceAlertId}
          type="warning"
          className="mt-4 mx-1 lg:mx-5"
          close={true}
          onSnap-alert-closed={() => updateClosed(performanceAlertId)}
        >
          <SnapAlertTitle>
            We recently completed system maintenance and are committed to
            ensuring the accuracy of all historical transaction data.
          </SnapAlertTitle>
          <SnapAlertDescription>
            If you notice any discrepancies, please contact Snap! Spend support
            at{" "}
            <a
              href="mailTo:spend.support@onsnap.com"
              target="_blank"
              rel="noreferrer"
              className="font-bold"
              style={{ color: "rgb(29, 78, 216)" }}
            >
              spend.support@onsnap.com.
            </a>{" "}
            Thank you for your patience!
          </SnapAlertDescription>
        </SnapAlert>
      )}
      {program?.organization?.checkImageAccess &&
        program.organization.checkImageAccess.map((checkImage) => {
          return (
            <SnapAlert
              key={checkImage.id}
              hidden={closedAlerts.some((id) => id === checkImage.id)}
              id={checkImage.id}
              type="warning"
              className="mt-4 mx-1 lg:mx-5"
              close={true}
              onSnap-alert-closed={() => updateClosed(checkImage.id)}
            >
              <SnapAlertTitle>Spend Support Message</SnapAlertTitle>
              <SnapAlertDescription>
                <div>
                  Spend Support has provided you with temporary access to an
                  image of{" "}
                  {checkImage.checkType.toLowerCase().replace("_", " ")} id{" "}
                  {checkImage.checkId}.
                </div>
              </SnapAlertDescription>
              <SnapAlertAction slot="start">
                <SnapLink
                  onClick={() => {
                    getImage({
                      variables: {
                        input: {
                          id: checkImage.id,
                          side: SpendCheckImageSide.Front,
                        },
                      },
                    }).then(() => toggleCheckViewer());
                  }}
                >
                  Click here to view check
                </SnapLink>
              </SnapAlertAction>
            </SnapAlert>
          );
        })}
      {user?._session?.pendingInvites &&
        user._session.pendingInvites.map((invite) => {
          return !invite.isDismissed ? (
            <SnapAlert
              hidden={closedAlerts.some((id) => id === invite.id)}
              key={invite.id}
              type={"info"}
              className="mt-4 mx-1 lg:mx-5"
              close={true}
              onSnap-alert-closed={() => updateClosed(invite.id)}
            >
              {invite.type === "guardian" ? (
                <SnapAlertTitle className="capitalize">
                  Pending {invite.type} Invite for{" "}
                  {invite.rosterName ?? "participant"}
                </SnapAlertTitle>
              ) : (
                <SnapAlertTitle className="capitalize">
                  Pending {invite.type} Invite for{" "}
                  {invite.groupName ?? invite.organizationName}
                </SnapAlertTitle>
              )}
              <SnapAlertDescription>
                <a
                  href={`invite/${invite.type}/${invite.id}`}
                  className="font-bold"
                  style={{ color: "rgb(29, 78, 216)" }}
                >
                  Accept Invite
                </a>
              </SnapAlertDescription>
            </SnapAlert>
          ) : null;
        })}
      <Outlet />
      {display?.makePaymentDataModalOpen.isOpen && (
        <MakePaymentModal
          makePaymentDataModalOpen={display?.makePaymentDataModalOpen}
          setMakePaymentDataModalOpen={display?.setMakePaymentDataModalOpen}
        />
      )}
      {changePaymentOpen && (
        <ChangePaymentModal
          isOpen={changePaymentOpen}
          toggle={changePaymentToggle}
        />
      )}
      {authorizeAutoPayOpen && (
        <AuthorizeAutoPay
          isOpen={authorizeAutoPayOpen}
          toggle={authorizeAutoPayToggle}
        />
      )}
      <AddGroup
        isAddGroupOpen={addGroupOpen}
        addGroupToggle={addGroupToggle}
        signupAgreement={program?.settings?.signUpAgreement}
      />
      {isSessionTimeoutOpen && (
        <SessionTimeout
          isSessionTimeoutOpen={isSessionTimeoutOpen}
          toggleSessionTimeout={toggleSessionTimeout}
        />
      )}
      {systemNotifications.length > 0 && (
        <SystemNotification
          isSystemNotificationOpen={isNotificationsOpen}
          toggleSystemNotification={toggleNotifications}
          notifications={systemNotifications}
        />
      )}
      {user?.isParent() && showStripePaymentSuccess && (
        <PaymentConfirmationModal stripeResponse={stripeResponse} />
      )}
      {isCheckViewerOpen && (
        <CustomModal
          isOpen={isCheckViewerOpen}
          toggle={toggleCheckViewer}
          title={"Check Viewer"}
        >
          <div className="flex flex-col overflow-hidden h-full">
            <div className="flex w-full justify-center my-2">
              <SnapButton
                variant={"primary"}
                buttonType="button"
                className="mx-5"
                onClick={() => {
                  if (selectedCheckView?.imageId) {
                    getImage({
                      variables: {
                        input: {
                          id: selectedCheckView.imageId,
                          side: SpendCheckImageSide.Front,
                        },
                      },
                    });
                  }
                }}
              >
                Front
              </SnapButton>
              <SnapButton
                variant={"secondary"}
                buttonType="button"
                className="mx-5"
                onClick={() => {
                  if (selectedCheckView?.imageId) {
                    getImage({
                      variables: {
                        input: {
                          id: selectedCheckView.imageId,
                          side: SpendCheckImageSide.Back,
                        },
                      },
                    });
                  }
                }}
              >
                Back
              </SnapButton>
            </div>
            <div className="max-h-[450px] overflow-y-auto">
              {selectedCheckView?.imageContent && (
                <img
                  src={selectedCheckView?.imageContent}
                  alt={selectedCheckView.imageName || "image"}
                  fs-exclude
                />
              )}
            </div>
          </div>
        </CustomModal>
      )}
    </>
  );
}

export default DisplayPage;
