import {
  SpendGroupsOverview,
  useSpendGroupsOverviewDashboardQuery,
} from "graphql/generated";
import { DateFormatSupported, FormatDate } from "helpers/format-date";
import { FormatMoney } from "helpers/format-money";
import { getBadgeColor } from "helpers/status-color";
import { useEffect, useState } from "react";
import Divider from "shared-components/divider";
import ShowingResults from "shared-components/showing-results";
import Spinner from "shared-components/spinner";
import TableRowLabelValue, {
  TableValueStyle,
} from "shared-components/table-row-label-value";
import { SnapBadge, SnapIcon, SnapPagination, SnapTable } from "suit";
import { ITEMS_PER_PAGE } from "../../../constants";
import { useAppContexts } from "hooks/use-context";

function TeamsOverview() {
  const { group, navigate } = useAppContexts();

  const [page, setPage] = useState(0);
  const { loading, data } = useSpendGroupsOverviewDashboardQuery({
    variables: {
      pagination: {
        limit: 10,
        offset: page * 10,
      },
    },
  });
  const [groupOverview, setGroupOverview] = useState<
    (SpendGroupsOverview | null)[]
  >([]);

  useEffect(() => {
    if (data && data.spendGroupsOverviewDashboard?.overview) {
      setGroupOverview(data.spendGroupsOverviewDashboard.overview);
    }
  }, [loading, data]);

  const handleGroupSelectNavigate = (id: string) => {
    let groups = group?.groups;
    let foundGroup = groups?.filter((group) => group.id === id).at(0);
    group?.setAndStoreActiveGroup(foundGroup);
    if (foundGroup?.sharedAccount) {
      navigate("/groups/collections");
    } else {
      navigate("/groups/group-banking");
    }
  };

  return (
    <div className="card">
      <p className="text-lg font-semibold text-gray-500">Groups Overview</p>
      <ShowingResults
        totalNumOfResults={
          data?.spendGroupsOverviewDashboard?.count || groupOverview.length || 0
        }
        numOfResultsBeingDisplayed={
          groupOverview.length <= 10
            ? groupOverview.length
            : ITEMS_PER_PAGE * page + 10 >= groupOverview.length
            ? groupOverview.length
            : ITEMS_PER_PAGE * page + 10
        }
        startingNumOfResults={
          groupOverview.length === 0 ? 0 : ITEMS_PER_PAGE * page + 1
        }
      />
      {/* Moobile View */}
      <div className="lg:hidden">
        {loading && (
          <div className="my-5">
            <Spinner />
          </div>
        )}
        {!loading &&
          groupOverview &&
          groupOverview.map((item, idx) => {
            let seasonName =
              item?.seasonName ||
              `${FormatDate(
                item?.seasonStartAt ?? "",
                DateFormatSupported.Numbers
              )} - ${FormatDate(
                item?.seasonEndAt ?? "",
                DateFormatSupported.Numbers
              )}`;
            return (
              <div
                key={idx}
                className="border border-gray-200 p-4 rounded-lg mt-4 first:mt-0 last:mb-4"
              >
                <div className="flex justify-between">
                  <p
                    className="text-base font-medium text-blue-600 cursor-pointer"
                    onClick={() =>
                      handleGroupSelectNavigate(item?.groupId ?? "")
                    }
                  >
                    {item?.name}
                  </p>
                </div>
                <p className="text-xs font-medium text-gray-500">
                  {seasonName}
                </p>
                <Divider isVisibleOnMobile />
                <p className="text-xs font-semibold text-gray-500 mt-2">
                  Cards
                </p>
                {item?.sharedAccount
                  ? "<span> - </span>"
                  : item?.cards
                      ?.filter(
                        (c) =>
                          c?.status === "Active" || c?.status === "Inactive"
                      )
                      .map((card, idx) => {
                        return (
                          <div
                            key={idx}
                            className="flex justify-between first:mt- mt-2"
                          >
                            <div className="flex">
                              <SnapIcon
                                icon="credit-card-solid"
                                color="#64748B"
                              />
                              <p className="px-1">{card?.lastFour}</p>
                              <p>{card?.expiration}</p>
                            </div>
                            <div>
                              <SnapBadge
                                color={getBadgeColor(card?.status ?? "")}
                              >
                                {card?.status}
                              </SnapBadge>
                            </div>
                          </div>
                        );
                      })}
                <Divider isVisibleOnMobile />
                <table className="w-full">
                  <tbody>
                    <TableRowLabelValue
                      label={"Balance"}
                      value={
                        item?.sharedAccount
                          ? " - "
                          : FormatMoney(item?.balance ?? 0)
                      }
                      className="mt-2"
                    />
                    <TableRowLabelValue
                      label={"Collected"}
                      value={FormatMoney(item?.collected ?? 0)}
                      className="mt-2"
                    />
                    <TableRowLabelValue
                      label={"Past Due"}
                      value={FormatMoney(item?.pastDue ?? 0)}
                      className="mt-2"
                      valueStyle={
                        (item?.pastDue ?? 0) > 0
                          ? TableValueStyle.TEXTICON
                          : undefined
                      }
                    />
                  </tbody>
                </table>
              </div>
            );
          })}
      </div>
      {/* Web View */}
      <SnapTable>
        <table className="ui celled hidden lg:table mt-0">
          <thead>
            <tr>
              <th className="w-[25%]">Group Name</th>
              <th>Balance</th>
              <th>Collected</th>
              <th>Past Due</th>
              <th>Cards</th>
            </tr>
          </thead>
          <tbody>
            {loading && (
              <tr>
                <td colSpan={5} className="py-5">
                  <Spinner />
                </td>
              </tr>
            )}
            {!loading &&
              groupOverview &&
              groupOverview.map((item, idx) => {
                let seasonName =
                  item?.seasonName ||
                  `${FormatDate(
                    item?.seasonStartAt ?? "",
                    DateFormatSupported.Numbers
                  )} - ${FormatDate(
                    item?.seasonEndAt ?? "",
                    DateFormatSupported.Numbers
                  )}`;
                return (
                  <tr key={idx}>
                    <td>
                      <p
                        className="text-sm font-medium text-blue-600 cursor-pointer"
                        onClick={() =>
                          handleGroupSelectNavigate(item?.groupId ?? "")
                        }
                      >
                        {item?.name}
                      </p>
                      <p>{seasonName}</p>
                    </td>
                    <td>
                      {item?.sharedAccount
                        ? " - "
                        : FormatMoney(item?.balance ?? 0)}
                    </td>
                    <td>{FormatMoney(item?.collected ?? 0)}</td>
                    <td className="flex">
                      {(item?.pastDue ?? 0) > 0 && (
                        <SnapIcon icon="exclamation-solid" color="#EF4444" />
                      )}
                      <p className="self-center">
                        {FormatMoney(item?.pastDue ?? 0)}
                      </p>
                    </td>
                    <td className="w-[20%]">
                      {item?.sharedAccount ? (
                        <span> - </span>
                      ) : (
                        item?.cards
                          ?.filter(
                            (c) =>
                              c?.status === "Active" || c?.status === "Inactive"
                          )
                          .map((card, idx) => {
                            return (
                              <div key={idx} className="flex first:mt-0 mt-2">
                                <SnapIcon
                                  icon="credit-card-solid"
                                  size="sm"
                                  color="#64748B"
                                />
                                <p className="px-1">{card?.lastFour}</p>
                                <p className="pr-2">{card?.expiration}</p>
                                <SnapBadge
                                  color={getBadgeColor(card?.status ?? "")}
                                >
                                  {card?.status}
                                </SnapBadge>
                              </div>
                            );
                          })
                      )}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </SnapTable>
      <SnapPagination
        itemCount={data?.spendGroupsOverviewDashboard?.count || 0}
        currentPage={page}
        pageSize={ITEMS_PER_PAGE}
        onSnap-pagination-page-changed={(e) => {
          setPage(e.detail);
        }}
      />
    </div>
  );
}

export default TeamsOverview;
