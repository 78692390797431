import React from "react";
import { SnapToggle } from "suit";
import InputMask from "./input-mask";
import SnapCard from "assets/snap_card.svg";

export type approvalSettingsType = {
  title: string;
  subTitle: string;
  type: "limit" | "card" | "toggle";
  onClick: () => void;
  isToggled: boolean;
  amount: string;
  setAmount: React.Dispatch<React.SetStateAction<string>>;
};
function ApprovalSettingCard({
  title,
  subTitle,
  type,
  onClick,
  isToggled,
  amount,
  setAmount,
}: approvalSettingsType) {
  return (
    <>
      {type === "limit" && (
        <div className="border rounded-lg p-4">
          <div className="flex mb-4">
            <SnapToggle checked={isToggled} onSnap-button-toggle={onClick} />
            <div className="ml-4">
              <p className="text-sm font-medium">{title}</p>
              <p className="text-sm text-gray-500">{subTitle}</p>
            </div>
          </div>
          <InputMask
            label="Amount Limit"
            amount={amount}
            setAmount={setAmount}
            hasError={false}
            onChange={() => {}}
            disabled={!isToggled}
          />
        </div>
      )}
      {type === "card" && (
        <div className="border rounded-lg p-4">
          <div className="flex mb-4">
            <SnapToggle checked={isToggled} onSnap-button-toggle={onClick} />
            <div className="ml-4">
              <p className="text-sm font-medium">{title}</p>
              <p className="text-sm text-gray-500">{subTitle}</p>
              <img src={SnapCard} alt="Card Logo" className="mt-3 h-32" />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ApprovalSettingCard;
