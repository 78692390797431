import React, { useEffect, useState } from "react";
import FixedModal, { BtnState } from "./fixed-modal";
import { paymentMethodType } from "pages/payment-methods";
import {
  useSpendGuardianInvoicesLazyQuery,
  useSpendPaymentsCardDetachMutation,
  useSpendUserBankAccessTokenDeleteMutation,
} from "graphql/generated";
import {
  GUARDIAN_INVOICES_V2,
  INVOICE_AUTOPAY,
} from "graphql/queries/invoices";
import {
  GET_GUARDIAN_HIGHLIGHT_2,
  USER_BANK_ACCOUNTS_LIST,
} from "graphql/queries/user";
import { useAppContexts } from "hooks/use-context";
import { SpinnerContainer } from "shared-components/spinner";

type RemovePaymentMethodProps = {
  confirmDeleteOpen: boolean;
  toggleConfirmDelete: () => void;
  selectedPaymentMethod: paymentMethodType;
  setSelectedPaymentMethod: React.Dispatch<
    React.SetStateAction<paymentMethodType | undefined>
  >;
  getCards: any;
  totalPaymentMethods: number;
};

function RemovePaymentMethod({
  confirmDeleteOpen,
  toggleConfirmDelete,
  selectedPaymentMethod,
  setSelectedPaymentMethod,
  getCards,
  totalPaymentMethods,
}: RemovePaymentMethodProps) {
  const { program, split } = useAppContexts();

  useEffect(() => {
    setSe2143RequirePaymentMethod(
      split?.isTreatmentOn(split.act.se2143) ?? false
    );
  }, [split]);
  const [se2143RequirePaymentMethod, setSe2143RequirePaymentMethod] =
    useState(false);
  const [hasAssignedInvoices, setHasAssignedInvoices] = useState(false);
  const [hasSinglePaymentMethod] = useState(
    totalPaymentMethods === 1 &&
      program?.settings.requirePaymentMethod &&
      se2143RequirePaymentMethod
  );
  const [guardianInvoicesQuery, { data: giData, loading: giLoading }] =
    useSpendGuardianInvoicesLazyQuery();
  const [deleteCard] = useSpendPaymentsCardDetachMutation({
    refetchQueries: [
      {
        query: INVOICE_AUTOPAY,
      },
      {
        query: GUARDIAN_INVOICES_V2,
        fetchPolicy: "network-only",
      },
      {
        query: GET_GUARDIAN_HIGHLIGHT_2,
      },
    ],
  });
  const [deleteUserBank] = useSpendUserBankAccessTokenDeleteMutation({
    refetchQueries: [
      {
        query: USER_BANK_ACCOUNTS_LIST,
      },
      {
        query: INVOICE_AUTOPAY,
      },
      {
        query: GUARDIAN_INVOICES_V2,
        fetchPolicy: "network-only",
      },
      {
        query: GET_GUARDIAN_HIGHLIGHT_2,
      },
    ],
  });

  useEffect(() => {
    if (se2143RequirePaymentMethod && program?.settings.requirePaymentMethod) {
      guardianInvoicesQuery({
        fetchPolicy: "no-cache",
      });
    }
    // eslint-disable-next-line
  }, [confirmDeleteOpen]);

  useEffect(() => {
    if (!giLoading && giData?.spendGuardianInvoices?.invoices) {
      const unpaidInvoices = giData.spendGuardianInvoices.invoices.filter(
        (i) => !i.paid || i.balanceDue > 0
      );
      const assignedInvoices = unpaidInvoices.filter(
        (ui) => ui.paymentMethodId === selectedPaymentMethod.id
      );
      setHasAssignedInvoices(assignedInvoices.length > 0);
    }
  }, [giData, giLoading, selectedPaymentMethod.id]);

  const handleButtonState = () => {
    if (hasSinglePaymentMethod || hasAssignedInvoices) {
      return BtnState.HIDDEN;
    } else {
      return BtnState.BASE;
    }
  };

  return (
    <FixedModal
      isOpen={confirmDeleteOpen}
      toggle={toggleConfirmDelete}
      title={"Delete Payment Method"}
      icon={"exclamation-solid"}
      iconColor="#FEE2E2"
      btn1={{
        text: "Delete",
        onClick: () => {
          if (selectedPaymentMethod?.type === "ach") {
            deleteUserBank({
              variables: {
                counterpartyId: selectedPaymentMethod?.id,
                id: selectedPaymentMethod?.entityId,
              },
              refetchQueries: [
                {
                  query: USER_BANK_ACCOUNTS_LIST,
                },
              ],
            });
          } else if (
            selectedPaymentMethod?.type === "card" &&
            selectedPaymentMethod.id
          ) {
            deleteCard({
              variables: {
                input: {
                  paymentMethodId: selectedPaymentMethod.id,
                },
              },
            }).then(() => {
              getCards();
            });
          } else {
            console.log("Unhandled payment method");
            return;
          }
          toggleConfirmDelete();
        },
        btnStyle: "danger",
        btnState: se2143RequirePaymentMethod ? handleButtonState() : undefined,
      }}
      btn2={{
        text: "Cancel",
        onClick: () => {
          setSelectedPaymentMethod(undefined);
          toggleConfirmDelete();
        },
      }}
    >
      {giLoading && <SpinnerContainer loading={true} />}
      {!giLoading && hasAssignedInvoices && (
        <div className="text-center text-sm text-gray-500">
          <p>
            Payment method {selectedPaymentMethod.name} currently has invoices
            attached.
          </p>
          <p>Please update the invoices before removing payment method</p>
        </div>
      )}
      {!giLoading && hasSinglePaymentMethod && (
        <div className="text-center text-sm text-gray-500">
          <p>
            Please add an additional payment method before removing{" "}
            {selectedPaymentMethod.name}
          </p>
        </div>
      )}

      {!giLoading && !hasAssignedInvoices && !hasSinglePaymentMethod && (
        <div className="text-center text-sm text-gray-500">
          <p>Are you sure you want to delete {selectedPaymentMethod?.name}?</p>
          <br />
          <p>
            This will deauthorize any invoices this payment method was
            associated with.
          </p>
          <br />
          <p>
            Please note that pending transactions using this payment method may
            still go through.
          </p>
        </div>
      )}
    </FixedModal>
  );
}

export default RemovePaymentMethod;
