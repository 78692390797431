import { getDatePickerValue, setDatePickerValue } from "helpers/date-picker";
import { FormatMoney } from "helpers/format-money";
import { useState } from "react";
import DatePicker from "shared-components/date-picker";
import Divider from "shared-components/divider";
import InputMask from "shared-components/input-mask";
import { ParticipantInvoice } from "types/invoice";

type CardProps = {
  invoice: ParticipantInvoice;
  idx: number;
  handleEdits: (index: number, dueDate: string, amount: string) => void;
};

function Card({ invoice, idx, handleEdits }: CardProps) {
  const [amount, setAmount] = useState(
    FormatMoney(invoice.amount).replace("$", "")
  );

  return (
    <div key={invoice.id} className="card rounded-lg">
      <div>
        <p className="text-sm text-gray-500">Description</p>
        <p className="text-md font-medium">{invoice.description}</p>
      </div>
      <div className="mt-3">
        <p className="text-sm text-gray-500">Group Name</p>
        <p className="text-md font-medium">{invoice.groupName}</p>
      </div>
      <div className="mt-3">
        <p className="text-sm text-gray-500">Season Name</p>
        <p className="text-md font-medium">{invoice.seasonName}</p>
      </div>
      <Divider isVisibleOnMobile />
      <DatePicker
        className="mt-3"
        label={"Due Date"}
        date={getDatePickerValue(invoice.dueDate ?? "")}
        setDate={(e) => {
          const newDate = setDatePickerValue(e);
          handleEdits(idx, newDate, amount);
        }}
      />
      <div className="mt-3">
        <InputMask
          amount={amount}
          setAmount={setAmount}
          hasError={false}
          onChange={() => {
            handleEdits(idx, invoice.dueDate, amount);
          }}
        />
      </div>
    </div>
  );
}

export default Card;
