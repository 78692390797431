import { SpendGroup, useSpendGroupUnArchiveMutation } from "graphql/generated";
import CustomModal, { BtnState } from "..";
import { SnapAlert, SnapAlertTitle } from "suit";
import { useContextStrict } from "helpers/context-strict";
import ToastContext from "context/toast-context";
import { useEffect, useState } from "react";

type UnarchiveGroupProps = {
  unarchiveOpen: boolean;
  unarchiveToggle: () => void;
  selectedGroup: SpendGroup | undefined;
};

function UnarchiveGroup({
  unarchiveOpen,
  unarchiveToggle,
  selectedGroup,
}: UnarchiveGroupProps) {
  const toast = useContextStrict(ToastContext);

  const [buttonState, setButtonState] = useState<BtnState>(BtnState.BASE);

  const [unarchiveGroup, { loading, data }] = useSpendGroupUnArchiveMutation();

  useEffect(() => {
    if (!loading && data && data.spendGroupUnArchive) {
      toast?.setToast({
        message: "Group Successfully Unarchived",
        type: "success",
      });
      unarchiveToggle();
      setButtonState(BtnState.BASE);
    }
    // eslint-disable-next-line
  }, [loading, data, toast]);

  const handleUnarchiveGroup = () => {
    setButtonState(BtnState.DISABLED);
    if (selectedGroup?.id) {
      unarchiveGroup({
        variables: {
          spendGroupUnArchiveId: selectedGroup.id,
        },
        refetchQueries: ["SpendGroupsFiltered", "SpendGroupsOverviewDashboard"],
        fetchPolicy: "no-cache",
        errorPolicy: "all",
      });
    } else {
      toast?.setToast({
        message: "Active group not set, please refresh the page",
        type: "warning",
      });
      setButtonState(BtnState.BASE);
    }
  };
  return (
    <CustomModal
      isOpen={unarchiveOpen}
      toggle={unarchiveToggle}
      title={"Unarchive Group"}
      btn1={{
        text: "Submit",
        onClick: handleUnarchiveGroup,
        btnState: buttonState,
      }}
      btn2={{
        text: "Cancel",
        onClick: unarchiveToggle,
      }}
    >
      <div className="modal-card">
        <SnapAlert type="warning" right-link-text="Details" right-link-href="">
          <SnapAlertTitle>
            Are you sure you want to archive this Group? {selectedGroup?.name}
          </SnapAlertTitle>
        </SnapAlert>
      </div>
    </CustomModal>
  );
}

export default UnarchiveGroup;
