import useModal from "hooks/use-modal";
import { useEffect, useState } from "react";
import { SnapDropDown, SnapIcon, SnapIndicator } from "suit";
import SendEmail from "./modal/send-email";
import { DropdownMenuItem } from "@snap-mobile/snap-ui/dist/types/utils";
import ArchiveParticipant from "pages/groups/rosters/modals/archive-participant";
import {
  PaymentScheduleStatus,
  useSpendUserExportEmailsLazyQuery,
  useSpendUserInviteResendMutation,
} from "graphql/generated";
import { useContextStrict } from "helpers/context-strict";
import SeasonContext from "context/season-context";
import ToastContext from "context/toast-context";
import { exportToCsv } from "helpers/export-csv";
import AddInvoice from "pages/groups/collections/modals/add-invoice";
import UnarchiveParticipant from "pages/groups/rosters/modals/unarchive-participant";

type BulkActionsV2Props = {
  selectedItems: {
    email?: string;
    groupRosterId?: string;
    userId?: string;
    groupId?: string;
    seasonId?: string;
    inviteId?: string;
  }[];
  handleDeselectAll: () => void;
  type: "rosters" | "collections" | "programs" | "groups" | "participants";
  selectedTab?: number;
};

function BulkActionsV2({
  selectedItems,
  handleDeselectAll,
  type,
  selectedTab,
}: BulkActionsV2Props) {
  const season = useContextStrict(SeasonContext);
  const toast = useContextStrict(ToastContext);

  const { isOpen: actionMenuOpen, toggle: actionMenuToggle } = useModal();
  const { isOpen: isSendEmailOpen, toggle: toggleSendEmail } = useModal();
  const { isOpen: isArchivedOpen, toggle: toggleArchive } = useModal();
  const { isOpen: isUnarchiveOpen, toggle: toggleUnarchive } = useModal();
  const { isOpen: isAddInvoiceOpen, toggle: toggleAddInvoice } = useModal();

  const [initBtn] = useState({
    text: "Send Email",
    action: toggleSendEmail,
  });
  const [menuItems, setMenuItems] = useState<DropdownMenuItem[]>([]);

  const [
    ExportEmails,
    { loading: loadingExportedEmails, data: exportEmailsData },
  ] = useSpendUserExportEmailsLazyQuery();

  const [reSendInvitesCollection] = useSpendUserInviteResendMutation({
    refetchQueries:
      type === "rosters"
        ? ["spendGroupRostersBySeasonV2"]
        : ["SpendRostersFiltered", "SpendInvitesFiltered"],
    fetchPolicy: "network-only",
    onCompleted: () => {
      toast.setToast({
        message: "Invite(s) has been sent",
        type: "success",
      });
      handleDeselectAll();
    },
  });

  useEffect(() => {
    if (exportEmailsData && exportEmailsData.spendUserExportEmails) {
      exportToCsv(exportEmailsData.spendUserExportEmails);
      handleDeselectAll();
    }
  }, [loadingExportedEmails, exportEmailsData, handleDeselectAll]);

  useEffect(() => {
    let options = [];
    if (type === "rosters" && selectedTab === 0) {
      options.push({
        name: "Archive Participant",
        text: "Archive Participant",
        value: "Archive Participant",
      });
    } else if (type === "rosters" && selectedTab === 1) {
      options.push({
        name: "Unarchive Participant",
        text: "Unarchive Participant",
        value: "Unarchive Participant",
      });
    }

    if (type === "collections") {
      options.push({
        name: "Add Invoice",
        text: "Add Invoice",
        value: "Add Invoice",
      });
    } else if (
      (type === "rosters" && selectedTab === 0) ||
      type === "programs" ||
      type === "groups" ||
      type === "participants"
    ) {
      options.push({
        name: "Export Email Address",
        text: "Export Email Address",
        value: "Export Email Address",
      });
      options.push({
        name: "Send Invite",
        text: "Send Invite",
        value: "Send Invite",
      });
    }
    setMenuItems(options);
  }, [selectedItems, type, selectedTab]);

  const handleExport = () => {
    let emails: string[] = selectedItems?.map((item) => item.email!) ?? [];
    ExportEmails({
      variables: {
        input: {
          emails: emails,
        },
      },
    });
  };

  const handleCheckActiveUserForResend = () => {
    if (
      type === "rosters" &&
      season?.selectedSeason?.paymentScheduleStatus !==
        PaymentScheduleStatus.Published
    ) {
      toast.setToast({
        message: "Can not send invites while payment schedule is not published",
        type: "warning",
      });
    } else {
      selectedItems?.forEach((user) => {
        reSendInvitesCollection({
          variables: {
            input: {
              id: user.inviteId,
              groupId: user.groupId ?? "",
              email: user.email ?? "",
              userId: user.userId ?? "",
              seasonId: user.seasonId ?? "",
              groupRosterId: user.groupRosterId ?? "",
            },
          },
        });
      });
      toast.setToast({
        message: "Resending invites - this may take a minute",
        type: "warning",
      });
    }
  };

  return (
    <>
      <div
        className={`${
          actionMenuOpen ? "static lg:fixed" : "fixed"
        }  flex lg:bottom-0 bottom-14 left-0 z-[999] bg-white border border-t border-black w-full py-4`}
      >
        <div className="flex mr-auto cursor-pointer items-center lg:ml-80 pl-2 lg:pl-0">
          <SnapIndicator text={selectedItems.length} color="gray" size="sm" />
          <p
            className="ml-3 text-blue-600 border-l border-gray-200 pl-4 font-bold"
            onClick={handleDeselectAll}
          >
            Deselect All
          </p>
        </div>
        <div className="flex cursor-pointer items-center mr-4">
          <SnapIcon icon="mail-solid" color="#3B82F6" size="xs"></SnapIcon>
          <p
            className="ml-3 text-blue-600 mr-4 font-bold"
            onClick={initBtn.action}
          >
            {initBtn.text}
          </p>
          <SnapDropDown
            onClick={actionMenuToggle}
            id="mobile-menu"
            size="lg"
            modalType="drawer"
            minimal
            menuPosition="top"
            options={menuItems}
            onSnap-dropdown-item-selected={(e) => {
              switch (e.detail.name) {
                case "Send Invite":
                  handleCheckActiveUserForResend();
                  break;
                case "Export Email Address":
                  handleExport();
                  break;
                case "Archive Participant":
                  toggleArchive();
                  break;
                case "Unarchive Participant":
                  toggleUnarchive();
                  break;
                case "Add Invoice":
                  toggleAddInvoice();
                  break;
              }
            }}
          />
        </div>
      </div>
      {isSendEmailOpen && (
        <SendEmail
          sendEmailOpen={isSendEmailOpen}
          sendEmailToggle={toggleSendEmail}
          selectedEmails={selectedItems.map((item) => item.email!)}
          role={
            type === "programs" || type === "groups" ? "Staff" : "Participants"
          }
        />
      )}
      {isArchivedOpen && (
        <ArchiveParticipant
          archiveParticipantOpen={isArchivedOpen}
          archiveParticipantToggle={toggleArchive}
          groupRosterIds={selectedItems.map((item) => item.groupRosterId!)}
          deselectAllAction={handleDeselectAll}
        />
      )}
      {isUnarchiveOpen && (
        <UnarchiveParticipant
          unarchiveParticipantOpen={isUnarchiveOpen}
          unarchiveParticipantToggle={toggleUnarchive}
          groupRosterIds={selectedItems.map((item) => item.groupRosterId!)}
          deselectAllAction={handleDeselectAll}
        />
      )}
      {isAddInvoiceOpen && (
        <AddInvoice
          isAddPlayerInvoiceOpen={isAddInvoiceOpen}
          addPlayerInvoiceToggle={toggleAddInvoice}
          groupRosterIds={selectedItems.map((item) => item.groupRosterId!)}
          deselectAllAction={handleDeselectAll}
        />
      )}
    </>
  );
}

export default BulkActionsV2;
