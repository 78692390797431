import DisplayContext from "context/display-context";
import GroupContext from "context/group-context";
import ProgramContext from "context/program-context";
import SeasonContext from "context/season-context";
import SplitIOContext from "context/splitio-context";
import UserContext from "context/user-context";
import useDisplay from "hooks/use-display";
import useGroup from "hooks/use-group";
import { ActiveProgram } from "hooks/use-program";
import useSeason from "hooks/use-season";
import useSplitIO from "hooks/use-splitio";
import { ActiveUser } from "hooks/use-user";
import { ReactNode, useEffect, useState } from "react";

interface ProvidersProps {
  children: ReactNode;
  user: ActiveUser;
  display: ReturnType<typeof useDisplay>;
  program: ActiveProgram;
  group: ReturnType<typeof useGroup>;
}

const Providers = ({
  children,
  user,
  display,
  program,
  group,
}: ProvidersProps) => {
  const season = useSeason();
  const splits = useSplitIO(program?.organization?.id, user.getUserId());

  const [splitsReady, setSplitsReady] = useState(false);
  useEffect(() => {
    splits.isReady?.then(() => setSplitsReady(true));
  }, [setSplitsReady, splits]);
  return (
    <UserContext.Provider value={user}>
      <ProgramContext.Provider value={program}>
        <SplitIOContext.Provider value={splits}>
          {splitsReady && (
            <GroupContext.Provider value={group}>
              <SeasonContext.Provider value={season}>
                <DisplayContext.Provider value={display}>
                  {children}
                </DisplayContext.Provider>
              </SeasonContext.Provider>
            </GroupContext.Provider>
          )}
        </SplitIOContext.Provider>
      </ProgramContext.Provider>
    </UserContext.Provider>
  );
};

export default Providers;
