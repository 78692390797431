import Divider from "shared-components/divider";
import { useContext, useEffect, useState } from "react";
import ProgramContext from "context/program-context";
import { SnapButton, SnapToggle } from "suit";
import { FormatMoney, ParseMoney } from "helpers/format-money";
import { useMutation } from "@apollo/client";
import { UPDATE_ORG_SETTINGS } from "graphql/mutations/organization";
import { GET_ORGANIZATION_SETTINGS } from "graphql/queries/organization";
import ToastContext from "context/toast-context";
import ApprovalSettingCard, {
  approvalSettingsType,
} from "shared-components/approval-setting-card";
import DisplayContext from "context/display-context";

function Approvals() {
  const program = useContext(ProgramContext);
  const display = useContext(DisplayContext);
  const toast = useContext(ToastContext);

  const [internalToggle, setInternalToggle] = useState(false);
  const [internalAmount, setInternalAmount] = useState("");
  const [externalToggle, setExternalToggle] = useState(false);
  const [externalAmount, setExternalAmount] = useState("");
  const [sendCheckToggle, setSendCheckToggle] = useState(false);
  const [sendCheckAmount, setSendCheckAmount] = useState("");
  const [debitCardToggle, setDebitCardToggle] = useState(false);
  const [sendAchToggle, setSendAchToggle] = useState(false);
  const [sendAchAmount, setSendAchAmount] = useState("");
  const [approversRequired, setApproversRequired] = useState(false);

  const [updateSettings, { loading, data }] = useMutation(UPDATE_ORG_SETTINGS, {
    refetchQueries: [{ query: GET_ORGANIZATION_SETTINGS }],
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    const settings = program?.settings;
    setInternalToggle(settings?.internalTransferApproval ?? false);
    setExternalToggle(settings?.externalTransferApproval ?? false);
    setSendCheckToggle(settings?.sendCheckApproval ?? false);
    setDebitCardToggle(settings?.debitCardApproval ?? false);
    setInternalAmount(
      FormatMoney(settings?.internalTransferLimit).replace("$", "")
    );
    setExternalAmount(
      FormatMoney(settings?.externalTransferLimit).replace("$", "")
    );
    setSendCheckAmount(FormatMoney(settings?.sendCheckLimit).replace("$", ""));
    setSendAchToggle(settings?.sendAchApproval ?? false);
    setSendAchAmount(FormatMoney(settings?.sendAchLimit).replace("$", ""));
    setApproversRequired(settings?.approversRequired === -1);
  }, [program?.settings]);

  useEffect(() => {
    if (!loading && data && data.spendSettingsUpdate) {
      toast?.setToast({
        message: "Settings updated successfully",
        type: "success",
      });
    }
    // eslint-disable-next-line
  }, [loading, data]);

  const handleSave = () => {
    let input = {
      internalTransferApproval: internalToggle,
      internalTransferLimit: ParseMoney(internalAmount),
      externalTransferApproval: externalToggle,
      externalTransferLimit: ParseMoney(externalAmount),
      sendCheckApproval: sendCheckToggle,
      sendCheckLimit: ParseMoney(sendCheckAmount),
      sendAchApproval: sendAchToggle,
      sendAchLimit: ParseMoney(sendAchAmount),
      debitCardApproval: debitCardToggle,
      approversRequired: approversRequired ? -1 : 1,
    };

    updateSettings({
      variables: {
        input,
      },
    });
  };

  const approvalSettings: approvalSettingsType[] = [
    {
      title: "Transfers - Internal",
      subTitle:
        "Internal transfer amounts greater than or equal to the amount limit will require approval.",
      type: "limit",
      onClick: () => {
        setInternalToggle(!internalToggle);
      },
      isToggled: internalToggle,
      amount: internalAmount,
      setAmount: setInternalAmount,
    },
    {
      title: "Transfers - External",
      subTitle:
        "External transfer amounts greater than or equal to the amount limit will require approval.",
      type: "limit",
      onClick: () => {
        setExternalToggle(!externalToggle);
      },
      isToggled: externalToggle,
      amount: externalAmount,
      setAmount: setExternalAmount,
    },
    {
      title: "Send Checks",
      subTitle:
        "Check amounts greater than or equal to the amount limit will require approval.",
      type: "limit",
      onClick: () => {
        setSendCheckToggle(!sendCheckToggle);
      },
      isToggled: sendCheckToggle,
      amount: sendCheckAmount,
      setAmount: setSendCheckAmount,
    },
    {
      title: "Send ACH",
      subTitle:
        "ACH credit amounts greater than or equal to the amount limit will require approval.",
      type: "limit",
      onClick: () => {
        setSendAchToggle(!sendAchToggle);
      },
      isToggled: sendAchToggle,
      amount: sendAchAmount,
      setAmount: setSendAchAmount,
    },
    {
      title: "New Debit Cards",
      subTitle: " All cards ordered will require approval.",
      type: "card",
      onClick: () => {
        setDebitCardToggle(!debitCardToggle);
      },
      isToggled: debitCardToggle,
      amount: "",
      setAmount: () => {},
    },
    {
      title: "Require All Approvers",
      subTitle: "Require all approvers to approve an approval",
      type: "toggle",
      onClick: () => {
        setDebitCardToggle(!debitCardToggle);
      },
      isToggled: debitCardToggle,
      amount: "",
      setAmount: () => {},
    },
  ];

  return (
    <div className="card">
      <p className="text-lg font-semibold">Approvals</p>
      <Divider />
      <div className="flex my-4">
        <SnapToggle
          checked={approversRequired}
          onSnap-button-toggle={(e) => {
            setApproversRequired(e.detail.checked);
          }}
        />
        <div className="ml-4">
          <p className="text-sm font-medium">Require All Approvers</p>
          <p className="text-sm text-gray-500">
            Require all approvers to approve an approval
          </p>
        </div>
      </div>
      <div className="grid lg:grid-cols-2 grid-cols-1 gap-4 mt-6">
        {approvalSettings.map((approval, idx) => {
          return (
            <div key={approval.title + idx}>
              <ApprovalSettingCard
                title={approval.title}
                subTitle={approval.subTitle}
                type={approval.type}
                onClick={approval.onClick}
                isToggled={approval.isToggled}
                amount={approval.amount}
                setAmount={approval.setAmount}
              />
            </div>
          );
        })}
      </div>
      <SnapButton
        variant="primary"
        className="flex justify-end mt-4"
        size="lg"
        onClick={handleSave}
        fullWidth={display?.isMobile}
      >
        Save
      </SnapButton>
    </div>
  );
}

export default Approvals;
