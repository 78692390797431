import { SnapTable } from "suit";
import { ParticipantInvoice } from "types/invoice";
import TableRow from "./table-row";

type TableProps = {
  invoices: ParticipantInvoice[];
  handleEdits: (index: number, dueDate: string, amount: string) => void;
};

function Table({ invoices, handleEdits }: TableProps) {
  return (
    <SnapTable>
      <table className="ui table-fixed">
        <thead>
          <tr>
            <th>Description</th>
            <th>Group Name</th>
            <th>Season Name</th>
            <th>Due Date</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          {invoices.map((i, idx) => {
            return (
              <TableRow
                key={i.id}
                invoice={i}
                idx={idx}
                handleEdits={handleEdits}
              />
            );
          })}
        </tbody>
      </table>
    </SnapTable>
  );
}

export default Table;
