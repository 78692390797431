import { gql } from "@apollo/client";

export const USER_BANK_ACCOUNTS = gql`
  query ExternalAccounts {
    spendUserBankAccounts {
      externalAccounts {
        id
        accountId
        availableBalance
        currentBalance
        mask
        name
        officialName
        subtype
        type
        institution {
          id
          name
        }
      }
      status
    }
  }
`;
export const USER_BANK_ACCOUNTS_LIST = gql`
  query ExternalAccountsList {
    spendUserBankAccountsList {
      externalAccounts {
        id
        entityId
        counterpartyId
        details {
          accountId
          availableBalance
          currentBalance
          mask
          name
          officialName
          subtype
          type
          institution {
            id
            name
          }
        }
        status
      }
    }
  }
`;

export const GET_GUARDIAN_HIGHLIGHT = gql`
  query SpendGuardianHighlight {
    spendGuardianHighlight {
      activeGroups {
        dueDate
        group {
          accountId
          archivedAt
          createdAt
          hasAccount
          id
          isArchived
          name
          organizationId
          programId
          seasons {
            id
            name
            startDateAt
            endDateAt
            isBudgetShared
            roster {
              id
              name
              email
              rosterEmail
            }
          }
          latestSeason {
            id
            name
            startDateAt
            endDateAt
            isBudgetShared
          }
        }
      }
      comingSoonInvoices {
        invoiceAmountDue
        invoiceDescription
        invoiceDueDate
        invoiceId
        invoiceStatus
        rosterName
        seasonName
        invoiceAutoPayAuthorized
        invoicePaymentMethod
        groupName
        isOptional
        optedIn
      }
      counts {
        activeGroups
        comingSoonInvoices
      }
    }
  }
`;
export const GET_GUARDIAN_HIGHLIGHT_2 = gql`
  query SpendGuardianHighlight2 {
    spendGuardianHighlight {
      unauthorizedInvoices {
        invoiceId
        invoiceDescription
        seasonName
        rosterName
        invoiceDueDate
        invoiceAmountDue
        invoiceStatus
        invoiceAutoPayAuthorized
        invoiceAutoPayStopped
        invoicePaymentMethod
        groupName
        isOptional
        optedIn
        groupRosterId
        groupId
        isAuthorized
        seasonId
      }
      activeGroups {
        dueDate
        group {
          accountId
          archivedAt
          createdAt
          hasAccount
          id
          isArchived
          name
          organizationId
          programId
          seasons {
            id
            name
            startDateAt
            endDateAt
            isBudgetShared
            roster {
              id
              name
              email
              rosterEmail
            }
          }
          latestSeason {
            id
            name
            startDateAt
            endDateAt
            isBudgetShared
          }
        }
      }
      comingSoonInvoices {
        invoiceAmountDue
        invoiceDescription
        invoiceDueDate
        invoiceId
        invoiceStatus
        rosterName
        seasonName
        invoiceAutoPayStopped
        invoiceAutoPayAuthorized
        invoicePaymentMethod
        invoiceOptedOutAt
        groupName
        isAuthorized
        isOptional
        optedIn
        invoiceNote
        authorizedAt
        creditMemos {
          id
          title
          note
          dateToApply
          creditApplied
          creditAmount
          isArchived
          groupName
        }
      }
      counts {
        activeGroups
        comingSoonInvoices
        unauthorizedInvoices
      }
    }
  }
`;
export const EXPORT_USERS_EMAILS = gql`
  query SpendUserExportEmails($input: SpendExportEmailsInput!) {
    spendUserExportEmails(input: $input) {
      content
      fileName
    }
  }
`;

export const GET_ALL_ROLES = gql`
  query Roles {
    spendRoles {
      roles {
        groupId
        id
        isArchived
        name
        organizationId
        permissions
        isNotSignedUp
        organizationName
        groupName
      }
      count
    }
  }
`;

export const USER_TOKEN = gql`
  query UserToken($permissions: [String!], $expirationTime: String) {
    userToken(permissions: $permissions, expirationTime: $expirationTime) {
      accessToken
    }
  }
`;
