import {
  SpendBankTransaction,
  SpendBankTransactionDetail,
  SpendLegacyTransaction,
  TransactionAttachment,
  TransactionNotes,
  useGetAttachmentsLazyQuery,
} from "graphql/generated";
import React, { useContext, useEffect, useState } from "react";
import Divider from "shared-components/divider";
import ChangeIncomeBudgetItem from "./change-income-budget-item";
import TransactionDetailsCard from "./transaction-details-card";
import TransactionHistoryCard from "./transaction-history-card";
import TransactionManageAttachmentsCard from "./transaction-manage-attachments-card";
import TransactionManangeNotesCard from "./transaction-manange-notes-card";
import TransactionReconciliationCard from "./transaction-reconciliation-card";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_NOTES_AND_ATTACHMENTS_BY_PAYMENT_ID,
  GET_TRANSACTION_BY_ID,
} from "graphql/queries/transactions";
import ScrollableModal from "../scrollable-modal";
import { getTransactionTitle, legacyTrx } from "../../../helpers/transaction";
import useModal from "../../../hooks/use-modal";
import { SnapButton } from "suit";
import ParentCancelAchModal from "../../../pages/dashboard/parent/ParentCancelAchModal";
import TransactionInvoicesCard from "./transaction-invoices-card";
import { UPDATE_INVOICE } from "graphql/mutations/invoice";
import ToastContext from "context/toast-context";
import { SpinnerContainer } from "shared-components/spinner";

type TransactionDetailsProps = {
  isOpen: boolean;
  toggle: () => void;
  tabSelectedValue: number;
  selectedTran: SpendBankTransaction | SpendLegacyTransaction;
  selectedModal: number;
  setSelectedModal: React.Dispatch<React.SetStateAction<number>>;
  setSelectedTab: (index: number) => void;
  canEditNotesAndAttachments: boolean;
  perspectiveId?: string;
  isParentView?: boolean;
  isLegacy?: boolean;
  legacyAccountId?: string;
};

function TransactionDetails({
  isOpen,
  toggle,
  tabSelectedValue,
  selectedTran,
  selectedModal,
  setSelectedModal,
  setSelectedTab,
  canEditNotesAndAttachments,
  perspectiveId,
  isParentView = false,
  legacyAccountId,
}: TransactionDetailsProps) {
  const isLegacyTransaction =
    selectedTran.__typename === "SpendLegacyTransaction";
  const [isPending, setIsPending] = useState(!isLegacyTransaction);
  const [hasAttKey, setHasAttachmentKey] = useState(!isLegacyTransaction);
  const [stopPaymentIssued, setStopPaymentIssued] = useState(false);
  const getTransactionDetail = () => {
    if (isLegacyTransaction) {
      const { __typename, ...trx } = selectedTran;
      const transactionDetail: SpendBankTransactionDetail = {
        ...trx,
        canCancel: false,
      };
      return transactionDetail;
    }
    return undefined;
  };
  const [transactionDetail, setTransactionDetail] = useState<
    SpendBankTransactionDetail | undefined
  >(getTransactionDetail());
  const {
    data: transactionData,
    loading: loadingTransactionData,
    error: errorTransactionData,
    refetch: refetchTransactionData,
  } = useQuery(GET_TRANSACTION_BY_ID, {
    variables: { spendTransactionId: selectedTran.id },
    fetchPolicy: "no-cache",
    skip: isLegacyTransaction,
  });

  useEffect(() => {
    if (
      !loadingTransactionData &&
      transactionData &&
      transactionData.spendTransaction
    ) {
      setTransactionDetail(transactionData.spendTransaction.transactionDetail);
      setIsPending(
        transactionData.spendTransaction.transactionDetail.status === "PENDING"
      );
      setStopPaymentIssued(!!selectedTran.metadata.tags.stopPaymentIssued);
      const key =
        transactionData.spendTransaction.transactionDetail?.metadata?.tags
          ?.attachmentKey;
      setHasAttachmentKey(key != null);
      if (key) {
        getKeyAttachments({
          variables: {
            id: key,
          },
        });
      }
    } else if (!loadingTransactionData && errorTransactionData) {
      // TODO: Add error handling for detail fetching. Toast? Error message in sections relevant?
      console.log(errorTransactionData);
    }
    // eslint-disable-next-line
  }, [transactionData, loadingTransactionData, errorTransactionData]);

  const { loading, data } = useQuery(GET_NOTES_AND_ATTACHMENTS_BY_PAYMENT_ID, {
    variables: { id: selectedTran.id },
    errorPolicy: "all",
  });
  const [getKeyAttachments, { loading: keyLoading, data: keyData }] =
    useGetAttachmentsLazyQuery();
  const [updateInvoice] = useMutation(UPDATE_INVOICE, {
    refetchQueries: [
      {
        query: GET_TRANSACTION_BY_ID,
        variables: { spendTransactionId: selectedTran?.id },
      },
    ],
  });
  const [isCancelPayment, setCancelPayment] = useState(false);
  const [notes, setNotes] = useState<TransactionNotes[]>([]);
  const [action, setAction] = useState("");
  const [attachments, setAttachments] = useState<TransactionAttachment[]>([]);
  const [additionalAttachments, setAdditionalAttachments] = useState<
    TransactionAttachment[]
  >([]);
  const [selectedTransIdx, setSelectedTransIdx] = useState(0);
  const [selectedBudgetItem, setSelectedBudgetItem] = useState("");
  const Toast = useContext(ToastContext);

  useEffect(() => {
    if (!loading && data) {
      if (data.spendTransactionAttachmentsByPaymentId) {
        setAttachments(data.spendTransactionAttachmentsByPaymentId.attachments);
      }
      if (data.spendTransactionNotesByPaymentId) {
        let notes = data.spendTransactionNotesByPaymentId.notes;
        if (notes.length) {
          setNotes(notes);
          setAction("Update");
        } else {
          setNotes([
            {
              content: "",
              createdAt: "",
              id: "",
              paymentId: "",
              updatedAt: "",
            },
          ]);
          setAction("Create");
        }
      }
    }
    if (!keyLoading && keyData) {
      if (keyData.spendTransactionAttachmentsByPaymentId) {
        setAdditionalAttachments(
          (keyData.spendTransactionAttachmentsByPaymentId
            .attachments as TransactionAttachment[]) || []
        );
      }
    }
  }, [loading, data, keyLoading, keyData]);

  const { isOpen: isOpenCancelAchModal, toggle: toggleCancelAchModal } =
    useModal();

  const getTrxTitle = (index?: number) => {
    if (index === 1) {
      return "";
    }
    if (isLegacyTransaction) {
      if (legacyAccountId) {
        legacyTrx.getTitle(
          selectedTran as SpendLegacyTransaction,
          legacyAccountId
        );
      } else {
        return selectedTran.description || selectedTran.id;
      }
    } else {
      return (
        getTransactionTitle(selectedTran as SpendBankTransaction) +
        (isCancelPayment ? " (Canceled)" : "")
      );
    }
  };
  return (
    <ScrollableModal
      isOpen={isOpen}
      toggle={toggle}
      title={
        {
          0: "Transaction Details",
          1: "",
        }[selectedModal]!
      }
      header={getTrxTitle(selectedModal)}
      tabOptions={{
        currentSelectedValue: tabSelectedValue,
        tabs:
          selectedModal === 0
            ? [
                {
                  text: "Details",
                  value: 0,
                  onClick: () => {
                    setSelectedTab(0);
                  },
                },
                {
                  text: "Manage Notes & Attachments",
                  value: 1,
                  onClick: () => {
                    setSelectedTab(1);
                  },
                },
              ]
            : [],
      }}
      btn1={
        selectedModal === 1
          ? {
              text: "Update Budget Items",
              onClick: () => {
                updateInvoice({
                  variables: {
                    spendInvoiceUpdateId:
                      transactionDetail?.reconciliation?.invoiceTransactions?.at(
                        selectedTransIdx
                      )?.invoiceId,
                    input: {
                      budgetItemId: selectedBudgetItem,
                    },
                  },
                });
                Toast &&
                  Toast.setToastProps({
                    message: "Invoice's budget item updated.",
                    type: "success",
                  });
                Toast?.toggleToast();
                toggle();
              },
            }
          : { text: "Close", onClick: toggle }
      }
      btn2={
        selectedModal === 1
          ? { text: "Cancel", onClick: () => setSelectedModal(0) }
          : undefined
      }
      hasBackBtn={
        selectedModal === 1
          ? { text: "back", onClick: () => setSelectedModal(0) }
          : undefined
      }
    >
      {
        {
          0: (
            <div className="relative bg-white p-4 lg:my-6 lg:mx-6 lg:rounded-lg lg:p-0">
              {
                {
                  0: (
                    <>
                      {transactionDetail && transactionDetail.canCancel && (
                        <div className="absolute top-0 right-0">
                          <SnapButton
                            size="xs"
                            icon={"stop-circle-solid"}
                            variant="danger"
                            onClick={toggleCancelAchModal}
                            disabled={isCancelPayment}
                          >
                            Cancel Payment
                          </SnapButton>
                          <ParentCancelAchModal
                            isOpen={isOpenCancelAchModal}
                            toggle={toggleCancelAchModal}
                            transaction={transactionDetail}
                            transactionTitle={getTrxTitle()}
                            onSuccess={() => {
                              refetchTransactionData();
                              setCancelPayment(true);
                            }}
                          />
                        </div>
                      )}
                      <TransactionDetailsCard
                        transaction={transactionDetail || selectedTran}
                        notes={notes}
                        perspectiveId={perspectiveId}
                        stopPaymentIssued={stopPaymentIssued}
                      />

                      <div className="relative">
                        {transactionDetail && (
                          <>
                            {(transactionDetail?.reconciliation
                              ?.invoiceTransactions?.length || 0) > 0 && (
                              <>
                                <Divider isVisibleOnMobile className="pt-2" />
                                <TransactionInvoicesCard
                                  transaction={transactionDetail}
                                  setSelectedModal={setSelectedModal}
                                  setSelectedTransIdx={setSelectedTransIdx}
                                  isParentView={isParentView}
                                />
                              </>
                            )}
                            <Divider isVisibleOnMobile className="pt-2" />
                            <TransactionHistoryCard
                              transaction={transactionDetail}
                            />
                            <Divider isVisibleOnMobile className="pt-2" />
                            <TransactionReconciliationCard
                              transaction={transactionDetail}
                            />
                          </>
                        )}
                        <SpinnerContainer loading={loadingTransactionData} />
                      </div>
                    </>
                  ),
                  1: (
                    <>
                      <TransactionManangeNotesCard
                        transactionId={selectedTran.id}
                        notes={notes}
                        action={action}
                        setNotes={setNotes}
                        canEditNotes={canEditNotesAndAttachments}
                      />
                      {(!isPending || hasAttKey) && (
                        <>
                          <Divider isVisibleOnMobile className="mb-4" />
                          <TransactionManageAttachmentsCard
                            attachments={[
                              ...attachments,
                              ...additionalAttachments,
                            ]}
                            paymentId={transactionDetail?.id ?? ""}
                            canEditAttachments={
                              canEditNotesAndAttachments && !isPending
                            }
                          />
                        </>
                      )}
                    </>
                  ),
                }[tabSelectedValue]
              }
            </div>
          ),
          1: (
            <div className="lg:h-[700px]">
              <ChangeIncomeBudgetItem
                selectedTran={transactionDetail}
                idxTran={selectedTransIdx}
                setSelectedBudgetItem={setSelectedBudgetItem}
              />
            </div>
          ),
        }[selectedModal]
      }
    </ScrollableModal>
  );
}

export default TransactionDetails;
