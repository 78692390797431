import { FilterOptions, Filters } from "types/filter-types";

export const accountMenuOptions: FilterOptions[] = [
  // {
  //   name: "All Accounts",
  //   selected: true,
  // },
];

export const transactionTypeMenuOptions: FilterOptions[] = [
  // {
  //   name: "All Transaction Type",
  //   selected: false,
  // },
  {
    name: "ACH",
    value: "receivedAchTransaction",
    selected: false,
  },
  {
    name: "Credit Memo",
    value: "credit_memo",
    selected: false,
  },
  {
    name: "Debit Card",
    value: "debit_card | cardTransaction",
    selected: false,
  },
  {
    name: "Deposit",
    value: "deposit",
    selected: false,
  },
  {
    name: "Fee",
    value: "fee",
    selected: false,
  },
  {
    name: "Invoice Payment",
    value: "invoice_payment",
    selected: false,
  },
  {
    name: "Invoice Refund",
    value: "invoice_refund",
    selected: false,
  },
  {
    name: "Send Check",
    value: "checkPayment",
    selected: false,
  },
  {
    name: "Transfer",
    value: "bookTransaction | ach",
    selected: false,
  },
  {
    name: "Withdrawal",
    value: "withdrawl",
    selected: false,
  },
];

export const stausMenuOptions: FilterOptions[] = [
  // {
  //   name: "All Statuses",
  //   selected: true,
  // },
  {
    name: "Failed",
    selected: false,
  },
  {
    name: "Pending",
    selected: false,
  },
  {
    name: "Returned",
    selected: false,
  },
  {
    name: "Settled",
    selected: false,
  },
];

export const paymentMethodMenuOptions: FilterOptions[] = [
  // {
  //   name: "All Payment Methods",
  //   selected: true,
  // },
  {
    name: "Bank",
    selected: false,
  },
  {
    name: "Cash",
    selected: false,
  },
  {
    name: "Check",
    selected: false,
  },
  {
    name: "Card",
    selected: false,
  },
];

export const playerMenuOptions: FilterOptions[] = [
  // {
  //   name: "All Participants",
  //   selected: true,
  // },
  // {
  //   name: "All Signed Up Participants",
  //   selected: false,
  // },
  // {
  //   name: `All "Not Signed Up" Participants`,
  //   selected: false,
  // },
  // {
  //   name: `All "No Invite Sent" Participants`,
  //   selected: false,
  // },
];

export const teamMenuOptions: FilterOptions[] = [
  // {
  //   name: "All Teams",
  //   selected: true,
  // },
];

export const statusMenuOptions: FilterOptions[] = [
  // {
  //   name: "All Statuses",
  //   selected: true,
  // },
  {
    name: "AutoPay Stopped",
    selected: false,
  },
  {
    name: "Awaiting Approval",
    selected: false,
  },
  {
    name: "Opt Out",
    selected: false,
  },
  {
    name: "Paid",
    selected: false,
  },
  {
    name: "Past Due",
    selected: false,
  },
  {
    name: "Refunded",
    selected: false,
  },
  {
    name: "Upcoming",
    selected: false,
  },
];

const includeArchivedOptions: FilterOptions[] = [
  {
    name: "Yes",
    selected: false,
  },
  {
    name: "No",
    selected: false,
  },
];

export const TransactionFilterOptions: Filters[] = [
  {
    filterName: "Account",
    type: "single",
    filterOptions: accountMenuOptions,
    // placeholder: accountMenuOptions[0].name,
    className: "lg:mr-4 mr-0",
  },
  {
    filterName: "Transaction Type",
    type: "multi",
    filterOptions: transactionTypeMenuOptions,
    // placeholder: transactionTypeMenuOptions[0].name,
    className: "lg:mr-4 mr-0",
  },
  {
    filterName: "Status",
    type: "multi",
    filterOptions: stausMenuOptions,
    // placeholder: stausMenuOptions[0].name,
  },
  {
    filterName: "Start Date",
    type: "date",
    placeholder: "",
    className: "mr-4 mt-4",
  },
  {
    filterName: "End Date",
    type: "date",
    placeholder: "",
    className: "mr-4 mt-4",
    hasError: false,
  },
  {
    filterName: "Payment Method",
    type: "multi",
    filterOptions: paymentMethodMenuOptions,
    // placeholder: paymentMethodMenuOptions[0].name,
  },
];

export const InvoiceFilterOptions: Filters[] = [
  {
    filterName: "Participants",
    type: "single",
    filterOptions: playerMenuOptions,
    // placeholder: playerMenuOptions[0].name,
    className: "lg:mr-4 mr-0",
  },
  {
    filterName: "Groups",
    type: "single",
    filterOptions: teamMenuOptions,
    // placeholder: teamMenuOptions[0].name,
    className: "lg:mr-4 mr-0",
  },
  {
    filterName: "Status",
    type: "single",
    filterOptions: statusMenuOptions,
    // placeholder: statusMenuOptions[0].name,
  },
  {
    filterName: "Start Date",
    type: "date",
    placeholder: "",
    className: "lg:mr-4 mt-4",
  },
  {
    filterName: "End Date",
    type: "date",
    placeholder: "",
    className: "lg:mr-4 mt-4",
    hasError: false,
  },
  {
    filterName: "Include Archived",
    type: "single",
    placeholder: "No",
    filterOptions: includeArchivedOptions,
  },
];

export const settingPermissions = [
  { permissionName: "Club Banking", admin: false },
  { permissionName: "Club Budget", admin: false },
  { permissionName: "Club Settings", admin: false },
  { permissionName: "Club Teams", admin: false },
  { permissionName: "Club Users", admin: false },
  { permissionName: "Team Banking ", admin: false },
  { permissionName: "Team Budget", admin: false },
  { permissionName: "Team Collections", admin: false },
  { permissionName: "Team Debit Cards", admin: false },
  { permissionName: "Team Players", admin: false },
  { permissionName: "Team Settings", admin: false },
  { permissionName: "Team Users", admin: false },
];
