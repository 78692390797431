import { DropdownMenuItem } from "@snap-mobile/snap-ui/dist/types/utils";
import {
  ApprovalType,
  useSpendGetApprovalsByTabQuery,
  useSpendUpdateApprovalMutation,
} from "graphql/generated";
import { DateFormatSupported, FormatDate } from "helpers/format-date";
import { FormatMoney } from "helpers/format-money";
import useModal from "hooks/use-modal";
import { useSpendPagination } from "hooks/use-spend-pagination";
import { useContext, useEffect, useState } from "react";
import DataCard from "shared-components/data-card";
import { SnapDropDown } from "suit";
import { LabelValueObject } from "types/label-value-object";
import Details from "./modals/details";
import Decline from "./modals/decline";
import Approve from "./modals/approve";
import ToastContext from "context/toast-context";
import { BtnState } from "shared-components/modal";
import { isNullOrEmpty } from "helpers/null-or-empty";
import GroupContext from "context/group-context";

function Pending() {
  const group = useContext(GroupContext);
  const toast = useContext(ToastContext);
  const { isOpen: isDetailsOpen, toggle: detailsToggle } = useModal();
  const { isOpen: isDeclineOpen, toggle: declineToggle } = useModal();
  const { isOpen: isApproveOpen, toggle: approveToggle } = useModal();

  const {
    sort,
    sortValue: currentSort,
    handleSortValue,
  } = useSpendPagination();

  const [selectedApproval, setSelectedApproval] = useState<ApprovalType>();
  const [approvals, setApprovals] = useState<ApprovalType[]>([]);
  const [btnState, setBtnState] = useState<BtnState>(BtnState.BASE);
  const [rejectionReason, setRejectionReason] = useState<string>("");
  const [approvalStatus, setApprovalStatus] = useState<
    "Approved" | "Declined" | undefined
  >(undefined);
  const [sortOptions] = useState<DropdownMenuItem[]>([
    {
      name: "Date Submitted: New to Old",
      text: "Date Submitted: New to Old",
      value: "date:DESC",
      selected: currentSort === "date:DESC",
    },
    {
      name: "Date Submitted: Old to New",
      text: "Date Submitted: Old to New",
      value: "date:ASC",
      selected: currentSort === "date:ASC",
    },
    {
      name: "Activity Type",
      text: "Activity Type",
      value: "type:ASC",
      selected: currentSort === "type:ASC",
    },
    {
      name: "Group",
      text: "Group",
      value: "group:ASC",
      selected: currentSort === "group:ASC",
    },
  ]);

  const [updateApproval, { loading: loadingUpdate, data: updateData }] =
    useSpendUpdateApprovalMutation({
      refetchQueries: ["SpendGetApprovalsByTab"],
      fetchPolicy: "network-only",
    });

  const { loading: loadingApprovals, data: approvalData } =
    useSpendGetApprovalsByTabQuery({
      variables: {
        input: {
          tab: "pending",
          groupId: group?.activeGroup?.id ?? undefined,
          sort,
        },
      },
    });

  useEffect(() => {
    if (
      !loadingApprovals &&
      approvalData &&
      approvalData.spendGetApprovalsByTab
    ) {
      setApprovals(approvalData.spendGetApprovalsByTab.approvals ?? []);
    }
  }, [loadingApprovals, approvalData]);

  useEffect(() => {
    if (!loadingUpdate) {
      if (updateData && updateData.spendUpdateApproval) {
        toast?.setToast({
          message: `${selectedApproval?.title.replace(
            "-",
            " "
          )} request has been ${approvalStatus?.toLowerCase()}`,
          type: approvalStatus === "Approved" ? "success" : "danger",
        });
        setBtnState(BtnState.BASE);
        if (isApproveOpen) {
          approveToggle();
        }
        if (isDeclineOpen) {
          declineToggle();
        }
        detailsToggle();
      }
    }
    // eslint-disable-next-line
  }, [loadingUpdate, updateData]);

  const handleToggleDetailModal = (approval: ApprovalType) => {
    setSelectedApproval(approval);
    detailsToggle();
  };

  const handleUpdateApproval = (status: "Approved" | "Declined") => {
    setApprovalStatus(status);
    setBtnState(BtnState.DISABLED);
    if (status === "Declined" && isNullOrEmpty(rejectionReason)) {
      toast?.setToast({
        message: "Reason is required to decline request",
        type: "danger",
      });
      setBtnState(BtnState.BASE);
    } else if (selectedApproval) {
      updateApproval({
        variables: {
          input: {
            id: selectedApproval?.id,
            status,
            reason: rejectionReason,
          },
        },
      });
    }
  };

  const prepLeftData = (approval: ApprovalType) => {
    const items: LabelValueObject[] = [];
    const leftKeys = ["assignee", "type", "receipent"];
    const leftItems = Object.entries(approval).filter(
      (item) => !!item[1] && leftKeys.includes(item[0])
    );
    leftItems.forEach((li) => {
      items.push({
        key: li[0],
        value: li[1]?.toString()!,
        customContainerStyle: "capitalize",
      });
    });

    items.push({
      key: "Submitted by",
      value: approval.submittedBy,
    });
    items.push({
      key: "",
      value: `${approval.numberOfApprovals} out of ${approval.numberOfApprovers} approvals`,
      className: "ml-0",
    });
    return items;
  };
  const prepRightData = (approval: ApprovalType) => {
    const items: LabelValueObject[] = [];
    const rightKeys = ["group", "amount"];
    const rightItems = Object.entries(approval).filter(
      (item) => !!item[1] && rightKeys.includes(item[0])
    );
    rightItems.forEach((ri) => {
      items.push({
        key: ri[0],
        value:
          ri[0] === "amount" ? FormatMoney(Number(ri[1])) : ri[1]?.toString()!,
        customContainerStyle: "capitalize",
      });
    });
    items.push({
      key: "Date Submitted",
      value: FormatDate(approval.createdAt, DateFormatSupported.Numbers),
    });
    return items;
  };

  return (
    <>
      <h2 className="text-lg font-semibold">Pending Approvals</h2>
      <div className="flex my-4">
        <p className="text-sm text-gray-500 mr-2">Sort</p>
        <SnapDropDown
          leftHang
          defaultValue={currentSort}
          options={sortOptions}
          modalType="drawer"
          onSnap-dropdown-item-selected={(e) => handleSortValue(e.detail.value)}
          className="flex items-center"
        />
      </div>
      {approvals.map((approval, idx) => {
        return (
          <DataCard
            key={`${approval.title} - ${idx}`}
            title={approval.title.replace("-", " ")}
            titleAction={() => handleToggleDetailModal(approval)}
            kvLeft={prepLeftData(approval)}
            kvRight={prepRightData(approval)}
            action={2}
            actionClick={() => handleToggleDetailModal(approval)}
          />
        );
      })}
      {!loadingApprovals && approvals.length === 0 && (
        <p>No Pending Approvals</p>
      )}
      {isDetailsOpen &&
        !isApproveOpen &&
        !isDeclineOpen &&
        selectedApproval && (
          <Details
            isOpen={isDetailsOpen}
            toggle={detailsToggle}
            selectedApproval={selectedApproval}
            declineToggle={declineToggle}
            approveToggle={approveToggle}
            type="pending"
          />
        )}
      {isDeclineOpen && selectedApproval && (
        <Decline
          isOpen={isDeclineOpen}
          toggle={declineToggle}
          handleDecline={handleUpdateApproval}
          type={selectedApproval?.title.replace("-", " ")}
          btnState={btnState}
          setRejectionReason={setRejectionReason}
        />
      )}
      {isApproveOpen && selectedApproval && (
        <Approve
          isOpen={isApproveOpen}
          toggle={approveToggle}
          handleApprove={handleUpdateApproval}
          type={selectedApproval?.title.replace("-", " ")}
          btnState={btnState}
        />
      )}
    </>
  );
}

export default Pending;
