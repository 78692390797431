import { PieChartItem } from "@snap-mobile/snap-ui/dist/types/utils";
import SeasonContext from "context/season-context";
import { FormatMoney } from "helpers/format-money";
import { getBadgeColor } from "helpers/status-color";
import { useContext, useEffect, useState } from "react";
import Spinner from "shared-components/spinner";
import { SnapBadge, SnapIcon, SnapPieChart } from "suit";
import LabelValue from "./label-value";
import ProgramContext from "context/program-context";
import { SpendTransactionTotals } from "../../../graphql/generated";
import { isPsPublished, getPsLabel } from "helpers/status-text";

type GroupOverviewProps = {
  paymentScheduleLoading: boolean;
};

function GroupOverview({ paymentScheduleLoading }: GroupOverviewProps) {
  const program = useContext(ProgramContext);
  const season = useContext(SeasonContext);
  const [transactionTotals, setTransactionTotals] =
    useState<SpendTransactionTotals | null>(null);
  const [chartOptions, setChartOptions] = useState<PieChartItem[]>([]);
  const [seasonLoading, setSeasonLoading] = useState(false);
  useEffect(() => {
    if (season?.selectedSeason) {
      setTransactionTotals(season?.selectedSeason?.transactionTotals || null);
      setSeasonLoading(false);
    } else {
      season?.requestSeason();
      setSeasonLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [season?.selectedSeason]);
  useEffect(() => {
    setChartOptions([
      {
        category: "Paid",
        value: transactionTotals?.paid ?? 0,
        color: "rgb(5,150,105)",
      },
      {
        category: "Processing",
        value: transactionTotals?.processing ?? 0,
        color: "rgb(217,119,6)",
      },
      {
        category: "Upcoming",
        value: transactionTotals?.upcoming ?? 0,
        color: "rgb(96,165,250)",
      },
      {
        category: "Past Due",
        value: transactionTotals?.pastDue ?? 0,
        color: "rgb(239,68,68)",
      },
      {
        category: "Credited",
        value: transactionTotals?.credited ?? 0,
        color: "rgb(71,85,105)",
      },
    ]);
  }, [transactionTotals]);
  if (seasonLoading) {
    return (
      <div className="min-h-[75px] lg:min-h-[130px] mt-[50px] lg:mt-[100px]">
        <Spinner className="" />
      </div>
    );
  }
  return (
    <div>
      <p className="text-lg font-medium">Group Overview</p>
      <p className="text-sm text-gray-500">{season?.selectedSeason?.name}</p>
      <div className="lg:grid grid-cols-3 mt-4">
        <div className="flex-col justify-between">
          <LabelValue
            color="green"
            label="Paid"
            value={FormatMoney(transactionTotals?.paid ?? 0)}
            valueColor={
              transactionTotals?.paid !== 0 ? "text-green-600" : "text-gray-600"
            }
            labelColor={"text-gray-800"}
            bgColor={"bg-green-100"}
          />
          <LabelValue
            color="yellow"
            label="Processing"
            value={FormatMoney(
              (transactionTotals && transactionTotals.processing) || 0
            )}
            valueColor={
              transactionTotals?.processing !== 0
                ? "text-yellow-600"
                : "text-gray-600"
            }
            labelColor={"text-gray-800"}
            bgColor={"bg-yellow-100"}
            className={"mt-3"}
          />
          <LabelValue
            color="blue"
            label="Upcoming"
            value={FormatMoney(
              (transactionTotals && transactionTotals.upcoming) || 0
            )}
            valueColor={
              transactionTotals?.upcoming !== 0
                ? "text-blue-600"
                : "text-gray-600"
            }
            labelColor={"text-gray-800"}
            bgColor={"bg-blue-100"}
            className={"mt-3"}
          />
          <LabelValue
            color="red"
            label="Past Due"
            value={FormatMoney(
              (transactionTotals && transactionTotals.pastDue) || 0
            )}
            valueColor={
              transactionTotals?.pastDue !== 0
                ? "text-red-600"
                : "text-gray-600"
            }
            labelColor={"text-gray-800"}
            bgColor={"bg-red-100"}
            className={"mt-3"}
          />
          <LabelValue
            color="gray"
            label="Credited"
            value={FormatMoney(
              (transactionTotals && transactionTotals.credited) || 0
            )}
            valueColor={"text-gray-600"}
            labelColor={"text-gray-800"}
            className={"mt-3"}
            bgColor={"bg-gray-100"}
          />
        </div>
        <div className="lg:inline-block self-center hidden">
          {chartOptions.length === 0 && <Spinner />}
          <SnapPieChart height={150} innerRadius={56} options={chartOptions} />
        </div>
        <div className="lg:border-l lg:border-t-0 lg:pl-14 lg:pt-0 border-t mt-6 pt-6">
          <div className="lg:flex-col flex">
            <p className="text-sm text-gray-600 lg:self-start lg:mr-0  self-center mr-auto">
              Payment Schedule
            </p>
            {paymentScheduleLoading ? (
              <Spinner className="lg:mr-auto lg:pl-10" />
            ) : (
              <SnapBadge
                title="Payment Schedule Status"
                color={getBadgeColor(
                  season?.selectedSeason?.paymentScheduleStatus ?? ""
                )}
                size="lg"
                className="capitalize"
              >
                {getPsLabel(season?.selectedSeason?.paymentScheduleStatus)}
              </SnapBadge>
            )}
          </div>
          <div className="lg:flex-col lg:mt-2 flex mt-4">
            <p className="text-sm text-gray-600 lg:self-start lg:mr-0  self-center mr-auto">
              Payment Processing
            </p>
            {!paymentScheduleLoading && (
              <p className="font-medium text-gray-700">
                {isPsPublished(season?.selectedSeason?.paymentScheduleStatus)
                  ? "Enabled"
                  : "Disabled"}
              </p>
            )}
          </div>
          {season?.selectedSeason?.isLinkEnabled && (
            <a
              href={program?.settings?.signUpLink ?? ""}
              rel="noreferrer"
              target="_blank"
            >
              <div className="lg:flex-col lg:mt-2 flex mt-4 cursor-pointer">
                <p className="text-sm text-gray-600 lg:self-start lg:mr-0  self-center mr-auto">
                  Sign Up Link
                </p>
                <div className="flex">
                  <p className="text-blue-600 text-base font-medium mr-1">
                    Available
                  </p>
                  <div className="flex">
                    <p className="text-blue-600 text-base font-medium mr-1">
                      Available
                    </p>
                    <SnapIcon icon="external-link-solid" color="#2563EB" />
                  </div>
                </div>
              </div>
            </a>
          )}
        </div>
      </div>
    </div>
  );
}

export default GroupOverview;
