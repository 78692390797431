import { DropdownMenuItem } from "@snap-mobile/snap-ui/dist/types/utils";
import DisplayContext from "context/display-context";
import { SpendCounterparty } from "graphql/generated";
import useModal from "hooks/use-modal";
import { useContext, useState } from "react";
import { SnapButton, SnapDropDown, SnapPagination, SnapTable } from "suit";
import DeleteCounterparty from "./delete-counterparty";
import { ITEMS_PER_PAGE } from "../../../constants";
import EinDisplay from "../send-check/ein-display";
import Divider from "shared-components/divider";

type ManageCounterpartiesProps = {
  toggleAddPayee: () => void;
  counterparties: SpendCounterparty[];
  type: "Program" | "Group";
};

function ManageCounterparties({
  toggleAddPayee,
  counterparties,
  type,
}: ManageCounterpartiesProps) {
  const display = useContext(DisplayContext);
  const { isOpen: isDeleteOpen, toggle: toggleDelete } = useModal();

  const [page, setPage] = useState(0);
  const [selectedCounterparty, setSelectedCounterparty] =
    useState<SpendCounterparty>();
  const [options] = useState<DropdownMenuItem[]>([
    {
      text: "Delete",
      name: "Delete",
      value: "Delete",
    },
  ]);

  return (
    <>
      <div className="flex justify-end">
        <SnapButton
          variant="primary"
          className="lg:w-36 w-full mb-6 lg:mb-0"
          size="sm"
          fullWidth
          onClick={toggleAddPayee}
        >
          Add Payee
        </SnapButton>
      </div>
      {display?.isDesktop && (
        <SnapTable>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Bank</th>
                <th>Routing #</th>
                <th>Account #</th>
                <th>EIN</th>
                <th>{/* Action */}</th>
              </tr>
            </thead>
            <tbody>
              {counterparties
                .slice(
                  page * ITEMS_PER_PAGE,
                  page * ITEMS_PER_PAGE + ITEMS_PER_PAGE
                )
                .map((counterparty) => {
                  return (
                    <tr key={counterparty.id}>
                      <td>{counterparty.name}</td>
                      <td>{counterparty.bankName}</td>
                      <td>{counterparty.routingNumber}</td>
                      <td className="flex justify-center items-center">
                        **{counterparty.accountLastFour}
                      </td>
                      <td>
                        {counterparty.einNumber && (
                          <EinDisplay
                            id={counterparty.id}
                            type={"counterparty"}
                          />
                        )}
                      </td>
                      <td>
                        <SnapDropDown
                          minimal
                          options={options}
                          onSnap-dropdown-item-selected={(e) => {
                            if (e.detail.value === "Delete") {
                              toggleDelete();
                            }
                            setSelectedCounterparty(counterparty);
                          }}
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </SnapTable>
      )}
      {display?.isMobile &&
        counterparties
          .slice(page * ITEMS_PER_PAGE, page * ITEMS_PER_PAGE + ITEMS_PER_PAGE)
          .map((counterparty) => {
            return (
              <div key={counterparty.id} className="border p-4 rounded-lg mt-4">
                <div className="flex justify-between">
                  <p className="font-medium">{counterparty.name}</p>
                  <SnapDropDown
                    buttonText="Options"
                    modalType="drawer"
                    minimal
                    options={[
                      {
                        name: "Delete",
                        text: "Delete",
                        value: "Delete",
                        selected: false,
                      },
                    ]}
                    onSnap-dropdown-item-selected={(e) => {
                      if (e.detail.value === "Delete") {
                        toggleDelete();
                      }
                      setSelectedCounterparty(counterparty);
                    }}
                  />
                </div>
                <Divider isVisibleOnMobile />
                <div className="grid grid-cols-4 mt-4">
                  <div>
                    <p className="text-xs font-semibold text-gray-500">Bank</p>
                    <p className="text-sm">{counterparty.bankName}</p>
                  </div>
                  <div>
                    <p className="text-xs font-semibold text-gray-500">
                      Account Number
                    </p>
                    <p className="text-sm">{counterparty.accountLastFour}</p>
                  </div>
                  <div>
                    <p className="text-xs font-semibold text-gray-500">
                      Routing Number
                    </p>
                    <p className="text-sm">{counterparty.routingNumber}</p>
                  </div>
                  <div>
                    <p className="text-xs font-semibold text-gray-500">EIN</p>
                    {counterparty.einNumber && (
                      <EinDisplay id={counterparty.id} type={"counterparty"} />
                    )}
                  </div>
                </div>
              </div>
            );
          })}
      <SnapPagination
        itemCount={counterparties.length}
        currentPage={page}
        pageSize={ITEMS_PER_PAGE}
        onSnap-pagination-page-changed={(e) => {
          setPage(e.detail);
        }}
      />

      {isDeleteOpen && (
        <DeleteCounterparty
          isDeleteOpen={isDeleteOpen}
          toggleDelete={toggleDelete}
          selectedCounterparty={selectedCounterparty}
          type={type}
        />
      )}
    </>
  );
}

export default ManageCounterparties;
