import { useContext } from "react";
import CustomModal, { BtnState, BtnType } from "shared-components/modal";
import { SnapAlert, SnapAlertTitle } from "suit";
import ToastContext from "context/toast-context";
import { useMutation } from "@apollo/client";
import { ARCHIVE_PARTICIPANT_FROM_SEASON } from "graphql/mutations/group";
type UnarchiveParticipantProps = {
  unarchiveParticipantOpen: boolean;
  unarchiveParticipantToggle: () => void;
  groupRosterIds: string[];
  deselectAllAction: () => void;
};

function UnarchiveParticipant({
  unarchiveParticipantOpen,
  unarchiveParticipantToggle,
  groupRosterIds,
  deselectAllAction,
}: UnarchiveParticipantProps) {
  const toast = useContext(ToastContext);
  const [unarchiveParticipants, { loading }] = useMutation(
    ARCHIVE_PARTICIPANT_FROM_SEASON,
    {
      refetchQueries: ["spendGroupRostersBySeasonV2"],
      onCompleted: () => {
        toast?.setToast({
          message: "Participant(s) have been unarchived.",
          type: "success",
        });
        unarchiveParticipantToggle();
        deselectAllAction();
      },
    }
  );

  const handleUnarchiveParticipant = () => {
    unarchiveParticipants({
      variables: {
        input: {
          groupRosterIds,
          isArchived: false,
        },
      },
    });
  };
  const btn1: BtnType = {
    text: "Unarchive",
    btnStyle: "danger",
    onClick: () => handleUnarchiveParticipant(),
    btnState: loading ? BtnState.DISABLED : BtnState.BASE,
  };

  const btn2: BtnType = {
    text: "Cancel",
    btnStyle: "tertiary",
    onClick: unarchiveParticipantToggle,
  };

  return (
    <CustomModal
      isOpen={unarchiveParticipantOpen}
      toggle={unarchiveParticipantToggle}
      title={"Unarchive Participants"}
      btn1={btn1}
      btn2={btn2}
    >
      <div className="modal-card pl-6">
        <SnapAlert type="warning" className="mb-3">
          <SnapAlertTitle>
            Are you sure you want to unarchive the selected participant(s) from
            this group?
          </SnapAlertTitle>
        </SnapAlert>
      </div>
    </CustomModal>
  );
}

export default UnarchiveParticipant;
