import { DropdownMenuItem } from "@snap-mobile/snap-ui/dist/types/utils";
import ProgramContext from "context/program-context";
import ToastContext from "context/toast-context";
import UserContext from "context/user-context";
import useModal from "hooks/use-modal";
import { useContext, useEffect, useState } from "react";
import SwitchRole from "shared-components/modal/switch-role";
import {
  SnapGlobalHeader,
  SnapHeaderWrapper,
  SnapLogo,
} from "suit";
import GlobalToastMessage from "../global-toast-message";
import SplitIOContext from "context/splitio-context";
// import { useUserImpersonateLogoutMutation } from "graphql/generated";

function Header() {
  const { isOpen, toggle } = useModal();
  const toast = useContext(ToastContext);
  const user = useContext(UserContext);
  const program = useContext(ProgramContext);
  const splits = useContext(SplitIOContext);
  const roles = user?.getAllRoles();
  const [roleOptions, setRoleOptions] = useState<DropdownMenuItem[]>([]);
  const [messagingEnabled, setMessagingEnabled] = useState(false);
  useEffect(() => {
    setMessagingEnabled(splits?.isTreatmentOn(splits?.act.se2136) || false);
  }, [splits]);
  useEffect(() => {
    if (roles && roles.length > 1) {
      let name =
        user?._session?.role?.name
          .split("_")
          .map((word) => `${word[0].toUpperCase()}${word.substring(1)}`)
          .join(" ") ?? "";
      let currentRole: DropdownMenuItem = {
        name: name ?? "",
        text: name ?? "",
        value: name ?? "",
        selected: true,
      };
      let switchRole: DropdownMenuItem = {
        name: "Switch Role",
        text: "Switch Role",
        value: "Switch Role",
        selected: false,
      };

      setRoleOptions([currentRole, switchRole]);
    }
  }, [roles, program, user]);

  return (
    <>
      <SnapGlobalHeader theme="spend" className="fixed">
        <SnapLogo white={true} product="snap-logo-spend"></SnapLogo>
        <div className="flex">
          <SnapHeaderWrapper
            showMessaging={messagingEnabled}
            showLoginButtons={false}
            menuItems={roleOptions}
            onSnap-header-session-item-selected={(e) => {
              if (e.detail.name === "Switch Role") {
                toggle();
              }
            }}
          ></SnapHeaderWrapper>
        </div>
        {toast?.isToastOpen && (
          <GlobalToastMessage
            title={toast.title}
            message={toast?.message}
            isToastOpen={toast?.isToastOpen}
            toggleToast={toast?.toggleToast}
            type={toast?.type}
            className={"mt-2"}
          />
        )}
      </SnapGlobalHeader>
      <SwitchRole
        isOpen={isOpen}
        toggle={toggle}
        currentRole={user?._session?.role}
        roles={roles}
      />
    </>
  );
}

export default Header;
