import { getDatePickerValue, setDatePickerValue } from "helpers/date-picker";
import { FormatMoney } from "helpers/format-money";
import { useState } from "react";
import DatePicker from "shared-components/date-picker";
import InputMask from "shared-components/input-mask";
import { ParticipantInvoice } from "types/invoice";

type TableRowProps = {
  invoice: ParticipantInvoice;
  idx: number;
  handleEdits: (index: number, dueDate: string, amount: string) => void;
};

function TableRow({ invoice, idx, handleEdits }: TableRowProps) {
  const [amount, setAmount] = useState(
    FormatMoney(invoice.amount).replace("$", "")
  );

  return (
    <tr key={invoice.id}>
      <td>{invoice.description}</td>
      <td>{invoice.groupName}</td>
      <td>{invoice.seasonName}</td>
      <td>
        <DatePicker
          label={""}
          date={getDatePickerValue(invoice.dueDate ?? "")}
          setDate={(e) => {
            const newDate = setDatePickerValue(e);
            handleEdits(idx, newDate, amount);
          }}
        />
      </td>
      <td>
        <InputMask
          label={""}
          amount={amount}
          setAmount={setAmount}
          hasError={false}
          onChange={() => {
            handleEdits(idx, invoice.dueDate, amount);
          }}
        />
      </td>
    </tr>
  );
}

export default TableRow;
