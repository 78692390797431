import React, { useState } from "react";
import HasAccount from "./has-account";
import NoAccount from "./no-account";
import {
  SpendAccountLimitsResponse,
  SpendBankAccount,
  SpendExternalTransferFee,
} from "graphql/generated";
import { transferError } from "types/errors";

type ExternalTransferProps = {
  hasLinkedBankAccount: boolean;
  openPlaid: () => void;
  linkedAccounts: SpendBankAccount[];
  despotAccount: string;
  setAmount: React.Dispatch<React.SetStateAction<string>>;
  feeAmount: string;
  setFeeAmount: React.Dispatch<React.SetStateAction<string>>;
  setNote: React.Dispatch<React.SetStateAction<string>>;
  setSelectedAccountId: React.Dispatch<React.SetStateAction<string>>;
  setDirection: React.Dispatch<React.SetStateAction<string>>;
  amount: string;
  hasErrorsObj: transferError;
  setHasErrorObj: React.Dispatch<React.SetStateAction<transferError>>;
  setExternalTo: React.Dispatch<React.SetStateAction<string>>;
  setExternalFrom: React.Dispatch<React.SetStateAction<string>>;
  limits?: SpendAccountLimitsResponse;
  externalTransferFee?: SpendExternalTransferFee | null;
};

function ExternalTransfer({
  hasLinkedBankAccount,
  openPlaid,
  linkedAccounts,
  despotAccount,
  setAmount,
  setNote,
  setSelectedAccountId,
  setDirection,
  amount,
  hasErrorsObj,
  setHasErrorObj,
  setExternalTo,
  setExternalFrom,
  limits,
  externalTransferFee,
  feeAmount,
  setFeeAmount,
}: ExternalTransferProps) {
  const [selectedOption, setSelectedOption] = useState("Transfer OUT");

  return (
    <div className="modal-card">
      {hasLinkedBankAccount ? (
        <HasAccount
          setSelectedOption={setSelectedOption}
          selectedOption={selectedOption}
          amount={amount}
          linkedAccounts={linkedAccounts}
          despotAccount={despotAccount}
          setAmount={setAmount}
          setNote={setNote}
          setSelectedAccountId={setSelectedAccountId}
          setDirection={setDirection}
          hasErrorsObj={hasErrorsObj}
          setHasErrorObj={setHasErrorObj}
          setExternalTo={setExternalTo}
          setExternalFrom={setExternalFrom}
          limits={limits}
          externalTransferFee={externalTransferFee}
          feeAmount={feeAmount}
          setFeeAmount={setFeeAmount}
        />
      ) : (
        <NoAccount openPlaid={openPlaid} />
      )}
    </div>
  );
}

export default ExternalTransfer;
