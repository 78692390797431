export const FormatMoney = (pennies: number | null | undefined) => {
  const amount = Number(((pennies ?? 0) / 100).toFixed(2));
  return Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(amount);
};
export const ParseMoney = (dollarAmount: string | null | undefined) => {
  if (!dollarAmount) return 0;
  const cleanedAmount = dollarAmount.replace(/[$,]/g, "").trim();
  const parsedAmount = parseFloat(cleanedAmount);
  if (isNaN(parsedAmount)) return 0;
  return Math.round(parsedAmount * 100);
};
