import { useMutation } from "@apollo/client";
import { SnapSelectMenuOption } from "@snap-mobile/snap-ui/dist/types/utils";
import ToastContext from "context/toast-context";
import UserContext from "context/user-context";
import cuid from "cuid";
import {
  SpendBankAccount,
  PaymentsApiCustomerPaymentMethod,
  SpendGroupRoster,
} from "graphql/generated";
import { DELETE_USER_PLAID_ACCESS } from "graphql/mutations/plaid";
import { useContextStrict } from "helpers/context-strict";
import { useContext, useEffect, useState } from "react";
import { PlaidLinkOptions, usePlaidLink } from "react-plaid-link";
import LinkBank, {
  getRefetchQueries,
} from "shared-components/banking/link-bank";
import { SubHeaderView } from "shared-components/modal/make-payment/sub-header-view";
import { OffSessionPaymentComponent } from "shared-components/payment-component/off-session-payment-component";
import { SnapSelectMenu } from "suit";

type SPMInput = {
  title?: string;
  groupId?: string | null;
  playerSelected?: SpendGroupRoster | null;
  payByCard: boolean;
  widgetOpen: boolean;
  groupAccountId: string;
  payByBank: boolean;
  isOnSession?: boolean;
  paymentTypeArgs: PaymentTypeSelectArgs;
  bankArgs: SpendBankSelectArgs;
  cardArgs: SpendCardSelectArgs;
  addNewOpen?: boolean;
  setAddNewOpen?: React.Dispatch<React.SetStateAction<boolean>>;
};

export type PaymentTypeSelectArgs = {
  paymentTypeOptions: SnapSelectMenuOption[];
  setPaymentTypeOptions: React.Dispatch<
    React.SetStateAction<SnapSelectMenuOption[]>
  >;
  paymentTypes: string[];
  setSelectedPaymentTypeOption: React.Dispatch<
    React.SetStateAction<`Pay by: ${"Card" | "Bank"}` | undefined>
  >;
};
export type SpendBankSelectArgs = {
  bankOptions: SnapSelectMenuOption[];
  setBankOptions: React.Dispatch<React.SetStateAction<SnapSelectMenuOption[]>>;
  bankAccounts: SpendBankAccount[];
  setSelectedBankAccount: React.Dispatch<
    React.SetStateAction<SpendBankAccount | undefined>
  >;
};
export type SpendCardSelectArgs = {
  cards: PaymentsApiCustomerPaymentMethod[];
  setCardOptions: React.Dispatch<React.SetStateAction<SnapSelectMenuOption[]>>;
  cardOptions: SnapSelectMenuOption[];
  setSelectedCard: React.Dispatch<
    React.SetStateAction<PaymentsApiCustomerPaymentMethod | undefined>
  >;
  cardCreate: () => void;
  cardBlock?: boolean;
};

export default function SelectPaymentMethod({
  title,
  payByCard,
  payByBank,
  widgetOpen,
  groupAccountId,
  groupId,
  paymentTypeArgs,
  bankArgs,
  cardArgs,
  addNewOpen,
  setAddNewOpen,
}: SPMInput) {
  const {
    paymentTypeOptions,
    setSelectedPaymentTypeOption,
    setPaymentTypeOptions,
  } = paymentTypeArgs;
  const { cardOptions, cards, setSelectedCard, cardCreate, setCardOptions } =
    cardArgs;
  const { bankAccounts, bankOptions, setBankOptions, setSelectedBankAccount } =
    bankArgs;
  const extPayId = cuid();
  const activeUser = useContextStrict(UserContext);
  const toast = useContext(ToastContext);
  const [accountStatus, setAccountStatus] = useState<
    { id: string; status: string } | undefined
  >();
  const [deletePlaidLink] = useMutation(DELETE_USER_PLAID_ACCESS, {
    refetchQueries: getRefetchQueries("user"),
  });
  const [plaidConfig, setPlaidConfig] = useState<PlaidLinkOptions>({
    onSuccess: (public_token, metadata) => {},
    onExit: (err, metadata) => {},
    onEvent: (eventName, metadata) => {},
    token: "",
  });

  const { open, ready } = usePlaidLink(plaidConfig);
  const openPlaid = () => {
    if (ready) {
      open();
    }
  };

  useEffect(() => {
    const selectedOption = bankOptions.find((bo) => bo.selected);
    if (selectedOption) {
      const foundAccount = bankAccounts.find(
        (ba) => ba.entityId === selectedOption.value
      );

      setAccountStatus({
        id: foundAccount?.entityId!,
        status: foundAccount?.status!,
      });
    }
  }, [bankAccounts, bankOptions]);

  const isVerifying = (
    accountStatus: { status: string; id: string } | undefined
  ) => {
    return (
      accountStatus && accountStatus?.status === "pending_manual_verification"
    );
  };
  return (
    <>
      <SubHeaderView text={title || "Choose Payment Method"} />
      <div className="mb-10">
        <SnapSelectMenu
          placeholder="- Select Payment Method -"
          selectMenuOptions={paymentTypeOptions}
          onSnap-select-menu-updated={(e) => {
            const op = e.detail.find((option) => option.selected)?.name;
            if (!op) {
              setSelectedPaymentTypeOption(undefined);
            } else {
              setSelectedPaymentTypeOption(
                op === "Pay by: Card" ? "Pay by: Card" : "Pay by: Bank"
              );
              if (op === "Pay by: Bank" && bankAccounts.length === 1) {
                const ba = bankAccounts[0];
                setAccountStatus({ id: ba.entityId!, status: ba.status ?? "" });
              }
            }
            setPaymentTypeOptions(e.detail);
            if (setAddNewOpen) {
              setAddNewOpen(false);
            }
          }}
        />
        {cardArgs.cardBlock && (
          <p className="mt-3">
            Unable to make card payments of $0.50 or less. Please select another
            payment method.
          </p>
        )}
        {payByCard && extPayId && !cardArgs.cardBlock && (
          <>
            {!widgetOpen && !addNewOpen ? (
              <div className="lg:flex justify-between lg:flex-col mt-2">
                <div className="pr-20 whitespace-nowrap text-xs font-semibold text-gray-500 self-center lg:self-start lg:mb-2">
                  <p>Select from connected Cards</p>
                </div>
                <SnapSelectMenu
                  placeholder="- Select Card -"
                  selectMenuOptions={cardOptions}
                  onSnap-select-menu-updated={(e) => {
                    const op = e.detail.find(
                      (option) => option.selected
                    )?.value;
                    if (!op) {
                      setAccountStatus(undefined);
                      setSelectedCard(undefined);
                    } else {
                      const pm = cards.find((p) => p.id === op);
                      setSelectedCard(pm);
                    }
                    setCardOptions(e.detail);
                  }}
                />
              </div>
            ) : (
              <OffSessionPaymentComponent
                returnUrl={`${document.baseURI}&externalId=${extPayId}`}
                payerDetails={{
                  id: "",
                  name: activeUser?.getName() ?? "",
                  email: activeUser?.getEmail() ?? "",
                }}
                destination={groupAccountId}
                externalPaymentId={extPayId}
                paymentMethodCreate={() => {
                  cardCreate();
                  if (setAddNewOpen) {
                    setAddNewOpen(false);
                  }
                }}
              />
            )}
          </>
        )}
        {payByBank && bankAccounts.length > 0 && !addNewOpen ? (
          <div className="lg:flex justify-between lg:flex-col mt-2">
            <div className="pr-20 whitespace-nowrap text-xs font-semibold text-gray-500 self-center lg:self-start lg:mb-2">
              <p>Linked Bank</p>
            </div>
            <SnapSelectMenu
              placeholder="- Select Bank Account -"
              selectMenuOptions={bankOptions}
              onSnap-select-menu-updated={(e) => {
                const op = e.detail.find((option) => option.selected)?.value;
                const ba = bankAccounts.find((b) => b.entityId === op);
                if (ba) {
                  setAccountStatus({
                    id: ba.entityId!,
                    status: ba.status ?? "",
                  });
                }
                setSelectedBankAccount(ba);
                setBankOptions(e.detail);
              }}
            />
            {isVerifying(accountStatus) && (
              <div className="lg:flex lg:mt-4 mt-10">
                <LinkBank
                  openPlaid={openPlaid}
                  setPlaidConfig={setPlaidConfig}
                  toast={toast}
                  type={"user"}
                  labelText={"Verify Account"}
                  groupId={groupId}
                  externalAuthId={accountStatus!.id}
                  hasLabel={false}
                  onSuccess={() => {
                    if (setAddNewOpen) {
                      setAddNewOpen(false);
                    }
                  }}
                />
                <p
                  className="self-center text-red-500 font-bold cursor-pointer"
                  onClick={() =>
                    deletePlaidLink({
                      variables: { id: accountStatus!.id },
                    })
                  }
                >
                  Delete Account
                </p>
              </div>
            )}
          </div>
        ) : (
          payByBank && (
            <div className="lg:flex">
              <LinkBank
                openPlaid={openPlaid}
                setPlaidConfig={setPlaidConfig}
                toast={toast}
                type={"user"}
                labelText={"Add Account"}
                groupId={groupId}
                onSuccess={() => {
                  if (setAddNewOpen) {
                    setAddNewOpen(false);
                  }
                }}
              />
            </div>
          )
        )}
        {setAddNewOpen &&
          !addNewOpen &&
          ((payByBank && bankAccounts.length > 0) ||
            (payByCard && cardArgs.cardOptions.length > 0)) && (
            <p
              className="text-sm text-blue-600 font-semibold mt-4 cursor-pointer"
              onClick={() => {
                setAddNewOpen(true);
              }}
            >
              Add New {payByCard ? "Card" : "Bank"}
            </p>
          )}
        {setAddNewOpen && addNewOpen && (
          <p
            className="text-sm text-red-600 font-semibold mt-4 cursor-pointer"
            onClick={() => {
              setAddNewOpen(false);
            }}
          >
            Cancel
          </p>
        )}
      </div>
    </>
  );
}
