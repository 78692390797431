import { useQuery } from "@apollo/client";
import { SnapSelectMenuOption } from "@snap-mobile/snap-ui/dist/types/utils";
import GroupContext from "context/group-context";
import ToastContext from "context/toast-context";
import UserContext from "context/user-context";
import cuid from "cuid";
import {
  SpendBankAccount,
  SpendGroupRoster,
  useExternalAccountsListQuery,
  useSpendInvoiceByIdLazyQuery,
  useSpendUserAchPaymentMutation,
} from "graphql/generated";
import {
  GET_GROUP_ROSTERS,
  GET_GUARDIAN_GROUP_ROSTERS,
} from "graphql/queries/group";
import { GET_GUARDIAN_HIGHLIGHT_2 } from "graphql/queries/user";
import { FormatMoney } from "helpers/format-money";
import { generateIdempotencyKey } from "helpers/idempotency";
import { CalcSum, MapAndCalcSum } from "helpers/map-and-reduce";
import useToast from "hooks/use-toast";
import { useContext, useEffect, useState } from "react";
import { PlaidLinkOptions, usePlaidLink } from "react-plaid-link";
import LinkBank from "shared-components/banking/link-bank";
import ToastMessage from "shared-components/toast-message";
import {
  SnapButton,
  SnapCheckboxButton,
  SnapRadioButton,
  SnapSelectMenu,
} from "suit";
import { SelectedInvoice } from "types/invoice";
import CustomModal from "..";
import DotProgress from "../../dot-progress";
import PaymentComponent, {
  PaymentComponentProps,
} from "../../payment-component/payment-component";
import { InvoiceCardView } from "./invoice-card-view";
import { InvoiceTableView } from "./invoice-table-view";
import { calcFee, defaultSelectedPaymentOption } from "./make-payment-helper";
import { SelectedPlayer } from "./selected-player";
import { SubHeaderView } from "./sub-header-view";
import SplitIOContext from "context/splitio-context";
import { useContextStrict } from "helpers/context-strict";
import { prepAndTrimDescription } from "helpers/payment-description";
import { extractBankLabel } from "helpers/banking";
type MakePaymentModalProps = {
  makePaymentDataModalOpen:
    | { isOpen: boolean; groupRosterId?: string }
    | undefined;
  setMakePaymentDataModalOpen:
    | React.Dispatch<
        React.SetStateAction<{ isOpen: boolean; groupRosterId?: string }>
      >
    | undefined;
  setFabOpen?: React.Dispatch<React.SetStateAction<boolean>>;
};
export const MakePaymentModal = ({
  makePaymentDataModalOpen,
  setMakePaymentDataModalOpen,
  setFabOpen,
}: MakePaymentModalProps) => {
  const activeUser = useContext(UserContext);
  const activeGroup = useContext(GroupContext)?.activeGroup;
  const toast = useContext(ToastContext);
  const splits = useContextStrict(SplitIOContext);
  useEffect(() => {
    setSpe1741Webhook(splits.isTreatmentOn(splits.act.spe1741));
  }, [splits]);
  const [spe1741Webhook, setSpe1741Webhook] = useState<boolean | undefined>();
  const extPayId = cuid();
  const { data: getGroupData } = useQuery(GET_GUARDIAN_GROUP_ROSTERS, {
    skip: !makePaymentDataModalOpen?.isOpen,
  });
  const { data: accountsData, loading: accountsLoading } =
    useExternalAccountsListQuery({
      skip: !makePaymentDataModalOpen,
    });
  const [
    achPayment,
    { data: createData, loading: createLoading, error: createError },
  ] = useSpendUserAchPaymentMutation({
    refetchQueries: [
      { query: GET_GROUP_ROSTERS },
      { query: GET_GUARDIAN_GROUP_ROSTERS },
      {
        query: GET_GUARDIAN_HIGHLIGHT_2,
      },
    ],
  });

  const [getInvoiceById] = useSpendInvoiceByIdLazyQuery();

  const [paymentData, setPaymentData] = useState<
    PaymentComponentProps | undefined
  >(undefined);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [step, setStep] = useState(0);
  const [payNowAmount, setPayNowAmount] = useState(0);
  const [achAuthorize, setAchAuthorize] = useState<string | undefined>();
  const [feesAmount, setFeesAmount] = useState(0);
  const [bankAccounts, setBankAccounts] = useState<SpendBankAccount[]>([]);
  const [selectedBankAccount, setSelectedBankAccount] = useState<
    SpendBankAccount | undefined
  >();
  const [selectableInvoices, setSelectableInvoices] = useState<
    SelectedInvoice[]
  >([]);
  // const [selectedCount, setSelectedCount] = useState<number>(0);
  const [playerSelected, setPlayerSelected] = useState<
    SpendGroupRoster | undefined
  >();
  const [players, setPlayers] = useState<SpendGroupRoster[] | undefined>([]);
  const [showNoInvoicesMessage, setShowNoInvoiceMessage] =
    useState<boolean>(false);
  const [selectedPaymentTypeOption, setSelectedPaymentTypeOption] = useState<
    `Pay by: ${"Card" | "Bank"}` | undefined
  >();
  const [paymentOptions, setPaymentOptions] = useState<SnapSelectMenuOption[]>(
    defaultSelectedPaymentOption
  );
  const [bankOptions, setBankOptions] = useState<SnapSelectMenuOption[]>([]);

  const [plaidConfig, setPlaidConfig] = useState<PlaidLinkOptions>({
    onSuccess: (_public_token, _metadata) => {},
    onExit: (_err, _metadata) => {},
    onEvent: (_eventName, _metadata) => {},
    token: "",
  });

  const { isToastOpen, ...toasty } = useToast();
  const payByBank = selectedPaymentTypeOption === "Pay by: Bank";
  const payByCard = selectedPaymentTypeOption === "Pay by: Card";

  const { open, ready } = usePlaidLink(plaidConfig);
  const openPlaid = () => {
    if (ready) {
      open();
    }
  };
  useEffect(() => {
    if (makePaymentDataModalOpen?.groupRosterId) {
      const findPlayer = players?.find(
        (player) => player.id === makePaymentDataModalOpen.groupRosterId
      );

      if (findPlayer) {
        selectPlayer(findPlayer);
        setStep(1);
      }
    }
  }, [makePaymentDataModalOpen?.groupRosterId, players]);

  const toggleMakePaymentModal = () => {
    if (setFabOpen) {
      setFabOpen(false);
    }
    setIsOpen(!isOpen);
    setStep(0);
    setErrors({});
    if (playerSelected) {
      setPlayerSelected(undefined);
      setIsDisable(true);
    }
    setMakePaymentDataModalOpen &&
      setMakePaymentDataModalOpen({ isOpen: false });
    setIsAuthorized(false);
    setAchAuthorize(undefined);
  };

  const [isDisable, setIsDisable] = useState(true);
  const [isDisableForInvoice, setIsDisbaleForInvoices] = useState(true);
  const [bankPaymentSubmitted, setBankPaymentSubmitted] = useState(false);

  const selectPlayer = (player: SpendGroupRoster) => {
    setPlayerSelected(player);
    // show invoices that are not paid
    if (player && player.invoices) {
      const unpaidInvoices = player.invoices
        .filter((invoice) => !invoice?.paid && invoice?.optedOutAt == null)
        .sort((a, b) =>
          a?.dueDate! > b?.dueDate! ? 1 : a?.dueDate! < b?.dueDate! ? -1 : 0
        )
        .map((invoice) => ({ ...invoice, selected: false }));
      setSelectableInvoices(unpaidInvoices);
      setSelectedPaymentTypeOption(undefined);
      setPaymentOptions(defaultSelectedPaymentOption);
      setIsDisable(false);
    }
  };

  const ActionPayByBank = () => {
    setBankPaymentSubmitted(true);
    if (!achAuthorize) {
      toast?.setToastProps({
        message: "Authorizing payment is required.",
        type: "warning",
      });
      setBankPaymentSubmitted(false);
      return;
    }
    const invoiceIds = selectableInvoices.reduce((acc, invoice) => {
      if (invoice.id && invoice.selected) {
        acc.push(invoice.id);
      }
      return acc;
    }, [] as string[]);
    if (!invoiceIds || invoiceIds.length === 0) {
      console.warn("There is no invoices selected to pay. Exit");
      setBankPaymentSubmitted(false);
      return;
    }
    const targetGroupId = playerSelected?.groupId;
    if (targetGroupId) {
      const targetGroupName = playerSelected?.group?.name;
      achPayment({
        variables: {
          input: {
            description: prepAndTrimDescription(targetGroupName || "", 22),
            externalId: extPayId,
            groupId: targetGroupId,
            paymentMethodId: selectedBankAccount?.id,
            paymentAmount: payNowAmount,
            invoiceIds,
            authorizedAt: achAuthorize,
          },
        },
      }).then(() => setBankPaymentSubmitted(false));
    } else {
      console.warn("Player selected has no group Id");
    }
  };
  useEffect(() => {
    if (!createLoading && createData && createData.spendUserAchPayment) {
      if (toast) {
        const message = `Ach payment sent with status ${createData.spendUserAchPayment.status}.`;
        toast.setToastProps({
          message: message,
          type: "success",
        });
        toast.toggleToast();
        toggleMakePaymentModal();
      }
      setBankPaymentSubmitted(false);
    } else if (!createLoading && createError?.message) {
      if (toasty) {
        toasty.setToastProps({
          title: "Payment Failure",
          message: createError?.message,
          type: "danger",
        });
        toasty.toggleToast();
      }
      setBankPaymentSubmitted(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createData, createLoading]);

  useEffect(() => {
    if (getGroupData?.spendGuardianGroupRosters?.groupRosters) {
      // only show players with invoices that are not paid
      setPlayers(
        getGroupData.spendGuardianGroupRosters.groupRosters.filter(
          (groupRoster: SpendGroupRoster) => {
            if (
              groupRoster &&
              groupRoster.invoices &&
              !groupRoster.isArchived &&
              groupRoster.invoices.length > 0 &&
              groupRoster.joinedAt != null
            ) {
              if (groupRoster.invoices.some((invoice) => !invoice?.paid)) {
                return true;
              }
            }
            return false;
          }
        )
      );
    }
  }, [getGroupData]);

  useEffect(() => {
    const percentFee =
      selectedPaymentTypeOption === "Pay by: Bank"
        ? activeGroup?.organizationFees?.achPercent ?? 0
        : activeGroup?.organizationFees?.cardPercent ?? 0;
    const baseFee =
      selectedPaymentTypeOption === "Pay by: Bank"
        ? activeGroup?.organizationFees?.achBaseFee ?? 0
        : activeGroup?.organizationFees?.cardBaseFee ?? 0;

    const selectedInvoices = selectableInvoices.filter(
      (inv) => inv.selected && (inv.optedIn || !inv.isOptional)
    );
    const calcFees = CalcSum(
      selectedInvoices.map((inv) => {
        return calcFee(inv.balanceDue ?? 0, percentFee, baseFee);
      })
    );

    const calcSum = MapAndCalcSum(selectedInvoices, "balanceDue");
    setFeesAmount(calcFees);
    setPayNowAmount(Math.round(calcSum + calcFees));
  }, [
    selectableInvoices,
    // selectedCount,
    selectedPaymentTypeOption,
    activeGroup,
  ]);

  useEffect(() => {
    if (!accountsLoading && accountsData?.spendUserBankAccountsList) {
      const tempAccounts = [
        ...(accountsData.spendUserBankAccountsList.externalAccounts || []),
      ];
      setBankAccounts(tempAccounts);

      setBankOptions(
        tempAccounts.map((tAccount, idx) => ({
          name: extractBankLabel(tAccount),
          value: `${tAccount.id}`,
          selected: idx === 0,
        }))
      );
      setSelectedBankAccount(tempAccounts?.at(0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountsLoading, accountsData?.spendUserBankAccountsList]);

  useEffect(() => {
    setShowNoInvoiceMessage((players?.length ?? 0) === 0);
  }, [players]);

  useEffect(() => {
    if (spe1741Webhook != null) {
      if (step === 2 && payByCard) {
        const selectedInvoiceIds = selectableInvoices
          .filter((inv) => inv.selected && inv.optedIn)
          .map((inv) => inv.id);
        const data: any = {
          invoiceIds: selectedInvoiceIds,
        };
        const {
          id: groupId,
          organizationId,
          sharedAccount,
        } = playerSelected?.group || {
          id: undefined,
          organizationId: undefined,
          sharedAccount: false,
        };
        const urlData = btoa(JSON.stringify(data));
        const groupRosterId = playerSelected?.id;
        const targetAccountId = playerSelected?.group?.accountId;
        const targetId =
          (sharedAccount ? organizationId : groupId) || "unknown";
        const targetGroupName = playerSelected?.group?.name;
        const activeUserId = activeUser?.getUserId();
        const activeUserName = activeUser?.getName();
        const activeUserEmail = activeUser?.getEmail();
        if (
          groupRosterId &&
          targetId &&
          targetAccountId &&
          activeUserId &&
          activeUserName &&
          activeUserEmail
        ) {
          generateIdempotencyKey(
            [
              targetId,
              activeUserId,
              prepAndTrimDescription(targetGroupName || "", 22) ?? "",
              `${payNowAmount}`,
            ].concat(selectedInvoiceIds as string[]),
            { hash: true, sort: true }
          ).then((value: string) => {
            const invoiceObj: any = {};
            if (spe1741Webhook) {
              for (let i = 0; i < selectedInvoiceIds.length; i++) {
                invoiceObj[`invoiceId${i + 1}`] = selectedInvoiceIds[i];
              }
              invoiceObj[`spendInvoiceCount`] = selectedInvoiceIds.length;
            }
            const metadata = {
              spendGroupRosterId: groupRosterId,
              spendPaymentType: "parentPayment",
              spendSourceId: activeUserId,
              spendDestinationId: targetId,
              spendExternalId: extPayId,
              spendUserId: activeUserId,
              spendPaymentWidget: "onSession",
              ...invoiceObj,
            };

            setPaymentData({
              finalDestination: targetAccountId,
              description: prepAndTrimDescription(targetGroupName || "", 22),
              amount: payNowAmount,
              snapAmount: feesAmount,
              metadata,
              returnUrl: spe1741Webhook
                ? document.baseURI
                : `${document.baseURI}?data=${urlData}`,
              payerDetails: {
                id: activeUserId,
                name: activeUserName,
                email: activeUserEmail,
              },
              externalPaymentId: extPayId,
              idempotencyKey: value,
            });
          });
        } else {
          toasty.setToastProps({
            message: "An error occured setting up your payment.",
            type: "danger",
          });
          toasty.toggleToast();
        }
      } else {
        setPaymentData(undefined);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step, spe1741Webhook]);

  const handleCheckId = (e: SelectedInvoice | null, checked?: any) => {
    let tempList = [...selectableInvoices];
    if (e === null) {
      tempList = tempList.map((sInv) => {
        if (sInv.isOptional && !checked) {
          return {
            ...sInv,
            selected: checked,
            optedIn: sInv.isOptional ? checked : sInv.optedIn,
          };
        } else {
          return {
            ...sInv,
            selected: checked,
          };
        }
      });
      setSelectableInvoices(tempList);
    } else {
      const index = tempList.findIndex((inv) => inv.id === e?.id);
      if (e.isOptional) {
        e.optedIn = checked;
      }
      tempList.splice(index, 1, { ...e, selected: checked });
      setSelectableInvoices(tempList);
    }
    if (tempList.filter((inv) => inv.selected).length === 0) {
      setIsDisbaleForInvoices(true);
    } else {
      setIsDisbaleForInvoices(false);
    }
  };

  const handleOptStatus = (status: boolean, invoice: SelectedInvoice) => {
    let idx = selectableInvoices.findIndex((i) => i.id === invoice?.id);
    let tempList = [...selectableInvoices];
    tempList.splice(idx, 1, { ...invoice, optedIn: status });
    setSelectableInvoices(tempList);
  };

  const [errors, setErrors] = useState<Record<string, string>>({});

  const handleValidateInvoices = async () => {
    const selectedInvoices = selectableInvoices.filter((si) => si.selected);
    let paid = false;
    for (const i of selectedInvoices) {
      if (i.id) {
        const resp = await getInvoiceById({
          variables: {
            id: i.id,
          },
        });
        if (resp.data?.spendInvoiceById?.paid) {
          paid = true;
        }
      }
    }
    if (!paid) {
      setStep(step + 1);
    } else {
      toasty.setToastProps({
        message:
          "One or more of the selected invoices have already been paid. Please refresh to re-sync",
        type: "danger",
      });
      toasty.toggleToast();
    }
  };

  return (
    <>
      <CustomModal
        isOpen={makePaymentDataModalOpen?.isOpen || false}
        toggle={toggleMakePaymentModal}
        title="Make Payment"
        customStyle="lg:w-[974px]"
      >
        <div className="modal-card lg:h-[500px] overflow-auto">
          {isToastOpen && (
            <ToastMessage
              message={toasty.message}
              isToastOpen={isToastOpen}
              toggleToast={function (): void {
                toasty.toggleToast();
              }}
              type={toasty.type}
            />
          )}
          {showNoInvoicesMessage && (
            <div className="mx-3">
              <h2 className="font-semibold text-center mt-4">
                No Invoices Due
              </h2>
            </div>
          )}
          {!showNoInvoicesMessage && (
            <div className="mx-3">
              <DotProgress state={step > 2 ? 2 : step} />
              <SubHeaderView text={"Choose Participant"} hideDivider={true} />
              {step < 1 && (
                <ul className="my-3 space-y-2">
                  {players &&
                    players.map((player, index) => {
                      const notPublished =
                        player.paymentScheduleStatus?.toLowerCase() !==
                        "published";
                      return (
                        <li key={`${player.id}-${index}`}>
                          <div
                            className={`flex rounded-2xl p-4 border-2 ${
                              notPublished
                                ? "border-gray-200 bg-gray-50"
                                : "hover:border-blue-400"
                            }`}
                          >
                            <SnapRadioButton
                              className={`mt-[2px] grow ${
                                notPublished ? "opacity-60" : ""
                              }`}
                              label={`${player.roster?.name} (${player.group?.name} | ${player.season?.name})`}
                              checked={playerSelected === player}
                              onClick={(_e) => {
                                if (notPublished) {
                                  setErrors({
                                    ...errors,
                                    [player.id || index]:
                                      "Payment schedule is not published",
                                  });
                                } else {
                                  setErrors({});
                                  selectPlayer(player);
                                }
                              }}
                              disabled={notPublished}
                            />
                            {notPublished && (
                              <span
                                className={
                                  "justify-end font-semibold text-red-400 capitalize"
                                }
                              >
                                ({player.paymentScheduleStatus})
                              </span>
                            )}
                          </div>
                          {errors[player.id || index] && (
                            <span
                              className={"ml-4 text-xs italic text-red-600"}
                            >
                              {errors[player.id || index]}
                            </span>
                          )}
                        </li>
                      );
                    })}
                </ul>
              )}
            </div>
          )}

          {step === 1 && (
            <div className="mx-3 mt-2">
              <SelectedPlayer playerSelected={playerSelected} />
              <SubHeaderView text={"Choose Invoices to Pay Now"} />
              <div className="hidden lg:flex">
                <InvoiceTableView
                  list={selectableInvoices}
                  isReadonly={false}
                  handleCheckId={handleCheckId}
                  handleOptStatus={handleOptStatus}
                />
              </div>
              <div>
                <InvoiceCardView
                  list={selectableInvoices}
                  isReadonly={false}
                  handleCheckId={handleCheckId}
                  handleOptStatus={handleOptStatus}
                />
              </div>
              <p className="text-sm text-gray-400 mt-4 lg:mt-0">
                *This includes fees to support our platform's technology and
                security safeguards
              </p>
              <SubHeaderView text={"Choose Payment Method"} />
              <div className="mb-10">
                <SnapSelectMenu
                  placeholder="- Select Payment Method -"
                  selectMenuOptions={paymentOptions}
                  onSnap-select-menu-updated={(e) => {
                    const op = e.detail.find((option) => option.selected)?.name;
                    if (!op) {
                      setSelectedPaymentTypeOption(undefined);
                      setPaymentOptions(e.detail);
                    } else {
                      setSelectedPaymentTypeOption(
                        op === "Pay by: Card" ? "Pay by: Card" : "Pay by: Bank"
                      );
                      setPaymentOptions(e.detail);
                    }
                  }}
                />
              </div>
              <SnapCheckboxButton
                checked={isAuthorized}
                label={`I authorize Snap! Spend to charge my payment method immediately for ${FormatMoney(
                  payNowAmount
                )}`}
                onClick={(e: any) => setIsAuthorized(e.target.checked)}
              />
            </div>
          )}
          {step === 2 && (
            <div className="mx-3 mt-2">
              <SelectedPlayer playerSelected={playerSelected} />
              <SubHeaderView text={"Choose Invoices"} />
              <div className="hidden lg:flex">
                <InvoiceTableView
                  list={selectableInvoices.filter(
                    (i) => i.optedIn || !i.isOptional
                  )}
                  isReadonly={true}
                  handleCheckId={handleCheckId}
                  handleOptStatus={handleOptStatus}
                />
              </div>
              <InvoiceCardView
                list={selectableInvoices}
                isReadonly={true}
                handleCheckId={handleCheckId}
                handleOptStatus={handleOptStatus}
              />

              <SubHeaderView text={"Choose Payment Method"} />
              {payByBank && (
                <SnapCheckboxButton
                  label="I authorize Snap! Spend to charge my payment method now for the above invoices."
                  onSnap-checkbox-updated={(e) => {
                    setAchAuthorize(
                      e.target.checked ? new Date().toISOString() : undefined
                    );
                  }}
                />
              )}
              {payByCard && paymentData != null && paymentData.amount > 50 && (
                <p>
                  By continuing, you are agreeing to the allow Snap! Spend to
                  make a payment now using the selected payment method.
                </p>
              )}
              {paymentData != null && paymentData.amount <= 50 && (
                <p>
                  Unable to make card payments of $0.50 or less. Please select
                  another payment method.
                </p>
              )}
              {step === 2 &&
                payByCard &&
                paymentData != null &&
                paymentData.amount > 50 &&
                extPayId && (
                  <PaymentComponent
                    key="supersecretkey"
                    description={paymentData.description}
                    finalDestination={paymentData.finalDestination}
                    externalPaymentId={paymentData.externalPaymentId}
                    snapAmount={paymentData.snapAmount}
                    amount={paymentData.amount}
                    metadata={paymentData.metadata}
                    returnUrl={paymentData.returnUrl}
                    payerDetails={paymentData.payerDetails}
                    idempotencyKey={paymentData.idempotencyKey}
                    className="border-0 mx-0 px-0"
                    options={spe1741Webhook}
                  />
                )}
              {step === 2 && payByBank && bankAccounts.length > 0 ? (
                <div className="flex justify-between lg:flex-col mt-2">
                  <SnapSelectMenu
                    placeholder="- Select Bank Account -"
                    selectMenuOptions={bankOptions}
                    className="mb-3"
                    onSnap-select-menu-updated={(e) => {
                      const op = e.detail.find(
                        (option) => option.selected
                      )?.value;
                      if (!op) {
                        setSelectedBankAccount(undefined);
                        setBankOptions(e.detail);
                      } else {
                        const acc = bankAccounts.find((b) => b.id === op);
                        setSelectedBankAccount(acc);
                      }
                    }}
                  />
                  {selectedBankAccount?.status ===
                    "pending_manual_verification" && (
                    <LinkBank
                      openPlaid={openPlaid}
                      setPlaidConfig={setPlaidConfig}
                      toast={toasty}
                      type={"user"}
                      labelText={"Verify Account"}
                      externalAuthId={selectedBankAccount?.entityId}
                    />
                  )}
                </div>
              ) : (
                payByBank && (
                  <LinkBank
                    openPlaid={openPlaid}
                    setPlaidConfig={setPlaidConfig}
                    toast={toasty}
                    type={"user"}
                    labelText={"Add Account"}
                  />
                )
              )}
            </div>
          )}
          <div className="flex justify-end space-x-3 mt-5 m-3">
            {step === 0 && (
              <SnapButton variant="tertiary" onClick={toggleMakePaymentModal}>
                Cancel
              </SnapButton>
            )}
            {step > 0 && (
              <SnapButton
                icon="arrow-narrow-left-line"
                variant="tertiary"
                onClick={() => {
                  if (makePaymentDataModalOpen?.groupRosterId && step === 1) {
                    toggleMakePaymentModal();
                  } else {
                    step > 0 && setStep(step - 1);
                    setIsDisable(false);
                  }
                }}
              >
                Back
              </SnapButton>
            )}
            {step < 2 && (
              <SnapButton
                icon="arrow-narrow-right-line"
                variant="primary"
                disabled={
                  step === 1
                    ? isDisableForInvoice ||
                      selectedPaymentTypeOption === undefined ||
                      !isAuthorized
                    : isDisable
                }
                onClick={() => {
                  if (!isDisable && step === 0) {
                    setStep(step + 1);
                    setIsDisable(true);
                  }
                  if (
                    step === 1 &&
                    !isDisableForInvoice &&
                    selectedPaymentTypeOption !== undefined
                  ) {
                    handleValidateInvoices();
                  }
                }}
              >
                Next
              </SnapButton>
            )}
            {step >= 2 && payByBank && (
              <SnapButton
                variant="primary"
                disabled={
                  bankPaymentSubmitted ||
                  selectedBankAccount == null ||
                  achAuthorize == null ||
                  selectedBankAccount?.status === "pending_manual_verification"
                }
                onClick={ActionPayByBank}
              >
                {`Pay${
                  payNowAmount === 0 ? "" : " " + FormatMoney(payNowAmount)
                } Now`}
              </SnapButton>
            )}
          </div>
        </div>
      </CustomModal>
    </>
  );
};
