import { createReactComponent } from "./create-component/create-component";
import { JSX } from "@snap-mobile/snap-ui/dist/types";

export const SnapMainNavigation = createReactComponent<
  JSX.SnapMainNavigation,
  HTMLSnapMainNavigationElement
>("snap-main-navigation");
export const SnapGlobalHeader = createReactComponent<
  JSX.SnapGlobalHeader,
  HTMLSnapGlobalHeaderElement
>("snap-global-header");
export const SnapLogo = createReactComponent<JSX.SnapLogo, HTMLSnapLogoElement>(
  "snap-logo"
);
export const SnapMessagingIcon = createReactComponent<
  JSX.SnapMessagingIcon,
  HTMLSnapMessagingIconElement
>("snap-messaging-icon");
export const SnapppSwitcher = createReactComponent<
  JSX.SnapAppSwitcher,
  HTMLSnapAppSwitcherElement
>("snap-app-switcher");
export const SnapSession = createReactComponent<
  JSX.SnapSession,
  HTMLSnapSessionElement
>("snap-session");
export const SnapOption = createReactComponent<
  JSX.SnapOption,
  HTMLSnapOptionElement
>("snap-option");
export const SnapCardGeneral = createReactComponent<
  JSX.SnapCardGeneral,
  HTMLSnapCardGeneralElement
>("snap-card-general");
export const SnapFilter = createReactComponent<
  JSX.SnapFilter,
  HTMLSnapFilterElement
>("snap-filter");
export const SnapButton = createReactComponent<
  JSX.SnapButton,
  HTMLSnapButtonElement
>("snap-button");
export const SnapTabs = createReactComponent<JSX.SnapTabs, HTMLSnapTabsElement>(
  "snap-tabs"
);
export const SnapIndicator = createReactComponent<
  JSX.SnapIndicator,
  HTMLSnapIndicatorElement
>("snap-indicator");
export const SnapIcon = createReactComponent<JSX.SnapIcon, HTMLSnapIconElement>(
  "snap-icon"
);
export const SnapActionSheet = createReactComponent<
  JSX.SnapActionSheet,
  HTMLSnapActionSheetElement
>("snap-action-sheet");
export const SnapInput = createReactComponent<
  JSX.SnapInput,
  HTMLSnapInputElement
>("snap-input");
export const SnapCheckboxButton = createReactComponent<
  JSX.SnapCheckboxButton,
  HTMLSnapCheckboxButtonElement
>("snap-checkbox-button");
export const SnapBadge = createReactComponent<
  JSX.SnapBadge,
  HTMLSnapBadgeElement
>("snap-badge");
export const SnapSelectMenu = createReactComponent<
  JSX.SnapSelectMenu,
  HTMLSnapSelectMenuElement
>("snap-select-menu");
export const SnapTable = createReactComponent<
  JSX.SnapTable,
  HTMLSnapTableElement
>("snap-table");
export const SnapLink = createReactComponent<JSX.SnapLink, HTMLSnapLinkElement>(
  "snap-link"
);
export const SnapDivider = createReactComponent<
  JSX.SnapDivider,
  HTMLSnapDividerElement
>("snap-divider");
export const SnapDropDown = createReactComponent<
  JSX.SnapDropdown,
  HTMLSnapDropdownElement
>("snap-dropdown");
export const SnapRadioButton = createReactComponent<
  JSX.SnapRadioButton,
  HTMLSnapRadioGroupElement
>("snap-radio-button");
export const SnapPagination = createReactComponent<
  JSX.SnapPagination,
  HTMLSnapPaginationElement
>("snap-pagination");
export const SnapToggle = createReactComponent<
  JSX.SnapToggle,
  HTMLSnapToggleElement
>("snap-toggle");
export const SnapAlert = createReactComponent<
  JSX.SnapAlert,
  HTMLSnapAlertElement
>("snap-alert");
export const SnapAlertTitle = createReactComponent<
  JSX.SnapAlertTitle,
  HTMLSnapAlertTitleElement
>("snap-alert-title");
export const SnapAlertDescription = createReactComponent<
  JSX.SnapAlertDescription,
  HTMLSnapAlertDescriptionElement
>("snap-alert-description");
export const SnapAlertAction = createReactComponent<
  JSX.SnapAlertAction,
  HTMLSnapAlertActionElement
>("snap-alert-action");
export const SnapPieChart = createReactComponent<
  JSX.SnapPieChart,
  HTMLSnapPieChartElement
>("snap-pie-chart");
export const SnapLabeledToggle = createReactComponent<
  JSX.SnapLabeledToggle,
  HTMLSnapLabeledToggleElement
>("snap-labeled-toggle");

export const SnapAppSwitcher = createReactComponent<
  JSX.SnapAppSwitcher,
  HTMLSnapAppSwitcherElement
>("snap-app-switcher");
export const SnapHeaderWrapper = createReactComponent<
  JSX.SnapHeaderWrapper,
  HTMLSnapHeaderWrapperElement
>("snap-header-wrapper");
export const SnapCard = createReactComponent<JSX.SnapCard, HTMLSnapCardElement>(
  "snap-card"
);
export const SnapCardHeader = createReactComponent<
  JSX.SnapCardHeader,
  HTMLSnapCardHeaderElement
>("snap-card-header");
export const SnapCardTitle = createReactComponent<
  JSX.SnapCardTitle,
  HTMLSnapCardTitleElement
>("snap-card-title");
export const SnapCardSubtitle = createReactComponent<
  JSX.SnapCardSubtitle,
  HTMLSnapCardSubtitleElement
>("snap-card-subtitle");
export const SnapCardContent = createReactComponent<
  JSX.SnapCardContent,
  HTMLSnapCardSubtitleElement
>("snap-card-content");
export const SnapCardSection = createReactComponent<
  JSX.SnapCardSection,
  HTMLSnapCardSectionElement
>("snap-card-section");
export const SnapCardContentFooter = createReactComponent<
  JSX.SnapCardContentFooter,
  HTMLSnapCardContentFooterElement
>("snap-card-content-footer");
