import { gql } from "@apollo/client";

export const CHANGE_ROLE = gql`
  mutation SpendRoleChange($roleId: String!, $sessionId: String!) {
    spendRoleChange(roleId: $roleId, sessionId: $sessionId) {
      id
    }
  }
`;

export const UPDATE_USER_ROLE = gql`
  mutation SpendUserRoleUpdate(
    $groupId: String
    $roleName: SpendRoleNameEnum!
    $userId: String!
    $inviteId: String!
  ) {
    spendUserRoleUpdate(
      groupId: $groupId
      roleName: $roleName
      userId: $userId
      inviteId: $inviteId
    ) {
      id
    }
  }
`;

export const SET_DEFAULT_ROLE = gql`
  mutation SpendUserRoleSet($roleId: String!) {
    spendUserRoleSet(roleId: $roleId) {
      id
    }
  }
`;

export const UPDATE_APPROVER_SETTING = gql`
  mutation SpendUserRoleUpdateIsApprover($input: SpendIsApproverUpdateInput) {
    spendUserRoleUpdateIsApprover(input: $input) {
      id
    }
  }
`;

export const ACKNOWLEDGE_NOTIFICATION = gql`
  mutation SpendAcknowledgeNotification(
    $input: SpendUserAcknowledgeNotificationInput
  ) {
    spendUserAcknowledgeNotification(input: $input) {
      success
    }
  }
`;
