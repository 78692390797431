import { ApolloQueryResult, OperationVariables } from "@apollo/client";
import DisplayContext from "context/display-context";
import { Maybe, SpendPayees } from "graphql/generated";
import useModal from "hooks/use-modal";
import { useContext, useState } from "react";
import Divider from "shared-components/divider";
import { SnapButton, SnapDropDown, SnapPagination, SnapTable } from "suit";
import { payeeData } from ".";
import { ITEMS_PER_PAGE } from "../../../constants";
import DeletePayee from "./delete-payee";
import { SpinnerContainer } from "shared-components/spinner";
import EinDisplay from "./ein-display";

type ManagePayeesProps = {
  payeeLoading: boolean;
  payees: SpendPayees[];
  setIsPayeeOpen: React.Dispatch<React.SetStateAction<boolean>>;
  refetchPayees: (
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<any>>;
  setPayeeData: React.Dispatch<React.SetStateAction<payeeData>>;
  payeeData: payeeData;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
  type: "Program" | "Group";
  referenceId: Maybe<string> | undefined;
};

function ManagePayees({
  payeeLoading,
  payees,
  setIsPayeeOpen,
  refetchPayees,
  setPayeeData,
  payeeData,
  setIsEdit,
  type,
  referenceId,
}: ManagePayeesProps) {
  const { isOpen: isDeletePayeeOpen, toggle: toggleDeletePayee } = useModal();
  const display = useContext(DisplayContext);
  const [page, setPage] = useState(0);
  const [payeeId, setPayeeId] = useState("");
  return (
    <div className="modal-card">
      <div className="lg:flex justify-between">
        <p className="font-medium text-gray-500">
          The number of payees on the roster cannot exceed 30.
        </p>
        <SnapButton
          variant="primary"
          size="md"
          className="px-4 mt-4 mb-2 lg:mt-0 lg:mb-0"
          fullWidth
          onClick={() => {
            setIsEdit(false);
            setPayeeData({
              id: "",
              name: "",
              address1: "",
              address2: "",
              city: "",
              state: "",
              zipCode: "",
            });
            setIsPayeeOpen(true);
          }}
        >
          Add Payee
        </SnapButton>
      </div>
      {display?.isDesktop ? (
        <SnapTable>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Street Address</th>
                <th>City</th>
                <th>State</th>
                <th>Zip</th>
                <th>EIN</th>
                <th>{/* actions */}</th>
              </tr>
            </thead>
            <tbody>
              {!payeeLoading ? (
                payees
                  .slice(
                    page * ITEMS_PER_PAGE,
                    page * ITEMS_PER_PAGE + ITEMS_PER_PAGE
                  )
                  .map((payee, idx) => {
                    let address = payee.address1;
                    if (payee.address2 !== null) {
                      address += ` ${payee.address2}`;
                    }

                    return (
                      <tr key={idx}>
                        <td>{payee.name}</td>
                        <td>{address}</td>
                        <td>{payee.city}</td>
                        <td>{payee.state}</td>
                        <td>{payee.zipCode}</td>
                        <td>
                          {payee.einNumber && (
                            <EinDisplay id={payee.id} type="payee" />
                          )}
                        </td>
                        <td align="right">
                          <SnapDropDown
                            minimal
                            options={[
                              {
                                name: "Edit",
                                text: "Edit",
                                value: "Edit",
                                selected: false,
                              },
                              {
                                name: "Delete",
                                text: "Delete",
                                value: "Delete",
                                selected: false,
                              },
                            ]}
                            onSnap-dropdown-item-selected={(e) => {
                              const { __typename, ...payE } = payee;
                              setPayeeData({
                                ...payE,
                                address2: payE.address2 ?? "",
                              });
                              setPayeeId(payee.id);
                              if (e.detail.value === "Edit") {
                                setIsPayeeOpen(true);
                                setIsEdit(true);
                              } else {
                                toggleDeletePayee();
                              }
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })
              ) : (
                <SpinnerContainer loading={payeeLoading} inTable colSpan={6} />
              )}
            </tbody>
          </table>
        </SnapTable>
      ) : !payeeLoading ? (
        payees
          .slice(page * ITEMS_PER_PAGE, page * ITEMS_PER_PAGE + ITEMS_PER_PAGE)
          .map((payee, idx) => {
            let address = payee.address1;
            if (payee.address2 !== null) {
              address += ` ${payee.address2}`;
            }
            return (
              <div key={idx} className="border p-4 rounded-lg mt-4">
                <div className="flex justify-between">
                  <p className="font-medium">{payee.name}</p>
                  <SnapDropDown
                    buttonText="Options"
                    modalType="drawer"
                    minimal
                    options={[
                      {
                        name: "Edit",
                        text: "Edit",
                        value: "Edit",
                        selected: false,
                      },
                      {
                        name: "Delete",
                        text: "Delete",
                        value: "Delete",
                        selected: false,
                      },
                    ]}
                    onSnap-dropdown-item-selected={(e) => {
                      const { __typename, ...payE } = payee;
                      setPayeeData({
                        ...payE,
                        address2: payE.address2 ?? "",
                      });
                      setPayeeId(payee.id);
                      if (e.detail.value === "Edit") {
                        setIsPayeeOpen(true);
                        setIsEdit(true);
                      } else {
                        toggleDeletePayee();
                      }
                    }}
                  />
                </div>
                <Divider isVisibleOnMobile />
                <p className="text-xs font-semibold text-gray-500 mt-2">
                  Address
                </p>
                <p className="text-sm">{address}</p>
                <div className="grid grid-cols-4 mt-4">
                  <div>
                    <p className="text-xs font-semibold text-gray-500">City</p>
                    <p className="text-sm">{payee.city}</p>
                  </div>
                  <div>
                    <p className="text-xs font-semibold text-gray-500">State</p>
                    <p className="text-sm">{payee.state}</p>
                  </div>
                  <div>
                    <p className="text-xs font-semibold text-gray-500">Zip</p>
                    <p className="text-sm">{payee.zipCode}</p>
                  </div>
                  <div>
                    <p className="text-xs font-semibold text-gray-500">EIN</p>
                    {payee.einNumber && (
                      <EinDisplay id={payee.id} type="payee" />
                    )}
                  </div>
                </div>
              </div>
            );
          })
      ) : (
        <SpinnerContainer loading={payeeLoading} />
      )}
      <div className="mt-4">
        <SnapPagination
          itemCount={payees.length}
          currentPage={page}
          pageSize={ITEMS_PER_PAGE}
          onSnap-pagination-page-changed={(event) => setPage(event.detail)}
        ></SnapPagination>
      </div>
      <DeletePayee
        isDeletePayeeOpen={isDeletePayeeOpen}
        toggleDeletePayee={toggleDeletePayee}
        payeeToDelete={{ ...payeeData, id: payeeId }}
        refetchPayees={refetchPayees}
        type={type}
        referenceId={referenceId}
      />
    </div>
  );
}

export default ManagePayees;
