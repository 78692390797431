import GroupContext from "context/group-context";
import SeasonContext from "context/season-context";
import UserContext from "context/user-context";
import {
  useSpendGroupRostersBySeasonV2Query,
  useSpendGroupRostersExportLazyQuery,
} from "graphql/generated";
import useModal from "hooks/use-modal";
import { useSpendPagination } from "hooks/use-spend-pagination";
import { useContext, useEffect, useState } from "react";
import { SnapActionSheet, SnapButton, SnapIcon, SnapPagination } from "suit";
import { SpendPermissions } from "types/roles-permissions";
import AddParticipant from "./modals/add-participant/index";
import FloatingActionBtn from "shared-components/floating-action-btn";
import Divider from "shared-components/divider";
import ShowingResults from "shared-components/showing-results";
import PageLimit from "shared-components/page-limit";
import Sort from "shared-components/sort";
import { rosterSortOptions } from "./modals/add-participant/helper";
import DisplayContext from "context/display-context";
import Cards from "./cards";
import Table from "./table";
import { LabelValueObject } from "types/label-value-object";
import { VerticalValueStyle } from "shared-components/vertical-label-value";
import { FormatDate } from "helpers/format-date";
import BulkActionsV2 from "shared-components/bulk-actions-v2";
import { RosterDataType } from "types/group-roster";
import BadgedTabs from "shared-components/badged-tabs";
import { DropdownMenuItem } from "@snap-mobile/snap-ui/dist/types/utils";
import Spinner, { SpinnerContainer } from "shared-components/spinner";
import { exportToCsv } from "helpers/export-csv";
import SplitIOContext from "context/splitio-context";

const tabs: DropdownMenuItem[] = [
  { name: "Active", text: "", value: "", selected: true },
  { name: "Archived", text: "", value: "", selected: false },
];

function Rosters() {
  const display = useContext(DisplayContext);
  const user = useContext(UserContext);
  const group = useContext(GroupContext);
  const season = useContext(SeasonContext);
  const split = useContext(SplitIOContext);

  const [se2146, setSE2146] = useState(false);

  useEffect(() => {
    const ff = split?.isTreatmentOn(split.act.se2146);
    setSE2146(ff || false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [split]);

  const { isOpen: isAddParticipantOpen, toggle: toggleAddParticipant } =
    useModal();
  const { isOpen: isFabOpen, toggle: toggleFabOpen } = useModal();

  const canEditParticipant =
    user?.checkSpendPermission(SpendPermissions.groupRostersUpdate) ?? false;

  const {
    sort,
    toggleSort,
    sortValue: currentSort,
    handleSortValue,
  } = useSpendPagination();

  const [selectedTab, setSelectedTab] = useState(0);
  const [exporting, setExporting] = useState(false);
  const [page, setPage] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [allSelected, setAllSelected] = useState(false);
  const [selectedRosterData, setSelectedRosterData] = useState<
    RosterDataType[]
  >([]);
  const [seasonRosterData, setSeasonRosterData] = useState<RosterDataType[]>(
    []
  );
  const [
    exportRosters,
    { loading: loadingExportRosters, data: dataExportRosters },
  ] = useSpendGroupRostersExportLazyQuery();

  const {
    loading: loadingRoster,
    data: rosterData,
    refetch: refetchRoster,
  } = useSpendGroupRostersBySeasonV2Query({
    variables: {
      seasonId: season?.selectedSeason?.id ?? "",
      pagination: {
        offset: page * pageLimit,
        limit: pageLimit,
      },
      sort,
      archived: selectedTab === 1,
    },
  });

  useEffect(() => {
    if (
      !loadingRoster &&
      rosterData?.spendGroupRostersBySeasonV2?.groupRosters
    ) {
      const rosterIds = new Set(selectedRosterData.map(({ id }) => id));
      const rosters = rosterData.spendGroupRostersBySeasonV2.groupRosters.map(
        (groupRoster) => {
          let isChecked = false;
          if (groupRoster?.id) {
            isChecked = rosterIds.has(groupRoster.id);
          }
          return {
            ...groupRoster,
            isChecked,
          };
        }
      );
      setSeasonRosterData(rosters);
    }
    // eslint-disable-next-line
  }, [loadingRoster, rosterData?.spendGroupRostersBySeasonV2]);

  useEffect(() => {
    let rosterItems = seasonRosterData.slice();
    let selected = rosterItems.filter((i) => i.isChecked);
    setAllSelected(
      rosterItems.length !== 0 && rosterItems.length === selected.length
    );
  }, [page, seasonRosterData]);

  useEffect(() => {
    setSelectedRosterData([]);
    setPage(0);
    setAllSelected(false);
    refetchRoster();
    // eslint-disable-next-line
  }, [selectedTab]);

  const handleSelectall = (
    selectedItems: RosterDataType[],
    selectAll: boolean,
    isChecked: boolean
  ) => {
    let tempSelectedItems = [...selectedRosterData];
    let tempItems: RosterDataType[] = [...seasonRosterData];

    selectedItems.forEach((item) => {
      let updatedItem = {
        ...item,
        isChecked: !item.isChecked,
      };

      if (selectAll) {
        updatedItem.isChecked = isChecked;
      }

      const foundItem = tempSelectedItems.find(
        (tempItem) => tempItem.id === item.id
      );
      if (!foundItem && updatedItem.isChecked) {
        tempSelectedItems.push(updatedItem);
      } else if (foundItem) {
        const idx = tempSelectedItems.findIndex(
          (item) => item.id === updatedItem.id
        );
        tempSelectedItems.splice(idx, 1);
      }

      const rosterIdx = tempItems.findIndex(
        (item) => item.id === updatedItem.id
      );

      tempItems.splice(rosterIdx, 1, updatedItem);
    });
    setSeasonRosterData(tempItems);
    setSelectedRosterData(tempSelectedItems);
  };

  const handleRosterDataInviteStatus = (data: RosterDataType) => {
    if (data.invite) {
      switch (data.invite.status?.toLowerCase()) {
        case "canceled":
          return "Canceled";
        case "pending":
        case "sent":
          return "Not Signed Up";
        case "accepted":
          return "Accepted";
        case "expired":
          return "Expired";
        default:
          return "No Invite Sent";
      }
    } else {
      return "No Invite Sent";
    }
  };

  const handleExport = () => {
    if (!exporting) {
      setExporting(true);
      exportRosters({
        variables: {
          filters: {
            seasonId: season?.selectedSeason?.id,
          },
        },
      }).then(() => setExporting(false));
    }
  };

  useEffect(() => {
    if (dataExportRosters && dataExportRosters.spendGroupRostersExport) {
      setExporting(false);
      exportToCsv(dataExportRosters.spendGroupRostersExport);
    }
  }, [loadingExportRosters, dataExportRosters]);

  const prepRightData = (item: RosterDataType, status: string) => {
    const rightData: LabelValueObject[] = [];
    rightData.push({ key: "Email", value: item.roster?.email ?? "" });
    rightData.push({
      key: "Status",
      value: status,
      valueStyle: VerticalValueStyle.Badge,
      className: "flex-col",
    });
    rightData.push({ key: "Joined At", value: FormatDate(item.joinedAt) });

    return rightData;
  };

  const handleDeselectAll = () => {
    const resetData = seasonRosterData.map((item) => {
      return {
        ...item,
        isChecked: false,
      };
    });
    setSeasonRosterData(resetData);
    setSelectedRosterData([]);
  };

  return (
    <div className="wrapper">
      <div className="card">
        <div className="flex h-12">
          <p className="mr-auto self-center text-lg font-semibold">
            Participants Roster
          </p>
          {selectedTab === 0 && (
            <div className="lg:flex hidden items-center">
              {se2146 && (
                <div
                  className="flex justify-end text-blue-600 mr-6 font-bold lg:text-base sm:text-sm cursor-pointer"
                  onClick={handleExport}
                >
                  {exporting ? (
                    <Spinner size="small" />
                  ) : (
                    <SnapIcon icon="download-solid" size="sm"></SnapIcon>
                  )}
                  <p className="self-center pl-2">Export All to CSV</p>
                </div>
              )}
              <SnapButton
                hidden={!canEditParticipant || group?.isArchived}
                variant="primary"
                onClick={toggleAddParticipant}
              >
                + Add Participant
              </SnapButton>
            </div>
          )}
          {isFabOpen && canEditParticipant && (
            <SnapActionSheet
              header="Collection's Actions"
              onClick={toggleFabOpen}
            >
              <Divider isVisibleOnMobile className="mt-0" />
              <div className="mx-4 mt-4" onClick={toggleAddParticipant}>
                <p>Add Participant</p>
              </div>
            </SnapActionSheet>
          )}
        </div>
        <BadgedTabs
          tabs={tabs}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
        <div className="lg:flex mt-4">
          <ShowingResults
            totalNumOfResults={
              rosterData?.spendGroupRostersBySeasonV2?.count ||
              seasonRosterData.length
            }
            numOfResultsBeingDisplayed={
              (rosterData?.spendGroupRostersBySeasonV2?.count ?? 0) <= 10
                ? seasonRosterData.length
                : pageLimit * page + pageLimit >=
                  (rosterData?.spendGroupRostersBySeasonV2?.count ?? 0)
                ? rosterData?.spendGroupRostersBySeasonV2?.count ?? 0
                : pageLimit * page + pageLimit
            }
            startingNumOfResults={
              seasonRosterData.length === 0 ? 0 : pageLimit * page + 1
            }
            hasCheckbox
            checkboxAction={(e) => {
              handleSelectall(seasonRosterData, true, e.target.checked);
            }}
            isNameBoxChecked={allSelected}
            hideCheckboxOnWeb
          />
          <PageLimit
            setPageLimit={setPageLimit}
            setPage={setPage}
            localStorageName={"collections-page-limit"}
          />
        </div>
        <Divider isVisibleOnMobile className="my-5 lg:hidden" />
        <Sort
          selectedSortOption={currentSort}
          options={rosterSortOptions}
          handleSort={(e) => handleSortValue(e.value)}
        />
        {loadingRoster ? (
          <SpinnerContainer loading={true} />
        ) : display?.isMobile ? (
          <Cards
            rosterData={seasonRosterData}
            canEditParticipant={canEditParticipant}
            isArchived={group?.isArchived ?? false}
            handleSelectall={handleSelectall}
            allSelected={allSelected}
            handleRosterDataInviteStatus={handleRosterDataInviteStatus}
            prepRightData={prepRightData}
          />
        ) : (
          <Table
            rosterData={seasonRosterData}
            canEditParticipant={canEditParticipant}
            isArchived={group?.isArchived ?? false}
            handleSelectall={handleSelectall}
            allSelected={allSelected}
            sort={sort}
            toggleSort={toggleSort}
            handleRosterDataInviteStatus={handleRosterDataInviteStatus}
          />
        )}
        <SnapPagination
          currentPage={page}
          itemCount={rosterData?.spendGroupRostersBySeasonV2?.count || 0}
          pageSize={pageLimit}
          onSnap-pagination-page-changed={(e) => setPage(e.detail)}
        />
      </div>
      {isAddParticipantOpen && (
        <AddParticipant
          isAddParticipantOpen={isAddParticipantOpen}
          toggleAddParticipant={toggleAddParticipant}
          seasonRoster={[]}
        />
      )}
      {!group?.isArchived && selectedTab === 0 && (
        <FloatingActionBtn
          onClick={toggleFabOpen}
          icon={"dots-horizontal-solid"}
        />
      )}
      {selectedRosterData.length > 0 && (
        <BulkActionsV2
          selectedItems={selectedRosterData.map((item) => {
            return {
              email: item.roster?.email ?? "",
              groupRosterId: item.id ?? "",
              userId: item.userId ?? "",
              groupId: item.group?.id ?? "",
              seasonId: item.seasonId ?? "",
              inviteId: item.invite?.id ?? undefined,
            };
          })}
          handleDeselectAll={handleDeselectAll}
          type="rosters"
          selectedTab={selectedTab}
        />
      )}
    </div>
  );
}

export default Rosters;
