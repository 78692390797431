import CustomModal, { BtnState } from "shared-components/modal";
import { SnapAlert, SnapAlertTitle, SnapInput } from "suit";
import TableRowLabelValue from "shared-components/table-row-label-value";
import { useEffect, useState } from "react";
import { ParticipantDetailType } from "..";
import { useSpendUpdateGroupRosterMutation } from "graphql/generated";
import { emptyStringCheck } from "helpers/empty-string-check";
import { ValidateEmail } from "helpers/validation";
import { useAppContexts } from "hooks/use-context";

type EditParticipantProps = {
  isOpen: boolean;
  toggle: () => void;
  participantDetails: Partial<ParticipantDetailType>;
  hasPendingInvite: boolean;
};
function EditParticipant({
  isOpen,
  toggle,
  participantDetails,
  hasPendingInvite,
}: EditParticipantProps) {
  const { toast, split, user } = useAppContexts();
  const [btnState, setBtnState] = useState(BtnState.BASE);
  const [name, setName] = useState<string | undefined>(undefined);
  const [addParticipantEmailFlag, setAddParticipantEmailFlag] =
    useState<boolean>(false);

  const [email, setEmail] = useState<string | undefined>(undefined);
  const [participantEmail, setParticipantEmail] = useState<string | undefined>(
    undefined
  );
  useEffect(() => {
    const ff = split?.isTreatmentOn(split.act.se2144);
    setAddParticipantEmailFlag(ff || false);
  }, [split]);
  const [updateRoster, { data, loading, error }] =
    useSpendUpdateGroupRosterMutation({
      refetchQueries: ["GetParticipantsDetails", "SpendGuardianHighlight2"],
    });

  useEffect(() => {
    if (!loading && data) {
      toast?.setToast({
        message: "Roster saved successfuly",
        type: "success",
      });
      toggle();
    } else if (!loading && error) {
      toast?.setToast({
        message: "Error saving roster",
        type: "danger",
      });
    }
    setBtnState(BtnState.BASE);
    // eslint-disable-next-line
  }, [loading, data, error]);

  const handleSave = () => {
    setBtnState(BtnState.DISABLED);
    if (!participantDetails?.rosterId) {
      toast?.setToast({
        message: "Missing participant roster id",
        type: "danger",
      });
      setBtnState(BtnState.BASE);
      return;
    }
    if (user?.isParent()) {
      if (emptyStringCheck(participantEmail)) {
        toast?.setToast({
          message: "Participant Email field is required",
          type: "danger",
        });
        setBtnState(BtnState.BASE);
        return;
      }
    } else {
      if (addParticipantEmailFlag) {
        if (
          emptyStringCheck(email) &&
          emptyStringCheck(name) &&
          emptyStringCheck(participantEmail)
        ) {
          toast?.setToast({
            message: "Fields are empty, no changes can be made.",
            type: "danger",
          });
          setBtnState(BtnState.BASE);
          return;
        }
      } else {
        if (emptyStringCheck(email) && emptyStringCheck(name)) {
          toast?.setToast({
            message: "Fields are empty, no changes can be made.",
            type: "danger",
          });
          setBtnState(BtnState.BASE);
          return;
        }
      }
    }

    if (!emptyStringCheck(email) && !ValidateEmail(email!)) {
      toast?.setToast({
        message: "A valid parent email address is required.",
        type: "danger",
      });
      setBtnState(BtnState.BASE);
      return;
    }
    if (
      !emptyStringCheck(participantEmail) &&
      !ValidateEmail(participantEmail!)
    ) {
      toast?.setToast({
        message: "A valid participant email address is required.",
        type: "danger",
      });
      setBtnState(BtnState.BASE);
      return;
    }

    updateRoster({
      variables: {
        input: {
          rosterId: participantDetails.rosterId,
          email,
          name,
          rosterEmail: participantEmail,
        },
      },
    });
  };
  return (
    <CustomModal
      isOpen={isOpen}
      toggle={toggle}
      title={"Edit Roster"}
      btn1={{
        text: "Save Changes",
        btnStyle: "primary",
        btnState,
        onClick: () => {
          handleSave();
        },
      }}
      btn2={{
        text: "Cancel",
        btnStyle: "tertiary",
        onClick: () => {
          toggle();
          setBtnState(BtnState.BASE);
        },
      }}
      customStyle="lg:mt-10 lg:w-[700px]"
    >
      <div className="modal-card mb-5">
        {!user?.isParent() && (
          <SnapAlert type="info" className="mb-3 lg:mb-2">
            <SnapAlertTitle>
              <p>
                Changes made will be applied to all non-accepted and future
                invites for this participant.
              </p>
            </SnapAlertTitle>
          </SnapAlert>
        )}
        <table className="w-full lg:w-auto">
          <tbody>
            <TableRowLabelValue
              key={"parti-name"}
              label={"Name"}
              value={participantDetails?.participantName}
              className={`lg:ml-24 mt-2`}
            />
            <TableRowLabelValue
              key={"parent-email"}
              label={"Parent Email"}
              value={participantDetails?.email}
              className={`lg:ml-24 mt-2`}
            />
            {addParticipantEmailFlag && (
              <TableRowLabelValue
                key={"parti-email"}
                label={"Participant Email"}
                value={participantDetails?.rosterEmail}
                className={`lg:ml-24 mt-2`}
              />
            )}
          </tbody>
        </table>
        {!user?.isParent() && (
          <div className="lg:w-96 mt-4">
            <SnapInput
              _id="email-input"
              _type="email"
              placeholder={""}
              onSnap-input-change={(e) => setName(e.detail.target.value || "")}
              label="New Name"
            />
          </div>
        )}
        {!user?.isParent() && (
          <div className="lg:w-96 mt-4">
            <SnapInput
              _id="email-input"
              _type="email"
              placeholder={""}
              onSnap-input-change={(e) => setEmail(e.detail.target.value || "")}
              label="New Email"
              disabled={!hasPendingInvite}
            />
          </div>
        )}
        {addParticipantEmailFlag && (
          <div className="lg:w-96 mt-4">
            <SnapInput
              _id="participant-email-input"
              _type="email"
              placeholder={""}
              onSnap-input-change={(e) =>
                setParticipantEmail(e.detail.target.value || "")
              }
              label="Participant Email"
            />
          </div>
        )}
      </div>
    </CustomModal>
  );
}

export default EditParticipant;
