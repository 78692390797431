import { useLazyQuery } from "@apollo/client";
import {
  GET_COUNTERPARTY_BY_ID,
  GET_PAYEE_BY_ID,
} from "graphql/mutations/organization";
import { USER_TOKEN } from "graphql/queries/user";
import { useEffect, useRef, useState } from "react";
import { SpinnerContainer } from "shared-components/spinner";

type EinDisplayProps = {
  id: string;
  type: "payee" | "counterparty";
};

function EinDisplay({ id, type }: EinDisplayProps) {
  const einRef = useRef<HTMLDivElement>(null);

  const [reveal, setReveal] = useState(false);

  const [getUserToken, { loading: loadingUserToken, data }] = useLazyQuery(
    USER_TOKEN,
    {
      variables: {},
    }
  );

  useEffect(() => {
    if (!loadingUserToken && data && data.userToken) {
      const token = data.userToken.accessToken;
      let ein: any = undefined;
      let vgsShowInstance: any = undefined;
      setTimeout(() => {
        setReveal(false);
        ein = undefined;
        vgsShowInstance = undefined;
      }, 10000);

      vgsShowInstance = window.VGSShow.create(
        process.env.REACT_APP_VGS_VAULT ?? "",
        () => {}
      ).setEnvironment(process.env.REACT_APP_VGS_ENVIRONMENT ?? "");
      vgsShowInstance.setCname(process.env.REACT_APP_VGS_CNAME || "");

      const request = {
        headers: { Authorization: `Bearer ${token}` },
        withCredentials: true,
        name: "value-text",
        method: "POST",
        path: "/graphql",
        htmlWrapper: "text",
        payload: {},
        jsonPathSelector: "",
      };
      if (type === "payee") {
        request.payload = {
          variables: { spendGetPayeeByIdId: id },
          query: GET_PAYEE_BY_ID,
        };
        request.jsonPathSelector = "data.spendGetPayeeById.einNumber";
      } else if (type === "counterparty") {
        request.payload = {
          variables: { counterpartyId: id },
          query: GET_COUNTERPARTY_BY_ID,
        };
        request.jsonPathSelector = "data.spendGetCounterpartyById.einNumber";
      }

      ein = vgsShowInstance.request(request);
      ein.render(einRef.current, {
        "font-family": "Arial, sans-serif",
        color: "black",
        "font-size": "14px",
        height: "50px",
        display: "flex",
        "align-items": "center",
      });
    }
  }, [loadingUserToken, data, id, type]);

  const handleReveal = () => {
    getUserToken({
      variables: {
        expirationTimeString: "30s",
        permissions: [],
      },
      fetchPolicy: "network-only",
    });
    setReveal(true);
  };

  return (
    <>
      {reveal && !loadingUserToken && (
        <div ref={einRef} className="h-12 w-1"></div>
      )}
      {reveal && loadingUserToken && <SpinnerContainer loading={true} />}
      {!reveal && (
        <p
          className="text-blue-600 font-semibold cursor-pointer"
          onClick={handleReveal}
        >
          Reveal
        </p>
      )}
    </>
  );
}

export default EinDisplay;
