import { createRef, useEffect, useRef } from "react";
import Papa from "papaparse";

type DragAndDropCSVProps = {
  label: string;
  description?: string | null;
  className?: string | null;
  setData: React.Dispatch<React.SetStateAction<any[]>>;
  setError: React.Dispatch<React.SetStateAction<string>>;
};

function DragAndDropCSV({
  label,
  description,
  className,
  setData,
  setError,
}: DragAndDropCSVProps) {
  // eslint-disable-next-line
  const dropRef = createRef<HTMLInputElement>();
  const fileElement = useRef<HTMLInputElement | null>(null);
  const handlefileElementClick = (ev: React.MouseEvent) => {
    fileElement !== null && fileElement.current?.click();
  };
  const handleDrag = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragIn = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragOut = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      if (e.dataTransfer.files[0].size >= 3000000) {
        setError("File too large");
      } else {
        uploadFile(e.dataTransfer.files[0]);
      }
    }
  };

  useEffect(() => {
    if (dropRef != null && dropRef.current) {
      let div = dropRef.current;
      div.addEventListener("dragenter", handleDragIn);
      div.addEventListener("dragleave", handleDragOut);
      div.addEventListener("dragover", handleDrag);
      div.addEventListener("drop", handleDrop);
    }
    // eslint-disable-next-line
  }, []);

  const handleFileChange = (event: any) => {
    if (event.target.files[0].size >= 3000000) {
      setError("File too large");
    } else {
      uploadFile(event.target.files[0]);
    }
  };

  const uploadFile = (file: any) => {
    setData([]);
    if (!file) return;
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (result: any) => {
        const { data } = result;
        if (data.length === 0) {
          setError("CSV file is empty");
          return;
        }

        // Ensure required columns exist
        const requiredColumns = ["first", "last", "email"];
        const fileColumns = Object.keys(data[0]);
        const missingColumns = requiredColumns.filter(
          (col) => !fileColumns.includes(col)
        );

        if (missingColumns.length > 0) {
          setError(`Missing required columns: ${missingColumns.join(", ")}`);
          return;
        }

        setError("");
        setData(
          data.map((row: { first: any; last: any; email: any }) => ({
            first: row.first || "",
            last: row.last || "",
            email: row.email || "",
          }))
        );
      },
      error: (err) => {
        setError("Error parsing CSV file: " + err.message);
      },
    });
  };
  return (
    <div className={`flex relative justify-center align-middle h-full w-full`}>
      <div className={`flex flex-col bg-blue-50 w-full ${className}`}>
        <p>{label}</p>
        <div
          ref={dropRef}
          className="border-2 border-dashed border-gray-200 flex flex-col justify-center py-3 rounded-lg cursor-pointer"
          onClick={(event) => handlefileElementClick(event)}
        >
          <p className="lg:flex justify-center text-sm font-medium hidden text-gray-800">
            Drag & Drop File or
            <span className="ml-2 font-bold text-blue-500">Browse</span>
          </p>
          <p className="text-sm font-bold lg:hidden text-blue-500 flex justify-center mt-2">
            Upload File
          </p>
          <input
            type="file"
            ref={fileElement}
            className="hidden"
            onChange={handleFileChange}
            accept="text/csv"
          />
          <p
            className={`${
              description ? "flex" : "hidden"
            } justify-center text-xs leading-4 font-normal text-gray-500`}
          >
            {description}
          </p>
        </div>
      </div>
    </div>
  );
}
export default DragAndDropCSV;
