type BadgeColor =
  | "white"
  | "blue"
  | "green"
  | "red"
  | "gray"
  | "yellow"
  | "purple"
  | undefined;
export const getBadgeColor = (status: string): BadgeColor => {
  const st = status.toLowerCase();
  switch (st) {
    case "archived":
    case "draft":
    case "opted out":
    case "unknown":
      return "gray";
    case "created":
    case "new":
    case "scheduled":
      return "blue";
    case "accepted":
    case "active":
    case "autopay enabled":
    case "approved":
    case "assigned":
    case "current":
    case "paid":
    case "processed":
    case "published":
    case "refunded":
    case "returned":
    case "sent":
    case "settled":
    case "signed up":
      return "green";
    case "no invite sent":
    case "unassigned":
    case "awaitingimages":
    case "pendingreview":
    case "awaiting approval":
    case "shipped":
    case "on hold":
    case "on_hold":
    case "unreconciled":
    case "partially_reconciled":
    case "inproduction":
    case "clearing":
    case "pending":
    case "processing":
    case "stoppaymentrequested":
      return "yellow";
    case "rejected":
    case "canceled":
    default:
      return "red";
  }
};
