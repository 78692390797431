import React from "react";
import CustomModal, { BtnState } from "shared-components/modal";
import { SnapInput } from "suit";

type DeclineType = {
  isOpen: boolean;
  toggle: () => void;
  handleDecline: (status: "Approved" | "Declined") => void;
  type: string;
  btnState: BtnState;
  setRejectionReason: React.Dispatch<React.SetStateAction<string>>;
};

function Decline({
  isOpen,
  toggle,
  handleDecline,
  type,
  setRejectionReason,
}: DeclineType) {
  return (
    <CustomModal
      isOpen={isOpen}
      toggle={toggle}
      title={`Decline ${type} Request`}
      btn1={{
        text: "Decline Request",
        onClick: () => {
          handleDecline("Declined");
        },
      }}
      btn2={{
        text: "Cancel",
        onClick: toggle,
      }}
    >
      <div className="modal-card">
        <p className="font-medium">Why was this request declined?</p>
        <SnapInput
          _id="decline input"
          textarea
          placeholder="Please write a message."
          onSnap-input-change={(e) => {
            setRejectionReason(e.detail.target.value);
          }}
          onBlur={(e) => setRejectionReason(e.target.value)}
        />
      </div>
    </CustomModal>
  );
}

export default Decline;
