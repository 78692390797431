import { useEffect, useState } from "react";
import DragAndDropCSV from "shared-components/drag-and-drop-csv";
import { SnapCheckboxButton, SnapTable } from "suit";
import { PreviewDataType } from ".";
type ParticipantImportRecord = {
  first: string;
  last: string;
  email: string;
};
type SelectableParticipantImportRecord = {
  first: string;
  last: string;
  email: string;
  selected: boolean;
};

export default function CsvUploader({
  setRostersData,
}: {
  setRostersData: React.Dispatch<React.SetStateAction<PreviewDataType[]>>;
}) {
  const [data, setData] = useState<SelectableParticipantImportRecord[]>([]);
  const [error, setError] = useState("");
  const [allSelected, setAllSelected] = useState(false);
  const [rawData, setRawData] = useState<ParticipantImportRecord[]>([]);
  useEffect(() => {
    setRostersData &&
      setRostersData(
        data
          .filter((d) => d.selected)
          .map((d) => ({ name: `${d.first} ${d.last}`, email: d.email }))
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  useEffect(() => {
    if (rawData.length) {
      setData(
        rawData.map((r) => ({
          first: r.first.trim(),
          last: r.last.trim(),
          email: r.email.trim(),
          selected: false,
        }))
      );
    } else {
      setData([]);
    }
  }, [rawData]);

  return (
    <div className="p-4">
      <DragAndDropCSV label={""} setData={setRawData} setError={setError} />
      {error && <p className="text-red-500">{error}</p>}
      {data.length > 0 && (
        <SnapTable>
          <table className="ui celled hidden lg:table">
            <thead>
              <tr>
                <th>
                  <SnapCheckboxButton
                    onClick={(e) => {
                      setData(
                        data.map((d) => ({
                          first: d.first,
                          last: d.last,
                          email: d.email,
                          selected: e.currentTarget.checked,
                        }))
                      );
                      setAllSelected(e.currentTarget.checked);
                    }}
                    checked={allSelected}
                  />
                </th>
                <th>First</th>
                <th className="">Last</th>
                <th className="">Email</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item: SelectableParticipantImportRecord, index) => {
                return (
                  <tr key={`csvrow-${index}-${item.email}`}>
                    <td>
                      <SnapCheckboxButton
                        checked={item.selected}
                        onClick={(e) =>
                          setData(
                            data.map((d) => ({
                              ...d,
                              selected:
                                d.email === item.email
                                  ? e.currentTarget.checked
                                  : d.selected,
                            }))
                          )
                        }
                      />
                    </td>
                    <td className="">{item.first}</td>
                    <td className="">{item.last}</td>
                    <td className="">{item.email}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </SnapTable>
      )}
    </div>
  );
}
