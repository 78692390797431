import Divider from "shared-components/divider";
import { SnapIcon } from "suit";
import { PreviewDataType } from ".";

type PreviewDataProps = {
  previewData: PreviewDataType[];
  setPreviewData: React.Dispatch<React.SetStateAction<PreviewDataType[]>>;
};

function PreviewData({ previewData, setPreviewData }: PreviewDataProps) {
  return (
    <>
      <p className="text-base font-medium">Selected Participants</p>
      <div
        //   ref={previewListElm}
        className="lg:flex lg:overflow-x-auto lg:pb-4"
      >
        {previewData.map((player, idx) => {
          return (
            <div
              key={idx}
              className="flex rounded-lg border border-gray-200 bg-gray-50 mt-4 px-4 py-3 lg:mr-4"
            >
              <div className="mr-auto">
                <p className="whitespace-nowrap font-semibold">{player.name}</p>
                <p className="whitespace-nowrap text-sm mt-0.5">
                  {player.email}
                </p>
              </div>
              <SnapIcon
                className="cursor-pointer ml-2"
                icon="trash-solid"
                color="gray"
                onClick={() => {
                  previewData.splice(idx, 1);
                  setPreviewData([...previewData]);
                }}
              />
            </div>
          );
        })}
      </div>
      <Divider isVisibleOnMobile className="mb-4" />
    </>
  );
}

export default PreviewData;
