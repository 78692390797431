import GroupContext from "context/group-context";
import ProgramContext from "context/program-context";
import { SpendBankAccount } from "graphql/generated";
import UnitComponentGenerator from "unit/unit-component-generator";
import useModal from "hooks/use-modal";
import { ToastType } from "hooks/use-toast";
import { useContext, useEffect, useState } from "react";
import { PlaidLinkOptions } from "react-plaid-link";
import BankingActions from "shared-components/banking-actions";
import LinkBank, {
  getDeletePlaidAccessUrl,
  getRefetchQueries,
} from "shared-components/banking/link-bank";
import VerticalLabelValue, {
  VerticalValueStyle,
} from "shared-components/vertical-label-value";
import { bankActions } from "types/club-banking";
import AddBankModal from "../pages/programs/banking/modals/add-bank-modal";
import RemoveAccount from "../pages/programs/banking/modals/remove-account";
import VerifyCodeModal from "../pages/programs/banking/modals/verify-code-modal";
import SplitIOContext from "context/splitio-context";
import { useContextStrict } from "helpers/context-strict";
import { useMutation } from "@apollo/client";
import { extractBankLabel } from "helpers/banking";
import { SpinnerContainer } from "./spinner";
import { SnapIcon } from "suit";

type AccountOverViewCardProps = {
  bankingActions: bankActions[];
  hasLinkedAccount: boolean;
  openPlaid: () => void;
  setPlaidConfig: React.Dispatch<React.SetStateAction<PlaidLinkOptions>>;
  toast: Omit<ToastType, "isToastOpen">;
  accountType: "program" | "group";
  linkedAccount: SpendBankAccount | undefined;
  canUpdateBanking: boolean;
  canAddAccount: boolean;
  isArchived?: boolean;
  accountStatus: string | null;
  isExternalTransferEnabled?: boolean;
};

function AccountOverViewCard({
  bankingActions,
  hasLinkedAccount,
  openPlaid,
  setPlaidConfig,
  toast,
  accountType,
  linkedAccount,
  canUpdateBanking,
  canAddAccount,
  isArchived,
  accountStatus,
  isExternalTransferEnabled,
}: AccountOverViewCardProps) {
  const program = useContext(ProgramContext);
  const group = useContext(GroupContext);

  const { isOpen: removeOpen, toggle: removeToggle } = useModal();
  const { isOpen: pinModalOpen, toggle: pinModalToggle } = useModal();
  const { isOpen: addBankOpen, toggle: addBankToggle } = useModal();

  const [se503flag, setSe503flag] = useState<boolean | undefined>();
  const [se2082flag, setSe2082flag] = useState<boolean | undefined>();
  const [activeAccountId, setActiveAccountId] = useState<string | undefined>(
    undefined
  );
  const [deletePlaidLink] = useMutation(getDeletePlaidAccessUrl(accountType), {
    refetchQueries: getRefetchQueries(
      accountType,
      group?.activeGroup?.id ?? undefined
    ),
  });

  useEffect(() => {
    if (accountType === "program") {
      setActiveAccountId(program?.getProgramAccount()?.id ?? undefined);
    } else if (accountType === "group") {
      setActiveAccountId(group?.activeGroup?.accountId ?? undefined);
    } else {
      console.log("Something went wrong");
    }
  }, [program, group, accountType]);

  const splits = useContextStrict(SplitIOContext);
  useEffect(() => {
    setSe503flag(splits.isTreatmentOn(splits.act.se503));
    setSe2082flag(splits.isTreatmentOn(splits.act.se2082));
  }, [splits]);
  return (
    <div className="card">
      <div className="grid lg:grid-cols-3">
        <div className="flex-col lg:mr-14">
          {activeAccountId ? (
            <UnitComponentGenerator
              type={"account"}
              data={{
                accountId: activeAccountId || undefined,
              }}
            />
          ) : (
            <SpinnerContainer loading={true} />
          )}
        </div>
        <div className="mt-4 lg:mt-0">
          {se503flag &&
            isExternalTransferEnabled &&
            (hasLinkedAccount && !isArchived ? (
              <VerticalLabelValue
                label="Linked Bank"
                labelStyle="text-sm font-normal"
                value={extractBankLabel(linkedAccount)}
                valueStyle={
                  canUpdateBanking
                    ? VerticalValueStyle.ActionLabel
                    : VerticalValueStyle.Text
                }
                actionLabel="Change"
                labelAction={removeToggle}
                customContainerStyle={"border-none lg:text-start flex flex-col"}
                actionLabelClassname="flex-col"
              />
            ) : (
              canAddAccount &&
              !isArchived && (
                <div className="grid grid-cols-2">
                  <LinkBank
                    openPlaid={openPlaid}
                    setPlaidConfig={setPlaidConfig}
                    toast={toast}
                    type={accountType === "program" ? "program" : "group"}
                    labelText={
                      accountStatus === "pending_manual_verification"
                        ? "Verify Account"
                        : "Add Account"
                    }
                  />
                  {accountStatus === "pending_manual_verification" && (
                    <p
                      className="self-center lg:mt-4 text-red-500 font-bold cursor-pointer"
                      onClick={() =>
                        deletePlaidLink({
                          variables:
                            accountType === "group"
                              ? {
                                  spendGroupBankAccessTokenDeleteId:
                                    group?.activeGroup?.id,
                                }
                              : undefined,
                        })
                      }
                    >
                      Delete Account
                    </p>
                  )}
                </div>
              )
            ))}
          {!se503flag &&
            (hasLinkedAccount && !isArchived ? (
              <VerticalLabelValue
                label="Linked Bank"
                labelStyle="text-sm font-normal"
                value={extractBankLabel(linkedAccount)}
                valueStyle={
                  canUpdateBanking
                    ? VerticalValueStyle.ActionLabel
                    : VerticalValueStyle.Text
                }
                actionLabel="Change"
                labelAction={removeToggle}
                customContainerStyle={"border-none lg:text-start flex flex-col"}
                actionLabelClassname="flex-col"
              />
            ) : (
              canAddAccount &&
              !isArchived && (
                <LinkBank
                  openPlaid={openPlaid}
                  setPlaidConfig={setPlaidConfig}
                  toast={toast}
                  type={accountType === "program" ? "program" : "group"}
                  labelText={
                    accountStatus === "pending_manual_verification"
                      ? "Verify Account"
                      : "Add Account"
                  }
                />
              )
            ))}
          <VerticalLabelValue
            label={`${accountType} Account Id`}
            value={activeAccountId}
            labelStyle="text-sm font-normal capitalize"
            valueStyle={VerticalValueStyle.Text}
            customContainerStyle={
              "border-none lg:text-start flex flex-col mt-4"
            }
          />
          {se2082flag && program && (
            <div className="flex">
              <VerticalLabelValue
                label={"ETF Rate"}
                value={program.getEtfRate()}
                labelStyle="text-sm font-normal capitalize"
                customContainerStyle={
                  "border-none lg:text-start flex flex-col mt-4"
                }
              />
              <SnapIcon
                icon="question-mark-circle-empty-solid"
                title={`${program?.getEtfDesc()}`}
              />
            </div>
          )}
        </div>
        <div
          className={`${
            canUpdateBanking &&
            "lg:flex lg:flex-col hidden px-16 border-l border-gray-200"
          }`}
        >
          {canUpdateBanking && (
            <>
              {bankingActions.map((actionItem, idx) => {
                return (
                  <BankingActions
                    key={actionItem.label + idx}
                    icon={actionItem.icon}
                    label={actionItem.label}
                    action={actionItem.onClick}
                    className={actionItem.className}
                  />
                );
              })}
            </>
          )}
        </div>
      </div>
      <VerifyCodeModal
        isOpen={pinModalOpen}
        toggle={pinModalToggle}
        addBankToggle={addBankToggle}
      />
      <AddBankModal isOpen={addBankOpen} toggle={addBankToggle} />
      <RemoveAccount
        removeOpen={removeOpen}
        removeToggle={removeToggle}
        toast={toast}
        accountType={accountType}
        groupId={group?.activeGroup?.id ?? ""}
      />
    </div>
  );
}

export default AccountOverViewCard;
