import { useContext, useEffect, useState } from "react";
import CustomModal, { BtnState, BtnType } from "shared-components/modal";
import { SnapCheckboxButton, SnapIndicator, SnapInput } from "suit";
import { useMutation } from "@apollo/client";
import { CREATE_NOTIFICATIONS } from "graphql/mutations/notification";
import GroupContext from "context/group-context";
import ToastContext from "context/toast-context";
type SendEmailProps = {
  sendEmailOpen: boolean;
  sendEmailToggle: () => void;
  selectedEmails: string[];
  role: "Staff" | "Participants";
};

function SendEmail({
  sendEmailOpen,
  sendEmailToggle,
  selectedEmails,
  role,
}: SendEmailProps) {
  const groupContext = useContext(GroupContext);
  const toast = useContext(ToastContext);

  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");
  const [isEmailCCed, setIsEmailCCed] = useState(false);
  const [isBtnActive, setIsBtnActive] = useState(true);

  const [sendEmail, { loading, data, error }] =
    useMutation(CREATE_NOTIFICATIONS);

  useEffect(() => {
    if (!loading) {
      if (data && data.spendNotificationCreate) {
        toast?.setToast({
          message: "Email Sent",
          type: "success",
        });
        handleReset();
        sendEmailToggle();
      } else if (error) {
        toast?.setToast({
          message: "Error email not sent",
          type: "danger",
        });
        setIsBtnActive(true);
      }
    }
    // eslint-disable-next-line
  }, [loading, data]);

  const handleSendEmail = () => {
    selectedEmails.forEach((email) => {
      sendEmail({
        variables: {
          input: {
            email,
            groupId: groupContext?.activeGroup?.id ?? null,
            isEmailCCed,
            message,
            subject,
          },
        },
      });
    });
  };
  const btn1: BtnType = {
    text: "Send",
    btnStyle: "primary",
    onClick: () => {
      setIsBtnActive(false);
      handleSendEmail();
    },
    btnState: isBtnActive ? BtnState.BASE : BtnState.DISABLED,
  };

  const btn2: BtnType = {
    text: "Cancel",
    btnStyle: "tertiary",
    onClick: () => {
      handleReset();
      sendEmailToggle();
    },
  };
  const handleReset = () => {
    setIsBtnActive(true);
    setSubject("");
    setMessage("");
    setIsEmailCCed(false);
  };
  return (
    <CustomModal
      isOpen={sendEmailOpen}
      toggle={() => {
        handleReset();
        sendEmailToggle();
      }}
      title={"Send Email"}
      btn1={btn1}
      btn2={btn2}
    >
      <div className="modal-card pl-6">
        <div className="flex">
          <p className="text-sm font-medium text-gray-500 mr-4">To</p>
          {selectedEmails.length > 1 ? (
            <div className="flex items-center">
              <p className="mr-4">{role}</p>
              <SnapIndicator
                text={selectedEmails.length}
                color="gray"
                size="tiny"
              />
            </div>
          ) : (
            <p>{selectedEmails[0]}</p>
          )}
        </div>
        <div className="mt-4">
          <SnapInput
            _id={"subject-input"}
            label="Subject"
            onBlur={(e) => setSubject(e.target.value)}
          />
        </div>
        <div className="mt-4">
          <SnapInput
            _id={"message-input"}
            label="Message"
            textarea
            onBlur={(e) => setMessage(e.target.value)}
          />
        </div>
        <SnapCheckboxButton
          label="CC my email"
          className="mt-4"
          checked={isEmailCCed}
          onClick={() => setIsEmailCCed(!isEmailCCed)}
        />
      </div>
    </CustomModal>
  );
}

export default SendEmail;
