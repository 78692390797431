import { ITEMS_PER_PAGE } from "../../../constants";
import {
  DateRangeEnum,
  ExternalTransferDirectionEnum,
  SpendBankTransaction,
  useSpendTransactionsCompletedWheredQuery,
  useSpendTransactionsPendingWheredQuery,
} from "graphql/generated";
import { useEffect, useState } from "react";
import Transactions from "./transactions";
import PendingCheckTransactions from "./pending-check-transactions";
import { LabelValueObject } from "types/label-value-object";
import { getTransactionType } from "helpers/transaction";
import { FormatMoney } from "helpers/format-money";
import { VerticalValueStyle } from "shared-components/vertical-label-value";

type TransactionsContainerProps = {
  isCheckTab: boolean;
  guardedTransactionMenuItems: any[];
  canUpdateBudgetItem: boolean;
  groupIdOrgId: string;
  groupIsArchived: boolean;
  type: "Program" | "Group";
};

function TransactionsContainer({
  isCheckTab,
  guardedTransactionMenuItems,
  canUpdateBudgetItem,
  groupIdOrgId,
  groupIsArchived,
  type,
}: TransactionsContainerProps) {
  const [pendingCheckTransactions, setPendingCheckTransactions] = useState<
    SpendBankTransaction[]
  >([]);
  const [pendingTransactions, setPendingTransactions] = useState<
    SpendBankTransaction[]
  >([]);
  const [totalCompleted, setTotalCompleted] = useState(0);
  const [completedTransactions, setCompletedTransactions] = useState<
    SpendBankTransaction[]
  >([]);
  const [completedPage, setCompletedPage] = useState(0);
  const [whereFilters, setWhereFilters] = useState<{
    type: string[] | null;
    reconciled: boolean | null;
    hasAttachment: boolean | null;
    nameIncludes: string | null;
    status: string[];
    direction: ExternalTransferDirectionEnum | null;
  }>({
    type: null,
    reconciled: null,
    hasAttachment: null,
    nameIncludes: null,
    status: ["Settled", "Canceled", "Returned"],
    direction: null,
  });
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeEnum>(
    DateRangeEnum.ThirtyDays
  );

  const {
    loading: loadingPending,
    data: pendingData,
    refetch: refetchPending,
  } = useSpendTransactionsPendingWheredQuery({
    variables: {
      where: {
        ...whereFilters,
        groupIdOrgId,
        status: ["Pending"],
      },
      disableCache: true,
    },
    skip: !groupIdOrgId,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  const {
    loading: loadingCompleted,
    data: completedData,
    refetch: refetchCompleted,
  } = useSpendTransactionsCompletedWheredQuery({
    variables: {
      pagination: {
        limit: ITEMS_PER_PAGE,
        offset: completedPage * ITEMS_PER_PAGE,
      },
      where: {
        ...whereFilters,
        groupIdOrgId,
      },
      disableCache: true,
      dateRange: selectedDateRange,
    },
    skip: !groupIdOrgId,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (
      !loadingCompleted &&
      completedData &&
      completedData.spendTransactionsFiltered
    ) {
      setTotalCompleted(completedData.spendTransactionsFiltered?.count || 0);
      const transactions = (completedData.spendTransactionsFiltered
        ?.transactions || []) as SpendBankTransaction[];
      setCompletedTransactions(transactions);
    }
  }, [loadingCompleted, completedData]);

  useEffect(() => {
    if (
      !loadingPending &&
      pendingData &&
      pendingData.spendTransactionsFiltered
    ) {
      const correlationIds: string[] = [];
      const transactions: SpendBankTransaction[] = [];
      const checks: SpendBankTransaction[] = [];
      pendingData.spendTransactionsFiltered.transactions?.forEach(
        (transaction) => {
          const correlationId = transaction?.correlationId;
          if (correlationId && !correlationIds.includes(correlationId)) {
            if (transaction.transactionType === "checkPayment") {
              checks.push(transaction as SpendBankTransaction);
            } else {
              transactions.push(transaction as SpendBankTransaction);
            }
            correlationIds.push(correlationId);
          }
        }
      );
      setPendingTransactions(transactions);
      setPendingCheckTransactions(checks);
    }
  }, [loadingPending, pendingData]);

  window.addEventListener("unitPaymentCreated", (e) => {
    refetchCompleted();
    refetchPending();
  });

  const prepLeftData = (transaction: SpendBankTransaction) => {
    let leftData: LabelValueObject[] = [];
    leftData.push({
      key: "Type",
      value: getTransactionType(transaction),
      className: "capitalize",
    });
    if (transaction.transactionNote) {
      leftData.push({
        key: "Note",
        value: transaction.transactionNote,
      });
    }
    if (!!transaction.metadata?.tags?.stopPaymentIssued) {
      leftData.push({
        key: "Stop Payment Issued",
        value: "True",
      });
    }
    return leftData;
  };

  const prepRightData = (transaction: SpendBankTransaction) => {
    let rightData: LabelValueObject[] = [];
    let date = transaction.effective?.split("T").at(0);
    let year = date?.split("-").at(0);
    let month = date?.split("-").at(1);
    let day = date?.split("-").at(2);

    rightData.push({
      key: "Date",
      value: `${month}/${day}/${year}`,
    });
    const isCredit = transaction.direction?.toLowerCase() === "credit";
    let factor = isCredit ? 1 : -1; // standard metric to determine direction

    const transactionValue =
      ((transaction.amount || 0) - (transaction.snapAmount || 0)) * factor;
    let amountColor = transactionValue <= 0 ? "text-red-600" : "text-gray-800";
    const isReturned = transaction.transactionStatus === "Returned";
    rightData.push({
      key: "Amount",
      value: FormatMoney(transactionValue),
      valueStyle: isReturned
        ? VerticalValueStyle.ReturnText
        : VerticalValueStyle.ColorText,
      valueColor: isReturned ? "text-gray-600" : amountColor,
    });

    if (transaction.metadata?.balance) {
      rightData.push({
        key: "Balance",
        value: FormatMoney(Number(transaction.metadata?.balance) || 0), // TODO - Update Balance
        customContainerStyle: !guardedTransactionMenuItems.length
          ? "lg:border-r-0"
          : "",
      });
    }

    return rightData;
  };
  const menuItemFilter = (transaction: SpendBankTransaction): any[] => {
    const paymentType = getTransactionType(transaction);
    let reconciledAmount = Math.abs(transaction.totalReconciled ?? 0);
    const amount = (transaction.amount ?? 0) - (transaction.snapAmount ?? 0);
    const isFullReconciled =
      transaction.totalReconciled != null && amount <= reconciledAmount;
    const reconciled = transaction.isReconciled
      ? isFullReconciled
        ? "Reconciled"
        : "Partially Reconciled"
      : "Unreconciled";
    let reconType: string[] = [];
    if (transaction.typeReconciled?.includes("budget_income")) {
      reconType.push("Reconciled Budget Income");
    }
    if (transaction.typeReconciled?.includes("budget_expense")) {
      reconType.push("Reconciled Budget Expense");
    }
    if (transaction.typeReconciled?.includes("invoice")) {
      reconType.push(
        paymentType === "Payment Invoice"
          ? "Auto Reconciled Invoice"
          : "Invoice"
      );
    }
    const filteredMenuItems = guardedTransactionMenuItems.filter((menuItem) => {
      if (transaction.transactionStatus === "Returned") {
        return false;
      }
      if (transaction.status?.toLowerCase() === "pending") {
        // menu items that are pending relavent
        const cancelTypes = ["checkpayment", "ach"];
        return (
          cancelTypes.includes(
            transaction.transactionType?.toLowerCase() ?? ""
          ) &&
          menuItem.type.includes(
            transaction.transactionType?.toLowerCase() ?? ""
          ) &&
          menuItem.isPending ===
            ((transaction.metadata?.status?.toLowerCase() ||
              transaction.transactionStatus?.toLowerCase()) ===
              "pending")
        );
      }
      if (!transaction.isReconciled) {
        // menu items that are not reconciled
        return (
          menuItem.type.includes(paymentType.toLowerCase()) &&
          menuItem.key.includes(reconciled) &&
          !menuItem.isPending
        );
      } else {
        return (
          menuItem.type.includes(paymentType.toLowerCase()) &&
          menuItem.key.includes(reconciled) &&
          reconType.includes(menuItem.secondaryKey)
        );
      }
    });
    return filteredMenuItems;
  };

  return isCheckTab ? (
    <PendingCheckTransactions
      pendingCheckTransactions={pendingCheckTransactions}
      loadingPending={loadingPending}
      prepLeftData={prepLeftData}
      prepRightData={prepRightData}
      canUpdateBudgetItem={canUpdateBudgetItem}
      menuItemFilter={menuItemFilter}
      groupIdOrgId={groupIdOrgId}
      type={type}
    />
  ) : (
    <Transactions
      canUpdateBudgetItem={canUpdateBudgetItem}
      pendingTransactions={pendingTransactions}
      completedTransactions={completedTransactions}
      totalCompleted={totalCompleted}
      completedPage={completedPage}
      setCompletedPage={setCompletedPage}
      whereFilters={whereFilters}
      setWhereFilters={setWhereFilters}
      setSelectedDateRange={setSelectedDateRange}
      loadingPending={loadingPending}
      loadingCompleted={loadingCompleted}
      prepLeftData={prepLeftData}
      prepRightData={prepRightData}
      menuItemFilter={menuItemFilter}
      groupIdOrgId={groupIdOrgId}
      groupIsArchived={groupIsArchived}
      type={type}
    />
  );
}

export default TransactionsContainer;
