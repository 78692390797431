import GroupContext from "context/group-context";
import { getBadgeColor } from "helpers/status-color";
import { useLocation, useNavigate } from "react-router-dom";
import {
  SnapBadge,
  SnapCheckboxButton,
  SnapIcon,
  SnapTable,
  SnapLink,
} from "suit";
import { useContext } from "react";
import { SpinnerContainer } from "shared-components/spinner";
import { SpendSortInput } from "graphql/generated";
import SortIcon from "shared-components/table/sort-icon";
import { RosterDataType } from "types/group-roster";
import { FormatMoney } from "helpers/format-money";

type TableProps = {
  seasonRosterData: RosterDataType[];
  handleSelectall: (
    selectedItems: RosterDataType[],
    selectAll: boolean,
    isChecked: boolean
  ) => void;
  allSelected: boolean;
  canEditParticipant: boolean;
  isArchived: boolean;
  page?: number;
  queryLoading?: boolean;
  handleRosterDataInviteStatus: (rosterData: RosterDataType) => string;
  sort: SpendSortInput | undefined;
  toggleSort: (field: string) => void;
};

function Table({
  seasonRosterData,
  handleSelectall,
  allSelected,
  canEditParticipant,
  isArchived,
  queryLoading,
  handleRosterDataInviteStatus,
  sort,
  toggleSort,
}: TableProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const group = useContext(GroupContext);
  return (
    <SnapTable>
      <table className="ui celled hidden lg:table">
        <thead>
          <tr>
            {canEditParticipant && !isArchived && (
              <th>
                <SnapCheckboxButton
                  onClick={(e) => {
                    handleSelectall(
                      seasonRosterData,
                      true,
                      e.currentTarget.checked
                    );
                  }}
                  checked={allSelected}
                />
              </th>
            )}
            <th>
              Participant
              <SortIcon
                field="participant"
                currentSort={sort}
                toggleSort={toggleSort}
              />
            </th>
            <th className="w-[11%]">
              Status
              <SortIcon
                field="status"
                currentSort={sort}
                toggleSort={toggleSort}
              />
            </th>
            <th className="pl-16">
              Paid
              <SortIcon
                field="paid"
                currentSort={sort}
                toggleSort={toggleSort}
              />
            </th>
            <th>Processing</th>
            <th>
              Upcoming
              <SortIcon
                field="upcoming"
                currentSort={sort}
                toggleSort={toggleSort}
              />
            </th>
            <th>
              Past Due
              <SortIcon
                field="pastDue"
                currentSort={sort}
                toggleSort={toggleSort}
              />
            </th>
            <th className="action-cell">
              Credited
              <SortIcon
                field="credited"
                currentSort={sort}
                toggleSort={toggleSort}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {!queryLoading ? (
            seasonRosterData.map((item: RosterDataType) => {
              const hasWarning = (item.total?.pastDue ?? 0) > 0;
              let status = handleRosterDataInviteStatus(item);
              return (
                <tr key={item.id}>
                  {canEditParticipant && !isArchived && (
                    <td>
                      <SnapCheckboxButton
                        checked={item.isChecked}
                        onClick={(e) =>
                          handleSelectall(
                            [item],
                            false,
                            e.currentTarget.checked
                          )
                        }
                      />
                    </td>
                  )}
                  <td>
                    <SnapLink
                      className="text-sm text-blue-600 cursor-pointer"
                      sr-only="srOnly"
                      onClick={() => {
                        group?.setParticipant(item.roster?.name ?? "");
                        if (location.pathname.startsWith("/groups")) {
                          navigate(
                            `/groups/participant-details/${item.roster?.id}`
                          );
                        } else {
                          navigate(
                            `/group/participant-details/${item.roster?.id}`
                          );
                        }
                      }}
                    >
                      {item.roster?.name ?? ""}
                    </SnapLink>
                  </td>
                  <td className="w-[11%]">
                    <SnapBadge
                      className="flex pb-1 capitalize"
                      color={getBadgeColor(status)}
                    >
                      {status.replace("_", " ")}
                    </SnapBadge>
                  </td>
                  <td className="pl-16">{FormatMoney(item.total?.paid)}</td>
                  <td className="pl-16">
                    {FormatMoney(item.total?.processing)}
                  </td>
                  <td className="pl-16">{FormatMoney(item.total?.upcoming)}</td>
                  <td>
                    <p className="flex justify-center">
                      {hasWarning && (
                        <SnapIcon
                          icon="exclamation-solid"
                          color="#EF4444"
                          size="sm"
                        />
                      )}
                      {FormatMoney(item.total?.pastDue)}
                    </p>
                  </td>
                  <td className="flex justify-end items-center">
                    {FormatMoney(item.total?.credited)}
                  </td>
                </tr>
              );
            })
          ) : (
            <SpinnerContainer loading={queryLoading} inTable colSpan={7} />
          )}
        </tbody>
      </table>
    </SnapTable>
  );
}

export default Table;
