import { SnapBadge, SnapCheckboxButton, SnapLink, SnapTable } from "suit";
import SortIcon from "shared-components/table/sort-icon";
import { SpendSortInput } from "graphql/generated";
import { useContext } from "react";
import GroupContext from "context/group-context";
import { useLocation, useNavigate } from "react-router-dom";
import { getBadgeColor } from "helpers/status-color";
import { SpinnerContainer } from "shared-components/spinner";
import { FormatDate } from "helpers/format-date";
import { RosterDataType } from "types/group-roster";

type TableProps = {
  rosterData: RosterDataType[];
  canEditParticipant: boolean;
  isArchived: boolean;
  handleSelectall: (
    selectedItems: RosterDataType[],
    selectAll: boolean,
    isChecked: boolean
  ) => void;
  allSelected: boolean;
  sort: SpendSortInput | undefined;
  toggleSort: (field: string) => void;
  queryLoading?: boolean;
  handleRosterDataInviteStatus: (rosterDara: RosterDataType) => string;
};

export default function Table({
  rosterData,
  canEditParticipant,
  isArchived,
  handleSelectall,
  allSelected,
  sort,
  toggleSort,
  queryLoading,
  handleRosterDataInviteStatus,
}: TableProps) {
  const group = useContext(GroupContext);

  const location = useLocation();
  const navigate = useNavigate();

  return (
    <SnapTable>
      <table className="ui celled hidden lg:table">
        <thead>
          <tr>
            {canEditParticipant && !isArchived && (
              <th>
                <SnapCheckboxButton
                  onClick={(e) => {
                    handleSelectall(rosterData, true, e.currentTarget.checked);
                  }}
                  checked={allSelected}
                />
              </th>
            )}
            <th>
              Participant
              <SortIcon
                field="participant"
                currentSort={sort}
                toggleSort={toggleSort}
              />
            </th>
            <th>
              Email
              <SortIcon
                field="email"
                currentSort={sort}
                toggleSort={toggleSort}
              />
            </th>
            <th>
              Status
              <SortIcon
                field="inviteStatus"
                currentSort={sort}
                toggleSort={toggleSort}
              />
            </th>
            <th>
              Joined At
              <SortIcon
                field="joinedAt"
                currentSort={sort}
                toggleSort={toggleSort}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {!queryLoading ? (
            rosterData.map((item: RosterDataType) => {
              let status = handleRosterDataInviteStatus(item);

              return (
                <tr key={item.id} className="h-16">
                  {canEditParticipant && !isArchived && (
                    <td>
                      <SnapCheckboxButton
                        checked={item.isChecked}
                        onClick={(e) =>
                          handleSelectall(
                            [item],
                            false,
                            e.currentTarget.checked
                          )
                        }
                      />
                    </td>
                  )}
                  <td>
                    <SnapLink
                      className="text-sm text-blue-600 cursor-pointer"
                      sr-only="srOnly"
                      onClick={() => {
                        group?.setParticipant(item.roster?.name ?? "");
                        if (location.pathname.startsWith("/groups")) {
                          navigate(
                            `/groups/participant-details/${item.roster?.id}`
                          );
                        } else {
                          navigate(
                            `/group/participant-details/${item.roster?.id}`
                          );
                        }
                      }}
                    >
                      {item.roster?.name}
                    </SnapLink>
                  </td>
                  <td>{item.roster?.email}</td>
                  <td>
                    <SnapBadge
                      className="flex pb-1 capitalize"
                      color={getBadgeColor(status)}
                    >
                      {status}
                    </SnapBadge>
                  </td>
                  <td>{FormatDate(item.joinedAt)}</td>
                </tr>
              );
            })
          ) : (
            <SpinnerContainer loading={queryLoading} inTable colSpan={7} />
          )}
        </tbody>
      </table>
    </SnapTable>
  );
}
