import { SnapSelectMenuOption } from "@snap-mobile/snap-ui/dist/types/utils";
import ProgramContext from "context/program-context";
import { FormatMoney } from "helpers/format-money";
import { useContext, useEffect, useState } from "react";
import { SnapSelectMenu } from "suit";
import { SpendOrgAchCredit } from "graphql/generated";
import InputMask from "shared-components/input-mask";
import { achInputErrors } from "types/errors";
import GroupContext from "context/group-context";
import SplitIOContext from "context/splitio-context";
import { useContextStrict } from "helpers/context-strict";
import { calculateFee } from "helpers/transaction";

type TransferCounterpartyProps = {
  counterpartyOptions: SnapSelectMenuOption[];
  achCreditInput: SpendOrgAchCredit;
  setACHCreditInput: React.Dispatch<React.SetStateAction<SpendOrgAchCredit>>;
  amount: string;
  setAmount: React.Dispatch<React.SetStateAction<string>>;
  achInputErrors: achInputErrors;
  setACHInputErrors: React.Dispatch<React.SetStateAction<achInputErrors>>;
};

function TransferCounterparty({
  counterpartyOptions,
  achCreditInput,
  setACHCreditInput,
  amount,
  setAmount,
  achInputErrors,
  setACHInputErrors,
}: TransferCounterpartyProps) {
  const program = useContext(ProgramContext);
  const group = useContext(GroupContext);
  const split = useContextStrict(SplitIOContext);

  const [ffAchFees, setFfAchFees] = useState(false);
  const [accountLimit, setAccountLimit] = useState(0);
  const [limit, setLimit] = useState("");
  const [feeAmount, setFeeAmount] = useState("");
  useEffect(() => {
    let isOn = false;
    if (program?.organization?.id) {
      isOn = split.isTreatmentOn(split.act.se2082, {
        organizationId: program?.organization.id,
      });
    } else {
      isOn = split.isTreatmentOn(split.act.se2082);
    }
    setFfAchFees(isOn);
  }, [split, program]);
  useEffect(() => {
    // eslint-disable-next-line
    if (group?.activeGroup != undefined) {
      setAccountLimit(group.accountLimits?.ach?.limits.dailyCredit ?? 0);
    } else {
      setAccountLimit(program?.accountLimits?.ach?.limits.dailyCredit ?? 0);
    }
  }, [program, group]);
  useEffect(() => {
    if (ffAchFees && program?.organization?.externalTransferFee) {
      const { percentage, base } = program.organization.externalTransferFee;
      setFeeAmount(calculateFee(amount, percentage, base));
    }
  }, [amount, ffAchFees, program?.organization]);
  return (
    <div>
      <p>
        Amount cannot exceed the daily limit of{" "}
        <span className="text-green-500 font-bold">
          {FormatMoney(accountLimit)}
        </span>
      </p>
      {ffAchFees && program?.organization?.externalTransferFee && (
        <p className="text-sm text-gray-500 font-bold mt-2">
          All external payments will include a{" "}
          {program.organization.externalTransferFee.percentage * 100}% fee.
        </p>
      )}
      <div className="lg:grid grid-cols-2 gap-6 mt-4 flex flex-col mb-6">
        <SnapSelectMenu
          label="Payee"
          placeholder="Select Payee"
          selectMenuOptions={counterpartyOptions}
          error={achInputErrors.counterpartyError}
          onSnap-select-menu-updated={(e) => {
            const selectedItem = e.detail.find((item) => item.selected);
            setACHCreditInput({
              ...achCreditInput,
              counterpartyId: selectedItem?.value!,
              counterpartyName: selectedItem?.name!,
            });
            setACHInputErrors({
              ...achInputErrors,
              counterpartyError: false,
            });
          }}
        />
        <div className="lg:mt-0 mt-4">
          <InputMask
            amount={amount}
            setAmount={setAmount}
            hasError={achInputErrors.amountError}
            onChange={() => {
              setACHInputErrors({
                ...achInputErrors,
                amountError: false,
              });
            }}
          />
          {ffAchFees && (
            <div className="mt-2">
              <div className="flex flex-row justify-between">
                <p>Fee:</p>
                <p className="font-bold mr-4">
                  {FormatMoney(Number(feeAmount ?? 0) * 100)}
                </p>
              </div>
              <div className="flex flex-row justify-between mt-2">
                <p>Payee will receive:</p>
                <p className="font-bold mr-4">
                  {FormatMoney(
                    (Number(amount ?? 0) - Number(feeAmount ?? 0)) * 100
                  )}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="mt-4">
        <p className="text-sm font-normal">Note</p>
        <textarea
          value={achCreditInput.note}
          className={`border-2 border-gray-200 w-full rounded-lg pb-28 px-4 pt-3 ${
            (achInputErrors.noteError || limit.length >= 81) && "border-red-500"
          }`}
          onChange={(e) => {
            if (e.target.value.length <= 80) {
              setLimit(e.target.value);
              setACHCreditInput({
                ...achCreditInput,
                note: e.target.value,
              });
              setACHInputErrors({
                ...achInputErrors,
                noteError: false,
              });
            }
          }}
        />
        <div className="flex justify-between">
          <p className="text-sm text-gray-500">Required</p>
          <p className="text-sm text-gray-500">Limit {limit.length} / 80</p>
        </div>
      </div>
    </div>
  );
}

export default TransferCounterparty;
