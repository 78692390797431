export const formatNumber = (number: string) => {
  return `${number.substring(0, 2)}(${number.substring(
    2,
    5
  )}) ${number.substring(5, 8)}-${number.substring(8)}`;
};

export const doubleDigit = (value: number) => {
  if (value < 0) {
    return `${value}`;
  }
  return String(value).padStart(2, "0");
};
