import { useEffect, useState } from "react";
import Approvals from "./approvals";
import OrgSignupLink from "./org-signup-link";
import { useContextStrict } from "helpers/context-strict";
import SplitIOContext from "context/splitio-context";
import UnitFileUpload from "./unit-file-upload";
import ProgramContext from "context/program-context";
import UserContext from "context/user-context";
import RequirePaymentMethod from "./require-payment-method";

function OrganizationSettings() {
  const user = useContextStrict(UserContext);
  const program = useContextStrict(ProgramContext);
  const splits = useContextStrict(SplitIOContext);
  const programRoles = ["program_admin", "program_staff"];
  const [spe1288DualApproval, setSPE1288DualApproval] = useState(false);
  const [se2143RequirePaymentMethod, setSe2143RequirePaymentMethod] =
    useState(false);

  useEffect(() => {
    setSPE1288DualApproval(splits.isTreatmentOn(splits.act.spe1288));
    setSe2143RequirePaymentMethod(splits.isTreatmentOn(splits.act.se2143));
  }, [splits]);

  return (
    <div className="wrapper">
      <OrgSignupLink />
      {program.settings.fileUploadEnabled && <UnitFileUpload />}
      {se2143RequirePaymentMethod &&
        programRoles.includes(user._session?.role?.name ?? "") && (
          <RequirePaymentMethod />
        )}
      {spe1288DualApproval &&
        user.isApprover() &&
        programRoles.includes(user._session?.role?.name ?? "") && <Approvals />}
    </div>
  );
}

export default OrganizationSettings;
