export const ITEMS_PER_PAGE = 10;

export const LATEST_TRANSACTION_NUMBER = 10;

export const TOAST_TIMEOUT = 3000;
export const DEBOUNCE_TIME = 500;

export const PROGRAM = "program";
export const GROUP = "group";
export const USER = "user";

const CLOUD_STORAGE_PROVIDER = (
  process.env.CLOUD_STORAGE_PROVIDER || "aws"
).toLowerCase();

export const CLOUD_STORAGE_CONFIG = (() => {
  const config = {
    aws: {
      accessKeyId: process.env.AWS_ACCESS_KEY_ID || "",
      buckets: {
        spendV2Bucket: process.env.AWS_S3_BUCKET || "",
      },
      endpoint: "https://s3.us-east-2.amazonaws.com",
      region: "us-east-2",
      secretAccessKey: process.env.AWS_SECRET_ACCESS_KEY || "",
    },
    gcp: {
      accessKeyId: process.env.GCP_ACCESS_KEY_ID || "",
      buckets: {
        spendV2Bucket: process.env.GCP_SPEND_BUCKET || "",
      },
      endpoint: "https://storage.googleapis.com",
      region: process.env.GCP_REGION || "us-east1",
      secretAccessKey: process.env.GCP_SECRET_ACCESS_KEY || "",
    },
  };

  return config[CLOUD_STORAGE_PROVIDER as keyof typeof config];
})();

export const ATTACHMENT_KEY = "attachmentKey";
