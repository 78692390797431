import { SnapInput, SnapSelectMenu } from "suit";
import { payeeErrors } from "types/errors";
import { payeeToAdd } from "types/programs";
import PayeeStates from "./payee-states";

type AddPayeeContentProps = {
  payeeToAdd: payeeToAdd;
  hasErrors: payeeErrors;
  setHasErrors: React.Dispatch<React.SetStateAction<payeeErrors>>;
  einNumberRef: (node: HTMLDivElement | null) => void;
};

function AddPayeeContent({
  payeeToAdd,
  hasErrors,
  setHasErrors,
  einNumberRef,
}: AddPayeeContentProps) {
  return (
    <>
      <div className="mt-2 pb-2   border-b-2">
        <p className="ml-4">Add Payee</p>
      </div>
      <div className="modal-card lg:mx-48 lg:mb-48">
        <SnapInput
          _id={""}
          label="Payee Name"
          helpText=" "
          maxlength="40"
          displayCharCount
          value={payeeToAdd.name}
          onSnap-input-change={(e) => {
            if (e.detail.target.value.length <= 40) {
              payeeToAdd.name = e.detail.target.value;
              setHasErrors({ ...hasErrors, nameError: false });
            }
          }}
          onBlur={(e) => {
            if (e.target.value.length <= 40) {
              payeeToAdd.name = e.target.value;
              setHasErrors({ ...hasErrors, nameError: false });
            }
          }}
          error={hasErrors.nameError}
          errorIcon={hasErrors.nameError}
        />
        <div className="lg:grid grid-cols-2 gap-6">
          <div className="lg:mt-4 mt-6">
            <SnapInput
              _id={""}
              label="Street Address"
              maxlength="50"
              helpText=" "
              displayCharCount
              value={payeeToAdd.address1}
              onSnap-input-change={(e) => {
                if (e.detail.target.value <= 50) {
                  payeeToAdd.address1 = e.detail.target.value;
                  setHasErrors({ ...hasErrors, address1Error: false });
                }
              }}
              onBlur={(e) => {
                if (e.target.value.length <= 50) {
                  payeeToAdd.address1 = e.target.value;
                  setHasErrors({ ...hasErrors, address1Error: false });
                }
              }}
              error={hasErrors.address1Error}
              errorIcon={hasErrors.address1Error}
            />
          </div>
          <div className="lg:mt-4 mt-6">
            <SnapInput
              _id={""}
              label="Apartment/Suit/Unit"
              maxlength="50"
              helpText=" "
              displayCharCount
              value={payeeToAdd?.address2}
              onSnap-input-change={(e) => {
                payeeToAdd.address2 = e.detail.target.value;
              }}
              onBlur={(e) => {
                payeeToAdd.address2 = e.target.value;
              }}
            />
          </div>
        </div>
        <div className="lg:grid grid-cols-2 gap-6">
          <div className="lg:mt-4 mt-6">
            <SnapInput
              _id={""}
              label="City"
              value={payeeToAdd.city}
              onSnap-input-change={(e) => {
                payeeToAdd.city = e.detail.target.value;
                setHasErrors({ ...hasErrors, cityError: false });
              }}
              onBlur={(e) => {
                payeeToAdd.city = e.target.value;
                setHasErrors({ ...hasErrors, cityError: false });
              }}
              error={hasErrors.cityError}
              errorIcon={hasErrors.cityError}
            />
          </div>
          <div className="lg:grid grid-cols-2 gap-6">
            <div className="lg:mt-4 mt-6">
              <SnapSelectMenu
                label="State"
                placeholder="Select State"
                error={hasErrors.stateError}
                selectMenuOptions={PayeeStates}
                onSnap-select-menu-updated={(e) => {
                  const selectedItem = e.detail.find((state) => state.selected);
                  if (selectedItem) {
                    payeeToAdd.state = selectedItem.value!;
                  } else {
                    payeeToAdd.state = "";
                  }
                }}
              />
            </div>
            <div className="lg:mt-4 mt-6">
              <SnapInput
                _id={""}
                label="Zip Code"
                value={payeeToAdd.zipCode}
                onSnap-input-change={(e) => {
                  payeeToAdd.zipCode = e.detail.target.value;
                  setHasErrors({ ...hasErrors, zipCodeError: false });
                }}
                onBlur={(e) => {
                  payeeToAdd.zipCode = e.target.value;
                  setHasErrors({ ...hasErrors, zipCodeError: false });
                }}
                error={hasErrors.zipCodeError}
                errorIcon={hasErrors.zipCodeError}
              />
            </div>
          </div>
        </div>
        <p className="mt-4 text-sm font-medium text-gray-700">EIN Number</p>
        <div
          ref={einNumberRef}
          id="EIN_NUMBER_INPUT_REF"
          className={`h-10 border-[2px] rounded-[10px] pl-3 border-gray-200`}
        ></div>
      </div>
    </>
  );
}

export default AddPayeeContent;
