import { useMutation, useQuery } from "@apollo/client";
import {
  DropdownMenuItem,
  SnapSelectMenuOption,
} from "@snap-mobile/snap-ui/dist/types/utils";
import DisplayContext from "context/display-context";
import GroupContext from "context/group-context";
import ProgramContext from "context/program-context";
import SeasonContext from "context/season-context";
import ToastContext from "context/toast-context";
import UserContext from "context/user-context";
import {
  PaymentScheduleStatus,
  SpendPaymentSchedule,
  useSpendGroupRostersBySeasonV2Query,
  useSpendPaymentScheduleRevertManyMutation,
  useSpendPaymentScheduleRevertMutation,
  useSpendSeasonUpdateMutation,
} from "graphql/generated";
import { UPDATE_PAYMENT_SCHEDULE } from "graphql/mutations/payment";
import { GET_GROUPS_FILTERED } from "graphql/queries/group";
import useModal from "hooks/use-modal";
import { useContext, useEffect, useState } from "react";
import Divider from "shared-components/divider";
import ScrollableModal, {
  BtnState,
  BtnType,
} from "shared-components/modal/scrollable-modal";
import ShowingResults from "shared-components/showing-results";
import Sort from "shared-components/sort";
import { VerticalValueStyle } from "shared-components/vertical-label-value";
import { SnapActionSheet, SnapButton, SnapPagination } from "suit";
import { LabelValueObject } from "types/label-value-object";
import { collectionActions, collectionModalOptions } from "types/programs";
import { SpendPermissions } from "types/roles-permissions";
import Cards from "./card";
import CancelPaymentScheduleEditModal from "./modals/payment-schedule/cancel-payment-schedule-edit";
import EditModeWarningPaymentScheduleModal from "./modals/payment-schedule/edit-mode-warning-payment-schedule";
import EditPaymentSchedule from "./modals/payment-schedule/edit-payment-schedule";
import ViewPaymentSchedule from "./modals/payment-schedule/view-payment-schedule";
import Table from "./table";
import { useSplitTreatments } from "@splitsoftware/splitio-react";
import { useContextStrict } from "helpers/context-strict";
import SplitIOContext from "context/splitio-context";
import { PAYMENT_SCHEDULE_BY_GROUP_OR_SEASON } from "graphql/queries/payment-schedule";
import { FormatMoney } from "helpers/format-money";
import { useSpendPagination } from "hooks/use-spend-pagination";
import PageLimit from "shared-components/page-limit";
import { isPsDraft, isPsPublished } from "helpers/status-text";
import FloatingActionBtn from "shared-components/floating-action-btn";
import { RosterDataType } from "types/group-roster";
import BulkActionsV2 from "shared-components/bulk-actions-v2";
import { collectionsSortOptions } from "../rosters/modals/add-participant/helper";

type ParticipantRosterProps = {
  setPaymentScheduleLoading: React.Dispatch<React.SetStateAction<boolean>>;
};
export type SpendPaymentScheduleWithFees = SpendPaymentSchedule & {
  received?: number;
  feeAmount?: number;
  hasError: boolean;
};
function ParticipantRoster({
  setPaymentScheduleLoading,
}: ParticipantRosterProps) {
  const { treatments, isReady } = useSplitTreatments({
    names: ["SE-469-payment-schedule-cancel-fix"],
  });
  const splits = useContextStrict(SplitIOContext);
  const [se579NotSavingPublish, setSe579NotSavingPublish] = useState(false);
  useEffect(() => {
    setSe579NotSavingPublish(splits.isTreatmentOn(splits.act.se579));
  }, [splits]);

  const program = useContext(ProgramContext);
  const season = useContext(SeasonContext);
  const group = useContext(GroupContext);
  const activeGroup = group?.activeGroup;
  const toast = useContext(ToastContext);
  const display = useContext(DisplayContext);

  const {
    sort,
    toggleSort,
    sortValue: currentSort,
    handleSortValue,
  } = useSpendPagination();

  const canEditParticipant =
    useContext(UserContext)?.checkSpendPermission(
      SpendPermissions.groupRostersUpdate
    ) ?? false;

  const spendPercent = program?.getSpendPercent() || 0;
  const spendBaseFee = program?.getSpendBaseFee() || 0;

  const { isOpen, toggle } = useModal();
  const { isOpen: isActionsOpen, toggle: toggleActionsOpen } = useModal();
  const { isOpen: editModeWarningOpen, toggle: editModeWarningToggle } =
    useModal();
  const { isOpen: revertChangesOpen, toggle: revertChangesToggle } = useModal();

  const [paymentScheduleStatus, setPaymentScheduleStatus] = useState<
    | PaymentScheduleStatus.Published
    | PaymentScheduleStatus.Draft
    | PaymentScheduleStatus.OnHold
    | null
    | undefined
  >();
  const [selectedModalOption, setSelectedModalOption] = useState(0);
  const [incomeCategories, setIncomeCategories] = useState<
    SnapSelectMenuOption[]
  >([]);
  const [invoices, setInvoices] = useState<SpendPaymentScheduleWithFees[]>([]);
  const [allSelected, setAllSelected] = useState(false);
  const [page, setPage] = useState(0);
  const [isBtnActive, setIsBtnActive] = useState(true);
  const [pageLimit, setPageLimit] = useState(10);
  const [selectedRosterData, setSelectedRosterData] = useState<
    RosterDataType[]
  >([]);
  const [seasonRosterData, setSeasonRosterData] = useState<RosterDataType[]>(
    []
  );

  let collectionActions: collectionActions[] = [
    {
      label: "Edit Payment Schedule",
      onClick: () => {
        handleModalOptions(0);
      },
      className: "mt-2",
      btnStyle: "secondary",
    },
  ];

  const { loading: loadingRoster, data: rosterData } =
    useSpendGroupRostersBySeasonV2Query({
      variables: {
        seasonId: season?.selectedSeason?.id ?? "",
        pagination: {
          offset: page * pageLimit,
          limit: pageLimit,
        },
        sort,
      },
      skip: !season?.selectedSeason?.id,
    });

  const { loading: loadingPaymentSchedule, data: paymentScheduleData } =
    useQuery(PAYMENT_SCHEDULE_BY_GROUP_OR_SEASON, {
      variables: {
        seasonId: season?.selectedSeason?.id,
        groupIdOrSeasonId: season?.selectedSeason?.id,
      },
    });
  const [updateSeason] = useSpendSeasonUpdateMutation({
    refetchQueries: ["SpendSeason"],
  });
  const [updatePSI, { data: psiData, loading: psiLoading }] = useMutation(
    UPDATE_PAYMENT_SCHEDULE,
    {
      refetchQueries: [
        "SpendGroupRostersBySeasonV2",
        {
          query: PAYMENT_SCHEDULE_BY_GROUP_OR_SEASON,
          variables: {
            seasonId: season?.selectedSeason?.id,
            groupIdOrSeasonId: season?.selectedSeason?.id,
          },
          fetchPolicy: "network-only",
        },
        "SpendOrganizationGroupsCategories",
      ],
    }
  );
  const [cancelPSI] = useSpendPaymentScheduleRevertMutation({
    refetchQueries: [
      "SpendGroupRostersBySeasonV2",
      {
        query: PAYMENT_SCHEDULE_BY_GROUP_OR_SEASON,
        variables: {
          seasonId: season?.selectedSeason?.id,
          groupIdOrSeasonId: season?.selectedSeason?.id,
        },
        fetchPolicy: "network-only",
      },
      "SpendOrganizationGroupsCategories",
    ],
  });
  const [cancelPSIMany] = useSpendPaymentScheduleRevertManyMutation({
    refetchQueries: [
      "SpendGroupRostersBySeasonV2",
      {
        query: PAYMENT_SCHEDULE_BY_GROUP_OR_SEASON,
        variables: {
          seasonId: season?.selectedSeason?.id,
          groupIdOrSeasonId: season?.selectedSeason?.id,
        },
        fetchPolicy: "network-only",
      },
      "SpendOrganizationGroupsCategories",
    ],
  });

  useEffect(() => {
    if (!psiLoading && psiData && psiData.spendPaymentScheduleUpdate) {
      toast &&
        toast.setToastProps({
          message: isPsPublished(paymentScheduleStatus)
            ? "Payment schedule published."
            : "Payment schedule is on hold.",
          type: isPsPublished(paymentScheduleStatus) ? "success" : "warning",
        });
      toast && toast.toggleToast();
      setIsBtnActive(true);
      if (selectedModalOption === 1) {
        toggle();
      } else if (isPsDraft(paymentScheduleStatus)) {
        setSelectedModalOption(1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [psiData, psiLoading]);

  useEffect(() => {
    const CalculateFee = (amountDue: number): number => {
      return amountDue * spendPercent + spendBaseFee;
    };

    if (!loadingPaymentSchedule && paymentScheduleData?.spendPaymentSchedules) {
      const invoicesUnordered = paymentScheduleData.spendPaymentSchedules.map(
        (schedule: SpendPaymentSchedule) => {
          return {
            ...schedule,
            received:
              (schedule.amountDue ?? 0) - CalculateFee(schedule.amountDue ?? 0),
            feeAmount: CalculateFee(schedule?.amountDue ?? 0),
            hasError: false,
          };
        }
      );
      invoicesUnordered.sort((a: any, b: any) =>
        a.description > b.description ? 1 : -1
      );
      setInvoices(invoicesUnordered);
      if (se579NotSavingPublish) {
        setIsBtnActive(true);
      }
      setPaymentScheduleLoading(loadingPaymentSchedule);
    }
    // eslint-disable-next-line
  }, [loadingPaymentSchedule, paymentScheduleData, isOpen]);

  useEffect(() => {
    if (
      !loadingRoster &&
      rosterData?.spendGroupRostersBySeasonV2?.groupRosters
    ) {
      const rosterIds = new Set(selectedRosterData.map(({ id }) => id));

      const rosters = rosterData.spendGroupRostersBySeasonV2.groupRosters.map(
        (groupRoster) => {
          let isChecked = false;
          if (groupRoster?.id) {
            isChecked = rosterIds.has(groupRoster.id);
          }
          return {
            ...groupRoster,
            isChecked,
          };
        }
      );
      setSeasonRosterData(rosters);
    }
    // eslint-disable-next-line
  }, [
    loadingRoster,
    rosterData,
    activeGroup,
    season?.selectedSeason,
    spendPercent,
    spendBaseFee,
    setSeasonRosterData,
  ]);

  useEffect(() => {
    let rosterItems = seasonRosterData.slice();
    let selected = rosterItems.filter((i) => i.isChecked);
    setAllSelected(
      rosterItems.length !== 0 && rosterItems.length === selected.length
    );
  }, [page, seasonRosterData]);

  useEffect(() => {
    const st = season?.selectedSeason?.paymentScheduleStatus;
    if (
      st === PaymentScheduleStatus.Draft ||
      st === PaymentScheduleStatus.OnHold ||
      st === PaymentScheduleStatus.Published
    ) {
      setPaymentScheduleStatus(st);
    } else {
      setPaymentScheduleStatus(PaymentScheduleStatus.Draft);
    }
  }, [season?.selectedSeason]);

  const modalOptions: collectionModalOptions[] = [
    {
      title: "Groups Payment Schedule",
      btn1: {
        text: isPsPublished(paymentScheduleStatus)
          ? "Turn editing mode on"
          : "Continue editing",
        onClick: () => {
          if (isPsPublished(paymentScheduleStatus)) {
            editModeWarningToggle();
          } else {
            setSelectedModalOption(1);
          }
        },
        btnStyle: "primary",
        btnState: canEditParticipant ? BtnState.BASE : BtnState.HIDDEN,
      },
    },
    {
      title: "Groups Payment Schedule",
      btn1: {
        text: "Publish",
        onClick: () => {
          callUpdateSeason(PaymentScheduleStatus.Published);
        },
        btnStyle: "primary",
        btnState: isBtnActive ? BtnState.BASE : BtnState.DISABLED,
      },
    },
  ];
  const [sortOptions] = useState<DropdownMenuItem[]>(collectionsSortOptions);

  const cancelPaymentInvoices = () => {
    // split is not created
    if (treatments["SE-469-payment-schedule-cancel-fix"]?.treatment === "on") {
      const ids: string[] = invoices.reduce((acc, invoice) => {
        if (invoice.id) acc.push(invoice.id);
        return acc;
      }, [] as string[]);

      cancelPSIMany({
        variables: {
          spendPaymentScheduleRevertIds: ids,
        },
      }).finally(() => {
        season?.requestSeason();
      });
    } else {
      if (isReady) {
        console.warn("Flag is not ready, fallback to previous logic");
      }
      invoices.forEach((invoice, idx) => {
        if (invoice.id) {
          cancelPSI({
            variables: {
              spendPaymentScheduleRevertId: invoice.id,
            },
          }).then(() => {
            // only hard fetch on last invoice
            if (idx === invoices.length - 1) {
              season?.requestSeason();
            }
          });
        } else {
          console.log("Unable to cancel due to missing Id");
        }
      });
    }
  };

  const callUpdateSeason = (
    status: PaymentScheduleStatus.Published | PaymentScheduleStatus.OnHold
  ) => {
    if (season?.selectedSeason?.id && status) {
      updateSeason({
        variables: {
          id: season.selectedSeason.id,
          input: {
            groupId: season.selectedSeason.groupId || group?.activeGroup?.id,
            paymentScheduleStatus: status,
          },
        },
      }).then(() => {
        toast &&
          toast.setToastProps({
            message:
              status === PaymentScheduleStatus.Published
                ? "Payment schedule published."
                : "Payment schedule is on hold.",
            type:
              status === PaymentScheduleStatus.Published
                ? "success"
                : "warning",
          });
        toast && toast.toggleToast();
        const currentSeasonUpdated = {
          ...group?.activeGroup?.currentSeason,
          paymentScheduleStatus: status,
        };
        group?.setAndStoreActiveGroup({
          ...group?.activeGroup,
          currentSeason: currentSeasonUpdated,
          latestSeason: currentSeasonUpdated,
        });
        season?.setAndStoreActiveSeason(currentSeasonUpdated);
      });

      if (status === PaymentScheduleStatus.Published) {
        toggle();
      } else {
        setSelectedModalOption(1);
      }
    } else {
      console.log("data missing", {
        id: season?.selectedSeason?.id,
        status,
        groupId: group?.activeGroup?.id,
      });
    }
  };
  const publishDraftPaymentInvoices = () => {
    invoices.forEach((i) => {
      const budgetItemId = i.budgetItemId;
      const foundBI = incomeCategories.find((ic) => ic.value === budgetItemId);
      if (!foundBI) {
        i.hasError = true;
      }
    });

    const hasErrors = invoices.some((i) => i.hasError);
    if (hasErrors) {
      toast?.setToastProps({
        message: "Invoices are missing budget items",
        type: "danger",
      });
      toast?.toggleToast();
    }

    invoices.forEach((invoice, idx) => {
      let invoiceStatus: string = "Draft";
      if (invoice.status === "new") {
        invoiceStatus = "new";
      }

      updatePSI({
        variables: {
          spendPaymentScheduleUpdateId: invoice.id,
          input: {
            status: invoiceStatus,
            seasonId: season?.selectedSeason?.id,
            dueDate: invoice.dueDate && new Date(invoice.dueDate).toISOString(),
          },
        },
        refetchQueries: [
          "SpendGroupRostersBySeasonV2",
          {
            query: PAYMENT_SCHEDULE_BY_GROUP_OR_SEASON,
            variables: {
              seasonId: season?.selectedSeason?.id,
              groupIdOrSeasonId: season?.selectedSeason?.id,
            },
            fetchPolicy: "network-only",
          },
          {
            query: GET_GROUPS_FILTERED,
            errorPolicy: "all",
            fetchPolicy: "network-only",
          },
          "SpendOrganizationGroupsCategories",
        ],
      }).finally(() => {
        if (idx === invoices.length - 1) {
          season?.requestSeason();
        }
      });
    });
  };
  const prepRightData = (item: RosterDataType, status: string) => {
    const rightData: LabelValueObject[] = [];
    rightData.push({
      key: "Status",
      value: status,
      valueStyle: VerticalValueStyle.Badge,
      className: "flex-col",
    });
    rightData.push({ key: "Paid", value: FormatMoney(item.total?.paid) });
    rightData.push({
      key: "Processing",
      value: FormatMoney(item.total?.processing),
    });
    rightData.push({
      key: "Upcoming",
      value: FormatMoney(item.total?.upcoming),
    });
    rightData.push({
      key: "Past Due",
      value: FormatMoney(item?.total?.pastDue || 0),
      hasWarning: (item.total?.pastDue ?? 0) > 0,
    });
    rightData.push({
      key: "Credited",
      value: FormatMoney(item.total?.credited),
    });

    return rightData;
  };

  const handleSelectall = (
    selectedItems: RosterDataType[],
    selectAll: boolean,
    isChecked: boolean
  ) => {
    let tempSelectedItems = [...selectedRosterData];
    let tempItems: RosterDataType[] = [...seasonRosterData];

    selectedItems.forEach((item) => {
      let updatedItem = {
        ...item,
        isChecked: !item.isChecked,
      };

      if (selectAll) {
        updatedItem.isChecked = isChecked;
      }

      const foundItem = tempSelectedItems.find(
        (tempItem) => tempItem.id === item.id
      );

      if (!foundItem && updatedItem.isChecked) {
        tempSelectedItems.push(updatedItem);
      } else if (foundItem) {
        const idx = tempSelectedItems.findIndex(
          (item) => item.id === updatedItem.id
        );
        tempSelectedItems.splice(idx, 1);
      }

      const rosterIdx = tempItems.findIndex(
        (item) => item.id === updatedItem.id
      );
      tempItems.splice(rosterIdx, 1, updatedItem);
    });

    setSeasonRosterData(tempItems);
    setSelectedRosterData(tempSelectedItems);
  };

  const handleModalOptions = (optionSelected: number) => {
    setSelectedModalOption(optionSelected);
    toggle();
  };

  const handleRosterDataInviteStatus = (rosterData: RosterDataType) => {
    if (rosterData.invite) {
      switch (rosterData.invite.status?.toLowerCase()) {
        case "canceled":
          return "Canceled";
        case "pending":
        case "sent":
          return "Not Signed Up";
        case "accepted":
          return (rosterData.total?.statuses?.[0] ?? "n/a")?.replace("_", " ");
        case "expired":
          return "Expired";
        default:
          return "No Invite Sent";
      }
    } else {
      return "No Invite Sent";
    }
  };

  const handleDeselectAll = () => {
    const resetData = seasonRosterData.map((item) => {
      return {
        ...item,
        isChecked: false,
      };
    });
    setSeasonRosterData(resetData);
    setSelectedRosterData([]);
  };

  let btn1: BtnType = modalOptions[selectedModalOption].btn1;
  let btn2: BtnType = {
    text: "Cancel",
    onClick: () => {
      if (selectedModalOption === 1) {
        revertChangesToggle();
      } else {
        toggle();
      }
    },
  };

  return (
    <div>
      <div className="flex">
        <p className="mr-auto self-center text-lg font-semibold">
          Participants Roster
        </p>
        <div className="lg:flex hidden">
          <SnapButton
            variant="secondary"
            className="mr-2"
            onClick={() => {
              handleModalOptions(0);
            }}
            disabled={loadingPaymentSchedule}
          >
            Edit Payment Schedule
          </SnapButton>
        </div>
        {isActionsOpen && (
          <SnapActionSheet
            header="Collection's Actions"
            onClick={toggleActionsOpen}
          >
            <Divider isVisibleOnMobile className="mt-0" />
            {collectionActions.map((actionItem, idx) => {
              return (
                <div
                  className="mx-4 mt-4"
                  key={idx}
                  onClick={actionItem.onClick}
                >
                  <p>{actionItem.label}</p>
                </div>
              );
            })}
          </SnapActionSheet>
        )}
      </div>
      <div className="lg:flex mt-4">
        <ShowingResults
          totalNumOfResults={
            rosterData?.spendGroupRostersBySeasonV2?.count ||
            seasonRosterData.length
          }
          numOfResultsBeingDisplayed={
            (rosterData?.spendGroupRostersBySeasonV2?.count ?? 0) <= 10
              ? seasonRosterData.length
              : pageLimit * page + pageLimit >=
                (rosterData?.spendGroupRostersBySeasonV2?.count ?? 0)
              ? rosterData?.spendGroupRostersBySeasonV2?.count ?? 0
              : pageLimit * page + pageLimit
          }
          startingNumOfResults={
            seasonRosterData.length === 0 ? 0 : pageLimit * page + 1
          }
          hasCheckbox
          checkboxAction={(e) =>
            handleSelectall(seasonRosterData, true, e.target.checked)
          }
          isNameBoxChecked={allSelected}
          hideCheckboxOnWeb
        />
        <PageLimit
          setPageLimit={setPageLimit}
          setPage={setPage}
          localStorageName={"collections-page-limit"}
        />
      </div>
      <Divider isVisibleOnMobile className="my-5 lg:hidden" />
      <Sort
        selectedSortOption={currentSort}
        options={sortOptions}
        handleSort={(e) => handleSortValue(e.value)}
      />
      {display?.isMobile ? (
        <Cards
          seasonRosterData={seasonRosterData}
          handleSelectall={handleSelectall}
          prepRightData={prepRightData}
          allSelected={allSelected}
          canEditParticipant={canEditParticipant}
          isArchived={group?.isArchived!}
          page={page}
          queryLoading={loadingRoster}
          handleRosterDataInviteStatus={handleRosterDataInviteStatus}
        />
      ) : (
        <Table
          seasonRosterData={seasonRosterData}
          handleSelectall={handleSelectall}
          allSelected={allSelected}
          canEditParticipant={canEditParticipant}
          isArchived={group?.isArchived!}
          page={page}
          queryLoading={loadingRoster}
          handleRosterDataInviteStatus={handleRosterDataInviteStatus}
          sort={sort}
          toggleSort={toggleSort}
        />
      )}
      <SnapPagination
        currentPage={page}
        itemCount={rosterData?.spendGroupRostersBySeasonV2?.count || 0}
        pageSize={pageLimit}
        onSnap-pagination-page-changed={(e) => setPage(e.detail)}
      />

      <ScrollableModal
        isOpen={isOpen}
        toggle={() => {
          toggle();
          setIsBtnActive(true);
        }}
        title={modalOptions[selectedModalOption].title}
        btn1={
          !group?.isArchived
            ? btn1
            : {
                text: "Cancel",
                btnStyle: "tertiary",
                onClick: toggle,
              }
        }
        btn2={!group?.isArchived ? btn2 : undefined}
      >
        {
          {
            0: (
              <ViewPaymentSchedule
                invoices={invoices}
                paymentScheduleStatus={paymentScheduleStatus || ""}
              />
            ),
            1: (
              <EditPaymentSchedule
                invoices={invoices}
                setInvoices={setInvoices}
                incomeCategories={incomeCategories}
                setIncomeCategories={setIncomeCategories}
                setIsBtnActive={setIsBtnActive}
                se579NotSavingPublish={se579NotSavingPublish}
              />
            ),
          }[selectedModalOption]
        }
      </ScrollableModal>
      {editModeWarningOpen && (
        <EditModeWarningPaymentScheduleModal
          isOpen={editModeWarningOpen}
          toggle={editModeWarningToggle}
          groupName={activeGroup?.name ?? null}
          publishDraftPaymentInvoices={callUpdateSeason}
        />
      )}
      {revertChangesOpen && (
        <CancelPaymentScheduleEditModal
          isOpen={revertChangesOpen}
          toggle={revertChangesToggle}
          proceedAction={() => {
            selectedModalOption === 1 && publishDraftPaymentInvoices();
          }}
          cancelAction={() => {
            cancelPaymentInvoices();
            revertChangesToggle();
            toggle();
          }}
        />
      )}
      {selectedRosterData.length > 0 && (
        <BulkActionsV2
          selectedItems={selectedRosterData.map((item) => {
            return {
              groupRosterId: item.id ?? "",
              email: item.roster?.email ?? "",
            };
          })}
          handleDeselectAll={handleDeselectAll}
          type={"collections"}
        />
      )}

      {!group?.activeGroup?.isArchived && (
        <FloatingActionBtn
          onClick={toggleActionsOpen}
          icon={"dots-horizontal-solid"}
        />
      )}
    </div>
  );
}

export default ParticipantRoster;
