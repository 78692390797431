import { SnapSelectMenuOption } from "@snap-mobile/snap-ui/dist/types/utils";
import ProgramContext from "context/program-context";
import SplitIOContext from "context/splitio-context";
import {
  SpendAccountLimitsResponse,
  SpendBankAccount,
  SpendExternalTransferFee,
} from "graphql/generated";
import { useContextStrict } from "helpers/context-strict";
import { FormatMoney } from "helpers/format-money";
import { calculateFee } from "helpers/transaction";
import React, { useEffect, useState } from "react";
import Divider from "shared-components/divider";
import InputMask from "shared-components/input-mask";
import { SnapRadioButton, SnapSelectMenu } from "suit";
import { transferError } from "types/errors";

type HasAccountProps = {
  setSelectedOption: React.Dispatch<React.SetStateAction<string>>;
  selectedOption: string;
  amount: string;
  feeAmount: string;
  setFeeAmount: React.Dispatch<React.SetStateAction<string>>;
  linkedAccounts: SpendBankAccount[];
  despotAccount: string;
  setAmount: React.Dispatch<React.SetStateAction<string>>;
  setNote: React.Dispatch<React.SetStateAction<string>>;
  setSelectedAccountId: React.Dispatch<React.SetStateAction<string>>;
  setDirection: React.Dispatch<React.SetStateAction<string>>;
  hasErrorsObj: transferError;
  setHasErrorObj: React.Dispatch<React.SetStateAction<transferError>>;
  setExternalTo: React.Dispatch<React.SetStateAction<string>>;
  setExternalFrom: React.Dispatch<React.SetStateAction<string>>;
  limits?: SpendAccountLimitsResponse;
  externalTransferFee?: SpendExternalTransferFee | null;
};

function HasAccount({
  setSelectedOption,
  selectedOption,
  amount,
  linkedAccounts,
  despotAccount,
  setAmount,
  setNote,
  setSelectedAccountId,
  setDirection,
  hasErrorsObj,
  setHasErrorObj,
  setExternalTo,
  setExternalFrom,
  limits,
  externalTransferFee,
  feeAmount,
  setFeeAmount,
}: HasAccountProps) {
  const programContext = useContextStrict(ProgramContext);
  const split = useContextStrict(SplitIOContext);
  const [ffAchFees, setFfAchFees] = useState(false);
  const [limit, setLimit] = useState("");
  const [limitError, setLimitError] = useState(false);
  const [numAccounts, setNumAccounts] = useState(2);
  const [selectedAccount, setSelectedAccount] = useState<
    SpendBankAccount | undefined
  >(undefined);
  const [selectMenuList, setSelectMenuList] = useState<SnapSelectMenuOption[]>(
    []
  );
  useEffect(() => {
    let isOn = false;
    if (programContext.organization?.id) {
      isOn = split.isTreatmentOn(split.act.se2082, {
        organizationId: programContext.organization.id,
      });
    } else {
      isOn = split.isTreatmentOn(split.act.se2082);
    }
    setFfAchFees(isOn);
  }, [split, programContext]);
  useEffect(() => {
    if (ffAchFees) {
      setFeeAmount(calculateFee(amount, externalTransferFee?.percentage));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount, ffAchFees]);
  useEffect(() => {
    // on load, set default to credit (out)
    setDirection("Credit");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setNumAccounts(linkedAccounts.length);
    if (linkedAccounts.length > 1) {
      setSelectMenuList(
        linkedAccounts.map((acc) => {
          return {
            name: acc.name ?? "",
            selected: false,
          };
        })
      );
    } else {
      setSelectedAccount(linkedAccounts.at(0));
      setSelectedAccountId(linkedAccounts.at(0)?.accountId ?? "");
      setExternalTo(linkedAccounts.at(0)?.name ?? "");
      setExternalFrom(despotAccount);
    }
    // eslint-disable-next-line
  }, [linkedAccounts, setSelectedAccountId]);
  const getLimit = (selectedOption: string, type: "monthly" | "daily") => {
    let amount: number | undefined;
    if (limits?.ach?.limits != null) {
      const { dailyCredit, monthlyCredit, dailyDebit, monthlyDebit } =
        limits.ach.limits;

      if (type === "monthly" && selectedOption === "Transfer IN") {
        amount = monthlyDebit ?? undefined;
      } else if (type === "monthly" && selectedOption === "Transfer OUT") {
        amount = monthlyCredit ?? undefined;
      } else if (type === "daily" && selectedOption === "Transfer IN") {
        amount = dailyDebit ?? undefined;
      } else if (type === "daily" && selectedOption === "Transfer OUT") {
        amount = dailyCredit ?? undefined;
      }
    }
    if (amount == null) {
      amount = type === "monthly" ? 10_000 : 1_000;
    }
    return FormatMoney(amount);
  };
  return (
    <>
      <p className="text-sm text-gray-500 font-medium">
        Transfers out of your account cannot exceed&nbsp;
        <span className="font-semibold text-gray-800">
          {getLimit(selectedOption, "daily")}
        </span>{" "}
        per day or{" "}
        <span className="font-semibold text-gray-800">
          {getLimit(selectedOption, "monthly")}
        </span>{" "}
        per month.
      </p>
      {ffAchFees && externalTransferFee && (
        <p className="text-sm text-gray-500 font-bold mt-4">
          All external transfers out will include a{" "}
          {externalTransferFee.percentage * 100}% fee.
        </p>
      )}
      <Divider isVisibleOnMobile className="mt-4 mb-4" />
      <p className="text-base font-semibold">Destination:</p>
      {numAccounts > 1 && (
        <SnapSelectMenu
          selectMenuOptions={selectMenuList}
          label="Select External Account"
          className="lg:w-[50%]"
          onSnap-select-menu-updated={(e) => {
            let acc = e.detail.find((acc) => acc.selected);
            let accNfo = linkedAccounts.find(
              (account) => account.name === acc?.name
            );
            setSelectedAccount(accNfo);
            setSelectedAccountId(accNfo?.accountId ?? "");
          }}
        />
      )}
      {selectedAccount && (
        <div className="lg:flex mt-4 lg:w-[80%]">
          <div
            className="flex py-4 lg:px-6 lg:border border-gray-200 rounded-lg  mr-6 w-[388px] cursor-pointer"
            onClick={() => {
              setSelectedOption("Transfer IN");
              setDirection("Debit");
              setExternalTo(despotAccount);
              setExternalFrom(selectedAccount?.name ?? "");
            }}
          >
            <SnapRadioButton checked={selectedOption === "Transfer IN"} />
            <div>
              <p className="text-sm font-medium">Transfer IN</p>
              <p className="text-sm text-gray-500">
                To: <span>{despotAccount}</span>
              </p>
              <p className="text-sm text-gray-500">
                From: <span>{selectedAccount?.name}</span>
              </p>
            </div>
          </div>
          <div
            className="flex py-4 lg:px-6 lg:border border-gray-200 rounded-lg  mr-6 w-[388px] cursor-pointer"
            onClick={() => {
              setSelectedOption("Transfer OUT");
              setDirection("Credit");
              setExternalTo(selectedAccount.name ?? "");
              setExternalFrom(despotAccount);
            }}
          >
            <SnapRadioButton checked={selectedOption === "Transfer OUT"} />
            <div>
              <p className="text-sm font-medium">Transfer OUT</p>
              <p className="text-sm text-gray-500">
                To: <span>{selectedAccount?.name}</span>
              </p>
              <p className="text-sm text-gray-500">
                From: <span>{despotAccount}</span>
              </p>
            </div>
          </div>
        </div>
      )}

      <div className="mt-4">
        <InputMask
          amount={amount}
          setAmount={setAmount}
          hasError={hasErrorsObj.amountError || hasErrorsObj.availableError}
          onChange={() =>
            setHasErrorObj({ ...hasErrorsObj, amountError: false })
          }
        />
        {ffAchFees && selectedOption === "Transfer OUT" && (
          <div className="mt-2 md:ml-[50%] md:w-[50%]">
            <div className="flex flex-row justify-between">
              <p>Fee:</p>
              <p className="font-bold mr-4">
                {FormatMoney(Number(feeAmount) * 100)}
              </p>
            </div>
            <div className="flex flex-row justify-between mt-2">
              <p>{selectedAccount?.name} will receive:</p>
              <p className="font-bold mr-4">
                {FormatMoney((Number(amount) - Number(feeAmount)) * 100)}
              </p>
            </div>
          </div>
        )}
      </div>
      <div className="mt-4">
        <p className="text-sm font-normal">Note</p>
        <textarea
          className={`border-2 border-gray-200 w-full rounded-lg pb-28 px-4 pt-3 ${
            (hasErrorsObj.noteError || limitError) && "border-red-500"
          }`}
          onChange={(e) => {
            setLimit(e.target.value);
            if (e.target.value.length >= 81) {
              setLimitError(true);
            } else {
              setLimitError(false);
              setNote(e.target.value);
            }
            setHasErrorObj({ ...hasErrorsObj, noteError: false });
          }}
        />
        <div className="flex justify-between">
          <p className="text-sm text-gray-500">Required</p>
          <p className="text-sm text-gray-500">Limit {limit.length} / 80</p>
        </div>
      </div>
    </>
  );
}

export default HasAccount;
