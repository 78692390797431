import { SpendRoster } from "graphql/generated";
import { emptyStringCheck } from "helpers/empty-string-check";
import { ValidateEmail } from "helpers/validation";

export const GroupRosterValidator = {
  validate: (roster: Partial<SpendRoster>) => {
    let errors: string[] = [];
    if (emptyStringCheck(roster.name)) {
      errors.push("Empty name not permitted.");
    }
    if (!roster.email || !ValidateEmail(roster.email)) {
      errors.push("Invalid email address.");
    }

    return errors;
  },
};

export const collectionsSortOptions = [
  {
    name: "Participant A - Z",
    text: "Participant A - Z",
    value: "participant:ASC",
  },
  {
    name: "Participant Z - A",
    text: "Participant Z - A",
    value: "participant:DESC",
  },
  {
    name: "Status A - Z",
    text: "Status A - Z",
    value: "status:ASC",
  },
  {
    name: "Status Z - A",
    text: "Status Z - A",
    value: "status:DESC",
  },
  {
    name: "Paid High to Low",
    text: "Paid High to Low",
    value: "paid:DESC",
  },
  {
    name: "Paid Low to High",
    text: "Paid Low to High",
    value: "paid:ASC",
  },
  {
    name: "Upcoming High to Low",
    text: "Upcoming High to Low",
    value: "upcoming:DESC",
  },
  {
    name: "Upcoming Low to High",
    text: "Upcoming Low to High",
    value: "upcoming:ASC",
  },
  {
    name: "Past Due High to Low",
    text: "Past Due High to Low",
    value: "pastDue:DESC",
  },
  {
    name: "Past Due Low to High",
    text: "Past Due Low to High",
    value: "pastDue:ASC",
  },
  {
    name: "Credited High to Low",
    text: "Credited High to Low",
    value: "credited:DESC",
  },
  {
    name: "Credited Low to High",
    text: "Credited Low to High",
    value: "created:ASC",
  },
];

export const rosterSortOptions = [
  {
    name: "Participant A - Z",
    text: "Participant A - Z",
    value: "participant:ASC",
  },
  {
    name: "Participant Z - A",
    text: "Participant Z - A",
    value: "participant:DESC",
  },
  {
    name: "Email A - Z",
    text: "Email A - Z",
    value: "email:ASC",
  },
  {
    name: "Email Z - A",
    text: "Email Z - A",
    value: "email:DESC",
  },
  {
    name: "Status A - Z",
    text: "Status A - Z",
    value: "inviteStatus:ASC",
  },
  {
    name: "Status Z - A",
    text: "Status Z - A",
    value: "inviteStatus:DESC",
  },
  {
    name: "Joined At A - Z",
    text: "Joined At A - Z",
    value: "joinedAt:ASC",
  },
  {
    name: "Joined At Z - A",
    text: "Joined At Z - A",
    value: "joinedAt:DESC",
  },
];
