import { useMutation } from "@apollo/client";
import DragDrog from "assets/DragDrop.svg";
import ToastContext from "context/toast-context";
import { UPLOAD_APPLICATION_FILES } from "graphql/mutations/organization";
import { UPLOAD_ATTACHMENTS } from "graphql/mutations/transactions";
import { GET_NOTES_AND_ATTACHMENTS_BY_PAYMENT_ID } from "graphql/queries/transactions";
import { createRef, useContext, useEffect, useRef, useState } from "react";
import { SnapIcon } from "suit";
import Spinner from "./spinner";

type DragAndDropProps = {
  label: string;
  type: "document" | "image";
  description?: string | null;
  className?: string | null;
  setUploadedFileHasError?: React.Dispatch<React.SetStateAction<boolean>>;
  paymentId: string;
  fromSettings?: boolean;
};

function DragAndDrop({
  label,
  type,
  description,
  className,
  setUploadedFileHasError,
  paymentId,
  fromSettings = false,
}: DragAndDropProps) {
  const toast = useContext(ToastContext);
  // eslint-disable-next-line
  const [dragging, setDragging] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadAttachment] = useMutation(UPLOAD_ATTACHMENTS);
  const [
    uploadUnitFile,
    { loading: loadingUnitFile, data: unitFileData, error: unitFileError },
  ] = useMutation(UPLOAD_APPLICATION_FILES);

  useEffect(() => {
    if (!loadingUnitFile) {
      if (unitFileData && unitFileData.spendUnitFileUpload) {
        toast?.setToast({
          message: "Successfully uploaded file",
          type: "success",
        });
      }
      if (unitFileError) {
        toast?.setToast({
          message: "There was an error while uploading the file",
          type: "danger",
        });
      }
    }
    // eslint-disable-next-line
  }, [loadingUnitFile, unitFileData]);

  const dropRef = createRef<HTMLInputElement>();
  const fileElement = useRef<HTMLInputElement | null>(null);
  const handlefileElementClick = (ev: React.MouseEvent) => {
    fileElement !== null && fileElement.current?.click();
  };
  const handleDrag = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragIn = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setDragging(true);
    }
  };
  const handleDragOut = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };
  const handleDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      if (e.dataTransfer.files[0].size >= 3000000) {
        setUploadedFileHasError && setUploadedFileHasError(true);
      } else {
        uploadFile(e.dataTransfer.files[0]);
      }
    }
  };

  useEffect(() => {
    if (dropRef != null && dropRef.current) {
      let div = dropRef.current;
      div.addEventListener("dragenter", handleDragIn);
      div.addEventListener("dragleave", handleDragOut);
      div.addEventListener("dragover", handleDrag);
      div.addEventListener("drop", handleDrop);
    }
    // eslint-disable-next-line
  }, []);

  const handleFileChange = (event: any) => {
    if (event.target.files[0].size >= 3000000) {
      setUploadedFileHasError && setUploadedFileHasError(true);
    } else {
      uploadFile(event.target.files[0]);
    }
  };

  const uploadFile = (file: any) => {
    const filename = file.name;
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      const contentData = reader.result
        ?.toString()
        .replace(`data:${file.type};base64,`, "");
      setIsUploading(true);
      if (fromSettings) {
        uploadUnitFile({
          variables: {
            fileToUpload: contentData,
            name: filename,
          },
        }).finally(() => setIsUploading(false));
      } else {
        uploadAttachment({
          variables: {
            input: {
              content: contentData,
              name: filename,
              paymentId: paymentId,
            },
          },
          refetchQueries: [
            {
              query: GET_NOTES_AND_ATTACHMENTS_BY_PAYMENT_ID,
              variables: { id: paymentId },
            },
            "GetAttachments",
          ],
          fetchPolicy: "network-only",
        }).finally(() => setIsUploading(false));
      }
    };
  };
  return (
    <div className={`flex relative justify-center align-middle h-full w-full`}>
      <div className={`flex flex-col bg-blue-50 w-full ${className}`}>
        <p>{label}</p>
        <div
          ref={dropRef}
          className="border-2 border-dashed border-gray-200 flex flex-col justify-center py-3 rounded-lg cursor-pointer"
          onClick={(event) => handlefileElementClick(event)}
        >
          {type === "image" ? (
            <img
              src={DragDrog}
              className="h-14"
              alt="Logo for Drag and Drop Feature"
            />
          ) : (
            <SnapIcon
              icon={
                type === "document" ? "document-text-line" : "photograph-line"
              }
              color="gray"
              size="lg"
            />
          )}
          <p className="lg:flex justify-center text-sm font-medium hidden text-gray-800">
            Drag & Drop File or
            <span className="ml-2 font-bold text-blue-500">Browse</span>
          </p>
          <p className="text-sm font-bold lg:hidden text-blue-500 flex justify-center mt-2">
            Upload File
          </p>
          <input
            type="file"
            ref={fileElement}
            className="hidden"
            onChange={handleFileChange}
            accept="application/pdf,image/jpeg,image/png"
          />
          <p
            className={`${
              description ? "flex" : "hidden"
            } justify-center text-xs leading-4 font-normal text-gray-500`}
          >
            {description}
          </p>
        </div>
      </div>
      {isUploading && (
        <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-10">
          <Spinner size="medium" className="mt-[5%]" />
        </div>
      )}
    </div>
  );
}
export default DragAndDrop;
