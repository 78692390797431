import { useState } from "react";
import Divider from "shared-components/divider";
import CustomModal, { BtnState, BtnType } from "shared-components/modal";
import { SnapIcon, SnapInput, SnapSelectMenu } from "suit";
import { emptyStringCheck } from "helpers/empty-string-check";
import { ValidateEmail } from "helpers/validation";
import useToast from "hooks/use-toast";
import ToastMessage from "shared-components/toast-message";
import { InvitePlus } from "types/invite";
import { SnapSelectMenuOption } from "@snap-mobile/snap-ui/dist/types/utils";
type InviteUserModalProps = {
  isOpen: boolean;
  toggle: () => void;
  handleSendInvites: (staff: InvitePlus[]) => void;
  allGroups: SnapSelectMenuOption[];
  usersData: InvitePlus[];
  isBtnActive: boolean;
  setIsBtnActive: React.Dispatch<React.SetStateAction<boolean>>;
};

function InviteUserModal({
  isOpen,
  toggle,
  handleSendInvites,
  allGroups,
  usersData,
  isBtnActive,
  setIsBtnActive,
}: InviteUserModalProps) {
  const initUser = {
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    type: "",
    isChecked: false,
    status: "Not Signed Up",
    hasWarning: false,
    userId: "",
  };
  const [hasErrors, setHasErrors] = useState([
    {
      firstName: false,
      lastName: false,
      email: false,
      role: false,
      group: false,
      sameEmail: false,
    },
  ]);
  const { isToastOpen, ...toast } = useToast();
  const [addUserList, setAddUserList] = useState<InvitePlus[]>([
    { ...initUser },
  ]);
  const [btnIdx, setBtnIdx] = useState(0);

  const closeModal = () => {
    setAddUserList([{ ...initUser }]);
    setBtnIdx(0);
    toggle();
  };
  const saveSendInvitations = () => {
    let usersEmails = usersData.map((user) => user.email);
    addUserList.forEach((user, i) => {
      if (emptyStringCheck(user.firstName)) {
        hasErrors[i].firstName = true;
      }
      if (emptyStringCheck(user.lastName)) {
        hasErrors[i].lastName = true;
      }
      if (!ValidateEmail(user.email ?? "")) {
        hasErrors[i].email = true;
      }
      if (user.type === "") {
        hasErrors[i].role = true;
      }
      if (user.type === "group_staff" && user.groupId === undefined) {
        hasErrors[i].group = true;
      }
      if (usersEmails.includes(user.email)) {
        hasErrors[i].sameEmail = true;
      }
      setHasErrors([...hasErrors]);
    });
    let validEntries = true;
    let validEmails = true;
    hasErrors.forEach((obj) => {
      if (obj.email || obj.firstName || obj.lastName || obj.role || obj.group) {
        return (validEntries = false);
      }
      if (obj.sameEmail) {
        return (validEmails = false);
      }
    });
    if (validEntries && validEmails) {
      setIsBtnActive(false);
      handleSendInvites(addUserList);
      closeModal();
    } else if (!validEmails) {
      toast.setToastProps({
        message: "Email already exist",
        type: "danger",
      });
      toast.toggleToast();
    } else {
      toast.setToastProps({
        message: "Please fill in all input fields",
        type: "danger",
      });
      toast.toggleToast();
    }
  };
  const btn1: BtnType = {
    text: "Invite Users",
    onClick: saveSendInvitations,
    btnState: isBtnActive ? BtnState.BASE : BtnState.DISABLED,
  };
  const btn2: BtnType = {
    text: "Cancel",
    onClick: closeModal,
  };

  const handleInputValue = (
    value: string,
    valToUpdate: string,
    user: InvitePlus,
    idx: number
  ) => {
    let newU: InvitePlus = { ...user };
    let tempUserList = [...addUserList];
    switch (valToUpdate) {
      case "First":
        newU.firstName = value;
        break;
      case "Last":
        newU.lastName = value;
        break;
      case "Email":
        newU.email = value;
        break;
      case "Role":
        newU.type =
          value === "Program Staff"
            ? "program_staff"
            : value === "Program Observer"
            ? "program_observer"
            : value === "Group Staff"
            ? "group_staff"
            : value === "Group Observer"
            ? "group_observer"
            : "";
        break;
      case "Group":
        newU.groupId = value;
        break;
    }
    tempUserList.splice(idx, 1, newU);
    setAddUserList(tempUserList);
  };

  const handleCantDelete = (u: InvitePlus) => {
    return (
      // u.first === "" ||
      // u.last === "" ||
      // u.email === "" ||
      // u.role === "" ||
      addUserList.length === 1
    );
  };

  return (
    <CustomModal
      isOpen={isOpen}
      toggle={closeModal}
      title={"Add Users"}
      btn1={btn1}
      btn2={btn2}
      customStyle="lg:h-[500px]"
    >
      <div className="modal-card">
        {isToastOpen && (
          <ToastMessage
            message={toast.message}
            isToastOpen={isToastOpen}
            type={toast.type}
            toggleToast={toast.toggleToast}
            className="lg:mt-12 lg:w-[30%] lg:ml-16"
          />
        )}
        <div>
          <p className="text-sm">
            Add club-level users and specify their role. Note that user roles
            will apply to all existing groups and to all new groups as they are
            created.
          </p>
        </div>
        <Divider className="lg:mt-4" isVisibleOnMobile />
        {addUserList.map((u, idx) => {
          let menuOptions = allGroups.map((group) => ({
            name: group.name,
            selected: u?.groupId === group?.value,
            value: group.value,
          }));
          return (
            <div key={`usrinfo_${idx}`} className="mt-4 rounded-lg">
              <div className="flex justify-between">
                <div className="text-lg">User Information {idx + 1}</div>
                <div
                  className={`${
                    handleCantDelete(u) ? "text-gray-400" : "text-blue-600"
                  } flex  font-bold cursor-pointer`}
                  onClick={() => {
                    if (!handleCantDelete(u)) {
                      const tempStaffInfo = [...addUserList];
                      tempStaffInfo.splice(idx, 1);
                      setAddUserList(tempStaffInfo);
                      setBtnIdx(btnIdx - 1);
                    }
                  }}
                >
                  <SnapIcon
                    size="sm"
                    className="my-auto"
                    icon="trash-solid"
                  ></SnapIcon>
                </div>
              </div>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-4">
                <SnapInput
                  _type="text"
                  _id={""}
                  label="First Name"
                  value={u.firstName || ""}
                  error={hasErrors[idx].firstName}
                  onSnap-input-change={(e) => {
                    handleInputValue(e.detail.target.value, "First", u, idx);
                    hasErrors[idx].firstName = false;
                  }}
                />
                <SnapInput
                  _type="text"
                  _id={""}
                  label="Last Name"
                  value={u.lastName || ""}
                  error={hasErrors[idx].lastName}
                  onSnap-input-change={(e) => {
                    handleInputValue(e.detail.target.value, "Last", u, idx);
                    hasErrors[idx].lastName = false;
                  }}
                />
                <SnapInput
                  _type="email"
                  _id={""}
                  label="Email"
                  value={u.email || ""}
                  error={hasErrors[idx].email || hasErrors[idx].sameEmail}
                  onSnap-input-change={(e) => {
                    handleInputValue(e.detail.target.value, "Email", u, idx);
                    hasErrors[idx].email = false;
                    hasErrors[idx].sameEmail = false;
                  }}
                />
                <div className="flex flex-col">
                  <SnapSelectMenu
                    label="Role"
                    error={hasErrors[idx].role}
                    modalTitle=""
                    placeholder="Select Role"
                    selectMenuOptions={[
                      {
                        name: "Program Staff",
                        selected: u?.type === "program_staff",
                      },
                      {
                        name: "Program Observer",
                        selected: u?.type === "program_observer",
                      },
                      {
                        name: "Group Staff",
                        selected: u?.type === "group_staff",
                      },
                      {
                        name: "Group Observer",
                        selected: u?.type === "group_observer",
                      },
                    ]}
                    onSnap-select-menu-updated={(e) => {
                      let selected =
                        e.detail.find((r) => r.selected)?.name ?? "";
                      handleInputValue(selected, "Role", u, idx);
                      hasErrors[idx].role = false;
                    }}
                  />
                </div>
                {u.type === "group_staff" || u.type === "group_observer" ? (
                  <div className="flex flex-col">
                    <div className="text-gray-800 font-medium leading-5 mb-1">
                      Group
                    </div>
                    <SnapSelectMenu
                      modalTitle=""
                      placeholder="Select Group"
                      selectMenuOptions={menuOptions}
                      error={hasErrors[idx].group}
                      onSnap-select-menu-updated={(e) => {
                        let selected =
                          e.detail.find((r) => r.selected)?.name ?? "";
                        let selectedId =
                          menuOptions.find((group) => group.name === selected)
                            ?.value ?? "";
                        handleInputValue(selectedId, "Group", u, idx);
                        hasErrors[idx].group = false;
                      }}
                    />
                  </div>
                ) : null}
              </div>
              <Divider />
            </div>
          );
        })}
        <div className="flex justify-between mt-4">
          <p className="text-lg text-gray-400">
            User Information {addUserList.length + 1}
          </p>
          <p
            className={`${
              addUserList[btnIdx].firstName === "" ||
              addUserList[btnIdx].lastName === "" ||
              addUserList[btnIdx].email === "" ||
              addUserList[btnIdx].type === ""
                ? "text-gray-400"
                : "text-blue-600"
            } font-bold cursor-pointer`}
            onClick={() => {
              setAddUserList([...addUserList, { ...initUser }]);
              setBtnIdx(btnIdx + 1);
              setHasErrors([
                ...hasErrors,
                {
                  firstName: false,
                  lastName: false,
                  email: false,
                  role: false,
                  group: false,
                  sameEmail: false,
                },
              ]);
            }}
          >
            Add User
          </p>
        </div>
      </div>
    </CustomModal>
  );
}

export default InviteUserModal;
