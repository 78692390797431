import ToastContext from "context/toast-context";
import { HandleRoleNameChangeToFront } from "helpers/front-end-role-change";
import React, { useContext, useState } from "react";
import CustomModal, { BtnState, BtnType } from "shared-components/modal";
import { SnapSelectMenu } from "suit";
import { RoleErrors } from "types/roles-permissions";
import { InvitePlus } from "types/invite";
import {
  SpendRoleNameEnum,
  SpendUserRoleUpdateMutationVariables,
} from "graphql/generated";
import { SnapSelectMenuOption } from "@snap-mobile/snap-ui/dist/types/utils";
type ChangeUserRoleModalProps = {
  isOpen: boolean;
  toggle: () => void;
  data: InvitePlus;
  handleRoleChange: ({
    userId,
    inviteId,
    roleName,
    groupId,
  }: SpendUserRoleUpdateMutationVariables) => void;
  allGroups: SnapSelectMenuOption[];
  isBtnActive: boolean;
  setIsBtnActive: React.Dispatch<React.SetStateAction<boolean>>;
};

function ChangeUserRoleModal({
  isOpen,
  toggle,
  data,
  handleRoleChange,
  allGroups,
  isBtnActive,
  setIsBtnActive,
}: ChangeUserRoleModalProps) {
  const toast = useContext(ToastContext);

  const [selectedRole, setSelectedRole] = useState<
    SpendRoleNameEnum | undefined
  >();
  const [selectedGroupId, setSelectedGroupId] = useState<string | undefined>(
    undefined
  );
  const [hasErrors, setHasErrors] = useState<RoleErrors>({
    TypeError: false,
    GroupError: false,
  });

  const btn1: BtnType = {
    text: "Change Role",
    btnState: isBtnActive ? BtnState.BASE : BtnState.DISABLED,
    onClick: () => {
      if (
        (selectedRole === SpendRoleNameEnum.GroupStaff ||
          selectedRole === SpendRoleNameEnum.GroupObserver) &&
        !selectedGroupId
      ) {
        hasErrors.TypeError = false;
        hasErrors.GroupError = true;
        setHasErrors({ TypeError: false, GroupError: true });
      }
      let { GroupError, TypeError } = hasErrors;

      if (GroupError || TypeError) {
        toast?.setToast({
          message: `${TypeError ? "Role" : "Group"} not selected`,
          type: "danger",
        });
      } else if (!selectedRole) {
        hasErrors.TypeError = true;
        setHasErrors({ ...hasErrors, TypeError: true });
        toast?.setToast({
          message: `${TypeError ? "Role" : "Group"} not selected -1`,
          type: "danger",
        });
      } else {
        setIsBtnActive(false);
        handleRoleChange({
          userId: data?.userId ?? "",
          roleName: selectedRole,
          inviteId: data?.id ?? "",
          groupId:
            selectedRole === SpendRoleNameEnum.ProgramStaff ||
            selectedRole === SpendRoleNameEnum.ProgramObserver
              ? null
              : selectedGroupId || "",
        });
        setHasErrors({ TypeError: false, GroupError: false });
      }
    },
  };
  const btn2: BtnType = {
    text: "Cancel",
    onClick: () => {
      setSelectedRole(undefined);
      setSelectedGroupId(undefined);
      if (toast?.isToastOpen) {
        toast?.toggleToast();
      }
      setHasErrors({ TypeError: false, GroupError: false });
      setIsBtnActive(true);
      toggle();
    },
  };

  return (
    <CustomModal
      isOpen={isOpen}
      toggle={toggle}
      title={"Change Role"}
      btn1={btn1}
      btn2={btn2}
    >
      <>
        <div className="modal-card">
          <div className="flex flex-col lg:items-center lg:mt-8 lg:mb-20">
            <div className="flex">
              <p className="text-gray-500 mr-6">User Name</p>
              <p>{`${data?.firstName} ${data?.lastName}`}</p>
            </div>
            <div className="flex mt-4">
              <p className="text-gray-500 mr-6">Current Role</p>
              <p className="capitalize">{`${HandleRoleNameChangeToFront(
                data?.type || ""
              )}`}</p>
            </div>
            <div className="flex flex-col mt-4 lg:w-[40%]">
              <div className="text-sm text-gray-800">New Role</div>
              <SnapSelectMenu
                error={hasErrors.TypeError}
                modalTitle="Select a role"
                placeholder="Select Role"
                selectMenuOptions={[
                  {
                    name: "Program Staff",
                    selected: selectedRole === SpendRoleNameEnum.ProgramStaff,
                    value: SpendRoleNameEnum.ProgramStaff,
                  },
                  {
                    name: "Program Observer",
                    selected:
                      selectedRole === SpendRoleNameEnum.ProgramObserver,
                    value: SpendRoleNameEnum.ProgramObserver,
                  },
                  {
                    name: "Group Staff",
                    selected: selectedRole === SpendRoleNameEnum.GroupStaff,
                    value: SpendRoleNameEnum.GroupStaff,
                  },
                  {
                    name: "Group Observer",
                    selected: selectedRole === SpendRoleNameEnum.GroupObserver,
                    value: SpendRoleNameEnum.GroupObserver,
                  },
                ]}
                onSnap-select-menu-updated={(e) => {
                  const selectedItem = e.detail.find(
                    (option) => option.selected
                  );
                  setSelectedRole(
                    (selectedItem?.value as SpendRoleNameEnum) ?? undefined
                  );

                  if (toast?.isToastOpen) toast?.toggleToast();
                  setHasErrors({ ...hasErrors, TypeError: false });
                }}
              />
              {selectedRole === SpendRoleNameEnum.GroupStaff ||
              selectedRole === SpendRoleNameEnum.GroupObserver ? (
                <React.Fragment>
                  <div className=" mt-5 text-sm text-gray-800">Group</div>
                  <SnapSelectMenu
                    error={hasErrors.GroupError}
                    modalTitle="Select a group"
                    placeholder="Select Group"
                    selectMenuOptions={allGroups}
                    onSnap-select-menu-updated={(e) => {
                      let selectedItem = e.detail.find((r) => r.selected);
                      setSelectedGroupId(selectedItem?.value);
                      if (toast?.isToastOpen) toast?.toggleToast();
                      setHasErrors({ ...hasErrors, GroupError: false });
                    }}
                  />
                </React.Fragment>
              ) : null}
            </div>
          </div>
        </div>
      </>
    </CustomModal>
  );
}

export default ChangeUserRoleModal;
