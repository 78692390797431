import { useMutation } from "@apollo/client";
import {
  UPDATE_ALL_GROUPS_SETTINGS,
  // UPDATE_ORG_SETTINGS,
} from "graphql/mutations/organization";
import { GET_ORGANIZATION_SETTINGS } from "graphql/queries/organization";
import { useAppContexts } from "hooks/use-context";
import { useEffect, useState } from "react";
import { SnapLabeledToggle } from "suit";

function RequirePaymentMethod() {
  const { program, toast } = useAppContexts();
  const [requirePaymentMethod, setRequirePaymentMethod] = useState(false);

  //KEEP FOR FUTURE UPDATES
  // const [updateSettings, { loading, data }] = useMutation(UPDATE_ORG_SETTINGS, {
  //   refetchQueries: [{ query: GET_ORGANIZATION_SETTINGS }],
  //   fetchPolicy: "no-cache",
  // });
  // useEffect(() => {
  //   if (!loading && data && data.spendSettingsUpdate) {
  //     toast?.setToast({
  //       message: "Settings updated successfully",
  //       type: "success",
  //     });
  //   }
  //   // eslint-disable-next-line
  // }, [loading, data]);

  const [updateAllSettings, { loading, data }] = useMutation(
    UPDATE_ALL_GROUPS_SETTINGS,
    {
      refetchQueries: [{ query: GET_ORGANIZATION_SETTINGS }],
      fetchPolicy: "no-cache",
    }
  );

  useEffect(() => {
    const settings = program?.settings;
    setRequirePaymentMethod(settings?.requirePaymentMethod ?? false);
  }, [program?.settings]);

  useEffect(() => {
    if (!loading && data && data.spendSettingsUpdateAll) {
      toast?.setToast({
        message: "Settings updated successfully",
        type: "success",
      });
    }
    // eslint-disable-next-line
  }, [loading, data]);

  return (
    <div className="card">
      <h1 className="text-lg font-semibold mb-4">Require Payment Method</h1>
      <SnapLabeledToggle
        label="This will require all invoices to have a payment method attached."
        onSnap-labeled-toggle-click={(e) => {
          updateAllSettings({
            variables: {
              input: {
                requirePaymentMethod: e.detail.checked,
              },
            },
          });
        }}
        checked={requirePaymentMethod}
      />
    </div>
  );
}

export default RequirePaymentMethod;
