import { SpendGroupRoster } from "graphql/generated";
import { FormatMoney, ParseMoney } from "helpers/format-money";
import { useEffect, useState } from "react";
import Card from "./card";
import Table from "./table";
import { SnapAlert, SnapAlertDescription, SnapAlertTitle } from "suit";
import { StepOneValues } from ".";

type StepTwoProps = {
  roster: SpendGroupRoster[];
  creditAmount: string;
  titleCredit: string;
  handleAmountInput: (
    amount: string,
    idx: number,
    invoiceAmount: number
  ) => void;
  invoicePlusAmount: {
    amount: string;
    invoiceId: string;
    invoiceError: boolean;
  }[];
  amountToApplyError: boolean;
  stepOneValues: StepOneValues;
};

function StepTwo({
  roster,
  creditAmount,
  titleCredit,
  handleAmountInput,
  invoicePlusAmount,
  amountToApplyError,
  stepOneValues,
}: StepTwoProps) {
  const [amount, setAmount] = useState(0);
  useEffect(() => {
    setAmount(ParseMoney(creditAmount));
  }, [creditAmount]);

  return (
    <div className="overflow-scroll lg:h-[500px]">
      {amountToApplyError ? (
        <>
          <SnapAlert type="danger">
            <SnapAlertTitle>Credit Remaining</SnapAlertTitle>
            <SnapAlertDescription>
              <p>
                Please apply all credits available to relevant invoices. Credit
                balance must be <span className="mx-1 font-bold">$0.00</span> to
                complete a credit memo.
              </p>
            </SnapAlertDescription>
          </SnapAlert>
        </>
      ) : (
        <>
          <SnapAlert type="info">
            <SnapAlertTitle>Credit Memo</SnapAlertTitle>
            <SnapAlertDescription>
              <p>
                The{" "}
                <span className="mx-1 font-bold">
                  {FormatMoney(Number(titleCredit) * 100)}
                </span>
                credit will be applied as indicated below; edit amounts as
                necessary.
              </p>
            </SnapAlertDescription>
          </SnapAlert>
        </>
      )}
      <div className="flex mt-4">
        <p className="text-gray-500 font-medium text-sm self-center mr-4">
          Participant
        </p>
        <p className=" font-medium">{stepOneValues.participantName}</p>
      </div>
      <Table
        roster={roster}
        handleAmountInput={handleAmountInput}
        invoicePlusAmount={invoicePlusAmount}
      />
      <Card
        roster={roster}
        handleAmountInput={handleAmountInput}
        invoicePlusAmount={invoicePlusAmount}
      />
      <div className="flex justify-between lg:justify-end mt-4 text-gray-500 ">
        <p className=" text-xs font-semibold self-center lg:mr-40">
          CREDIT AMOUNT REMAIN TO APPLY
        </p>
        <p className={`text-sm ${amount > 0 && "text-red-600"}`}>
          {FormatMoney(amount)}
        </p>
      </div>
    </div>
  );
}

export default StepTwo;
