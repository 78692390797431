import { SnapSelectMenuOption } from "@snap-mobile/snap-ui/dist/types/utils";
import {
  SpendDisputes,
  useSpendGetDisputesQuery,
  useSpendSimpleGroupsQuery,
} from "graphql/generated";
import { FormatDate } from "helpers/format-date";
import { FormatMoney } from "helpers/format-money";
import { useAppContexts } from "hooks/use-context";
import { useEffect, useState } from "react";
import { SnapSelectMenu, SnapTable } from "suit";

function AllDisputes() {
  const { program, split, navigate } = useAppContexts();

  useEffect(() => {
    if (!split?.isTreatmentOn(split.act.se2176)) {
      navigate("/programs/banking");
    }
  }, [split, navigate]);

  const [targetId, setTargetId] = useState<string | undefined>();
  const [disputes, setDisputes] = useState<SpendDisputes[]>([]);
  const [selectMenuOptions, setSelcetMenuOptions] = useState<
    SnapSelectMenuOption[]
  >([]);

  const { loading: loadingDisputes, data: disputesData } =
    useSpendGetDisputesQuery({
      variables: {
        targetId,
      },
      fetchPolicy: "no-cache",
    });
  const { loading: loadingGroups, data: groupsData } =
    useSpendSimpleGroupsQuery();

  useEffect(() => {
    if (!loadingDisputes && disputesData?.spendGetDisputes) {
      setDisputes(disputesData?.spendGetDisputes.disputes);
    }
  }, [loadingDisputes, disputesData]);
  useEffect(() => {
    if (!loadingGroups && groupsData) {
      const options =
        groupsData.spendGroups?.groups?.map((g) => {
          return {
            label: g?.name!,
            name: g?.name!,
            value: g?.id!,
            selected: false,
          };
        }) ?? [];
      options.sort((a, b) => (a.label > b.label ? 1 : -1));
      const org = program?.organization;
      const defaultOptions: SnapSelectMenuOption[] = [
        {
          label: " - All - ",
          name: " - All - ",
          value: undefined,
          selected: true,
        },
        {
          label: org?.nickname ?? org?.legalName ?? "",
          name: org?.nickname ?? org?.legalName ?? "",
          value: org?.id ?? "",
          selected: false,
        },
      ];
      setSelcetMenuOptions([...defaultOptions, ...options]);
    }
  }, [loadingGroups, groupsData, program?.organization]);

  return (
    <div className="wrapper">
      <div className="card">
        <h1 className="text-lg font-medium">All Disputes</h1>
        <div className="w-[30%] my-3">
          Group Filter
          <SnapSelectMenu
            selectMenuOptions={selectMenuOptions}
            search={true}
            onSnap-select-menu-updated={(e) => {
              const selected = e.detail.find((e) => e.selected);
              if (selected) {
                setTargetId(selected.value);
              }
            }}
          />
        </div>
        <SnapTable>
          <table>
            <thead>
              <tr>
                <th>Account Name</th>
                <th>Reason</th>
                <th>Status</th>
                <th>Has Evidence</th>
                <th>Created At</th>
                <th>Due By</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {disputes.map((d) => {
                return (
                  <tr key={d.id}>
                    <td className="py-5">{d.accountName}</td>
                    <td className="capitalize">
                      {d.reason.replaceAll("_", " ")}
                    </td>
                    <td className="capitalize">
                      {d.status.replaceAll("_", " ")}
                    </td>
                    <td className="capitalize">{d.hasEvidence}</td>
                    <td>{FormatDate(d.createdAt)}</td>
                    <td>{FormatDate(d.dueBy)}</td>
                    <td>{FormatMoney(d.amount)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </SnapTable>
      </div>
    </div>
  );
}

export default AllDisputes;
