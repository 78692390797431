import { useMutation } from "@apollo/client";
import { SnapSelectMenuOption } from "@snap-mobile/snap-ui/dist/types/utils";
import GroupContext from "context/group-context";
import SeasonContext from "context/season-context";
import { SpendPaymentSchedule } from "graphql/generated";
import { CREATE_PAYMENT_SCHEDULE } from "graphql/mutations/payment";
import { PAYMENT_SCHEDULE_BY_GROUP_OR_SEASON } from "graphql/queries/payment-schedule";
import { emptyStringCheck } from "helpers/empty-string-check";
import { ParseMoney } from "helpers/format-money";
import useToast from "hooks/use-toast";
import { SetStateAction, useContext, useEffect, useState } from "react";
import InputMask from "shared-components/input-mask";
import Spinner from "shared-components/spinner";
import ToastMessage from "shared-components/toast-message";
import {
  SnapButton,
  SnapCheckboxButton,
  SnapInput,
  SnapSelectMenu,
} from "suit";
import { addInvoiceError } from "types/errors";
import { SpendPaymentScheduleWithFees } from "../../participants-roster";
import DatePicker from "shared-components/date-picker";
import { isNullOrEmpty } from "helpers/null-or-empty";
import { getDatePickerValue, setDatePickerValue } from "helpers/date-picker";

type AddInvoiceProps = {
  categories: SnapSelectMenuOption[];
  setIsBtnActiveForPublish: React.Dispatch<SetStateAction<boolean>>;
  se579NotSavingPublish: boolean;
  currentInvoices: SpendPaymentScheduleWithFees[];
};

function AddInvoice({
  categories,
  setIsBtnActiveForPublish,
  se579NotSavingPublish,
  currentInvoices,
}: AddInvoiceProps) {
  const season = useContext(SeasonContext);
  const group = useContext(GroupContext);
  const [newInvoice, setNewInvoice] = useState<SpendPaymentSchedule>({
    groupId: group?.activeGroup?.id,
    seasonId: season?.selectedSeason?.id,
  });
  const [hasErrorObj, setHasErrorObj] = useState<addInvoiceError>({
    descriptionError: false,
    dateError: false,
    amountError: false,
    budgetError: false,
    duplicateNameError: false,
  });

  const [dropdownCat, setDropdownCat] = useState<SnapSelectMenuOption[]>([]);
  const [collapseDiv, setCollapseDiv] = useState(false);
  const [isBtnActive, setIsBtnActive] = useState(true);

  const [savePaymentSchedule, { data, loading, error }] = useMutation(
    CREATE_PAYMENT_SCHEDULE,
    {
      refetchQueries: [
        "SpendGroupRostersBySeason",
        {
          query: PAYMENT_SCHEDULE_BY_GROUP_OR_SEASON,
          variables: {
            seasonId: season?.selectedSeason?.id,
            groupIdOrSeasonId: season?.selectedSeason?.id,
          },
          fetchPolicy: "network-only",
        },
      ],
      fetchPolicy: "network-only",
    }
  );

  const { isToastOpen, ...toast } = useToast(
    "Payment Schedule Saved",
    "success"
  );

  const [amount, setAmount] = useState("");

  useEffect(() => {
    setDropdownCat(categories);
  }, [categories]);

  useEffect(() => {
    if (se579NotSavingPublish) {
      setIsBtnActiveForPublish(!loading);
    }
    if (data?.spendPaymentScheduleCreate?.id && !loading) {
      setNewInvoice({
        groupId: group?.activeGroup?.id,
        seasonId: season?.selectedSeason?.id,
      });
      setAmount("");
      setDropdownCat(
        categories.map((cat) => {
          return {
            ...cat,
            selected: false,
          };
        })
      );
      setIsBtnActive(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading, group]);

  useEffect(() => {
    if (error && !loading) {
      toast.setToastProps({
        message: "Error Saving Payment Schedule",
        type: "danger",
      });
      toast.toggleToast();
      setIsBtnActive(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, loading]);

  const addInvoice = () => {
    const errorState = { ...hasErrorObj };
    const parsedAmount = ParseMoney(amount);
    if (emptyStringCheck(newInvoice.description)) {
      errorState.descriptionError = true;
    }
    if (isNullOrEmpty(newInvoice.dueDate)) {
      errorState.dateError = true;
    }
    if (parsedAmount <= 0.5) {
      errorState.amountError = true;
    }
    if (newInvoice.budgetItemId == null) {
      errorState.budgetError = true;
    }
    if (
      currentInvoices.some(
        (inv) =>
          inv.description?.trim().toLocaleLowerCase() ===
          newInvoice.description?.trim().toLocaleLowerCase()
      )
    ) {
      errorState.duplicateNameError = true;
    }
    setHasErrorObj({ ...errorState });
    let {
      descriptionError,
      dateError,
      amountError,
      budgetError,
      duplicateNameError,
    } = errorState;
    if (
      descriptionError ||
      dateError ||
      amountError ||
      budgetError ||
      duplicateNameError
    ) {
      let message = "Please fill in all the input fields";
      if (amountError) {
        message = "Invoice amount must be greater than $0.50";
      } else if (duplicateNameError) {
        message =
          "The description provided is not unique among this season's invoices. Please use a different description.";
      }
      toast.setToastProps({
        message,
        type: "danger",
      });
      toast.toggleToast();
    } else {
      setIsBtnActive(false);
      savePaymentSchedule({
        variables: {
          input: {
            ...newInvoice,
            description: newInvoice.description?.trim(),
            amountDue: parsedAmount,
          },
        },
      }).finally(() => {
        season?.requestSeason();
      });
    }
  };

  return (
    <div className="mt-4 border lg:border-x lg:border-t border-x-0 border-t-1 lg:p-4 pb-4 lg:rounded-lg pt-4 lg:mt-0">
      {isToastOpen && (
        <ToastMessage
          message={toast.message}
          isToastOpen={isToastOpen}
          toggleToast={toast.toggleToast}
          type={toast.type}
        />
      )}
      <p
        className={`text-lg lg:font-semibold font-medium cursor-pointer ${
          !collapseDiv ? "mb-4" : ""
        }`}
        onClick={() => setCollapseDiv(!collapseDiv)}
      >
        Add Invoice
      </p>
      <div className={collapseDiv ? "hidden" : ""}>
        <SnapInput
          _id={""}
          label="Invoice Description"
          maxlength="250"
          displayCharCount
          placeholder="Enter a short description"
          helpText=" "
          value={newInvoice.description || ""}
          onSnap-input-change={(e) => {
            setNewInvoice({
              ...newInvoice,
              description: e.detail.target.value,
            });
            setHasErrorObj({ ...hasErrorObj, descriptionError: false });
          }}
          error={hasErrorObj.descriptionError}
        />
        <div className="lg:grid grid-cols-2 mt-4">
          <DatePicker
            label={"Date"}
            className={"lg:mr-4"}
            date={getDatePickerValue(newInvoice.dueDate ?? "")}
            setDate={(e) => {
              const dateValue = setDatePickerValue(e);
              setNewInvoice({
                ...newInvoice,
                dueDate: dateValue,
              });
              setHasErrorObj({ ...hasErrorObj, dateError: false });
            }}
            hasError={hasErrorObj.dateError}
          />
          <div className="lg:mt-0 mt-4">
            <InputMask
              amount={amount}
              setAmount={setAmount}
              hasError={hasErrorObj.amountError}
              onChange={() =>
                setHasErrorObj({ ...hasErrorObj, amountError: false })
              }
            />
          </div>
        </div>
        <SnapCheckboxButton
          label="Allow participants to opt out of this payment for this invoice"
          className="mt-4"
          onSnap-checkbox-updated={(e) => {
            setNewInvoice({ ...newInvoice, isOptional: e.detail.checked });
          }}
        />
        <div className="mt-4">
          <SnapInput
            _id={""}
            textarea
            label="Note to Parents"
            onSnap-input-change={(e) => {
              setNewInvoice({ ...newInvoice, note: e.detail.target.value });
            }}
            value={newInvoice.note ?? ""}
          />
        </div>
        <div className="mt-4 lg:flex">
          <SnapSelectMenu
            modalTitle="Income Budget Items"
            className="lg:w-[85%] mr-auto"
            label="Income Budget Item"
            placeholder="Select a Budget Item"
            selectMenuOptions={dropdownCat}
            onSnap-select-menu-updated={({ detail }) => {
              setDropdownCat(detail);
              setNewInvoice({
                ...newInvoice,
                budgetItemId: detail.find(
                  (det: { selected: boolean }) => det.selected
                )?.value,
              });
              setHasErrorObj({ ...hasErrorObj, budgetError: false });
            }}
            error={hasErrorObj.budgetError}
          />
          <SnapButton
            variant="primary"
            fullWidth
            className="flex self-center mt-6"
            onClick={addInvoice}
            disabled={isBtnActive ? false : true}
          >
            {isBtnActive ? "Add Invoice" : <Spinner size="small" />}
          </SnapButton>
        </div>
      </div>
    </div>
  );
}

export default AddInvoice;
