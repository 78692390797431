import Details from "./details";
import Invoices from "./invoices";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  GroupRosterFilterEnum,
  Maybe,
  SpendGroupRoster,
  SpendInvoice,
  useGetParticipantsDetailsQuery,
} from "graphql/generated";
import GroupContext from "context/group-context";
import { DateFormatSupported, FormatDate } from "helpers/format-date";
import UserContext from "context/user-context";
import { SpendPermissions } from "types/roles-permissions";
import { ParticipantInvoice } from "types/invoice";
import { isNullOrEmpty } from "helpers/null-or-empty";
export type ParticipantDetailType =
  | {
      userId: string;
      rosterId: string;
      participantName: string;
      guardian: string;
      email: string;
      rosterEmail: string | null;
      phone: string;
      Group: {
        name: string;
        season: string;
        joinedDate: string;
        status: string;
        email: string;
        isSelected: boolean;
        groupId: string;
        guardian: string;
        seasonId: string;
        groupRosterId: string;
        sharedAccount: boolean;
      }[];
    }
  | undefined;
function ParticipantDetails() {
  const canEditParticipants =
    useContext(UserContext)?.checkSpendPermission(
      SpendPermissions.groupBudgetUpdate
    ) ?? false;
  const groupContext = useContext(GroupContext);
  const [invoices, setInvoices] = useState<ParticipantInvoice[]>([]);
  const [invoicesAmount, setInvoiceAmount] = useState(0);
  const [participantData, setParticipantData] = useState<SpendGroupRoster[]>(
    []
  );
  const [detailData, setDetailData] =
    useState<ParticipantDetailType>(undefined);
  const { id } = useParams();
  const { data, loading } = useGetParticipantsDetailsQuery({
    variables: {
      filterBy: GroupRosterFilterEnum.RosterId,
      filterValue: id,
    },
    fetchPolicy: "network-only",
    skip: !id,
  });

  useEffect(() => {
    if (
      !loading &&
      data &&
      data.spendGroupRosters &&
      data.spendGroupRosters.groupRosters
    ) {
      const firstGroupRoster = data.spendGroupRosters.groupRosters.at(0);
      if (firstGroupRoster) {
        groupContext?.setParticipant(firstGroupRoster.roster?.name ?? "");
        setParticipantData(
          data.spendGroupRosters.groupRosters as SpendGroupRoster[]
        );
        let pd: ParticipantDetailType = {
          userId: firstGroupRoster.userId ?? "",
          rosterId: firstGroupRoster.rosterId ?? "",
          participantName: firstGroupRoster.roster?.name ?? "",
          guardian: firstGroupRoster.guardianName ?? "N/A",
          rosterEmail: firstGroupRoster.roster?.rosterEmail || null,
          email: firstGroupRoster?.roster?.email ?? "N/A",
          phone: firstGroupRoster?.phoneNumber ?? "N/A",
          Group: data.spendGroupRosters.groupRosters
            .filter((roster) => !roster?.isArchived)
            .map((roster) => {
              let seasonName;
              let foundSeason = roster?.group?.seasons?.find(
                (season) => season?.id === roster.seasonId
              );
              seasonName = {
                name: foundSeason?.name,
                startDateAt: foundSeason?.startDateAt,
                endDateAt: foundSeason?.endDateAt,
              };
              return {
                name: roster?.group?.name ?? "N/A",
                season:
                  seasonName?.name ??
                  `${FormatDate(
                    seasonName?.startDateAt ?? "",
                    DateFormatSupported.NumbersLight
                  )} - ${FormatDate(
                    seasonName?.endDateAt ?? "",
                    DateFormatSupported.NumbersLight
                  )}`,
                joinedDate: roster?.joinedAt ?? "N/A",
                status:
                  roster?.invite?.status === "accepted"
                    ? "Signed Up"
                    : "Not Signed Up",
                email: roster?.roster?.email || "",
                isSelected: false,
                groupId: roster?.group?.id || "",
                seasonId: roster?.seasonId ?? "",
                guardian: firstGroupRoster.guardianName ?? "N/A",
                groupRosterId: roster?.id ?? "",
                sharedAccount: roster?.group?.sharedAccount ?? false,
              };
            }),
        };
        const participantInvoices = data.spendGroupRosters.groupRosters.flatMap(
          (groupRoster) => {
            return groupRoster?.invoices?.map((invoice) => {
              const foundSeason = groupRoster.group?.seasons?.find(
                (season) => season?.id === groupRoster.seasonId
              );
              const hasNote = !isNullOrEmpty(invoice?.note);
              const reconciledNotes = invoice?.reconciledTransactions
                ?.map((rt) => rt?.note)
                .join(", ");
              let note = invoice?.note;
              if (hasNote && reconciledNotes?.length) {
                note = `${note}, ${reconciledNotes}`;
              } else if (!hasNote && reconciledNotes) {
                note = reconciledNotes;
              }

              return {
                ...invoice,
                status: getInvoiceStatus(invoice),
                groupName: groupRoster.group?.name,
                groupId: groupRoster.group?.id,
                seasonName: foundSeason?.name,
                seasonId: foundSeason?.id,
                seasonStartDate: foundSeason?.startDateAt,
                seasonEndDate: foundSeason?.endDateAt,
                participantName: groupRoster.roster?.name,
                rosterId: groupRoster.rosterId,
                note,
              };
            });
          }
        );
        const sortedInvoices = participantInvoices.sort((a, b) => {
          return new Date(a?.dueDate ?? "") < new Date(b?.dueDate ?? "")
            ? -1
            : 1;
        });
        setInvoiceAmount(sortedInvoices.length);
        setInvoices(sortedInvoices as ParticipantInvoice[]);
        setDetailData(pd);
      }
    }
    // eslint-disable-next-line
  }, [data, loading]);

  const getInvoiceStatus = (invoice: Maybe<SpendInvoice>) => {
    const apiStatus = invoice?.status?.replace("_", " ");
    if (apiStatus == null || apiStatus === "upcoming") {
      return "not paid";
    }
    return apiStatus;
  };
  return (
    <div className="wrapper">
      {
        <>
          <Details
            participantDetails={detailData}
            participantData={participantData}
            canEditParticipants={canEditParticipants}
            loading={loading}
          />
          <Invoices
            participantDetails={detailData}
            participant={participantData}
            canEditParticipants={canEditParticipants}
            loading={loading}
            invoices={invoices}
            setInvoices={setInvoices}
            getInvoiceStatus={getInvoiceStatus}
            invoicesAmount={invoicesAmount}
            setInvoiceAmount={setInvoiceAmount}
          />
        </>
      }
    </div>
  );
}

export default ParticipantDetails;
