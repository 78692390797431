import { Maybe } from "graphql/generated";
import { useEffect, useState } from "react";
import Pending from "./pending";
import AwaitingDocs from "./awaiting-documents";

type ApplicationStatusProps = {
  status: Maybe<string> | undefined;
  message: string;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  applicationURL: Maybe<string> | undefined;
};

function ApplicationStatus({
  status,
  setIsLoading,
  applicationURL,
}: ApplicationStatusProps) {
  const [option, setOption] = useState(0);

  useEffect(() => {
    setIsLoading(false);
    switch (status) {
      case "PendingReview":
        setOption(0);
        break;
      case "AwaitingDocuments":
        setOption(1);
        break;
    }
  }, [status, setIsLoading]);

  return (
    <div className="wrapper">
      <div className="card-no-gutters mt-10">
        {
          {
            0: <Pending />,
            1: <AwaitingDocs applicationURL={applicationURL} />,
          }[option]
        }
      </div>
    </div>
  );
}

export default ApplicationStatus;
