import GroupContext from "context/group-context";
import ProgramContext from "context/program-context";
import { useContext, useEffect, useState } from "react";
import Divider from "shared-components/divider";
import GroupOverview from "./group-overview";
import ParticipantRoster from "./participants-roster";

function Collections() {
  const program = useContext(ProgramContext);
  const group = useContext(GroupContext);
  const [paymentScheduleLoading, setPaymentScheduleLoading] = useState(true);

  useEffect(() => {
    if (!group?.activeGroup) {
      group?.setAndStoreActiveGroup(group?.groups?.at(0));
    }
  }, [group, program]);
  return (
    <div className="wrapper">
      <div className="card">
        <GroupOverview paymentScheduleLoading={paymentScheduleLoading} />
        <Divider isVisibleOnMobile className="mt-6 mb-8" />
        <ParticipantRoster
          setPaymentScheduleLoading={setPaymentScheduleLoading}
        />
      </div>
    </div>
  );
}

export default Collections;
