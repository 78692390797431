import { useCallback, useContext, useEffect, useState } from "react";
import CustomModal, { BtnType } from "shared-components/modal";
import ManageCounterparties from "./manage-counterparties";
import TransferCounterparty from "./transfer-counterparty";
import useModal from "hooks/use-modal";
import AddCounterparty from "./add-counterparty";
import {
  SpendCounterparty,
  SpendOrgAchCredit,
  useSpendGetGroupCounterpartiesLazyQuery,
  useSpendGetOrganizationCounterpartiesLazyQuery,
} from "graphql/generated";
import ProgramContext from "context/program-context";
import GroupContext from "context/group-context";
import { SnapSelectMenuOption } from "@snap-mobile/snap-ui/dist/types/utils";
import { counterpartyToAddErrors } from "../../../types/errors";
import { useLazyQuery, useMutation } from "@apollo/client";
import { ORG_ACH_CREDIT } from "graphql/mutations/organization";
import { GROUP_ACH_CREDIT } from "graphql/mutations/group";
import { FormatMoney, ParseMoney } from "helpers/format-money";
import ToastContext from "context/toast-context";
import { achInputErrors } from "../../../types/errors";
import { isNullOrEmpty } from "helpers/null-or-empty";
import { ToastProp } from "hooks/use-toast";
import { USER_TOKEN } from "graphql/queries/user";

export const CREATE_ORG_COUNTERPARTY = `
  mutation SpendOrganizationCounterpartyCreate(
    $vgsInput: SpendCounterpartyCreateInput
  ) {
    spendOrganizationCounterpartyCreate(input: $vgsInput) {
      id
    }
  }
`;
export const CREATE_GROUP_COUNTERPARTY = `
  mutation SpendGroupCounterpartyCreate($vgsInput: SpendCounterpartyCreateInput) {
    spendGroupCounterpartyCreate(input: $vgsInput) {
      id
    }
  }
`;

const ACCOUNT_NUMBER_INPUT_NAME = "account-number-input";
const CONFIRM_ACCOUNT_NUMBER_INPUT_NAME = "confirm-account-number-input";
const EIN_NUMBER_INPUT_NAME = "ein-number-input";

type ACHCreditProps = {
  isSendACHOpen: boolean;
  sendACHToggle: () => void;
  sendMoneyToggle: () => void;
  type: "Program" | "Group";
};

export type counterpartyToAdd = {
  groupOrOrgId: string;
  name: string;
  routingNumber: string;
  entityType: string;
  accountType: string;
  einNumber?: string;
};

function ACHCredit({
  isSendACHOpen,
  sendACHToggle,
  sendMoneyToggle,
  type,
}: ACHCreditProps) {
  const program = useContext(ProgramContext);
  const group = useContext(GroupContext);
  const toast = useContext(ToastContext);

  const { isOpen: isAddPayeeOpen, toggle: toggleAddPayee } = useModal();

  const [selectedTab, setSelctedTab] = useState(0);
  const [counterparties, setCounterparties] = useState<SpendCounterparty[]>([]);
  const [counterpartyOptions, setCounterpartyOptions] = useState<
    SnapSelectMenuOption[]
  >([]);

  const [entityOptions] = useState<SnapSelectMenuOption[]>([
    {
      name: "Business",
      selected: false,
    },
    {
      name: "Person",
      selected: false,
    },
  ]);

  const [accountTypeOptions] = useState<SnapSelectMenuOption[]>([
    {
      name: "Checking",
      selected: false,
    },
    {
      name: "Savings",
      selected: false,
    },
  ]);
  const [counterpartyToAdd, setCounterpartyToAdd] = useState<counterpartyToAdd>(
    {
      groupOrOrgId: "",
      name: "",
      routingNumber: "",
      entityType: "",
      accountType: "",
    }
  );
  const [counterpartyToAddErrors, setCounterpartyToAddErrors] =
    useState<counterpartyToAddErrors>({
      nameError: false,
      routingNumberError: false,
      entityTypeError: false,
      accountTypeError: false,
    });
  const [achCreditInput, setACHCreditInput] = useState<SpendOrgAchCredit>({
    counterpartyId: "",
    counterpartyName: "",
    note: "",
    amount: 0,
  });
  const [achInputErrors, setACHInputErrors] = useState<achInputErrors>({
    counterpartyError: false,
    noteError: false,
    amountError: false,
  });
  const [amount, setAmount] = useState("");

  const successToast: ToastProp = {
    message: `${FormatMoney(ParseMoney(amount))} successfully sent to ${
      achCreditInput.counterpartyName
    }`,
    type: "success",
  };
  const approvalToast: ToastProp = {
    message: `Approval has been submitted for the amount of ${FormatMoney(
      ParseMoney(amount)
    )} to be sent to ${achCreditInput.counterpartyName}`,
    type: "success",
  };
  const errorToast: ToastProp = {
    message: "Error transferring funds",
    type: "danger",
  };

  const [form, setForm] = useState<VGSForm>();
  const accountNumberRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (!isAddPayeeOpen || form === undefined || node === null) {
        return;
      }

      form.field(node, {
        name: ACCOUNT_NUMBER_INPUT_NAME,
        type: "text",
        css: {
          color: "#1E293B",
          "font-size": "0.875rem",
          "margin-y": "0px",
          height: "38px",
        },
      });
    },
    [isAddPayeeOpen, form]
  );
  const confirmAccountNumberRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (!isAddPayeeOpen || form === undefined || node === null) {
        return;
      }

      form.field(node, {
        name: CONFIRM_ACCOUNT_NUMBER_INPUT_NAME,
        type: "text",
        css: {
          color: "#1E293B",
          "font-size": "0.875rem",
          "margin-y": "0px",
          height: "38px",
          "&.invalid.touched": {
            color: "red",
          },
        },
        validations: [
          "required",
          {
            type: "compareValue",
            params: {
              field: ACCOUNT_NUMBER_INPUT_NAME,
              function: "match",
            },
          },
        ],
        classes: {
          invalid: "field-invalid",
        },
      });
    },
    [isAddPayeeOpen, form]
  );
  const einNumberRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (!isAddPayeeOpen || form === undefined || node === null) {
        return;
      }

      form.field(node, {
        name: EIN_NUMBER_INPUT_NAME,
        type: "text",
        css: {
          color: "#1E293B",
          "font-size": "0.875rem",
          "margin-y": "0px",
          height: "38px",
          "&.invalid.touched": {
            color: "red",
          },
        },
      });
    },
    [isAddPayeeOpen, form]
  );

  const groupOrOrgId =
    type === "Program" ? program?.organization?.id! : group?.activeGroup?.id!;
  const [
    getOrgCounterparties,
    { loading: loadingOrgCounterparties, data: orgCounterpartyData },
  ] = useSpendGetOrganizationCounterpartiesLazyQuery({
    fetchPolicy: "network-only",
  });
  const [
    getGroupCounterparties,
    { loading: loadingGroupCounterparties, data: groupCounterpartyData },
  ] = useSpendGetGroupCounterpartiesLazyQuery({
    fetchPolicy: "network-only",
  });

  const [
    achOrgTransfer,
    { loading: loadingOrgACH, data: orgACHData, error: orgACHError },
  ] = useMutation(ORG_ACH_CREDIT, {
    refetchQueries: [
      "SpendTransactionsCompletedWhered",
      "SpendTransactionsPendingWhered",
    ],
    fetchPolicy: "network-only",
  });

  const [
    achGroupTransfer,
    { loading: loadingGroupACH, data: groupACHData, error: groupAchError },
  ] = useMutation(GROUP_ACH_CREDIT, {
    refetchQueries: [
      "SpendTransactionsCompletedWhered",
      "SpendTransactionsPendingWhered",
    ],
    fetchPolicy: "network-only",
  });

  const [getUserToken] = useLazyQuery(USER_TOKEN, {
    variables: {},
  });

  useEffect(() => {
    if (!loadingOrgACH) {
      if (orgACHData && orgACHData.spendOrganizationACHCredit?.paymentId) {
        dismount("Success");
      }
      if (orgACHData && orgACHData.spendOrganizationACHCredit?.approvalId) {
        dismount("Approval");
      }
      if (orgACHError) {
        dismount("Error");
      }
    }
    // eslint-disable-next-line
  }, [loadingOrgACH, orgACHData, orgACHError]);

  useEffect(() => {
    if (!loadingGroupACH) {
      if (groupACHData && groupACHData.spendGroupACHCredit?.paymentId) {
        dismount("Success");
      }
      if (groupACHData && groupACHData.spendGroupACHCredit?.approvalId) {
        dismount("Approval");
      }
      if (groupAchError) {
        dismount("Error");
      }
    }
    // eslint-disable-next-line
  }, [loadingGroupACH, groupACHData, groupAchError]);

  useEffect(() => {
    if (type === "Program") {
      getOrgCounterparties({
        variables: {
          groupOrOrgId,
        },
      });
    } else {
      getGroupCounterparties({
        variables: {
          groupOrOrgId,
        },
      });
    }
    // eslint-disable-next-line
  }, [type]);

  useEffect(() => {
    if (!loadingOrgCounterparties && orgCounterpartyData) {
      setCounterparties(
        orgCounterpartyData.spendGetOrganizationCounterparties
          ?.counterparties ?? []
      );
      handleInitSelectItems(
        orgCounterpartyData.spendGetOrganizationCounterparties
          ?.counterparties ?? []
      );
    }
  }, [loadingOrgCounterparties, orgCounterpartyData]);

  useEffect(() => {
    if (!loadingGroupCounterparties && groupCounterpartyData) {
      setCounterparties(
        groupCounterpartyData.spendGetGroupCounterparties?.counterparties ?? []
      );
      handleInitSelectItems(
        groupCounterpartyData.spendGetGroupCounterparties?.counterparties ?? []
      );
    }
  }, [loadingGroupCounterparties, groupCounterpartyData]);

  useEffect(() => {
    if (form !== undefined) {
      return;
    }
    if (process.env.REACT_APP_VGS_VAULT === undefined) {
      throw new Error("REACT_APP_VGS_VAULT not set");
    }
    if (process.env.REACT_APP_VGS_ENVIRONMENT === undefined) {
      throw new Error("REACT_APP_VGS_ENVIRONMENT not set");
    }

    const formInstance = window.VGSCollect.create(
      process.env.REACT_APP_VGS_VAULT,
      process.env.REACT_APP_VGS_ENVIRONMENT,
      () => {}
    );
    formInstance.useCname(process.env.REACT_APP_VGS_CNAME || "");
    setForm(formInstance);
  }, [form]);

  const dismount = (type: "Success" | "Error" | "Approval") => {
    if (type === "Success") {
      toast?.setToast(successToast);
    } else if (type === "Approval") {
      toast?.setToast(approvalToast);
    } else {
      toast?.setToast(errorToast);
    }
    sendACHToggle();
    sendMoneyToggle();
  };

  const handleInitSelectItems = (counterpartyData: SpendCounterparty[]) => {
    const mappedOptions = counterpartyData.map((counterparty) => {
      return {
        name: `${counterparty.name} - ${counterparty.accountLastFour}`,
        value: counterparty.id,
        selected: false,
      };
    });
    mappedOptions.sort((a, b) =>
      a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
    );
    setCounterpartyOptions(mappedOptions);
  };

  const handleAddCounterparty = async () => {
    if (isNullOrEmpty(counterpartyToAdd.name)) {
      toast?.setToast({
        message: "Payee name can not be empty",
        type: "danger",
      });
      setCounterpartyToAddErrors({
        ...counterpartyToAddErrors,
        nameError: true,
      });
    } else if (isNullOrEmpty(counterpartyToAdd.routingNumber)) {
      toast?.setToast({
        message: "Routing number can not be empty",
        type: "danger",
      });
      setCounterpartyToAddErrors({
        ...counterpartyToAddErrors,
        routingNumberError: true,
      });
    } else if (counterpartyToAdd.routingNumber.length < 9) {
      toast?.setToast({
        message: "Routing number not complete",
        type: "danger",
      });
      setCounterpartyToAddErrors({
        ...counterpartyToAddErrors,
        routingNumberError: true,
      });
    } else if (isNullOrEmpty(counterpartyToAdd.entityType)) {
      toast?.setToast({
        message: "Please select an entity type",
        type: "danger",
      });
      setCounterpartyToAddErrors({
        ...counterpartyToAddErrors,
        entityTypeError: true,
      });
    } else if (isNullOrEmpty(counterpartyToAdd.accountType)) {
      toast?.setToast({
        message: "Please select an account type",
        type: "danger",
      });
      setCounterpartyToAddErrors({
        ...counterpartyToAddErrors,
        accountTypeError: true,
      });
    } else {
      const {
        data: {
          userToken: { accessToken },
        },
      } = await getUserToken();

      const query =
        type === "Program"
          ? CREATE_ORG_COUNTERPARTY
          : CREATE_GROUP_COUNTERPARTY;

      form?.submit(
        "/graphql",
        {
          data: (formValues: Record<string, unknown>) => {
            const vgsInput = {
              groupOrOrgId,
              name: counterpartyToAdd.name,
              entityType: counterpartyToAdd.entityType,
              accountType: counterpartyToAdd.accountType,
              routingNumber: counterpartyToAdd.routingNumber,
              accountNumber: formValues["account-number-input"],
              einNumber: formValues["ein-number-input"],
            };
            const variables = { vgsInput };

            return {
              query,
              variables,
            };
          },
          method: "POST",
          withCredentials: true,
          headers: { Authorization: `Bearer ${accessToken}` },
        },
        async (responseStatus: any, data: any) => {
          let hasErrors = false;
          if (data.errors && data.errors.length) {
            hasErrors = true;
            toast?.setToast({
              message: "Invalid account or routing number, please re-check",
              type: "danger",
            });
          }
          try {
            if (type === "Program") {
              await getOrgCounterparties({
                variables: {
                  groupOrOrgId,
                },
              });
            } else {
              await getGroupCounterparties({
                variables: {
                  groupOrOrgId,
                },
              });
            }
            if (!hasErrors) {
              toggleAddPayee();
              setForm(undefined);
            }
          } catch (e) {
            console.log("e", e);
          }
        },
        (errors: any) => {
          if (errors["confirm-account-number-input"]) {
            toast?.setToast({
              message:
                "Account and confirm account do not match. Please go back and re-try",
              type: "danger",
            });
          }
        }
      );
    }
  };

  const handleTransfer = () => {
    let input: SpendOrgAchCredit & {
      groupId?: string;
    } = {
      ...achCreditInput,
      amount: ParseMoney(amount),
      note: achCreditInput.note.trim(),
    };

    if (type === "Group") {
      input = {
        ...input,
        groupId: groupOrOrgId,
      };
    }

    if (
      isNullOrEmpty(input.counterpartyId) ||
      isNullOrEmpty(input.counterpartyName)
    ) {
      toast?.setToast({
        message: "Please select a payee",
        type: "danger",
      });
      setACHInputErrors({
        ...achInputErrors,
        counterpartyError: true,
      });
    } else if (input.amount <= 0) {
      toast?.setToast({
        message: "Amount must be greater than 0",
        type: "danger",
      });
      setACHInputErrors({
        ...achInputErrors,
        amountError: true,
      });
    } else if (isNullOrEmpty(input.note)) {
      toast?.setToast({
        message: "Note is required",
        type: "danger",
      });
      setACHInputErrors({
        ...achInputErrors,
        noteError: true,
      });
    } else if (input.note.length >= 81) {
      toast?.setToast({
        message: "Note can not exceed 80 characters",
        type: "danger",
      });
      setACHInputErrors({
        ...achInputErrors,
        noteError: true,
      });
    } else {
      if (type === "Program") {
        achOrgTransfer({
          variables: {
            input,
          },
        });
      } else {
        achGroupTransfer({
          variables: {
            input,
          },
        });
      }
    }
  };

  const resetInputs = () => {
    setCounterpartyToAddErrors({
      nameError: false,
      routingNumberError: false,
      entityTypeError: false,
      accountTypeError: false,
    });
    setCounterpartyToAdd({
      groupOrOrgId: "",
      name: "",
      routingNumber: "",
      entityType: "",
      accountType: "",
    });
  };

  const btn1: BtnType | undefined = isAddPayeeOpen
    ? {
        text: "Continue",
        onClick: handleAddCounterparty,
      }
    : selectedTab === 0
    ? {
        text: "Submit",
        onClick: handleTransfer,
      }
    : undefined;
  const btn2: BtnType | undefined =
    selectedTab === 0 || isAddPayeeOpen
      ? {
          text: "Cancel",
          onClick: () => {
            if (isAddPayeeOpen) {
              toggleAddPayee();
            } else {
              sendACHToggle();
            }
            resetInputs();
          },
        }
      : undefined;

  return (
    <>
      <CustomModal
        isOpen={isSendACHOpen}
        toggle={sendACHToggle}
        title={isAddPayeeOpen ? "" : "Send ACH"}
        header={isAddPayeeOpen ? "Add Payee" : undefined}
        btn1={btn1}
        btn2={btn2}
        tabOptions={
          isAddPayeeOpen
            ? undefined
            : {
                currentSelectedValue: selectedTab,
                tabs: [
                  {
                    text: "Send Money",
                    value: 0,
                    onClick: () => {
                      setSelctedTab(0);
                    },
                  },
                  {
                    text: "Manage Payees",
                    value: 1,
                    onClick: () => {
                      setSelctedTab(1);
                    },
                  },
                ],
              }
        }
        hasBackBtn={
          isAddPayeeOpen
            ? {
                text: "Back",
                onClick: () => {
                  toggleAddPayee();
                  resetInputs();
                },
              }
            : undefined
        }
      >
        {isAddPayeeOpen ? (
          <AddCounterparty
            counterpartyToAdd={counterpartyToAdd}
            counterpartyToAddErrors={counterpartyToAddErrors}
            setCounterpartyToAddErrors={setCounterpartyToAddErrors}
            accountNumberRef={accountNumberRef}
            confirmAccountNumberRef={confirmAccountNumberRef}
            einNumberRef={einNumberRef}
            entityOptions={entityOptions}
            accountTypeOptions={accountTypeOptions}
          />
        ) : (
          {
            0: (
              <div className="modal-card">
                <TransferCounterparty
                  counterpartyOptions={counterpartyOptions}
                  achCreditInput={achCreditInput}
                  setACHCreditInput={setACHCreditInput}
                  amount={amount}
                  setAmount={setAmount}
                  achInputErrors={achInputErrors}
                  setACHInputErrors={setACHInputErrors}
                />
              </div>
            ),
            1: (
              <div className="modal-card">
                <ManageCounterparties
                  toggleAddPayee={toggleAddPayee}
                  counterparties={counterparties}
                  type={type}
                />
              </div>
            ),
          }[selectedTab]
        )}
      </CustomModal>
    </>
  );
}

export default ACHCredit;
