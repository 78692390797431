import { DropdownMenuItem } from "@snap-mobile/snap-ui/dist/types/utils";
import { useEffect, useState } from "react";
import BadgedTabs from "shared-components/badged-tabs";
import Pending from "../../../shared-components/approvals/pending";
import History from "shared-components/approvals/history";
import { useAppContexts } from "hooks/use-context";

function ProgramApprovals() {
  const { split, navigate } = useAppContexts();

  useEffect(() => {
    if (!split?.isTreatmentOn(split.act.spe1288)) {
      navigate("/programs/banking");
    }
  }, [split, navigate]);

  const [selectedTab, setSelectedTab] = useState(0);
  const [tabs] = useState<DropdownMenuItem[]>([
    {
      name: "Pending",
      text: "Pending",
      value: "pending",
      selected: true,
    },
    {
      name: "History",
      text: "History",
      value: "history",
      selected: false,
    },
  ]);

  return (
    <div className="wrapper">
      <div className="card">
        <BadgedTabs
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          tabs={tabs}
        />
        {
          {
            0: <Pending />,
            1: <History />,
          }[selectedTab]
        }
      </div>
    </div>
  );
}

export default ProgramApprovals;
