import { useLazyQuery } from "@apollo/client";
import { SnapSelectMenuOption } from "@snap-mobile/snap-ui/dist/types/utils";
import ProgramContext from "context/program-context";
import {
  InvoiceReportFilterEnum,
  Maybe,
  SpendGroup,
  SpendInvoice,
  useSpendPaginatedInvoicesQuery,
} from "graphql/generated";
import { INVOICES_EXPORT_V2 } from "graphql/queries/invoices";
import { InvoiceFilterOptions } from "helpers/dummy-data";
import { exportToCsv } from "helpers/export-csv";
import { resetFilters } from "helpers/reset-filters";
import { updateFilterCount } from "helpers/update-filter-count";
import { updateFilterSelection } from "helpers/update-filter-selection";
import useModal from "hooks/use-modal";
import { useSpendPagination } from "hooks/use-spend-pagination";
import useToast from "hooks/use-toast";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import DisplayFilterOptions from "shared-components/display-filter-options";
import Divider from "shared-components/divider";
import CustomModal, { BtnType } from "shared-components/modal";
import ShowingResults from "shared-components/showing-results";
import Sort from "shared-components/sort";
import ToastMessage from "shared-components/toast-message";
import { SnapIcon, SnapIndicator } from "suit";
import { Filters } from "types/filter-types";
import { ITEMS_PER_PAGE } from "../../../constants";
import InvoiceListItem from "./invoice-list-item";
import GroupContext from "context/group-context";
import { ArchiveName } from "helpers/archive-name";
import { isNullOrEmpty } from "helpers/null-or-empty";

function Invoices() {
  const location = useLocation();
  const Program = useContext(ProgramContext);
  const Group = useContext(GroupContext);
  const { sort, page, setPage, toggleSort, toggleMobileSort } =
    useSpendPagination();
  const { isToastOpen, toggleToast, ...toast } = useToast();
  const { isOpen, toggle } = useModal();
  const [invoicesData, setInvoicesData] = useState<SpendInvoice[]>([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedFilterCount, setSelectedFilterCount] = useState(0);
  const [invoiceFilters, setInvoiceFilters] =
    useState<Filters[]>(InvoiceFilterOptions);
  const [totalInvoices, setTotalInvoices] = useState(0);
  const [parOps, setParOpts] = useState<
    { name: string; email: string; id: string }[]
  >([]);
  const [groupOps, setGroupOps] = useState<{ name: string; id: string }[]>([]);
  const [appliedFilters, setAppliedFilters] = useState<
    { by: InvoiceReportFilterEnum; value: string }[]
  >([
    {
      by: InvoiceReportFilterEnum.ExcludeArchived,
      value: "false",
    },
  ]);
  const [exporting, setExporting] = useState(false);

  const [
    ExportInvoicesV2,
    { loading: loadingExportedInvoicesV2, data: exportInvoiceDataV2 },
  ] = useLazyQuery(INVOICES_EXPORT_V2);

  const {
    loading: loadingInvoices,
    data: invoiceData,
    refetch: refetchInvoices,
    variables: invoiceVariables,
  } = useSpendPaginatedInvoicesQuery({
    variables: {
      pagination: {
        limit: ITEMS_PER_PAGE,
        offset: page * ITEMS_PER_PAGE,
      },
      filters: appliedFilters,
      sort,
    },
  });

  useEffect(() => {
    if (location && location.state) {
      let filters: { by: InvoiceReportFilterEnum; value: string }[] = [];
      if (location.state.groupRosterId) {
        filters.push({
          by: InvoiceReportFilterEnum.GroupRosterId,
          value: location.state.groupRosterId,
        });
      }
      if (location.state.status) {
        filters.push({
          by: InvoiceReportFilterEnum.Status,
          value: location.state.status,
        });
      }
      setAppliedFilters(filters);
    }
  }, [location]);

  useEffect(() => {
    if (exportInvoiceDataV2 && exportInvoiceDataV2.spendInvoicesExportV2) {
      setExporting(false);
      exportToCsv(exportInvoiceDataV2.spendInvoicesExportV2);
    }
  }, [loadingExportedInvoicesV2, exportInvoiceDataV2]);

  useEffect(() => {
    if (Program && Group?.groupsWithSeasonsRoster) {
      handlePrepFilterData(Group?.groupsWithSeasonsRoster);
    }

    // eslint-disable-next-line
  }, [Group?.groupsWithSeasonsRoster, Group?.groups]);

  useEffect(() => {
    if (!loadingInvoices && invoiceData && invoiceData.spendPaginatedInvoices) {
      setTotalInvoices(invoiceData?.spendPaginatedInvoices?.count ?? 0);
      setInvoicesData(
        invoiceData?.spendPaginatedInvoices?.invoices as SpendInvoice[]
      );
    }
    // eslint-disable-next-line
  }, [loadingInvoices, invoiceData]);

  const handlePrepFilterData = (Groups: Maybe<SpendGroup>[]) => {
    const { participantIndex, groupIndex, statusIndex } =
      handleGetFiltersIndex();

    let groupsToPrep = Groups;
    let playerOptionsArr: { name: string; email: string; id: string }[] = [];
    groupsToPrep.forEach((group) => {
      let seasons = group?.seasons;
      seasons?.forEach((season) => {
        let groupRoster = season?.groupRoster;
        groupRoster?.forEach((roster) => {
          if (!roster?.isArchived) {
            playerOptionsArr.push({
              name: ArchiveName(
                roster?.roster?.name ?? "",
                group?.isArchived ?? false
              ),
              email: roster?.roster?.email ?? "",
              id: roster?.id ?? "",
            });
          }
        });
      });
    });

    let newSet = removeDups(playerOptionsArr);
    setParOpts(newSet);
    const participantOptions = newSet.map((playerOption) => ({
      name: playerOption.name,
      selected: location?.state?.groupRosterId === playerOption.id,
    }));

    const sortedParticipantOptions = participantOptions.sort((a, b) =>
      a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
    );

    let allParticipantOptions: Filters = {
      ...InvoiceFilterOptions[participantIndex],
      filterOptions: sortedParticipantOptions,
    };
    InvoiceFilterOptions.splice(participantIndex, 1, allParticipantOptions);

    setGroupOps(
      Groups.map((group) => {
        return {
          name: ArchiveName(group?.name ?? "", group?.isArchived ?? false),
          id: group?.id ?? "",
        };
      })
    );
    const groupOptions = Groups.map((group) => {
      return {
        name: ArchiveName(group?.name ?? "", group?.isArchived ?? false),
        selected: false,
      };
    });

    const sortedGroupOptions = groupOptions.sort((a, b) =>
      a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
    );

    let allGroupOptions: Filters = {
      ...InvoiceFilterOptions[groupIndex],
      filterOptions: sortedGroupOptions,
    };
    InvoiceFilterOptions.splice(groupIndex, 1, allGroupOptions);

    if (location?.state?.status) {
      let statusOptions = InvoiceFilterOptions[statusIndex].filterOptions?.map(
        (option) => {
          return {
            ...option,
            selected:
              location.state.status ===
              option.name.replace(" ", "_").toLowerCase(),
          };
        }
      );
      let allStatusOptions: Filters = {
        ...InvoiceFilterOptions[statusIndex],
        filterOptions: statusOptions,
      };
      InvoiceFilterOptions.splice(statusIndex, 1, allStatusOptions);
    }
    setInvoiceFilters(InvoiceFilterOptions);
    const activeFilterCount = updateFilterCount(InvoiceFilterOptions);
    setSelectedFilterCount(activeFilterCount || 0);
  };

  const removeDups = (
    options: { name: string; email: string; id: string }[]
  ) => {
    return options.filter(
      (ele, ind) =>
        ind ===
        options.findIndex(
          (elem) => elem.name === ele.name && elem.email === ele.email
        )
    );
  };

  const handleFilters = (
    selectedFilterName: string,
    details: SnapSelectMenuOption[],
    date?: string
  ) => {
    const updatedFilters = updateFilterSelection(
      invoiceFilters,
      selectedFilterName,
      details,
      date
    );
    if (updatedFilters === invoiceFilters) {
      setInvoiceFilters([]);
    }
    const activeFilterCount = updateFilterCount(updatedFilters);
    setSelectedFilterCount(activeFilterCount || 0);
    setInvoiceFilters(updatedFilters);

    if (selectedFilterName === "Include Archived") {
      const { includeArchivedIndex } = handleGetFiltersIndex();
      const selectedOption = updatedFilters[
        includeArchivedIndex
      ].filterOptions?.find((op) => op.selected);
      Group?.setIncludeArchiveGroupsWithSeasons(selectedOption?.name === "Yes");
    }
  };

  const handleResetFilters = () => {
    const defaultFilters = resetFilters(invoiceFilters);
    setInvoiceFilters(defaultFilters);
    setSelectedFilterCount(0);
    setStartDate("");
    setEndDate("");
    refetchInvoices({
      ...invoiceVariables,
      filters: [
        {
          by: InvoiceReportFilterEnum.ExcludeArchived,
          value: "false",
        },
      ],
    });
  };

  const handleGetSelectedFilters = () => {
    let Filters: { by: InvoiceReportFilterEnum; value: string }[] = [];
    const { participantIndex, groupIndex, statusIndex } =
      handleGetFiltersIndex();
    let participantFilters = invoiceFilters[
      participantIndex
    ].filterOptions?.find((option) => option.selected)?.name;
    let groupRosterId = parOps.find(
      (option) => option.name === participantFilters
    )?.id;
    let selectedGroupOption = invoiceFilters[groupIndex].filterOptions?.find(
      (option) => option.selected
    )?.name;
    let groupId = groupOps.find(
      (option) => option.name === selectedGroupOption
    )?.id;
    let selectedStatusOption = invoiceFilters[statusIndex].filterOptions?.find(
      (option) => option.selected
    )?.name;
    let includeArchived =
      invoiceFilters[5].filterOptions?.find((option) => option.selected)
        ?.name ?? "No";
    if (groupRosterId) {
      Filters.push({
        by: InvoiceReportFilterEnum.GroupRosterId,
        value: groupRosterId,
      });
    }
    if (groupId) {
      Filters.push({
        by: InvoiceReportFilterEnum.GroupId,
        value: groupId,
      });
    }
    if (selectedStatusOption?.length) {
      Filters.push({
        by: InvoiceReportFilterEnum.Status,
        value: selectedStatusOption?.replace(" ", "_").toLowerCase() ?? "",
      });
    }
    Filters.push({
      by: InvoiceReportFilterEnum.ExcludeArchived,
      value: includeArchived === "No" ? "true" : "false",
    });
    if (!isNullOrEmpty(startDate)) {
      Filters.push({
        by: InvoiceReportFilterEnum.DateAfter,
        value: startDate,
      });
    }
    if (!isNullOrEmpty(endDate)) {
      Filters.push({
        by: InvoiceReportFilterEnum.DateBefore,
        value: endDate,
      });
    }

    return Filters;
  };

  const handleApplyFilters = () => {
    const Filters = handleGetSelectedFilters();
    const { startDateIndex } = handleGetFiltersIndex();
    if (
      !isNullOrEmpty(startDate) &&
      !isNullOrEmpty(endDate) &&
      new Date(startDate).getTime() > new Date(endDate).getTime()
    ) {
      let tempFilters = [...invoiceFilters];
      let filterOption: Filters = {
        ...invoiceFilters[startDateIndex],
        hasError: true,
      };
      tempFilters.splice(startDateIndex, 1, filterOption);
      setInvoiceFilters(tempFilters);
      toast.setToastProps({
        message: "End Date cannot be before Start Date",
        type: "danger",
      });
      toggleToast();
    } else {
      if (invoiceFilters[startDateIndex].hasError) {
        let tempFilters = [...invoiceFilters];
        let filterOption: Filters = {
          ...invoiceFilters[startDateIndex],
          hasError: false,
        };
        tempFilters.splice(startDateIndex, 1, filterOption);
        setInvoiceFilters(tempFilters);
      }
      setAppliedFilters(Filters);
      setPage(0);
      toggle();
    }
  };

  const handleGetFiltersIndex = () => {
    const participantIndex = InvoiceFilterOptions.findIndex(
      (options) => options.filterName === "Participants"
    );
    const groupIndex = InvoiceFilterOptions.findIndex(
      (options) => options.filterName === "Groups"
    );
    const statusIndex = InvoiceFilterOptions.findIndex(
      (options) => options.filterName === "Status"
    );
    const startDateIndex = InvoiceFilterOptions.findIndex(
      (options) => options.filterName === "Start Date"
    );
    const endDateIndex = InvoiceFilterOptions.findIndex(
      (options) => options.filterName === "End Date"
    );
    const includeArchivedIndex = InvoiceFilterOptions.findIndex(
      (options) => options.filterName === "Include Archived"
    );

    return {
      participantIndex,
      groupIndex,
      statusIndex,
      startDateIndex,
      endDateIndex,
      includeArchivedIndex,
    };
  };

  const generateSortList = () => {
    const list = [
      { label: "Group", value: "group" },
      { label: "Participant", value: "participant" },
      { label: "Due Date", value: "dueDate" },
      { label: "Invoice Amount", value: "invoiceAmount" },
      { label: "Balance Due", value: "balanceDue" },
    ];
    const res = list.flatMap((item: any) => {
      return [
        {
          name: `${item.label} A-Z`,
          text: `${item.label} A-Z`,
          value: `${item.value} ASC`,
          selected: sort?.field === item.value && sort?.order === "ASC",
        },
        {
          name: `${item.label} Z-A`,
          text: `${item.label} Z-A`,
          value: `${item.value} DESC`,
          selected: sort?.field === item.value && sort?.order === "DESC",
        },
      ];
    });
    return res;
  };

  const handleExport = () => {
    const Filters = handleGetSelectedFilters();
    setExporting(true);
    ExportInvoicesV2({
      variables: {
        filters: Filters,
      },
    }).then(() => setExporting(false));
  };

  const btn1: BtnType = {
    text: "Apply Filters",
    btnStyle: "primary",
    onClick: () => {
      handleApplyFilters();
    },
  };
  const btn2: BtnType = {
    text: "Cancel",
    btnStyle: "tertiary",
    onClick: toggle,
  };

  return (
    <div className="wrapper">
      <div className="card">
        <div className="flex" onClick={toggle}>
          <SnapIcon icon="filter-solid" size="sm" color="#3B82F6" />
          <p className="px-2 text-blue-600 font-bold cursor-pointer">
            All Filters
          </p>
          {selectedFilterCount !== 0 && (
            <SnapIndicator
              text={selectedFilterCount}
              color="blue"
              className="self-center"
            />
          )}
        </div>
        <Divider isVisibleOnMobile />
        {
          <>
            <p className="lg:font-semibold font-medium text-lg mt-4">
              Invoices
            </p>
            <div className="flex justify-between">
              <ShowingResults
                totalNumOfResults={totalInvoices}
                numOfResultsBeingDisplayed={
                  totalInvoices <= 10
                    ? invoicesData.length
                    : ITEMS_PER_PAGE * page + 10 >= totalInvoices
                    ? totalInvoices
                    : ITEMS_PER_PAGE * page + 10
                }
                startingNumOfResults={
                  invoicesData.length === 0 ? 0 : ITEMS_PER_PAGE * page + 1
                }
              />
              <div
                className="flex text-blue-600 font-bold lg:text-base sm:text-sm cursor-pointer"
                onClick={handleExport}
              >
                <SnapIcon icon="download-solid" size="sm"></SnapIcon>
                <p className="self-center">Export as CSV</p>
              </div>
            </div>
            <Sort
              selectedSortOption={""}
              options={generateSortList()}
              handleSort={(e) => {
                let selectedArr = e.value.split(" ");
                toggleMobileSort(selectedArr[0], selectedArr[1]);
              }}
            />
            {!loadingInvoices && invoicesData && invoicesData.length === 0 ? (
              <div>
                <p>No Invoices</p>
              </div>
            ) : (
              <InvoiceListItem
                listItems={invoicesData}
                totalItems={totalInvoices}
                page={page}
                setPage={setPage}
                toggleSort={toggleSort}
                currentSort={sort}
                loadingInvoices={loadingInvoices}
                exporting={exporting}
              />
            )}
          </>
        }
      </div>
      <CustomModal
        isOpen={isOpen}
        toggle={toggle}
        title="Filters"
        btn1={btn1}
        btn2={btn2}
        customStyle="lg:h-[520px]"
      >
        <div className="modal-card relative">
          <div className="flex">
            <div className="flex mr-auto text-sm">
              <p className="font-semibold">
                {" "}
                {selectedFilterCount} Filters Applied
              </p>
            </div>
            <p
              className=" text-base font-bold text-blue-600 cursor-pointer"
              onClick={handleResetFilters}
            >
              Reset Filters
            </p>
          </div>
          <DisplayFilterOptions
            filters={invoiceFilters}
            isDate={false}
            handleFilters={handleFilters}
            handleResetFilters={handleResetFilters}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
          {isToastOpen && (
            <ToastMessage
              message={toast.message}
              isToastOpen={isToastOpen}
              toggleToast={toggleToast}
              type={toast.type}
              className={`lg:top-[125%] top-[100%]`}
            />
          )}
        </div>
      </CustomModal>
    </div>
  );
}

export default Invoices;
