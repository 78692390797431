import { useQuery } from "@apollo/client";
import GroupContext from "context/group-context";
import { Maybe, SpendBudget, SpendCategory } from "graphql/generated";
import { GET_BUDGETS_SIMPLE } from "graphql/queries/budgets";
import { SetStateAction, useContext, useEffect } from "react";
import { SnapAlert, SnapAlertDescription, SnapAlertTitle } from "suit";
import AddInvoice from "./add-invoice";
import PaymentScheduleCard from "./card";
import ImportInvoices from "./import-invoices";
import PaymentScheduleTable from "./table";
import { SnapSelectMenuOption } from "@snap-mobile/snap-ui/dist/types/utils";
import Divider from "shared-components/divider";
import { SpendPaymentScheduleWithFees } from "../../participants-roster";
import DisplayContext from "context/display-context";
import SeasonContext from "context/season-context";
import UserContext from "context/user-context";

type EditPaymentScheduleProps = {
  invoices: SpendPaymentScheduleWithFees[];
  setInvoices: React.Dispatch<
    React.SetStateAction<SpendPaymentScheduleWithFees[]>
  >;
  incomeCategories: SnapSelectMenuOption[];
  setIncomeCategories: React.Dispatch<SetStateAction<SnapSelectMenuOption[]>>;
  setIsBtnActive: React.Dispatch<SetStateAction<boolean>>;
  se579NotSavingPublish: boolean;
};

function EditPaymentSchedule({
  invoices,
  setInvoices,
  setIsBtnActive,
  se579NotSavingPublish,
  incomeCategories,
  setIncomeCategories,
}: EditPaymentScheduleProps) {
  const roleAccess = ["program_staff", "program_admin"];
  const User = useContext(UserContext);
  const Display = useContext(DisplayContext);
  const Group = useContext(GroupContext);
  const Season = useContext(SeasonContext);

  const { loading: loadingBudgets, data: dataBudgets } = useQuery(
    GET_BUDGETS_SIMPLE,
    {
      variables: {
        groupId: Group?.activeGroup?.id ?? "",
        seasonId: Season?.selectedSeason?.id,
      },
    }
  );

  useEffect(() => {
    if (dataBudgets && dataBudgets.spendBudgetsSummary) {
      let selectOptions: SnapSelectMenuOption[] = [];
      dataBudgets.spendBudgetsSummary.summaryByCategory.forEach(
        (cat: SpendCategory) => {
          cat?.budgets?.forEach((budget: Maybe<SpendBudget>) => {
            if (cat.type === "income") {
              selectOptions.push({
                name: `${cat?.name} | ${budget?.description}`,
                value: `${budget?.id}`,
                selected: false,
              });
            }
          });
        }
      );
      setIncomeCategories(selectOptions);
    }
    // eslint-disable-next-line
  }, [loadingBudgets, dataBudgets]);

  return (
    <div className="modal-card">
      {invoices.length > 0 && (
        <SnapAlert type="warning">
          <SnapAlertTitle>Payment Schedule ON-HOLD</SnapAlertTitle>
          <SnapAlertDescription>
            <p>
              Payment collection temporarily suspended until you finish editing
              and publish changes. Invoices will be modified only for
              participants invoices without payment or credits applied.
            </p>
          </SnapAlertDescription>
        </SnapAlert>
      )}
      <p className="text-lg font-semibold mt-4">Details</p>
      <table className="w-full mt-4">
        <tbody>
          <tr className="lg:table-row flex flex-col">
            <td className="mr-auto">
              <p className="mr-1 font-normal text-sm text-gray-500">Group</p>
            </td>
            <td className="flex lg:table-cell">{Group?.activeGroup?.name}</td>
          </tr>
          <tr className="lg:table-row flex flex-col mt-4 lg:mt-0">
            <td className="mr-auto">
              <p className="mr-1 font-normal text-sm text-gray-500">Season</p>
            </td>
            <td className="flex lg:table-cell">
              {Season?.selectedSeason?.name}
            </td>
          </tr>
        </tbody>
      </table>
      {!invoices.length && (
        <SnapAlert
          type="info"
          right-link-text="Details"
          right-link-href=""
          className="mt-4"
        >
          <SnapAlertTitle>
            Build your Group's payment schedule here. After you publish the
            payment schedule you will be able to invite parents to set-up an
            auto-pay account.
          </SnapAlertTitle>
          <SnapAlertDescription>
            <ul>
              <li>
                <b>New</b> - A payment schedule invoice is not required to
                publish and begin inviting parents. This will allow payment
                processing of individual invoices assigned in the participant's
                details page.
              </li>
              <li>
                You do not need to add a "pay all now" invoice - parents will
                automatically be given that option.
              </li>
              <li>
                Payment schedule items are applied to all participants on the
                group. To modify amounts and due dates on a
                participant-by-participant basis, go to the participant detail
                pages.
              </li>
              <li>
                Set due dates at least a week before you need the money. Bank
                payments can take 5-7 days to clear, card payments are quicker.
              </li>
            </ul>
          </SnapAlertDescription>
        </SnapAlert>
      )}
      <Divider isVisibleOnMobile />
      <div className="mt-4">
        <p className="lg:mb-0 mb-4 text-lg font-semibold">
          Payment Schedule Invoices
        </p>
        {Display?.isDesktop ? (
          <PaymentScheduleTable
            invoices={invoices}
            categories={incomeCategories}
            isEdit={true}
            setInvoices={setInvoices}
          />
        ) : (
          <PaymentScheduleCard
            invoices={invoices}
            categories={incomeCategories}
            isEdit={true}
            setInvoices={setInvoices}
          />
        )}
      </div>
      <AddInvoice
        categories={incomeCategories}
        setIsBtnActiveForPublish={setIsBtnActive}
        se579NotSavingPublish={se579NotSavingPublish}
        currentInvoices={invoices}
      />
      {roleAccess.includes(User?._session?.role?.name ?? "") && (
        <ImportInvoices categories={incomeCategories} />
      )}
    </div>
  );
}

export default EditPaymentSchedule;
