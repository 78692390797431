import { SpendGroup, useSpendGroupArchiveMutation } from "graphql/generated";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SnapAlert, SnapAlertDescription, SnapAlertTitle } from "suit";
import CustomModal, { BtnState } from "..";
import GroupContext from "context/group-context";
import { DASHBOARD_GROUP_OVERVIEW } from "graphql/queries/organization";
import SeasonContext from "context/season-context";
import ToastContext from "context/toast-context";

type ArchiveGroupProps = {
  archiveOpen: boolean;
  archiveToggle: () => void;
  selectedGroup: SpendGroup | undefined;
};

function ArchiveGroup({
  archiveOpen,
  archiveToggle,
  selectedGroup,
}: ArchiveGroupProps) {
  const navigate = useNavigate();
  const group = useContext(GroupContext);
  const season = useContext(SeasonContext);
  const toast = useContext(ToastContext);

  const [buttonState, setButtonState] = useState<BtnState>(BtnState.BASE);
  const [
    archiveGroup,
    { loading: loadingArchive, data: archiveData, error: archiveError },
  ] = useSpendGroupArchiveMutation({
    refetchQueries: ["SpendGroupsFiltered"],
  });

  useEffect(() => {
    if (!loadingArchive && archiveData && archiveData.spendGroupArchive) {
      toast?.setToast({
        message: "Group Archived Successfully",
        type: "success",
      });
      archiveToggle();
      navigate("/groups/active");
      group?.setAndStoreActiveGroup(undefined);
      season?.setAndStoreActiveSeason(undefined);
      group?.setIsArchived(false);
      season?.handleSelectedSeason();
      setButtonState(BtnState.BASE);
    }
    if (!loadingArchive && archiveError) {
      toast?.setToast({
        message: "Error Archiving Group",
        type: "danger",
      });
      setButtonState(BtnState.BASE);
    }
    // eslint-disable-next-line
  }, [loadingArchive, archiveData, archiveError]);

  return (
    <CustomModal
      isOpen={archiveOpen}
      toggle={() => {
        archiveToggle();
        setButtonState(BtnState.BASE);
      }}
      title="Archive Group"
      btn1={{
        text: "Archive Group",
        onClick: () => {
          setButtonState(BtnState.DISABLED);
          if (selectedGroup?.id) {
            archiveGroup({
              variables: { spendGroupArchiveId: selectedGroup.id },
              refetchQueries: [
                "SpendGroupsFiltered",
                {
                  query: DASHBOARD_GROUP_OVERVIEW,
                },
              ],
              fetchPolicy: "no-cache",
              errorPolicy: "all",
            });
          } else {
            toast?.setToast({
              message: "Active group not set, please refresh the page",
              type: "warning",
            });
          }
        },
        btnStyle: "primary",
        btnState: buttonState,
      }}
      btn2={{
        text: "Cancel",
        btnStyle: "tertiary",
        onClick: () => {
          archiveToggle();
          setButtonState(BtnState.BASE);
        },
      }}
    >
      <div className="modal-card">
        <SnapAlert type="warning" right-link-text="Details" right-link-href="">
          <SnapAlertTitle>
            Are you sure you want to archive this Group? {selectedGroup?.name}
          </SnapAlertTitle>
          <SnapAlertDescription>
            <p>
              Archived groups are not included in tallies of Paid, Processing,
              Upcoming and Past Due amounts. Any outstanding invoices will not
              be billed, and parents will not receive reminders about these
              invoices. All seasons will be archived and you will not be able to
              refund payments made to this group.
            </p>
            <p className="mt-4">
              If this group has a Snap! Spend banking account, you will no
              longer be able to make deposits to the account and all group debit
              cards will be deactivated.
            </p>
          </SnapAlertDescription>
        </SnapAlert>
      </div>
    </CustomModal>
  );
}

export default ArchiveGroup;
