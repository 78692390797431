import ToastContext from "context/toast-context";
import UserContext from "context/user-context";
import {
  SpendSystemNotification,
  useSpendAcknowledgeNotificationMutation,
} from "graphql/generated";
import { useContextStrict } from "helpers/context-strict";
import { isNullOrEmpty } from "helpers/null-or-empty";
import { useEffect, useState } from "react";
import ScrollableModal from "../scrollable-modal";

type SystemNotificationProps = {
  isSystemNotificationOpen: boolean;
  toggleSystemNotification: () => void;
  notifications: SpendSystemNotification[];
};

function SystemNotification({
  isSystemNotificationOpen,
  toggleSystemNotification,
  notifications,
}: SystemNotificationProps) {
  const toasty = useContextStrict(ToastContext);
  const user = useContextStrict(UserContext);
  const [index, setIndex] = useState(0);
  const [acknowledgeNotif, { data, loading }] =
    useSpendAcknowledgeNotificationMutation();
  const handleAcknowledge = (id?: string) => {
    if (!id) {
      toasty.setToast({
        title: "Error",
        message:
          "Unable to process this acknowledgement. Please refresh the page. If the issue continues, please contact support.",
        type: "danger",
      });
      return;
    }
    acknowledgeNotif({
      variables: {
        input: {
          notificationId: id,
        },
      },
    });
    if (notifications.length - 1 === index) {
      user.clearNotifications();
      toggleSystemNotification();
    } else {
      setIndex(index + 1);
    }
  };
  useEffect(() => {
    if (!loading && data?.spendUserAcknowledgeNotification?.success) {
      toasty.setToast({
        title: "Acknowledgement Received",
        message: "Thank you.",
        type: "success",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading]);

  return (
    <ScrollableModal
      isOpen={isSystemNotificationOpen}
      toggle={() => {}}
      title={"Message from SNAP! SPEND"}
      containerStyle="lg:w-[500px] lg:mt-[100px]"
      actionRequired={true}
      btn1={{
        text: "Acknowledge",
        btnStyle: "primary",
        onClick: () => {
          handleAcknowledge(notifications.at(index)?.id || undefined);
        },
      }}
    >
      {notifications.length > 1 && (
        <div className="text-center text-sm text-gray-600 mt-2">
          {index + 1} of {notifications.length}
        </div>
      )}
      <p className="text-lg text-center my-2 mx-8 font-semibold">
        {notifications.at(index)?.title}
      </p>
      {notifications
        .at(index)
        ?.description?.split("\n")
        .map((msg) => {
          if (msg.startsWith("*")) {
            return <p className="mx-4">&bull;{msg.replace("*", "")}</p>;
          } else {
            if (isNullOrEmpty(msg)) {
              return <br className="max-h-[12px]" />;
            }
            return <p className="mx-4 min-h-[12px]">{msg}</p>;
          }
        })}
      <p className="text-xs mx-2 mt-5">
        If you have any questions or concerns regarding this notification please
        reach out to support using the chat bubble in the lower corner.
      </p>
    </ScrollableModal>
  );
}

export default SystemNotification;
