import {
  DropdownMenuItem,
  SnapSelectMenuOption,
} from "@snap-mobile/snap-ui/dist/types/utils";
import UserContext from "context/user-context";
import { getBadgeColor } from "helpers/status-color";
import useModal from "hooks/use-modal";
import { useEffect, useState } from "react";
import Divider from "shared-components/divider";
import Filter from "shared-components/filter";
import ShowingResults from "shared-components/showing-results";
import Sort from "shared-components/sort";
import { SnapBadge, SnapIcon, SnapPagination } from "suit";
import { SpendPermissions } from "types/roles-permissions";
import Table from "./table";
import Cards from "./card";
import { debounce } from "helpers/debounce";
import {
  SpendInviteType,
  SpendRoleNameEnum,
  SpendSortInput,
  SpendSortOrderEnum,
  SpendUserRoleUpdateMutationVariables,
  useSpendInvitesFilteredQuery,
  useSpendSimpleGroupsQuery,
  useSpendUserInviteArchiveMutation,
  useSpendUserRoleUpdateIsApproverMutation,
  useSpendUserRoleUpdateMutation,
} from "graphql/generated";
import { ITEMS_PER_PAGE } from "../../../constants";
import DisplayContext from "context/display-context";
import ChangeUserRoleModal from "../program/modals/change-user-role-modal";
import ToastContext from "context/toast-context";
import { useContextStrict } from "helpers/context-strict";
import {
  HandleAllCheckboxAction,
  hasAllById,
} from "helpers/all-checkbox-action";
import { inviteStatusToText } from "helpers/status-text";
import { InvitePlus } from "types/invite";
import EnableApprover from "shared-components/fixed-modal/enable-approver";
import DisableApprover from "shared-components/fixed-modal/disable-approver";
import BulkActionsV2 from "shared-components/bulk-actions-v2";

const FILTER_INVITE_OPTIONS: DropdownMenuItem[] = [
  {
    name: "All Users",
    text: "All Users",
    value: "-all-",
    selected: true,
  },
  { name: "Active", text: "Active", value: "accepted", selected: false },
  {
    name: "Not Signed Up",
    text: "Not Signed Up",
    value: "pending",
    selected: false,
  },
  {
    name: "Undeliverable",
    text: "Undeliverable",
    value: "undeliverable",
    selected: false,
  },
];

function Group() {
  const display = useContextStrict(DisplayContext);
  const user = useContextStrict(UserContext);
  const toast = useContextStrict(ToastContext);

  const { isOpen: changeRoleOpen, toggle: changeRoleToggle } = useModal();
  const { isOpen: enableApproverOpen, toggle: enableApproverToggle } =
    useModal();
  const { isOpen: disableApproverOpen, toggle: disableApproverToggle } =
    useModal();

  const [isBtnActive, setIsBtnActive] = useState(true);
  const [allGroups, setAllGroups] = useState<SnapSelectMenuOption[]>([]);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [selectedItems, setSelectedItems] = useState<InvitePlus[]>([]);
  const [menuClickData, setMenuClickData] = useState<InvitePlus | null>(null);
  const [isNameBoxChecked, setNameBoxChecked] = useState(false);
  const [staff, setStaff] = useState<InvitePlus[]>([]);
  const [searchText, setSearchText] = useState("");
  const [selectedStatus, setStatus] = useState<string>("");

  const setSearchValue = (e: any) => {
    setSearchText(e.target.value);
  };
  const setDebounceSearch = debounce(setSearchValue, 500);
  const [sort, setSort] = useState<SpendSortInput | undefined>();
  const handleSort = (data: any) => {
    const sortValue = data.value;
    toggleSort(sortValue);
  };

  const toggleSort = (sortField: string) => {
    setSort({
      field: sortField,
      order:
        sortField !== sort?.field
          ? SpendSortOrderEnum.Asc
          : sort?.order === SpendSortOrderEnum.Asc
          ? SpendSortOrderEnum.Desc
          : SpendSortOrderEnum.Asc,
    });
  };

  const [
    updateApproverSetting,
    {
      loading: loadingApproverUpdate,
      data: approverUpdateSettingData,
      error: approverUpdateError,
    },
  ] = useSpendUserRoleUpdateIsApproverMutation({
    refetchQueries: ["SpendInvitesFiltered"],
  });

  const { data, loading } = useSpendInvitesFilteredQuery({
    variables: {
      includeGroup: true,
      pagination: {
        limit: ITEMS_PER_PAGE,
        offset: page * ITEMS_PER_PAGE,
      },
      where: {
        type: [SpendInviteType.GroupStaff, SpendInviteType.GroupObserver],
        nameIncludes:
          searchText && searchText.length > 2 ? searchText : undefined,
        status: selectedStatus || undefined,
      },
      sort: sort,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  useSpendSimpleGroupsQuery({
    onCompleted: (data) => {
      if (data && data.spendGroups?.groups) {
        const groupsArray: SnapSelectMenuOption[] = data.spendGroups.groups.map(
          (gr) => {
            return {
              name: gr?.name ?? "",
              value: gr?.id ?? "",
              selected: false,
            };
          }
        );
        if (groupsArray) setAllGroups(groupsArray);
      }
    },
  });

  const [
    changeRole,
    {
      loading: loadingRoleChange,
      data: roleChangeData,
      error: changeRoleError,
    },
  ] = useSpendUserRoleUpdateMutation({
    refetchQueries: ["SpendInvitesFiltered"],
  });

  const [archiveUser, { loading: loadingArchiveUser, data: archiveUserData }] =
    useSpendUserInviteArchiveMutation({
      refetchQueries: ["SpendInvitesFiltered"],
      fetchPolicy: "no-cache",
    });

  useEffect(() => {
    if (!loadingApproverUpdate && approverUpdateSettingData) {
      toast?.setToast({
        message: "Successfully updated approver setting",
        type: "success",
      });
      setIsBtnActive(true);
      if (enableApproverOpen) {
        enableApproverToggle();
      }
      if (disableApproverOpen) {
        disableApproverToggle();
      }
    }

    if (!loadingApproverUpdate && approverUpdateError) {
      toast?.setToast({
        message: "Error updating approver setting",
        type: "danger",
      });
      setIsBtnActive(true);
    }
    // eslint-disable-next-line
  }, [loadingApproverUpdate, approverUpdateSettingData, approverUpdateError]);

  useEffect(() => {
    if (!loadingRoleChange && roleChangeData) {
      toast.setToast({
        message: "Role Change Successfully",
        type: "success",
      });
      setIsBtnActive(true);
      changeRoleToggle();
    }

    if (!loadingRoleChange && changeRoleError) {
      toast?.setToast({
        message: "Error changing users role",
        type: "danger",
      });
      setIsBtnActive(true);
    }
    // eslint-disable-next-line
  }, [loadingRoleChange, roleChangeData]);

  useEffect(() => {
    if (!loadingArchiveUser && archiveUserData) {
      toast.setToast({
        message: "User Archived Successfully",
        type: "success",
      });
    }
    // eslint-disable-next-line
  }, [loadingArchiveUser, archiveUserData]);

  useEffect(() => {
    if (!loading && data && data.spendInvitesFiltered) {
      const inviteIds = new Set(selectedItems.map(({ id }) => id));

      const groupUsersArray = data.spendInvitesFiltered.invites?.map(
        (invite, idx) => {
          let isChecked = false;
          if (invite?.id) {
            isChecked = inviteIds.has(invite.id);
          }

          return {
            ...invite,
            id: invite?.id ?? `card-${idx}`, // fake id
            isChecked,
            hasWarning: false,
          };
        }
      );
      if (groupUsersArray) {
        setStaff(groupUsersArray);
      }
      setNameBoxChecked(
        groupUsersArray?.every((user) => user.isChecked) ?? false
      );
      setTotal(data.spendInvitesFiltered.count ?? 0);
    }
    // eslint-disable-next-line
  }, [data, loading]);

  const handleRoleChange = ({
    userId,
    roleName,
    groupId,
    inviteId,
  }: SpendUserRoleUpdateMutationVariables) => {
    if (
      page > 0 &&
      staff.length === 1 &&
      (roleName === SpendRoleNameEnum.ProgramStaff ||
        roleName === SpendRoleNameEnum.ProgramObserver)
    ) {
      setPage(page - 1);
    }

    changeRole({
      variables: {
        roleName,
        userId,
        groupId,
        inviteId,
      },
      refetchQueries: ["SpendInvitesFiltered"],
      fetchPolicy: "network-only",
    });
  };

  const handleApproverSettingUpdate = (
    userId: string,
    type: string,
    isEnabled: boolean,
    groupId?: string
  ) => {
    updateApproverSetting({
      variables: {
        input: {
          userId,
          type,
          isEnabled,
          groupId,
        },
      },
    });
  };

  const handleRemoveUser = (idx: number) => {
    const userToRemove = staff[idx];
    archiveUser({
      variables: {
        spendUserInviteArchiveId: userToRemove.id,
      },
    });
  };

  const getBadge = (status: string) => {
    return (
      <SnapBadge
        color={getBadgeColor(inviteStatusToText(status))}
        className="ml-2 capitalize"
      >
        {inviteStatusToText(status)}
      </SnapBadge>
    );
  };

  const handleCheckboxAction = (idx: number, data: InvitePlus) => {
    let newCollectionItem: InvitePlus = {
      ...data,
      isChecked: !data.isChecked,
    };

    let currentlySelectedItems = [...selectedItems];
    if (newCollectionItem.isChecked) {
      currentlySelectedItems.push(newCollectionItem);
    } else {
      let idx = currentlySelectedItems.findIndex(
        (i) => i.id === newCollectionItem.id
      );
      currentlySelectedItems.splice(idx, 1);
    }
    setSelectedItems(currentlySelectedItems);

    staff.splice(idx, 1, newCollectionItem);
    setStaff(staff);
    setNameBoxChecked(hasAllById(currentlySelectedItems, staff));
  };

  const handleDeselectAll = () => {
    const tempUsers = [...staff];
    let updatedTempUsers = tempUsers.map((invite) => {
      return {
        id: invite.id,
        isChecked: false,
        firstName: invite.firstName,
        lastName: invite.lastName,
        email: invite.email,
        type: invite.type,
        status: invite.status,
        group: invite.group,
        debitCard: invite.debitCard,
        hasWarning: invite.hasWarning,
      };
    });
    setStaff(updatedTempUsers);
    setSelectedItems([]);
    setNameBoxChecked(false);
  };

  const handleChangeFilter = (e: CustomEvent) => {
    let selectedFilter = e.detail.value;
    setStatus(selectedFilter === "-all-" ? "" : selectedFilter);
  };

  const canUpdateGroupUser =
    user.checkSpendPermission(SpendPermissions.groupAdminsUpdate) ?? false;

  const menuItems: DropdownMenuItem[] = canUpdateGroupUser
    ? [
        {
          name: "Remove User",
          text: "Remove User",
          value: "Remove User",
        },
        { text: "Change Role", name: "Change Role", value: "changeRole" },
      ]
    : [];
  return (
    <div className="wrapper">
      <div className="card">
        <div className="flex border-2 border-gray-200 w-full rounded-xl py-1 px-2 gap-2">
          <SnapIcon icon="search-solid" size="sm" color="#475569" />
          <input
            type="text"
            placeholder="Search"
            onChange={setDebounceSearch}
            className="w-full outline-none"
          />
        </div>
        <Filter
          dropdownOptions={FILTER_INVITE_OPTIONS}
          handleSelect={(e) => handleChangeFilter(e)}
          className="lg:mt-4 mb-6 mt-8"
        />
        <Divider isVisibleOnMobile />
        <div className="text-lg font-semibold mt-4">Group Users Overview</div>
        <ShowingResults
          totalNumOfResults={total}
          numOfResultsBeingDisplayed={staff.length <= 10 ? staff.length : 10}
          startingNumOfResults={staff.length === 0 ? 0 : 1}
          hasCheckbox={canUpdateGroupUser}
          hideCheckboxOnWeb
          isNameBoxChecked={isNameBoxChecked}
          checkboxAction={(e) => {
            setNameBoxChecked(e.currentTarget.checked);
            HandleAllCheckboxAction(
              isNameBoxChecked,
              staff,
              setStaff,
              setSelectedItems,
              selectedItems
            );
          }}
        />
        <Divider isVisibleOnMobile className="lg:hidden mt-2 mb-5" />
        {!display?.isDesktop && (
          <Sort
            selectedSortOption={""}
            options={[
              { name: "Name", text: "Name", value: "name" },
              { name: "Email", text: "Email", value: "email" },
              { name: "Role", text: "Role", value: "type" },
              { name: "Group", text: "Group", value: "group" },
              { name: "Status", text: "Status", value: "status" },
            ]}
            handleSort={handleSort}
          />
        )}

        <div className="relative">
          {!display?.isDesktop ? (
            <Cards
              teamUsers={staff}
              loading={loading}
              handleCheckboxAction={handleCheckboxAction}
              handleRemoveUser={handleRemoveUser}
              menuItems={menuItems}
              changeRoleToggle={changeRoleToggle}
              setMenuClickData={setMenuClickData}
              canUpdateGroupUser={canUpdateGroupUser}
              enableApproverToggle={enableApproverToggle}
              disableApproverToggle={disableApproverToggle}
            />
          ) : (
            <Table
              teamUsers={staff}
              loading={loading}
              handleCheckboxAction={handleCheckboxAction}
              handleRemoveUser={handleRemoveUser}
              menuItems={menuItems}
              getBadge={getBadge}
              setTeamUsers={setStaff}
              isNameBoxChecked={isNameBoxChecked}
              setNameBoxChecked={setNameBoxChecked}
              sort={sort}
              toggleSort={toggleSort}
              changeRoleToggle={changeRoleToggle}
              setMenuClickData={setMenuClickData}
              canUpdateGroupUser={canUpdateGroupUser}
              setSelectedItems={setSelectedItems}
              selectedItems={selectedItems}
              enableApproverToggle={enableApproverToggle}
              disableApproverToggle={disableApproverToggle}
            />
          )}
        </div>
        <SnapPagination
          currentPage={page}
          itemCount={total}
          pageSize={ITEMS_PER_PAGE}
          onSnap-pagination-page-changed={(e) => {
            setNameBoxChecked(false);
            setPage(e.detail);
          }}
        />
      </div>
      {selectedItems.length > 0 && (
        <BulkActionsV2
          selectedItems={selectedItems.map((item) => {
            return {
              email: item.email ?? undefined,
              userId: item.userId ?? undefined,
              inviteId: item.id,
              groupId: item.groupId ?? undefined,
            };
          })}
          handleDeselectAll={handleDeselectAll}
          type={"groups"}
        />
      )}
      {changeRoleOpen && (
        <ChangeUserRoleModal
          isOpen={changeRoleOpen}
          toggle={changeRoleToggle}
          data={menuClickData!}
          handleRoleChange={handleRoleChange}
          allGroups={allGroups}
          isBtnActive={isBtnActive}
          setIsBtnActive={setIsBtnActive}
        />
      )}

      {enableApproverOpen && (
        <EnableApprover
          isEnableApproverOpen={enableApproverOpen}
          enableApproverToggle={enableApproverToggle}
          data={menuClickData!}
          handleApproverSettingUpdate={handleApproverSettingUpdate}
        />
      )}
      {disableApproverOpen && (
        <DisableApprover
          disableApproverOpen={disableApproverOpen}
          disableApproverToggle={disableApproverToggle}
          data={menuClickData!}
          handleApproverSettingUpdate={handleApproverSettingUpdate}
        />
      )}
    </div>
  );
}

export default Group;
