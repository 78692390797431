import DataCard from "shared-components/data-card";
import { useContextStrict } from "helpers/context-strict";
import GroupContext from "context/group-context";
import { useLocation, useNavigate } from "react-router-dom";
import { LabelValueObject } from "types/label-value-object";
import { SpinnerContainer } from "shared-components/spinner";
import { RosterDataType } from "types/group-roster";

type CardProps = {
  rosterData: RosterDataType[];
  canEditParticipant: boolean;
  isArchived: boolean;
  handleSelectall: (
    selectedItems: RosterDataType[],
    selectAll: boolean,
    isChecked: boolean
  ) => void;
  allSelected: boolean;
  queryLoading?: boolean;
  handleRosterDataInviteStatus: (rosterDara: RosterDataType) => string;
  prepRightData: (
    rosterData: RosterDataType,
    status: string
  ) => LabelValueObject[];
};

function Cards({
  rosterData,
  canEditParticipant,
  isArchived,
  handleSelectall,
  allSelected,
  queryLoading,
  handleRosterDataInviteStatus,
  prepRightData,
}: CardProps) {
  const group = useContextStrict(GroupContext);

  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="mb-4">
      {!queryLoading ? (
        rosterData.map((item: RosterDataType) => {
          let status = handleRosterDataInviteStatus(item);
          return (
            <DataCard
              key={item.id}
              hasCheckbox={canEditParticipant && !isArchived}
              isChecked={item.isChecked || allSelected}
              checkboxAction={(e) =>
                handleSelectall([item], false, e.currentTarget.checked)
              }
              title={item.roster?.name ?? ""}
              titleAction={() => {
                group?.setParticipant(item.roster?.name ?? "");
                if (location.pathname.startsWith("/groups")) {
                  navigate(`/groups/participant-details/${item.roster?.id}`);
                } else {
                  navigate(`/group/participant-details/${item.roster?.id}`);
                }
              }}
              kvLeft={[]}
              kvRight={prepRightData(item, status)}
              action={0}
              className={`flex ${
                item.isChecked && "bg-blue-50 border-blue-400"
              }`}
            />
          );
        })
      ) : (
        <SpinnerContainer loading={queryLoading} />
      )}
    </div>
  );
}

export default Cards;
