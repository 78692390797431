import FixedModal from "shared-components/fixed-modal/fixed-modal";
import { BtnState } from "shared-components/modal";

type ApproveType = {
  isOpen: boolean;
  toggle: () => void;
  handleApprove: (status: "Approved" | "Declined") => void;
  type: string;
  btnState: BtnState;
};

function Approve({
  isOpen,
  toggle,
  handleApprove,
  type,
  btnState,
}: ApproveType) {
  return (
    <FixedModal
      isOpen={isOpen}
      toggle={toggle}
      title={`Approve ${type} Request`}
      icon="check-solid"
      iconColor="#D1FAE5"
      btn1={{
        text: "Approve",
        onClick: () => {
          handleApprove("Approved");
        },
        btnState,
      }}
      btn2={{
        text: "Cancel",
        onClick: toggle,
      }}
    >
      <p>Are you sure you want to approve this activity?</p>
    </FixedModal>
  );
}

export default Approve;
