import { SnapSelectMenuOption } from "@snap-mobile/snap-ui/dist/types/utils";
import SeasonContext from "context/season-context";
import {
  SpendPaymentSchedule,
  useSpendPaymentScheduleUpdateMutation,
} from "graphql/generated";
import { DateFormatSupported, FormatDate } from "helpers/format-date";
import { FormatMoney } from "helpers/format-money";
import { useContext, useEffect, useState } from "react";
import Divider from "shared-components/divider";
import Spinner from "shared-components/spinner";
import { SnapIcon, SnapSelectMenu } from "suit";
import { SpendPaymentScheduleWithFees } from "../../participants-roster";
import { PAYMENT_SCHEDULE_BY_GROUP_OR_SEASON } from "graphql/queries/payment-schedule";
import DatePicker from "shared-components/date-picker";
import { getDatePickerValue, setDatePickerValue } from "helpers/date-picker";

type PaymentScheduleCardProps = {
  invoices: SpendPaymentScheduleWithFees[];
  categories: SnapSelectMenuOption[];
  isEdit: boolean;
  setInvoices?: React.Dispatch<
    React.SetStateAction<SpendPaymentScheduleWithFees[]>
  >;
};

function PaymentScheduleCard({
  invoices,
  categories,
  isEdit,
  setInvoices,
}: PaymentScheduleCardProps) {
  const Season = useContext(SeasonContext);
  const [idIsLoading, setIdIsLoading] = useState<string | undefined>();
  const [updatePSI] = useSpendPaymentScheduleUpdateMutation({
    refetchQueries: [
      "SpendGroupRostersBySeason",
      {
        query: PAYMENT_SCHEDULE_BY_GROUP_OR_SEASON,
        variables: {
          seasonId: Season?.selectedSeason?.id,
          groupIdOrSeasonId: Season?.selectedSeason?.id,
        },
        fetchPolicy: "network-only",
      },
      "SpendOrganizationGroupsCategories",
    ],
  });
  const saveInvoice = (invoice: Partial<SpendPaymentSchedule>) => {
    if (!invoice.id) {
      console.error("Trying to save invoice without an Id", invoice);
      return;
    }
    updatePSI({
      variables: {
        spendPaymentScheduleUpdateId: invoice.id,
        input: {
          dueDate: invoice.dueDate && new Date(invoice.dueDate).toISOString(),
          budgetItemId: invoice.budgetItemId,
          status: invoice.status,
          seasonId: Season?.selectedSeason?.id,
        },
      },
    }).then(() => {
      Season?.requestSeason();
    });
  };
  useEffect(() => {
    setIdIsLoading(undefined);
  }, [invoices]);
  return (
    <div>
      {invoices.length === 0 && (
        <p>Payment schedule empty, add invoices below.</p>
      )}
      {invoices.map((invoice, idx) => {
        let options: SnapSelectMenuOption[] = categories.map(
          (cat: SnapSelectMenuOption) => {
            return {
              ...cat,
              selected: invoice.budgetItemId === cat.value,
            };
          }
        );
        const cellStyleOverride = {
          backgroundColor: `${invoice.status === "deleted" ? "#FFAAAA50" : ""}`,
        };
        return (
          <div
            className="lg:hidden border border-gray-200 rounded-lg p-4 first:mt-0 mt-4"
            style={cellStyleOverride}
            key={`${invoice?.id}_${idx}`}
          >
            {isEdit ? (
              <>
                <div className="flex justify-between gap-4">
                  <div>
                    <p className="texet-sm font-medium">
                      {invoice.description}
                    </p>
                    <p className="text-xs font-semibold text-gray-500">
                      {invoice.note}
                    </p>
                  </div>
                  {idIsLoading === invoice.id && <Spinner size={"small"} />}
                  {idIsLoading !== invoice.id && (
                    <SnapIcon
                      icon={`${
                        invoice.status === "deleted"
                          ? "refresh-line"
                          : "trash-solid"
                      }`}
                      color="#2563EB"
                      className="cursor-pointer"
                      onClick={() => {
                        if (!idIsLoading) {
                          setIdIsLoading(invoice.id || undefined);
                          const status = ["draft", "new"].includes(
                            invoice.status || ""
                          )
                            ? "deleted"
                            : "draft";
                          saveInvoice({ ...invoice, status });
                        }
                      }}
                    />
                  )}
                </div>
                <Divider isVisibleOnMobile />
                <DatePicker
                  className="mt-4"
                  label={"Due Date"}
                  date={getDatePickerValue(invoice.dueDate ?? "")}
                  setDate={(e) => {
                    const newDate = setDatePickerValue(e);
                    saveInvoice({
                      id: invoice.id,
                      seasonId: invoice.seasonId,
                      dueDate: newDate,
                    });
                    invoices.splice(idx, 1, {
                      ...invoice,
                      dueDate: newDate,
                    });
                    setInvoices && setInvoices([...invoices]);
                  }}
                />
                <SnapSelectMenu
                  className="flex flex-col mt-4"
                  label="Reconcile To"
                  placeholder="Select a budget"
                  disabled={invoice.status === "deleted"}
                  selectMenuOptions={[...options]}
                  error={invoice.hasError}
                  onSnap-select-menu-updated={(e) => {
                    const selected = e.detail.find((option) => option.selected);
                    saveInvoice({
                      id: invoice.id,
                      seasonId: invoice.season?.id,
                      dueDate: invoice.dueDate,
                      budgetItemId: selected?.value,
                    });
                    invoices.splice(idx, 1, {
                      ...invoice,
                      hasError: false,
                    });
                    setInvoices && setInvoices([...invoices]);
                  }}
                />
                <Divider isVisibleOnMobile />
                <div className="flex mt-3">
                  <p className="mr-auto text-xs font-bold text-gray-500">
                    Collecting
                  </p>
                  <p className="text-sm font-medium text-green-600">
                    {FormatMoney(
                      invoice.received
                        ? invoice.received
                        : invoice.amountDue ?? 0
                    )}
                  </p>
                </div>
                <div className="flex mt-3">
                  <p className="mr-auto text-xs font-bold text-gray-500">
                    Fees
                  </p>
                  <p className="text-sm font-medium">
                    {FormatMoney(invoice.feeAmount ? invoice.feeAmount : 0)}
                  </p>
                </div>
                <div className="flex mt-3">
                  <p className="mr-auto text-xs font-bold text-gray-500">
                    Guardian Pays
                  </p>
                  <p className="text-sm font-medium">
                    {invoice.amountDue && FormatMoney(invoice.amountDue)}
                  </p>
                </div>
                <div className="flex mt-3">
                  <p className="mr-auto text-xs font-bold text-gray-500">
                    Optional?
                  </p>
                  <p className="text-sm font-medium">
                    {invoice.isOptional ? "Optional" : "Required"}
                  </p>
                </div>
              </>
            ) : (
              <>
                <p className="text-xs font-bold text-gray-500">Description</p>
                <p className="texet-sm font-medium">{invoice.description}</p>
                <p className="text-xs font-semibold text-gray-500">
                  {invoice.note}
                </p>
                <Divider isVisibleOnMobile className="mt-3" />
                <div className="flex mt-3">
                  <p className="mr-auto text-xs font-bold text-gray-500">
                    Due Date
                  </p>
                  <p className="text-sm font-medium">
                    {invoice.dueDate
                      ? FormatDate(invoice.dueDate, DateFormatSupported.Numbers)
                      : "No Date"}
                  </p>
                </div>
                <div className="flex mt-3">
                  <p className="mr-auto text-xs font-bold text-gray-500">
                    You Receive
                  </p>
                  <p className="text-sm font-medium">
                    {FormatMoney(
                      invoice.received
                        ? invoice.received
                        : invoice.amountDue ?? 0
                    )}
                  </p>
                </div>
                <div className="flex mt-3">
                  <p className="mr-auto text-xs font-bold text-gray-500">
                    Fees
                  </p>
                  <p className="text-sm font-medium">
                    {FormatMoney(invoice.feeAmount ? invoice.feeAmount : 0)}
                  </p>
                </div>
                <div className="flex mt-3">
                  <p className="mr-auto text-xs font-bold text-gray-500">
                    Guardian Pays
                  </p>
                  <p className="text-sm font-medium">
                    {invoice.amountDue && FormatMoney(invoice.amountDue)}
                  </p>
                </div>
                <div className="flex mt-3">
                  <p className="mr-auto text-xs font-bold text-gray-500">
                    Optional?
                  </p>
                  <p className="text-sm font-medium">
                    {invoice.isOptional ? "Optional" : "Required"}
                  </p>
                </div>
              </>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default PaymentScheduleCard;
