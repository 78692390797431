import {
  DropdownMenuItem,
  SnapSelectMenuOption,
} from "@snap-mobile/snap-ui/dist/types/utils";
import { ITEMS_PER_PAGE } from "../../constants";
import DisplayContext from "context/display-context";
import {
  ApprovalType,
  useSpendGetApprovalsByTabQuery,
} from "graphql/generated";
import { DateFormatSupported, FormatDate } from "helpers/format-date";
import { FormatMoney } from "helpers/format-money";
import { getBadgeColor } from "helpers/status-color";
import useModal from "hooks/use-modal";
import { useContext, useEffect, useState } from "react";
import DataCard from "shared-components/data-card";
import Divider from "shared-components/divider";
import CustomModal from "shared-components/modal";
// import { SearchInput } from "shared-components/search-input";
import ShowingResults from "shared-components/showing-results";
import {
  SnapBadge,
  SnapButton,
  SnapDropDown,
  SnapIcon,
  SnapInput,
  SnapPagination,
  SnapSelectMenu,
} from "suit";
import { LabelValueObject } from "types/label-value-object";
import { useSpendPagination } from "hooks/use-spend-pagination";
import DatePicker from "shared-components/date-picker";
import { getDatePickerValue, setDatePickerValue } from "helpers/date-picker";
import Details from "./modals/details";
import PageLimit from "shared-components/page-limit";
import ToastContext from "context/toast-context";

function History() {
  const display = useContext(DisplayContext);
  const toast = useContext(ToastContext);

  const { isOpen: isMoreFiltersOpen, toggle: moreFiltersToggle } = useModal();
  const { isOpen: isDetailsOpen, toggle: detailsToggle } = useModal();
  const {
    sort,
    sortValue: currentSort,
    handleSortValue,
  } = useSpendPagination();

  const [selectedApproval, setSelectedApproval] = useState<ApprovalType>();
  const [startDate, setStartDate] = useState<string | undefined>(undefined);
  const [endDate, setEndDate] = useState<string | undefined>(undefined);
  const [startDateFilter, setStartDateFilter] = useState<string | undefined>(
    undefined
  );
  const [endDateFilter, setEndDateFilter] = useState<string | undefined>(
    undefined
  );
  const [pageLimit, setPageLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [approvals, setApprovals] = useState<ApprovalType[]>([]);
  const [approvalCount, setApprovalCount] = useState(0);
  const [selectedGroup, setSelectedGroup] = useState<string | undefined>();
  const [selectedStaff, setSelectedStaff] = useState<string | undefined>();
  // const [searchText, setSearchText] = useState("");
  const [selectedFilters, setSelectedFilters] = useState<string[] | undefined>(
    undefined
  );
  const [groupOptions, setGroupOptions] = useState<SnapSelectMenuOption[]>([
    {
      name: "All",
      selected: true,
    },
  ]);
  const [staffOptions, setStaffOptions] = useState<SnapSelectMenuOption[]>([]);
  const [options] = useState<DropdownMenuItem[]>([
    {
      name: "Date Approved: New to Old",
      text: "Date Approved: New to Old",
      value: "dateApproved:DESC",
      selected: currentSort === "dateApproved:DESC",
    },
    {
      name: "Date Approved: Old to New",
      text: "Date Approved: Old to New",
      value: "dateApproved:ASC",
      selected: currentSort === "dateApproved:ASC",
    },
    {
      name: "Date Submitted: New to Old",
      text: "Date Submitted: New to Old",
      value: "date:DESC",
      selected: currentSort === "date:DESC",
    },
    {
      name: "Date Submitted: Old to New",
      text: "Date Submitted: Old to New",
      value: "date:ASC",
      selected: currentSort === "date:ASC",
    },
    {
      name: "Activity Type",
      text: "Activity Type",
      value: "type:ASC",
      selected: currentSort === "type:ASC",
    },
    {
      name: "Group",
      text: "Group",
      value: "group:ASC",
      selected: currentSort === "group:ASC",
    },
  ]);

  const { loading: loadingApprovals, data: approvalData } =
    useSpendGetApprovalsByTabQuery({
      variables: {
        input: {
          tab: "history",
          sort,
          filter: {
            type: selectedFilters,
            groupName: selectedGroup,
            staffName: selectedStaff,
            date: {
              startDate: startDateFilter,
              endDate: endDateFilter,
            },
          },
          pagination: {
            limit: pageLimit,
            offset: page * pageLimit,
          },
        },
      },
    });

  useEffect(() => {
    if (!loadingApprovals && approvalData?.spendGetApprovalsByTab) {
      setApprovalCount(approvalData.spendGetApprovalsByTab.approvalCount);
      setApprovals(approvalData.spendGetApprovalsByTab.approvals ?? []);
      setGroupOptions([
        ...(approvalData.spendGetApprovalsByTab.groups?.map((group) => {
          return {
            name: group,
            selected: false,
          };
        }) ?? []),
      ]);
      setStaffOptions(
        approvalData.spendGetApprovalsByTab.staff?.map((staffName) => {
          return {
            name: staffName,
            selected: false,
          };
        }) ?? []
      );
    }
  }, [loadingApprovals, approvalData]);

  const activeStyle = "bg-blue-100";
  const sharedFilterStyle = `flex justify-center border border-gray-200 py-2 lg:px-6 text-sm rounded-lg mr-3 hover:${activeStyle}`;

  const handleFilterSelect = (selectedItem: string) => {
    const selected = selectedFilters?.includes(selectedItem);
    let tempItems = [...(selectedFilters ?? [])];
    if (selected) {
      tempItems = tempItems.filter((i) => i !== selectedItem);
    } else {
      tempItems.push(selectedItem);
    }
    if (tempItems.length) {
      setSelectedFilters(tempItems);
    } else {
      setSelectedFilters(undefined);
    }
  };

  const prepTitleExtra = (status: string) => {
    return (
      <div className="flex">
        <SnapBadge color={getBadgeColor(status)} className="ml-5">
          {status}
        </SnapBadge>
      </div>
    );
  };
  const prepLeftData = (approval: ApprovalType) => {
    const items: LabelValueObject[] = [];
    const leftKeys = ["assignee", "type", "receipent"];
    const leftItems = Object.entries(approval).filter(
      (item) => !!item[1] && leftKeys.includes(item[0])
    );
    leftItems.forEach((li) => {
      items.push({
        key: li[0],
        value: li[1]?.toString()!,
        customContainerStyle: "capitalize",
      });
    });

    items.push({
      key: "Submitted by",
      value: approval.submittedBy,
    });
    return items;
  };
  const prepRightData = (approval: ApprovalType) => {
    const items: LabelValueObject[] = [];
    const rightKeys = ["group", "amount"];
    const rightItems = Object.entries(approval).filter(
      (item) => !!item[1] && rightKeys.includes(item[0])
    );
    rightItems.forEach((ri) => {
      items.push({
        key: ri[0],
        value:
          ri[0] === "amount" ? FormatMoney(Number(ri[1])) : ri[1]?.toString()!,
        customContainerStyle: "capitalize",
      });
    });
    items.push({
      key: "Date Submitted",
      value: FormatDate(approval.createdAt, DateFormatSupported.Numbers),
    });
    return items;
  };

  const handleApplyFilters = () => {
    const foundGroup = groupOptions.find((g) => g.selected);
    const foundStaff = staffOptions.find((s) => s.selected);
    setSelectedGroup(foundGroup?.name);
    setSelectedStaff(foundStaff?.name);
    if (new Date(startDate ?? "") > new Date(endDate ?? "")) {
      toast?.setToast({
        message: "End Date cannot be before start date",
        type: "danger",
      });
    } else {
      setStartDateFilter(startDate);
      setEndDateFilter(endDate);
    }
  };

  const handleResetFilter = () => {
    setGroupOptions(
      groupOptions.map((g) => {
        return {
          ...g,
          selected: false,
        };
      })
    );
    setStaffOptions(
      staffOptions.map((s) => {
        return {
          ...s,
          selected: false,
        };
      })
    );
    setSelectedGroup(undefined);
    setSelectedStaff(undefined);
    setStartDate(undefined);
    setEndDate(undefined);
    setStartDateFilter(undefined);
    setEndDateFilter(undefined);
  };

  return (
    <div className="mt-4">
      {/* <SearchInput setSearchValue={setSearchText} /> */}
      <div className="lg:flex">
        <div className="grid lg:grid-cols-4 grid-cols-2 cursor-pointer">
          <p
            onClick={() => handleFilterSelect("transfer")}
            className={`${sharedFilterStyle} ${
              selectedFilters?.includes("transfer") && activeStyle
            }`}
          >
            Transfers
          </p>
          <p
            onClick={() => handleFilterSelect("send-check")}
            className={`${sharedFilterStyle} ${
              selectedFilters?.includes("send-check") && activeStyle
            }`}
          >
            Send Checks
          </p>
          <p
            onClick={() => handleFilterSelect("send-ach")}
            className={`lg:mt-0 mt-2 ${sharedFilterStyle} ${
              selectedFilters?.includes("send-ach") && activeStyle
            }`}
          >
            Send ACHs
          </p>
          <p
            onClick={() => handleFilterSelect("debit-card")}
            className={`lg:mt-0 mt-2 ${sharedFilterStyle} ${
              selectedFilters?.includes("debit-card") && activeStyle
            }`}
          >
            Debit Cards
          </p>
        </div>
        {display?.isMobile && <Divider isVisibleOnMobile />}
        <div
          className="flex items-center lg:mt-0 mt-4 cursor-pointer"
          onClick={() => {
            moreFiltersToggle();
          }}
        >
          <SnapIcon icon="filter-solid" size="sm" color="#3B82F6" />
          <p className="ml-1 text-blue-500 font-bold">
            {display?.isDesktop
              ? isMoreFiltersOpen
                ? "Less "
                : "More "
              : null}
            Filters
          </p>
          {display?.isDesktop && (
            <SnapIcon
              icon={isMoreFiltersOpen ? "chevron-up-line" : "chevron-down-line"}
              size="sm"
              color="#3B82F6"
            />
          )}
        </div>
      </div>
      {display?.isDesktop && isMoreFiltersOpen && (
        <>
          <div className="grid grid-cols-4 gap-4 mt-6">
            <DatePicker
              label={"Start Date"}
              date={getDatePickerValue(startDate ?? "")}
              setDate={(e) => {
                const newDate = setDatePickerValue(e);
                setStartDate(newDate);
              }}
            />
            <DatePicker
              label={"End Date"}
              date={getDatePickerValue(endDate ?? "")}
              setDate={(e) => {
                const newDate = setDatePickerValue(e);
                setEndDate(newDate);
              }}
            />
            <SnapSelectMenu
              label="Groups"
              selectMenuOptions={groupOptions}
              placeholder="Select Group"
              onSnap-select-menu-updated={(e) => {
                setGroupOptions(e.detail);
              }}
            />
            <SnapSelectMenu
              label="Staff"
              selectMenuOptions={staffOptions}
              placeholder="Select Staff"
              onSnap-select-menu-updated={(e) => {
                setStaffOptions(e.detail);
              }}
            />
          </div>
          <div className="mt-4 flex justify-end gap-4">
            <SnapButton onClick={handleResetFilter}>Reset Filters</SnapButton>
            <SnapButton variant="primary" onClick={handleApplyFilters}>
              Apply Filters
            </SnapButton>
          </div>
        </>
      )}

      <Divider />
      <h2 className="text-lg font-semibold mt-4">Completed Approvals</h2>
      <div className="lg:flex">
        <ShowingResults
          totalNumOfResults={approvalCount}
          numOfResultsBeingDisplayed={
            approvals.length <= ITEMS_PER_PAGE
              ? approvals.length
              : ITEMS_PER_PAGE * page + ITEMS_PER_PAGE >= approvals.length
              ? approvals.length
              : ITEMS_PER_PAGE * page + ITEMS_PER_PAGE
          }
          startingNumOfResults={
            approvals.length === 0 ? 0 : ITEMS_PER_PAGE * page + 1
          }
        />
        <PageLimit
          setPageLimit={setPageLimit}
          setPage={setPage}
          localStorageName={"participant-page-limit"}
        />
      </div>
      <Divider isVisibleOnMobile />
      <div className="flex my-4">
        <p className="text-sm text-gray-500 mr-2">Sort</p>
        <SnapDropDown
          leftHang
          defaultValue={currentSort}
          options={options}
          modalType="drawer"
          onSnap-dropdown-item-selected={(e) => handleSortValue(e.detail.value)}
          className="flex items-center"
        />
      </div>
      {approvals.map((approval, idx) => {
        return (
          <DataCard
            key={approval.title + idx}
            title={approval.title.replace("-", " ")}
            titleExtra={prepTitleExtra(approval.status)}
            titleAction={() => {
              setSelectedApproval(approval);
              detailsToggle();
            }}
            kvLeft={prepLeftData(approval)}
            kvRight={prepRightData(approval)}
          />
        );
      })}
      <SnapPagination
        currentPage={page}
        itemCount={approvalCount}
        pageSize={pageLimit}
        onSnap-pagination-page-changed={(e) => {
          setPage(e.detail);
        }}
      />
      {display?.isMobile && (
        <CustomModal
          isOpen={isMoreFiltersOpen}
          toggle={moreFiltersToggle}
          title="Filters"
          btn1={{
            text: "Apply Filters",
          }}
          btn2={{
            text: "Reset Filters",
          }}
        >
          <div className="modal-card flex flex-col">
            <SnapInput
              _id="Date Input"
              _type="date"
              label="Start Date"
              className="mt-4"
            />
            <SnapInput
              _id="Date Input"
              _type="date"
              label="End Date"
              className="mt-4"
            />
            <SnapSelectMenu
              label="Groups"
              selectMenuOptions={groupOptions}
              placeholder="Select Group"
              className="mt-4"
            />
            <SnapSelectMenu
              label="Staff"
              selectMenuOptions={staffOptions}
              placeholder="Select Staff"
              className="mt-4"
            />
          </div>
        </CustomModal>
      )}
      {isDetailsOpen && selectedApproval && (
        <Details
          isOpen={isDetailsOpen}
          toggle={detailsToggle}
          selectedApproval={selectedApproval}
          type="history"
        />
      )}
    </div>
  );
}

export default History;
