import { FullStory } from "@fullstory/browser";
import { getAuthTokenInfo, removeAuthToken } from "helpers/get-auth-token-data";
import useDisplay from "hooks/use-display";
import useGroup from "hooks/use-group";
import useModal from "hooks/use-modal";
import useProgram from "hooks/use-program";
import ApplicationStatus from "pages/application-status";
import DisplayInviteFlow from "pages/parent-sign-up/display-flow";
import DisplaySignupFlow from "pages/parent-sign-up/display-sign-up-flow";
import { ReactNode, useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "shared-components/header/Header";
import MainNavigation from "shared-components/main-navigation/MainNavigation";
import Deposit from "shared-components/banking/deposit";
import Authorization from "shared-components/fixed-modal/authorization";
import FixedModal from "shared-components/fixed-modal/fixed-modal";
import Footer from "shared-components/footer";
import { SnapLoader } from "shared-components/snap-loader";
import "./App.css";
import UserContext from "./context/user-context";
import { useApiStatusQuery } from "./graphql/generated";
import { redirectToSso } from "./helpers/sso";
import useUser, { ActiveUser } from "./hooks/use-user";
import UnAuthorized from "pages/unauthorized-user";
import ExpiredInvite from "pages/expired-invite";
import ApplicationForm from "pages/application-form";
import ChatWidgetController from "shared-components/widgets/hubspot-widget-controller";
import { ProductFruits } from "react-product-fruits";
import Providers from "App.provider";
import ToastContext from "context/toast-context";
import useToast from "hooks/use-toast";

function App({ updateRoutes }: { updateRoutes?: (user: ActiveUser) => void }) {
  const location = useLocation();

  const user = useUser();
  const program = useProgram(user);
  const toast = useToast();
  const group = useGroup(user);
  const display = useDisplay();
  const { data: apiStatus } = useApiStatusQuery();
  const [hasUnitAuthorized, setHasUnitAuthorized] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [isUserReady, setIsUserReady] = useState(false);

  const [userHasApplicationInProgress, setUserHasApplicationInProgress] =
    useState(false);
  const [userHasExpiredInvite, setUserHasExpiredInvite] = useState(false);
  const [userHasRole, setHasUserRole] = useState(false);
  const [userHasCompletedUnitApplication, setUserHasCompletedUnitApplication] =
    useState(false);
  const [userUnauthorized, setUserUnauthorized] = useState(false);
  const [parentHasInviteInProgress, setParentHasInviteInProgress] =
    useState(false);
  const [parentSignup, setParentSignup] = useState(false);
  const { isOpen: isSignoutOpen, toggle: signoutToggle } = useModal();

  useEffect(() => {
    setUserHasApplicationInProgress(location.pathname.includes("/application"));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (user._session) {
      if (user._session?.role == null && user._session?.message == null) {
        //IF THE USER DOESNT HAVE A ROLE - ORG APP NOT APPROVED
        // AND DOES NOT HAVE A MESSAGE - THEY HAVE TO START OR THEY HAVE AN APPLICATION IN PROGRESS
        setUserUnauthorized(true);
        return;
      }
      //IF THE USER DOES NOT HAVE A ROLE - ORG APP IS NOT APPROVED
      // BUT IF THEY HAVE A MESSAGE - THEY HAVE COMPLETED THE ORG APPLICATION FORM
      //SEE ORG COMPLETED FORM TO SEE STATUS OF FORM
      if (user._session?.role == null && user._session?.message != null) {
        setUserHasCompletedUnitApplication(true);
        return;
      }
      let { token, tokenExpires, hex } = getAuthTokenInfo();
      if (token) {
        const hash = btoa(user._session.userId ?? "");
        if (
          new Date().getTime() < new Date(tokenExpires).getTime() &&
          hex === hash
        ) {
          setHasUnitAuthorized(true);
          setIsLoading(false);
        } else {
          removeAuthToken();
          setHasUnitAuthorized(false);
        }
      } else {
        setHasUnitAuthorized(false);
      }
      setIsUserReady(true);
      FullStory("setProperties", {
        type: "page",
        properties: {
          platform: "spend",
          user: user._session?.userId,
          group: group.activeGroup?.id,
          program: program.organization?.id,
        },
      });
    } else {
      if (user._user?.me === undefined || user._session === undefined) {
        setUserUnauthorized(false);
        setHasUnitAuthorized(false);
        return;
      }

      const timer = setTimeout(() => {
        redirectToSso();
      }, 500);
      return () => clearTimeout(timer);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user._session, user._user?.me]);

  useEffect(() => {
    //IF INVITE IS EXPIRED
    setUserHasExpiredInvite(
      user._session?.status === "expired" && !user._session.isDismissed
    );
    //IF USER HAS A ROLE
    setHasUserRole(user._session?.role ? true : false);

    //IS USER HAS A ROLE OF GUARDIAN - THEY ARE A PARENT
    //AND THEY HAVE A STATUS OF 'not_signed_up' - THEY HAVE NOT COMPLETED SIGN UP FLOW
    const signUpOptions = ["not_signed_up", "alternate_signup"];

    if (user._session?.role?.name && user._session.status) {
      setParentHasInviteInProgress(
        user._session.role.name === "guardian" &&
          signUpOptions.includes(user._session.status)
      );
    } else {
      setParentHasInviteInProgress(false);
    }
    setParentSignup(location.pathname.includes("/signup/parents"));

    const role = user?.getRole();
    if (role && updateRoutes) {
      updateRoutes(user);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserReady]);

  useEffect(() => {
    if (user._session?.role?.name === "guardian" || user.isImpersonating()) {
      setHasUnitAuthorized(true);
      setIsLoading(false);
    }
  }, [user, user._session?.role]);

  const userInfo = {
    username: user._user?.me?.id ?? "",
    email: user?._user?.me?.email ?? "",
    firstname: user._user?.me?.firstName ?? "",
    lastname: user._user?.me?.lastName ?? "",
    signUpAt: "signUpAt",
    role: "user",
  };

  const renderMain = (): ReactNode => {
    if (display.isDepositCheckOpen) {
      return <Deposit />;
    }

    return (
      <div className="App relative">
        <Header />
        <MainNavigation
          isAuthorized={hasUnitAuthorized}
          userHasExpiredInvite={userHasExpiredInvite}
        ></MainNavigation>
        <main
          className="pt-11 left-0 right-0 lg:pb-[35px] md:ml-[90px] lg:ml-[285px] bg-gray-100"
          style={{ minHeight: "90vh" }}
        >
          {isLoading && <AppLoading />}
          {userUnauthorized && <UnAuthorized setIsLoading={setIsLoading} />}
          {userHasCompletedUnitApplication && (
            <ApplicationStatus
              status={user?._session?.status}
              message={user?._session?.message!}
              setIsLoading={setIsLoading}
              applicationURL={user._session?.url}
            />
          )}
          {isUserReady && userHasRole && (
            <>
              {parentSignup ? ( //Sussy behavior here
                <FixedModal
                  isOpen={isSignoutOpen}
                  toggle={signoutToggle}
                  title={"Already Signed In"}
                >
                  <div className="modal-card">
                    <p>Already Signed in please sign out to continue</p>
                  </div>
                </FixedModal>
              ) : (
                <>
                  {userHasExpiredInvite ? (
                    <ExpiredInvite setExpiredInvite={setUserHasExpiredInvite} />
                  ) : hasUnitAuthorized ? (
                    !!apiStatus?.apiReady && (
                      <>
                        <ProductFruits
                          workspaceCode="ZnLckJiXN6YDrnV7"
                          language="en"
                          user={userInfo}
                        />
                        <Outlet />
                        <ChatWidgetController />
                      </>
                    )
                  ) : (
                    <Authorization
                      isAuthorized={hasUnitAuthorized}
                      setIsAuthorized={setHasUnitAuthorized}
                      setIsLoading={setIsLoading}
                      user={{
                        id: user._session!.userId!,
                        name: user.getName(),
                      }}
                    />
                  )}
                </>
              )}
            </>
          )}
        </main>
        <Footer className="left-0 right-0 pb-24 px-1 lg:pb-[35px] md:pb-10 md:ml-[90px] lg:ml-[290px]" />
      </div>
    );
  };
  return (
    <div className="absolute w-full h-full">
      <ToastContext.Provider value={toast}>
        {userHasApplicationInProgress && (
          <UserContext.Provider value={user}>
            <ApplicationForm />
          </UserContext.Provider>
        )}
        {!userHasApplicationInProgress && (
          <Providers
            display={display}
            user={user}
            program={program}
            group={group}
          >
            {parentSignup ? (
              <DisplaySignupFlow setIsLoading={setIsLoading} />
            ) : parentHasInviteInProgress ? (
              <DisplayInviteFlow setIsLoading={setIsLoading} />
            ) : (
              <>{renderMain()}</>
            )}
          </Providers>
        )}
      </ToastContext.Provider>
    </div>
  );
}
const AppLoading = () => {
  const [textEnabled, setTextEnabled] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setTextEnabled(true);
    }, 10000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="w-full h-screen">
      <div className="flex justify-center items-center h-full my-auto">
        <div>
          <SnapLoader />
          {textEnabled && (
            <h1 className="text-2xl mt-10" id="snap-right-path">
              Thank you for your patience while we retrieve your information...
            </h1>
          )}
        </div>
      </div>
    </div>
  );
};
export default App;
