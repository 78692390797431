import { useContext, useEffect, useState } from "react";
import ScrollableModal from "shared-components/modal/scrollable-modal";
import { GroupRosterChecked } from "types/group-roster";
import PreviewData from "./preview-data";
import { SnapButton, SnapIcon, SnapInput, SnapLink } from "suit";
import { useSpendRostersFilteredLazyQuery } from "graphql/generated";
import { GroupRosterValidator } from "./helper";
import { collectionData } from "types/programs";
import useModal from "hooks/use-modal";
import DisplayContext from "context/display-context";
import GroupContext from "context/group-context";
import ToastContext from "context/toast-context";
import { BtnState } from "shared-components/modal";
import SeasonContext from "context/season-context";
import { useMutation } from "@apollo/client";
import { CREATE_GROUP_ROSTERS } from "graphql/mutations/group";
import AddParticipantTable from "./table";
import AddParticipantCard from "./card";
import CsvUploader from "./csvUploader";
import SplitIOContext from "context/splitio-context";

type AddParticipantType = {
  isAddParticipantOpen: boolean;
  toggleAddParticipant: () => void;
  seasonRoster: collectionData[];
};

export type PreviewDataType = {
  name: string;
  email: string;
};

function AddParticipant({
  isAddParticipantOpen,
  toggleAddParticipant,
  seasonRoster,
}: AddParticipantType) {
  const display = useContext(DisplayContext);
  const group = useContext(GroupContext);
  const season = useContext(SeasonContext);
  const toast = useContext(ToastContext);
  const split = useContext(SplitIOContext);

  const [se2145, setSe2145] = useState(false);

  useEffect(() => {
    const splitRes = split?.isTreatmentOn(split.act.se2145) || false;
    setSe2145(splitRes);
  }, [split]);

  const { isOpen: isSelectFromExistingOpen, toggle: toggleSelectFromExisting } =
    useModal();
  const { isOpen: isBulkUploadFormOpen, toggle: toggleBulkUploadForm } =
    useModal();
  const { isOpen: isCsvUploadFormOpen, toggle: toggleCsvUploadForm } =
    useModal();

  const [buttonState, setButtonState] = useState<BtnState>(BtnState.BASE);
  const [participantBatchValues, setParticipantBatchValues] = useState("");
  const [participantCSVValues, setParticipantCSVValues] = useState<
    PreviewDataType[]
  >([]);
  const [existingSearchText, setExistingSearchText] = useState("");
  const [previewData, setPreviewData] = useState<PreviewDataType[]>([]);
  const [participantDataError, setParticipantDataError] = useState<
    string | undefined
  >();
  const [participantData, setParticipantData] = useState({
    name: "",
    email: "",
  });
  const [rosterSelectable, setRosterSelectable] = useState<
    GroupRosterChecked[]
  >([]);

  const [rostersQuery, { data, loading }] = useSpendRostersFilteredLazyQuery(
    {}
  );

  const [createGroupRosters, { data: grMutateData, loading: grMutateLoading }] =
    useMutation(CREATE_GROUP_ROSTERS, {
      refetchQueries: ["spendGroupRostersBySeasonV2"],
    });

  useEffect(() => {
    if (!grMutateLoading && grMutateData) {
      toast &&
        toast.setToastProps({
          message: "Successfully added participant(s)",
          type: "success",
        });
      toast && toast.toggleToast();
      setButtonState(BtnState.BASE);
      toggleAddParticipant();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grMutateData, grMutateLoading]);

  useEffect(() => {
    if (!loading && data?.spendRostersFiltered) {
      if (data.spendRostersFiltered.rosters) {
        const dataList = data.spendRostersFiltered.rosters
          .filter((r) => r != null)
          .filter((r) =>
            r!.groupRosters?.every(
              (gr) => gr && gr.groupId !== group?.activeGroup?.id
            )
          );

        setRosterSelectable(
          dataList.map((r) => ({
            roster: {
              name: r!.name,
              id: r!.id,
              email: r!.email,
            },
            group: {
              name: r!.groupRosters?.at(0)?.group?.name || "n/a",
            },
            isChecked: false,
          }))
        );
      }
    }
  }, [group?.activeGroup?.id, data, loading]);

  const validateFormData = () => {
    const errors = GroupRosterValidator.validate(participantData);
    if (
      previewData.some(
        (data) =>
          data.email?.toLowerCase() === participantData.email.toLowerCase() &&
          data.name?.toLowerCase() === participantData.name.toLowerCase()
      )
    ) {
      errors.push("User already added to the preview");
    }
    if (
      seasonRoster.find(
        (roster) =>
          roster.email?.toLowerCase() === participantData.email.toLowerCase() &&
          roster.title.toLowerCase() === participantData.name.toLowerCase()
      )
    ) {
      errors.push("User already added to roster");
    }
    if (errors.length === 0) {
      setParticipantDataError(undefined);
      return true;
    }
    setParticipantDataError(errors.join(" "));
    return false;
  };

  const handleAddParticipant = () => {
    if (validateFormData()) {
      setParticipantData({
        name: "",
        email: "",
      });
      setPreviewData([
        ...previewData,
        {
          name: participantData.name,
          email: participantData.email,
        },
      ]);
    }
  };

  const onGroupRosterSelect = (index: number) => {
    const selected = rosterSelectable.at(index);
    if (selected) {
      selected.isChecked = !selected?.isChecked;
      rosterSelectable.splice(index, 1, selected);
      setRosterSelectable([...rosterSelectable]);
    }
  };

  const addPreviewData = (newData: PreviewDataType[]) => {
    const makeIdent = (roster?: PreviewDataType) => {
      return `${roster?.name}${roster?.email}`;
    };
    const existingNameAndEmails = new Set(previewData.map((d) => makeIdent(d)));
    const newPreviewData = newData.reduce((acc, d) => {
      if (!d) return acc;
      const errors = GroupRosterValidator.validate(d);
      if (!errors.length && !existingNameAndEmails.has(makeIdent(d))) {
        existingNameAndEmails.add(makeIdent(d));
        acc.push(d);
      } else {
        // TODO: throw error or display errors;
        console.log(errors);
      }
      return acc;
    }, [] as PreviewDataType[]);

    setPreviewData([...previewData, ...newPreviewData]);
    return newPreviewData?.length;
  };

  const handleExistingSearch = () => {
    if (existingSearchText === "") {
      rostersQuery();
    } else if (existingSearchText.length < 3) {
      toast?.setToast({
        message: "Minimum 3 letters in search text",
        type: "danger",
      });
    } else {
      rostersQuery({
        variables: {
          where: {
            nameIncludes: existingSearchText,
          },
        },
      });
    }
  };

  const batchTextValueToGroupRoster = (batchText: string) => {
    const rows = batchText.split("\n");
    const reducedRows = rows.reduce((acc, row) => {
      if (!row.trim()) return acc; // skip empty row
      const [name, email] = row.trim().split(/[\t]/);
      const rosterGroupInput = {
        name: name ? name.trim() : "",
        email: email ? email.trim() : "",
      };

      const errors = GroupRosterValidator.validate(rosterGroupInput);
      if (!errors.length) {
        acc.push({
          roster: rosterGroupInput,
          isChecked: false,
        });
      }
      return acc;
    }, [] as GroupRosterChecked[]);
    return reducedRows;
  };

  const handleSubmit = () => {
    setButtonState(BtnState.BASE);
    if (previewData?.length === 0) {
      toast &&
        toast.setToastProps({
          message: "No participants added",
          type: "warning",
        });
      toast && toast.toggleToast();
      return;
    }

    const input = {
      groupId: group?.activeGroup?.id,
      seasonId: season?.selectedSeason?.id,
      rosters: previewData.map((data) => {
        return {
          email: data.email.toLowerCase(),
          name: data.name,
        };
      }),
    };
    if (input.groupId && input.seasonId) {
      setButtonState(BtnState.DISABLED);
      createGroupRosters({ variables: { input } });
    } else {
      toast &&
        toast.setToastProps({
          message: "Something went wrong, please refresh the page.",
          type: "danger",
        });
      setButtonState(BtnState.BASE);
      toast && toast.toggleToast();
    }
  };

  return (
    <ScrollableModal
      isOpen={isAddParticipantOpen}
      toggle={() => {
        toggleAddParticipant();
        setButtonState(BtnState.BASE);
      }}
      title="Add Participant to Group"
      btn1={{
        text: "Submit",
        onClick: handleSubmit,
        btnState: buttonState,
      }}
      btn2={{
        text: "Cancel",
        onClick: toggleAddParticipant,
      }}
    >
      <div className="modal-card">
        {previewData.length > 0 && (
          <PreviewData
            previewData={previewData}
            setPreviewData={setPreviewData}
          />
        )}
        <p className="text-base font-medium">
          Enter participant name and parent email address below.
        </p>
        <div className="lg:grid grid-cols-3 mt-4">
          <SnapInput
            _id={"participant_name_input"}
            className="mr-6"
            label="Participant Name"
            value={participantData.name || ""}
            onBlur={(e) => {
              setParticipantData({ ...participantData, name: e.target.value });
            }}
          />

          <SnapInput
            _id={"parent_email_input"}
            className="mr-6"
            label="Parent Email"
            value={participantData.email || ""}
            onBlur={(e) => {
              setParticipantData({ ...participantData, email: e.target.value });
            }}
          />
          <SnapButton
            variant="primary"
            className={"lg:mt-6 mt-4 lg:w-[70%]"}
            fullWidth
            buttonType={"submit"}
            onClick={handleAddParticipant}
          >
            Add a New Participant
          </SnapButton>
        </div>
        {participantDataError && (
          <p className="text-base font-medium text-red-500 mt-2">
            {participantDataError}
          </p>
        )}
        <div className="border border-gray-200 mt-4 rounded-xl px-4">
          <button
            tabIndex={-1}
            className="flex relative hover:bg-gray-50 rounded-2xl -mx-4 p-4"
            style={{ width: "calc(100% + 32px)" }}
            onClick={toggleSelectFromExisting}
          >
            <span className="mr-auto text-lg font-medium pr-8">
              Select from Existing
            </span>
            <SnapIcon
              icon={isSelectFromExistingOpen ? "minus-solid" : "plus-solid"}
              className="self-center absolute right-4"
              size="md"
              color="#64748B"
            />
          </button>
          {isSelectFromExistingOpen && (
            <>
              <div className="flex mt-4">
                <SnapInput
                  _id="input-with-button"
                  _type="text"
                  input-btn="Search"
                  input-btn-variant="secondary"
                  className="lg:w-[40%] w-full"
                  onBlur={(e) => {
                    setExistingSearchText(e.target.value);
                  }}
                  onInput-btn-click={(_) => handleExistingSearch()}
                />
              </div>
              {display?.isDesktop ? (
                <AddParticipantTable
                  list={rosterSelectable}
                  isLoading={loading}
                  onRosterSelect={onGroupRosterSelect}
                />
              ) : (
                <AddParticipantCard
                  list={rosterSelectable}
                  isLoading={loading}
                  onRosterSelect={onGroupRosterSelect}
                />
              )}
              <div className="flex justify-end mb-4">
                <SnapButton
                  variant="primary"
                  onClick={() => {
                    const checkedRosterList = rosterSelectable.filter(
                      (x) => x.isChecked
                    );
                    addPreviewData(
                      checkedRosterList.map((data) => {
                        return {
                          name: data.roster?.name ?? "",
                          email: data.roster?.email ?? "",
                        };
                      })
                    );
                  }}
                >
                  Add To List
                </SnapButton>
              </div>
            </>
          )}
        </div>
        <div className="border border-gray-200 mt-4 rounded-xl px-4">
          <button
            tabIndex={-1}
            className="flex relative hover:bg-gray-50 rounded-2xl -mx-4 p-4"
            style={{ width: "calc(100% + 32px)" }}
            onClick={toggleBulkUploadForm}
          >
            <span className="mr-auto text-lg font-medium pr-8">
              Bulk Upload Form
            </span>
            <SnapIcon
              icon={isBulkUploadFormOpen ? "minus-solid" : "plus-solid"}
              className="self-center absolute right-4"
              size="md"
              color="#64748B"
            />
          </button>
          {isBulkUploadFormOpen && (
            <>
              <p className="lg:mt-2 mt-4 mb-2 text-base font-medium text-gray-500">
                Copy participant names and email addresses from a spreadsheet
                and paste them here for bulk upload. Participants names should
                be in the first one or two columns and the parent email address
                should be in the last column. There should be no other
                information pasted in here.
              </p>
              <SnapLink
                class="mt-0 mb-2"
                href="https://helpdesk.snapraise.com/support-center/adding-participants-in-bulk"
                sr-only="srOnly"
              >
                Show me how it works!
              </SnapLink>
              <SnapInput
                _id={""}
                textarea
                onSnap-input-change={(e) => {
                  setParticipantBatchValues(e.detail.target.value);
                }}
              />
              <div className="flex justify-end my-4">
                <SnapButton
                  variant="primary"
                  value={participantBatchValues}
                  onClick={() => {
                    const newGroupRosters = batchTextValueToGroupRoster(
                      participantBatchValues
                    ).map((data) => {
                      return {
                        name: data.roster?.name ?? "",
                        email: data.roster?.email ?? "",
                      };
                    });
                    if (!addPreviewData(newGroupRosters)) {
                      toast?.setToast({
                        message: "No new group roster were added!",
                        type: "danger",
                      });
                    }
                  }}
                >
                  Add To List
                </SnapButton>
              </div>
            </>
          )}
        </div>
        {se2145 && (
          <div className="border border-gray-200 mt-4 rounded-xl px-4">
            <button
              tabIndex={-1}
              className="flex relative hover:bg-gray-50 rounded-2xl -mx-4 p-4"
              style={{ width: "calc(100% + 32px)" }}
              onClick={toggleCsvUploadForm}
            >
              <span className="mr-auto text-lg font-medium pr-8">
                CSV Upload Form
              </span>
              <SnapIcon
                icon={isCsvUploadFormOpen ? "minus-solid" : "plus-solid"}
                className="self-center absolute right-4"
                size="md"
                color="#64748B"
              />
            </button>
            {isCsvUploadFormOpen && (
              <>
                <p className="lg:mt-2 mt-4 mb-2 text-base font-medium text-gray-500">
                  Provide a csv with at least the columns 'first', 'last',
                  'email'.
                </p>
                <CsvUploader setRostersData={setParticipantCSVValues} />
                <div className="flex justify-end my-4">
                  <SnapButton
                    variant="primary"
                    onClick={() => {
                      if (!addPreviewData(participantCSVValues)) {
                        toast?.setToast({
                          message: "No new group roster were added!",
                          type: "danger",
                        });
                      }
                    }}
                  >
                    Add To List
                  </SnapButton>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </ScrollableModal>
  );
}

export default AddParticipant;
