import ProgramContext from "context/program-context";
import SplitIOContext from "context/splitio-context";
import UserContext from "context/user-context";
import {
  ApprovalDetailType,
  ApprovalType,
  useSpendGetApprovalDetailsQuery,
} from "graphql/generated";
import { useContextStrict } from "helpers/context-strict";
import { FormatDate } from "helpers/format-date";
import { FormatMoney } from "helpers/format-money";
import LabelValue from "pages/groups/collections/label-value";
import { useEffect, useState } from "react";
import CustomModal from "shared-components/modal";
import { SpinnerContainer } from "shared-components/spinner";

type DetailsType = {
  isOpen: boolean;
  toggle: () => void;
  selectedApproval: ApprovalType;
  type: "pending" | "history";
  declineToggle?: () => void;
  approveToggle?: () => void;
};

function Details({
  isOpen,
  toggle,
  selectedApproval,
  declineToggle,
  approveToggle,
  type,
}: DetailsType) {
  const user = useContextStrict(UserContext);
  const program = useContextStrict(ProgramContext);
  const split = useContextStrict(SplitIOContext);
  const [approvalDetails, setApprovalDetails] = useState<ApprovalDetailType>();
  const [showFeeMessage, setShowFeeMessage] = useState(false);
  const [percent, setPercent] = useState(0);
  const [userApproved, setUserApproved] = useState(false);
  const { loading: loadingApprovalDetails, data: approvalDetailsData } =
    useSpendGetApprovalDetailsQuery({
      variables: {
        spendGetApprovalDetailsId: selectedApproval.id,
      },
    });
  useEffect(() => {
    const isOn = split.isTreatmentOn(split.act.se2082, {
      organizationId: program.organization!.id,
    });
    setShowFeeMessage(
      isOn &&
        program.organization?.externalTransferFee != null &&
        approvalDetails?.transferType === "External"
    );
    setPercent(
      (program.organization?.externalTransferFee?.percentage || 0) * 100
    );

    setUserApproved(
      (selectedApproval.approverIds ?? []).includes(user._session?.userId!)
    );
    // eslint-disable-next-line
  }, [split, program.organization, approvalDetails, selectedApproval]);
  useEffect(() => {
    if (
      !loadingApprovalDetails &&
      approvalDetailsData?.spendGetApprovalDetails
    ) {
      setApprovalDetails(
        approvalDetailsData.spendGetApprovalDetails.approval ?? undefined
      );
    }
  }, [loadingApprovalDetails, approvalDetailsData]);

  return (
    <CustomModal
      isOpen={isOpen}
      toggle={toggle}
      title={`${selectedApproval?.title.replace("-", " ")} Request`}
      btn1={
        type === "pending" && !userApproved
          ? {
              text: "Approve",
              onClick: approveToggle,
            }
          : { text: "Close", onClick: toggle, btnStyle: "tertiary" }
      }
      btn2={
        type === "pending" && !userApproved
          ? {
              text: "Decline",
              onClick: declineToggle,
            }
          : undefined
      }
    >
      <div className="modal-card lg:w-[70%] lg:mx-auto">
        {loadingApprovalDetails && <SpinnerContainer loading={true} />}
        {!loadingApprovalDetails && approvalDetails && (
          <>
            {/* TRANSFER - Start */}
            {showFeeMessage && (
              <p className="text-sm justify-end text-center">
                Note: External Transfers OUT include the {percent}% fee.
              </p>
            )}
            {approvalDetails.transferType && (
              <LabelValue
                label={"Transfer Type"}
                value={approvalDetails.transferType}
              />
            )}
            {approvalDetails.from && (
              <LabelValue label={"From"} value={approvalDetails.from} />
            )}
            {approvalDetails.to && (
              <LabelValue label={"To"} value={approvalDetails.to} />
            )}
            {/* TRANSFER - END */}
            {/* CARD, SEND CHECK, SEND ACH - Start */}
            {approvalDetails.recipient && (
              <LabelValue
                label={"Recipient"}
                value={approvalDetails.recipient}
              />
            )}
            {approvalDetails.amount && (
              <LabelValue
                label={"Amount"}
                value={FormatMoney(approvalDetails.amount)}
              />
            )}
            {approvalDetails.memo && (
              <LabelValue label={"Memo"} value={approvalDetails.memo} />
            )}
            {approvalDetails.note && (
              <LabelValue label={"Note"} value={approvalDetails.note} />
            )}
            {/* SEND CHECK, SEND ACH - Start */}
            {/* CARD - Start */}
            {approvalDetails.shippingAddress && (
              <div className="flex justify-between">
                <p className="text-sm font-bold">Shipping Address</p>
                <div className="text-sm font-semibold">
                  <p>{approvalDetails.shippingAddress.street}</p>
                  {approvalDetails.shippingAddress.street2 && (
                    <p>{approvalDetails.shippingAddress.street2}</p>
                  )}
                  <p>{`${approvalDetails.shippingAddress.city} ${approvalDetails.shippingAddress.state}, ${approvalDetails.shippingAddress.postalCode}`}</p>
                </div>
              </div>
            )}
            {/* CARD - End */}
            {approvalDetails.group && (
              <LabelValue label={"Group"} value={approvalDetails.group} />
            )}
            <LabelValue
              label={"Submitted By"}
              value={approvalDetails.submittedBy}
            />
            <LabelValue
              label={"Date Submitted"}
              value={FormatDate(approvalDetails.dateSubmitted)}
            />
            {approvalDetails.reason && (
              <LabelValue label={"Reason"} value={approvalDetails.reason} />
            )}
            {type === "pending" && userApproved && (
              <p className="flex ml-auto">
                Waiting on others to approve (
                {selectedApproval.numberOfApprovals}/{" "}
                {selectedApproval.numberOfApprovers})
              </p>
            )}
          </>
        )}
      </div>
    </CustomModal>
  );
}

export default Details;
