type FooterProps = {
  className?: string;
};
function Footer({ className }: FooterProps) {
  return (
    <div
      className={`text-center text-sm text-gray-600 font-medium bg-gray-100 ${className}`}
    >
      <p>
        Snap! Spend is not a bank. Banking services are provided by Thread Bank;
        Member FDIC. Deposits are FDIC-insured through Thread Bank; Member FDIC.
        Your funds are FDIC insured up to $250,000 through Thread Bank; Member
        FDIC. The Snap! Spend Visa® Debit Card is issued by Thread Bank pursuant
        to a license from Visa U.S.A. Inc. and may be used everywhere Visa debit
        cards are accepted.
      </p>
    </div>
  );
}

export default Footer;
